import { createSelector } from 'reselect';
import { contactStateType } from './contact.types';

const getSubState = (state: any) => state.contact as contactStateType;

const getContactInformationItems = createSelector(
    getSubState,
    (state) => state.contactInformationItems,
);
const getContactText = (path: string, currentLanguage?: string) =>
    createSelector(
        getContactInformationItems,
        (items) =>
            items?.find((item) => item.language === currentLanguage && item.path === path)?.text,
    );

export default {
    getContactText,
};
