import React from 'react';
import './Footer.less';
import { useSelector } from 'react-redux';
import { applicationSelectors } from '../../../redux/application';

const Footer = ({}) => {

    const applicationName = useSelector(applicationSelectors.getApplicationName);
    const imprintConfig = useSelector(applicationSelectors.getImprintConfig);
    const privacyConfig = useSelector(applicationSelectors.getPrivacyConfig);

    return (
        <div className='footer static bottom-0 container mx-auto text-center text-xs mt-1 mb-1 text-gray-500'>
            {applicationName}
            {(imprintConfig && imprintConfig.url) &&
            <>&nbsp; | <a href={imprintConfig.url} target={'_blank'}>Impressum</a></>}
            {(privacyConfig && privacyConfig.url) &&
            <>&nbsp; | <a href={privacyConfig.url} target={'_blank'}>Datenschutz</a></>}
        </div>
    );
}

export default Footer;
