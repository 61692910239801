import React, { useEffect, useRef, useState } from 'react';
import './NotificationWrapper.less';
import Icon from '../../../components/envago/Icon/Icon';
import classNames from 'classnames';
import { Transition } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { notificationActions, notificationSelectors } from '../../../redux/notification';

export enum NotificationType {
    INFO,
    WARNING,
    ERROR,
    SUCCESS,
}

const Notification = ({
    title,
    message,
    type = NotificationType.INFO,
    onDispose,
}: {
    title: string;
    message: string;
    type?: NotificationType;
    onDispose: () => void;
}) => {
    const [isOpen, setOpen] = useState(false);

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (!isOpen) {
            setOpen(true);
        }

        if (isOpen) {
            timeout = setTimeout(() => {
                setOpen(false);
                onDispose();
            }, 10000);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [isOpen, onDispose]);

    const handleClose = () => {
        setOpen(false);
        onDispose();
    };

    let color = 'bg-blue-100';
    let colorText = undefined;
    let icon = Icon.Path.mdiInformationOutline;
    switch (type) {
        case NotificationType.INFO:
            color = 'bg-blue-100';
            icon = Icon.Path.mdiInformationOutline;
            break;
        case NotificationType.ERROR:
            color = 'bg-red-100';
            colorText = 'text-red-700';
            icon = Icon.Path.mdiCloseOctagonOutline;
            break;
        case NotificationType.WARNING:
            color = 'bg-yellow-100';
            icon = Icon.Path.mdiAlertCircleOutline;
            break;
        case NotificationType.SUCCESS:
            color = 'bg-green-200';
            icon = Icon.Path.mdiCheck;
            break;
    }

    return (
        <Transition
            show={isOpen}
            appear={true}
            enter="transition transform duration-500"
            enterFrom="-translate-y-full opacity-0"
            enterTo="translate-y-0 opacity-100"
            leave="transition duration-100 transform"
            leaveFrom="translate-y-0 opacity-100"
            leaveTo="-translate-y-full opacity-0"
        >
            <div className={classNames('bg-white notification md:w-96 w-full p-4 rounded shadow-xl flex gap-4')}>
                <div className={'notification--icon-wrapper'}>
                    <div className={classNames(color, 'rounded-full  inline-block p-2')}>
                        <Icon className={'h-6'} path={icon} />
                    </div>
                </div>
                <div>
                    <h2
                        className={classNames('notification--title flex justify-between', colorText, {
                            'text-accent': colorText === undefined,
                        })}
                    >
                        {title}
                        <div onClick={handleClose}>
                            <Icon className={'w-8 text-gray-300 hover:text-gray-200 transition'} path={Icon.Path.mdiClose} />
                        </div>
                    </h2>
                    <div className={'notification--content mt-2 text-gray-500 text-sm'}>
                        <p>{message}</p>
                    </div>
                </div>
            </div>
        </Transition>
    );
};

const NotificationWrapper = () => {
    const dispatch = useDispatch();

    const notifications = useRef<any[]>([]);

    const currentNotification = useSelector(notificationSelectors.getNotification);
    const [, updateState] = React.useState<any>();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        if (currentNotification && currentNotification?.id) {
            notifications.current.push(currentNotification);
            forceUpdate();
        }

        return () => {
            if (currentNotification) {
                dispatch(notificationActions.clearNotification());
            }
        };
    }, [currentNotification, forceUpdate]);

    return (
        <div className="notification-wrapper absolute top-0 right-0 p-2 container z-50">
            <div className={'fixed top-2 p-2 right-0 gap-2 flex flex-col z-50'}>
                {notifications.current.map((notification, index) => (
                    <Notification
                        onDispose={() => {
                            notifications.current.splice(0, 1);
                            forceUpdate();
                        }}
                        type={notification.type}
                        message={notification.message}
                        title={notification.title}
                        key={`index-${index}`}
                    />
                ))}
            </div>
        </div>
    );
};

export default NotificationWrapper;
