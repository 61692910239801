import React, { ReactNode } from 'react';
import './Information.less';
import Icon from '../Icon/Icon';
import classNames from 'classnames';

export enum InformationType {
    INFO,
    WARNING,
    ERROR,
    SUCCESS,
}

const Information = ({
    children,
    className,
    showIcon = true,
    type = InformationType.INFO,
    hidden = false,
}: {
    children: ReactNode;
    showIcon?: boolean;
    className?: string;
    type?: InformationType;
    hidden?: boolean;
}) => {
    if (!children) return null;

    let color = 'bg-blue-100';
    let colorText = '';
    let icon = Icon.Path.mdiInformationOutline;
    switch (type) {
        case InformationType.INFO:
            color = 'bg-blue-100';
            icon = Icon.Path.mdiInformationOutline;
            break;
        case InformationType.ERROR:
            color = 'bg-red-100';
            colorText = 'text-red-900';
            icon = Icon.Path.mdiCloseOctagonOutline;
            break;
        case InformationType.WARNING:
            color = 'bg-yellow-100';
            icon = Icon.Path.mdiAlertCircleOutline;
            break;
        case InformationType.SUCCESS:
            color = 'bg-green-200';
            icon = Icon.Path.mdiCheck;
            break;
    }
    if (hidden) {
        color = 'invisible';
    }

    return (
        <div className={classNames(`information box-type-${type} ${color} p-2 rounded-md align-baseline flex items-start ${colorText}`, className)}>
            {showIcon && (
                <div className={'justify-self-baseline self-baseline mx-2 flex items-center'} style={{ height: '1.25rem' }}>
                    <Icon className={'h-4'} path={icon} />
                </div>
            )}
            <div className={'w-full'}>{children}</div>
        </div>
    );
};

export const InformationDetails = ({ children, title }: { children: ReactNode; title: string }) => {
    return (
        <div className={'flex flex-col'}>
            <div className={'font-medium text-gray-800 -my-1'}>{title}</div>
            <div className={'text-sm my-1 text-gray-600'}>{children}</div>
        </div>
    );
};

export default Information;
