import React from 'react';
import './BackButton.less';
import Button from '../../../components/envago/Button/Button';
import Icon from '../../../components/envago/Icon/Icon';
import { useIsMobile } from '../../../helper/LayoutHelper';
import { useHistory, useLocation } from 'react-router';

const BackButton = ({}) => {
    const isMobile = useIsMobile();
    const location = useLocation();

    const history = useHistory();

    const splitLocation = location.pathname.split('/');
    // console.log("location,", location.pathname.split('/'))

    if(splitLocation.length <= 2)
        return null;

    if(!isMobile)
        return null;
    return (
        <div className='back-button inline-block mr-2'>
            <Button
                primary={true}
                onClick={()=>{
                    history.goBack();
                }}
                small={true} className={'p-2'}><Icon path={Icon.Path.mdiChevronLeft}/>zurück</Button>
        </div>
    );
};

export default BackButton;
