import { MeterReadingHelpType } from './help.types';

export const types = {
    LOAD_METERREADING_INSTRUCTIONS: 'LOAD_METERREADING_INSTRUCTIONS',
    LOAD_METERREADING_INSTRUCTIONS_DONE: 'LOAD_METERREADING_INSTRUCTIONS_DONE',
    LOAD_METERREADING_INSTRUCTIONS_ERROR: 'LOAD_METERREADING_INSTRUCTIONS_ERROR',

    GET_GLOBAL_HELP_LANGUAGES: 'GET_GLOBAL_HELP_LANGUAGES',
    GET_GLOBAL_HELP_LANGUAGES_DONE: 'GET_GLOBAL_HELP_LANGUAGES_DONE',
    GET_GLOBAL_HELP_LANGUAGES_ERROR: 'GET_GLOBAL_HELP_LANGUAGES_ERROR',
};

const loadMeterReadingInstructions = () => ({ type: types.LOAD_METERREADING_INSTRUCTIONS });
const loadMeterReadingInstructionsDone = (data: MeterReadingHelpType) => ({
    type: types.LOAD_METERREADING_INSTRUCTIONS_DONE,
    data,
});
const loadMeterReadingInstructionsError = () => ({
    type: types.LOAD_METERREADING_INSTRUCTIONS_ERROR,
});

const getGlobalHelpLanguages = () => ({ type: types.GET_GLOBAL_HELP_LANGUAGES });
const getGlobalHelpLanguagesDone = (globalHelpEnabled: boolean) => ({
    type: types.GET_GLOBAL_HELP_LANGUAGES_DONE,
    payload: globalHelpEnabled,
});
const getGlobalHelpLanguagesError = () => ({ type: types.GET_GLOBAL_HELP_LANGUAGES_ERROR });

export default {
    loadMeterReadingInstructions,
    loadMeterReadingInstructionsDone,
    loadMeterReadingInstructionsError,
    getGlobalHelpLanguages,
    getGlobalHelpLanguagesDone,
    getGlobalHelpLanguagesError,
};
