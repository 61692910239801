import { AnyAction } from 'redux';
import { ApplicationStateType } from './application.types';
import { applicationTypes } from './index';

const initialState: ApplicationStateType = {
    loaded: false,
    isFocused: document.hasFocus(),
    name: undefined,
    applicationId: undefined,
    config: undefined,
};

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case applicationTypes.LOAD_APPLICATION_DONE: {
            const { payload } = action;
            return {
                ...state,
                loaded: true,
                applicationId: payload.applicationId,
                name: payload.name,
                config: payload.config,
            };
        }
        case applicationTypes.WINDOW_FOCUS:
            return { ...state, isFocused: true };
        case applicationTypes.WINDOW_BLUR:
            return { ...state, isFocused: false };
        default:
            return state;
    }
};

export default reducer;
