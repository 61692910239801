import React from 'react';
import './LoginErrorDialog.less';
import Dialog from '../../../../components/envago/Dialog/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { applicationSelectors } from '../../../../redux/application';
import Button from '../../../../components/envago/Button/Button';
import { push } from 'connected-react-router';
import { Trans, useTranslation } from 'react-i18next';

const LoginErrorDialog = ({}) => {
    const { t, i18n } = useTranslation();

    const applicationName = useSelector(applicationSelectors.getApplicationName);
    const isUnknownUserReadingAllowed = useSelector(applicationSelectors.isUnknownUserReadingAllowed);
    const dispatch = useDispatch();

    return (
        <div className="login-error-dialog">
            <Dialog
                title={t('login.error.dialog.title')}
                content={
                    <>
                        <p>
                            <Trans i18nKey={'login.error.dialog.content'}>
                                Wenn du eine Ablesekarte erhalten hast, so wurden die Daten der Karte eventuell noch nicht übermittelt.
                                <br />
                                <br />
                                Probiere es einfach später nochmal!
                            </Trans>
                        </p>
                        {isUnknownUserReadingAllowed && (
                            <p className={'mt-4'}>
                                {t('login.error.dialog.unknownUserReading')}
                                <Button
                                    onClick={() => {
                                        dispatch(push('/ablesung-ohne-zugang'));
                                    }}
                                    primary={false}
                                    secondary={true}
                                    small={true}
                                    block={true}
                                >
                                    {t('login.error.dialog.buttons.unknownUserReading')}
                                </Button>
                            </p>
                        )}
                    </>
                }
                actions={[
                    <Button
                        primary={true}
                        block={true}
                        onClick={() => {
                            dispatch(push('/'));
                        }}
                    >
                        {t('login.error.dialog.buttons.close')}
                    </Button>,
                ]}
                visible={true}
                description={t('login.error.dialog.description')}
            />
        </div>
    );
};

export default LoginErrorDialog;
