import React, {useState} from 'react';
import './MeterReadingUnit.less';
import {resolveUnitByObis} from "../../../helper/MeterHelper";
import classNames from "classnames";

const MeterReadingUnit = ({obis, className, unit} : {obis?: string, className?: string, unit?: string}) => {

    if(!unit && obis){
        unit = resolveUnitByObis(obis);
    }


    return (
        <div className={classNames('meter-reading-unit', className)}>
            {unit}
        </div>
    );
};

export default MeterReadingUnit;
