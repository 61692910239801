import React from 'react';
import './MeterReadingJobHelp.less';
import MarkdownViewer from '../../../../components/envago/MarkdownViewer/MarkdownViewer';
import { useSelector } from 'react-redux';
import { helpSelectors } from '../../../../redux/help';
import ContactInformation from '../../../../components/envago/ContactInformation/ContactInformation';
import { METER_TYPES } from '../../../../helper/MeterHelper';

type Props = {
    obis?: string;
    meterNumber?: string;
    numberOfMeterRates: number;
    meterType?: METER_TYPES;
};

const MeterReadingJobHelp = ({ obis, meterNumber, numberOfMeterRates, meterType }: Props) => {



    const instructions = useSelector(
        helpSelectors.getInstructionsForMeter(numberOfMeterRates, meterNumber, obis, meterType),
    );

    const text =
        instructions?.reduce((res, instruction) => res + '\n\n' + instruction.markdown, '') || '';

    if(!obis && !meterType)
        return null;

    return (
        <div className="px-4 meter-reading-job-help flex flex-col max-w-full">
            <div className={''}>
                <MarkdownViewer className={''} text={text} />
                <ContactInformation
                    path={'jobs.jobHelp'}
                />
            </div>
        </div>
    );
};

export default MeterReadingJobHelp;
