import { ReactChild, ReactChildren, ReactNode } from 'react';
import { useIsMobile, useWindowDimensions } from '../../helper/LayoutHelper';
import './SideBarLayout.less'

const SideBarLayout = ({ children, sidebar }: { children: ReactNode, sidebar: ReactNode }) => {

    let isMobile = useIsMobile();


    if(isMobile){
        return <div className={'side-mobile flex w-screen flex-1'}>
            {sidebar}
        </div>;
    }

    return <div className={'side-wrapper flex flex-1 '}>

        {/*<div*/}
        {/*    className={'relative flex w-min md:w-max pt-2 lg:w-4/12 lg:max-w-md pr-2 mr-2 md:max-w-xs sm:flex hidden overflow-y-auto'}*/}
        {/*    style={{*/}
        {/*        maxHeight: 'calc(100vh - 100px)',*/}
        {/*    }}>*/}
        {/*    <div className={'fixed'}>*/}
        {/*        <div className={'flex-1'} style={{*/}

        {/*            overflow: 'auto',*/}
        {/*        }}>*/}
        {/*            */}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}

        <div className={'sidebar fixed-wrapper fixed overflow-y-auto overflow-x-hidden flex'}
            style={{
                maxHeight: 'calc(100% - 90px)',

            }}
        >

                {sidebar}

        </div>
        <div className={'sidebar-main flex-1 '} style={{

        }}>
            {children}
            {/*<h1 className={'h-36'}>dummy content</h1><h1 className={'h-36'}>dummy content</h1><h1*/}
            {/*className={'h-36'}>dummy content</h1><h1 className={'h-36'}>dummy content</h1><h1 className={'h-36'}>dummy*/}
            {/*content</h1>*/}
            {/*<h1 className={'h-36'}>dummy content</h1><h1 className={'h-36'}>dummy content</h1><h1*/}
            {/*className={'h-36'}>dummy content</h1><h1 className={'h-36'}>dummy content</h1>*/}
        </div>

    </div>;
};

export default SideBarLayout;
