import React, { Fragment, ReactNode, useEffect, useRef } from 'react';
import './Dialog.less';
import { Dialog as HeadlessDialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import useEscape from '../../../helper/Hooks';

interface DialogTypes {
    title?: string;
    description?: string | ReactNode;
    content?: ReactNode | string;
    actions?: ReactNode[];
    visible: boolean;
    children?: ReactNode | string;
    classNameContent?: string;
    closable?: boolean;
    onClose?: (opened: boolean) => void;
    isStatic?: boolean;
}

const Dialog = ({ title, description, content, actions, visible, children, classNameContent, closable, onClose, isStatic = true }: DialogTypes) => {
    useEscape(() => {
        if (onClose) onClose(false);
    });

    useEffect(() => {
        return () => {
            setTimeout(() => {
                let portal = document.getElementById('headlessui-portal-root');

                if (!portal) {
                    document.documentElement.removeAttribute('style');
                }
            }, 500);
        };
    }, [visible]);

    let completeButtonRef = useRef(null);

    const buttonExists = actions ? actions?.length > 0 : false;

    if (!actions || actions.length === 0) {
        actions = [<button key={'default-button'} className={'opacity-0 absolute bottom-0'}></button>];
    }

    return (
        <Transition as={Fragment} show={visible}>
            <HeadlessDialog
                static={true}
                initialFocus={completeButtonRef}
                open={visible}
                className="dialog"
                onClose={() => {
                    // setIsOpen(false)
                }}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-500"
                    enterFrom="opacity-0 scale-80"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-80"
                >
                    <div className={'dialog--wrapper'}>
                        <HeadlessDialog.Overlay className={'dialog--overlay'} />
                        <div className={classNames('dialog--content', classNameContent)}>
                            {closable && (
                                <button
                                    className={'absolute right-2 top-2 focus:outline-none cursor-pointer'}
                                    onClick={() => {
                                        if (onClose) {
                                            onClose(false);
                                        }
                                    }}
                                >
                                    <Icon path={Icon.Path.mdiClose} className={'w-8 text-gray-300 hover:text-gray-200 transition'} />
                                </button>
                            )}

                            {title && <HeadlessDialog.Title className={'dialog--title'}>{title || ''}</HeadlessDialog.Title>}

                            <div className={'dialog--body flex flex-1 flex-col'}>
                                <HeadlessDialog.Description className={'dialog--description'}>{description}</HeadlessDialog.Description>

                                <div className={'dialog--text overflow-y-auto flex-1'}>{children || content}</div>
                            </div>

                            <div className={'flex-0'} />
                            {actions && actions.length > 0 && (
                                <div className={classNames('dialog--actions', { hidden: !buttonExists, flex: buttonExists })}>{actions}</div>
                            )}

                            <button ref={completeButtonRef} className={'hidden'}></button>
                        </div>
                    </div>
                </Transition.Child>
            </HeadlessDialog>
        </Transition>
    );
};

export default Dialog;
