import React, { useEffect, useState } from 'react';
import './QRLogin.less';
import Dialog from '../../../../components/envago/Dialog/Dialog';
import QrReader from 'react-qr-reader';
import Button from '../../../../components/envago/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { loginActions, loginSelectors } from '../../../../redux/login';
import Loading from '../../../../components/common/Loading/Loading';
import { isIOS, isSafari, isDesktop } from 'react-device-detect';
import Information, {
    InformationType,
} from '../../../../components/envago/Information/Information';
import Icon from '../../../../components/envago/Icon/Icon';
import { Trans, useTranslation } from 'react-i18next';

const hasWebcam = () =>
    new Promise((resolve, reject) => {
        console.log('isSafari?', isSafari, 'isIos?', isIOS);
        if (isIOS && !isSafari) return resolve(false);
        // @ts-ignore-start
        navigator.getMedia =
            (navigator as any).getUserMedia || // use the proper vendor prefix
            // @ts-ignore
            navigator.webkitGetUserMedia ||
            // @ts-ignore
            navigator.mozGetUserMedia ||
            // @ts-ignore
            navigator.msGetUserMedia;
        // @ts-ignore
        navigator.getMedia(
            { video: true },
            function () {
                resolve(true);
            },
            function () {
                resolve(false);
            },
        );
        // @ts-ignore-end
    });

const QRLogin = ({}) => {
    const { t, i18n } = useTranslation();

    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();
    const [webcamAvailable, setWebcamAvailable] = useState(false);
    const isLoading = useSelector(loginSelectors.isLoading);

    const [webcamError, setWebcamError] = useState(false);

    const [errorDialogVisible, setErrorDialogVisible] = useState(false);

    const checkWebcam = () => {
        hasWebcam()
            .then((res) => {
                if (res === true) {
                    setWebcamAvailable(true);
                    setVisible(true);
                } else {
                    setWebcamError(true);
                    setErrorDialogVisible(true);
                }
            })
            .catch((e) => {
                setWebcamError(true);
                setErrorDialogVisible(true);
            });
    };

    // if (!webcamAvailable)
    //     return null;

    if (isIOS && !isSafari) return null;

    return (
        <>
            <Dialog
                visible={errorDialogVisible}
                onClose={setErrorDialogVisible}
                closable={true}
                actions={[
                    <Button
                        key={'dialog-close-button'}
                        onClick={() => {
                            setErrorDialogVisible(false);
                        }}
                        primary={true}
                    >
                        {t('login.qrcode.buttons.close')}
                    </Button>,
                ]}
                title={t('login.qrcode.error.access.dialogtitle')}
            >
                <p>
                    <b>{t('login.qrcode.error.access.title')}</b>
                    <br />
                    {t('login.qrcode.error.access.text')}
                </p>
            </Dialog>
            {webcamError && <p className={'mt-4'}>{t('login.qrcode.error.device.title')}</p>}
            {!webcamError && (
                <div className="qrlogin mt-4">
                    <p>
                        <Trans i18nKey={'login.qrcode.teaser.text'}>
                            Du hast einen QR-Code auf einer Ablesekarte oder in einem Brief
                            erhalten? Und du hast eine Webcam?
                            <br />
                            Scanne jetzt einfach den QR-Code um schnell und einfach angemeldet zu
                            werden:
                        </Trans>
                    </p>
                    <Button
                        className={'mt-4'}
                        block={true}
                        onClick={() => {
                            checkWebcam();
                        }}
                    >
                        <>
                            <Icon path={Icon.Path.mdiQrcodeScan} className={'h-6 mr-2'} />
                            {t('login.qrcode.buttons.login')}
                        </>
                    </Button>

                    <small>{t('login.qrcode.teaser.access')}</small>

                    <Dialog
                        title={'Anmeldung mit QR-Code'}
                        content={
                            <div>
                                {t('login.qrcode.dialog.instruction')}
                                {!webcamError && (
                                    <div className={'w-full flex justify-center mt-4'}>
                                        {!isLoading ? (
                                            <QrReader
                                                className={'w-3/6'}
                                                delay={200}
                                                facingMode={'environment'}
                                                onScan={(scan) => {
                                                    if (scan) {
                                                        let match =
                                                            scan.match(/^https:\/\/.*\/m\/(\w*)$/);
                                                        if (match) {
                                                            if (!isLoading) {
                                                                dispatch(
                                                                    loginActions.loginWithToken(
                                                                        match[1],
                                                                    ),
                                                                );
                                                            }
                                                        }
                                                    }
                                                }}
                                                onError={(e) => {
                                                    console.log(e);
                                                    setWebcamError(true);
                                                }}
                                            />
                                        ) : (
                                            <Loading text={'Anmeldung wird geprüft...'} />
                                        )}
                                    </div>
                                )}

                                {webcamError && (
                                    <Information type={InformationType.ERROR} className={'mt-4'}>
                                        <p>
                                            {t('login.qrcode.error.access.title')}
                                            <br />
                                            {t('login.qrcode.error.access.text')}
                                        </p>
                                    </Information>
                                )}
                            </div>
                        }
                        actions={[
                            <Button
                                key={'1'}
                                block={true}
                                onClick={() => {
                                    setVisible(false);
                                }}
                            >
                                {t('login.qrcode.buttons.close')}
                            </Button>,
                        ]}
                        visible={visible}
                    />
                </div>
            )}
        </>
    );
};

export default QRLogin;
