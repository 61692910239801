import LabeledList from '../../../../components/envago/LabeledList/LabeledList';
import OptionalLabel from '../../../../components/envago/OptionalLabel/OptionalLabel';
import Input from '../../../../components/envago/Input/Input';
import React, { useEffect, useState } from 'react';
import Button from '../../../../components/envago/Button/Button';
import Information, {
    InformationType,
} from '../../../../components/envago/Information/Information';
import { isIOS, isSafari } from 'react-device-detect';
import QRLogin from '../QRLogin/QRLogin';
import { useDispatch, useSelector } from 'react-redux';
import { loginActions, loginSelectors } from '../../../../redux/login';
import { applicationSelectors } from '../../../../redux/application';
import { useTranslation } from 'react-i18next';

const Login = () => {
    const { t } = useTranslation();

    const loginPasswordMinLength = useSelector(applicationSelectors.loginPasswordMinLength);
    const loginUsernameMinLength = useSelector(applicationSelectors.loginUsernameMinLength);
    const isLoginPasswordInputAsCleartextEnabled = useSelector(
        applicationSelectors.isLoginPasswordInputAsCleartextEnabled,
    );

    const hasError = useSelector(loginSelectors.hasError);
    const isLoading = useSelector(loginSelectors.isLoading);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        setLoginDisabled(
            !(
                username.length >= loginUsernameMinLength &&
                password.length >= loginPasswordMinLength
            ),
        );
    }, [username, password, loginPasswordMinLength, loginUsernameMinLength]);

    const [loginDisabled, setLoginDisabled] = useState(true);

    const dispatch = useDispatch();
    const login = () => {
        dispatch(loginActions.loginWithUsername(username, password));
        setPassword('');
    };

    return (
        <>
            <p className={'mt-4'}>{t('login.subtitle')}</p>
            <LabeledList className={'mt-4'}>
                <OptionalLabel label={t('login.labels.username')}>
                    <Input
                        className={'w-full bg-gray-100'}
                        value={username}
                        onChange={(value) => setUsername(value)}
                        placeholder={t('login.placeholders.username')}
                    />
                </OptionalLabel>
                <OptionalLabel label={t('login.labels.password')}>
                    <Input
                        className={'w-full bg-gray-100 mt-1'}
                        value={password}
                        onChange={(value) => setPassword(value)}
                        placeholder={t('login.placeholders.password')}
                        type={isLoginPasswordInputAsCleartextEnabled ? 'text' : 'password'}
                        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                            event.stopPropagation();
                            if (event.key === 'Enter' && !loginDisabled) login();
                        }}
                    />
                </OptionalLabel>
                <OptionalLabel label={undefined}>
                    <Button
                        primary={true}
                        isLoading={isLoading}
                        disabled={loginDisabled || isLoading}
                        onClick={login}
                        block={true}
                    >
                        {t('login.buttons.login')}
                    </Button>
                </OptionalLabel>

                <OptionalLabel className={'mb-2'}>
                    {hasError && (
                        <Information type={InformationType.ERROR}>
                            {t('login.error.login.text')}
                        </Information>
                    )}
                    {!hasError && <div style={{ height: 40 }}>&nbsp;</div>}
                </OptionalLabel>
            </LabeledList>

            {!(isIOS && !isSafari) && (
                <>
                    <hr />
                    <LabeledList>
                        <OptionalLabel label={t('login.labels.qr-login')}>
                            <QRLogin />
                        </OptionalLabel>
                    </LabeledList>
                </>
            )}
        </>
    );
};

export default Login;
