import { call, delay, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { jobsActions, jobsSelectors, jobsTypes } from './index';
import axios, { AxiosError } from 'axios';
import { loginSelectors, loginTypes } from '../login';
import { JobType, MeterRateTaskType } from './jobs.types';
import { normalize } from 'normalizr';
import { jobListSchema } from '../store.schema';
import { getToken } from '../login/login.saga';
import { notificationActions } from '../notification';
import { offlineActions, offlineSelectors } from '../offline';
import { MeterReadingTaskResultAction } from './jobs.actions';
import { NotificationType } from '../../screens/MainScreen/NotificationWrapper/NotificationWrapper';
import { communicationActions } from '../communication';
import { LoginType } from '../login/login.types';

function* loadJobs() {
    const logins: Array<[string, LoginType]> = yield select(loginSelectors.getLogins);
    const jobs: JobType[] = yield select(jobsSelectors.getJobs);

    if (logins?.length === 0) {
        // Remove all jobs if no logins exist
        yield put(jobsActions.removeJobsByIds(jobs?.map((j) => j.id)));

        yield put(jobsActions.loadJobsSetLoading(false));
        return;
    }

    const isBlocked: boolean = yield select(jobsSelectors.isLoadingBlocked);

    if (isBlocked) {
        return;
    }

    yield put(jobsActions.loadJobsSetLoading(true));

    const loginIds = logins?.map(([loginId]) => loginId);

    // Refresh jobs data for all logins.
    for (const loginId of loginIds) {
        try {
            const token: string = yield call(getToken, loginId);
            const { data } = yield axios.get('/api/pro/job', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const normalizedJobsDataForLogin = normalize(
                data.jobs.map((job: JobType) => enrichJob(loginId, job)),
                jobListSchema,
            );

            // Remove outdated jobs, that are not part of the response (and belong to the same account id).
            const refreshedJobIdsForLogin = normalizedJobsDataForLogin.result;
            const existingJobsForLogin: JobType[] = yield select(jobsSelectors.getJobsByAccountId(loginId));

            const outdatedJobsIdsForLogin = existingJobsForLogin.map((j) => j.id).filter((id) => !refreshedJobIdsForLogin.includes(id));

            if (outdatedJobsIdsForLogin.length > 0) {
                yield put(jobsActions.removeJobsByIds(outdatedJobsIdsForLogin));
            }

            yield put(jobsActions.addEntities(normalizedJobsDataForLogin));
        } catch (err) {
            const isOnline: boolean = yield select(offlineSelectors.isOnline);

            if (isOnline) {
                const error: AxiosError = err;

                if (error.response?.status === 401) {
                    console.log('Account expired');
                } else {
                    yield put(notificationActions.showErrorNotification('Aktualisierung fehlgeschlagen', 'Aufträge konnten nicht abgerufen werden'));
                }
            }
        }
    }

    const orphanedJobs = jobs.filter((job) => !loginIds.includes(job.login));

    // Remove all jobs which do not belong to any existing login.
    if (orphanedJobs.length > 0) {
        yield put(jobsActions.removeJobsByIds(orphanedJobs.map((job) => job.id)));
    }

    yield delay(1000);
    yield put(jobsActions.loadJobsSetLoading(false));
}

const enrichJob = (loginId: string, job?: JobType) => {
    if (!job) return undefined;

    return {
        ...job,
        login: loginId,
        sameTargetReadingDates: !!job.meter.meterRateTasks.reduce((res: any, mr: MeterRateTaskType) => {
            if (res === undefined) {
                return mr.targetReadingDate;
            }
            if (res === mr.targetReadingDate) return res;
            return false;
        }, undefined),
    };
};

function* sendTaskResults(action: MeterReadingTaskResultAction) {
    const job: JobType = yield select(jobsSelectors.getJobById(action.jobId));
    const token: string = yield getToken(job.login);

    try {
        const {
            data,
        }: {
            data: JobType;
        } = yield axios.post(
            `/api/pro/job/${job.id}/meterRateTask/result`,
            {
                results: action.taskResults,
                readingDate: action.readingDate,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );

        yield put(jobsActions.sendMeterTaskResultsDone(action.jobId, enrichJob(job.login, data)));
        yield put(notificationActions.scrollToTop());
        yield put(notificationActions.showNotification('Senden erfolgreich', 'Die Ergebnisse wurden erfolgreich gesendet.', NotificationType.SUCCESS));

        yield put(communicationActions.unblockSendCRMReadingReceiptRequest());
    } catch (err) {
        yield put(notificationActions.scrollToTop());
        const isOnline: boolean = yield select(offlineSelectors.isOnline);
        if (!isOnline || !err.status) {
            yield put(offlineActions.queueAction(action));
        } else {
            yield put(
                notificationActions.showErrorNotification(
                    'Senden fehlgeschlagen',
                    'Die Ergebnisse konnten nicht gesendet werden. Bitte versuche es später erneut.',
                ),
            );
        }
    }
}

function* removeJobsForAccount({ accountId }: { accountId: string; type: string }) {
    let jobIds: string[] = yield select(jobsSelectors.getJobIdsByAccountId(accountId));
    yield put(jobsActions.removeJobsByIds(jobIds));
}

function* fetchJobsAfterAccountAdded() {
    yield put(jobsActions.loadJobsSetLoading(false));
    yield put(jobsActions.loadJobs());
}

export default function* jobsSaga() {
    yield takeEvery(jobsTypes.LOAD_JOBS, loadJobs);
    yield takeLatest(loginTypes.ADD_ACCOUNT, fetchJobsAfterAccountAdded);
    yield takeEvery(jobsTypes.SEND_METER_TASK_RESULTS, sendTaskResults);

    yield takeEvery(loginTypes.REMOVE_ACCOUNT, removeJobsForAccount);

    // unblock after reload
    yield put(jobsActions.loadJobsSetLoading(false));

    let numberOfLogins: number = yield select(loginSelectors.getLoginCount);
    if (numberOfLogins > 0) {
        yield put(jobsActions.loadJobs());
    }
}
