import {
    SharedAccountType,
    ShareJobsRequestType,
    ShareJobsResultType,
    SendSubAccountEmailType,
} from './shares.types';
import { Action } from 'redux';

export const types = {
    LOAD_ALL_SUBACCOUNTS: 'LOAD_ALL_SUBACCOUNTS',
    LOAD_SUBACCOUNT: 'LOAD_SUBACCOUNT',
    LOAD_SUBACCOUNT_DONE: 'LOAD_SUBACCOUNT_DONE',
    CREATE_SUBACCOUNT: 'CREATE_SUBACCOUNT',
    CREATE_SUBACCOUNT_DONE: 'CREATE_SUBACCOUNT_DONE',
    CREATE_SUBACCOUNT_ERROR: 'CREATE_SUBACCOUNT_ERROR',
    CREATE_SUBACCOUNT_RESET: 'CREATE_SUBACCOUNT_RESET',

    SEND_SUBACCOUNT_EMAIL: 'SEND_SUBACCOUNT_EMAIL',
    SEND_SUBACCOUNT_EMAIL_DONE: 'SEND_SUBACCOUNT_EMAIL_DONE',
    SEND_SUBACCOUNT_EMAIL_ERROR: 'SEND_SUBACCOUNT_EMAIL_ERROR',

    DELETE_SHARE: 'DELETE_SHARE',
    DELETE_SHARE_DONE: 'DELETE_SHARE_DONE',

    DELETE_JOB_FROM_SHARE: 'DELETE_JOB_FROM_SHARE',

    CREATE_LOGIN_FOR_SUBACCOUNT: 'CREATE_LOGIN_FOR_SUBACCOUNT',
    CREATE_LOGIN_FOR_SUBACCOUNT_DONE: 'CREATE_LOGIN_FOR_SUBACCOUNT_DONE',
};

export interface LoginActionType extends Action {
    accountId: string;
}

const createSubAccount = (data: ShareJobsRequestType, accountId: string) => ({
    type: types.CREATE_SUBACCOUNT,
    data,
    accountId,
});

const createSubAccountDone = (account: ShareJobsResultType) => ({
    type: types.CREATE_SUBACCOUNT_DONE,
    account: account,
});

const createSubAccountError = (error: boolean) => ({
    type: types.CREATE_SUBACCOUNT_ERROR,
    error: error,
});

const createSubAccountReset = () => ({ type: types.CREATE_SUBACCOUNT_RESET });

const sendSubAccountEmails = (
    mailTo: Array<string>,
    mailToName: string | undefined,
    accountId: string,
    token: string,
    username: string,
    password: string,
    salutation = 'Sehr geehrte Damen und Herren,',
) =>
    ({
        type: types.SEND_SUBACCOUNT_EMAIL,
        data: {
            accountId,
            mailTo,
            mailToName,
            password,
            salutation,
            token,
            username,
        },
    } as { type: string; data: SendSubAccountEmailType });

const sendSubAccountEmailsDone = (mailTo: Array<string>) => ({
    type: types.SEND_SUBACCOUNT_EMAIL_DONE,
    mailTo,
});
const sendSubAccountEmailsError = (message: string) => ({
    type: types.SEND_SUBACCOUNT_EMAIL_ERROR,
    message,
});

const loadAllSubAccounts = () => ({ type: types.LOAD_ALL_SUBACCOUNTS });

const loadSubAccountsForAccount = (accountId: string) => ({
    type: types.LOAD_SUBACCOUNT,
    accountId,
});
const loadSubAccountDoneForAccount = (accountId: string, sharedAccounts: SharedAccountType[]) => ({
    type: types.LOAD_SUBACCOUNT_DONE,
    sharedAccounts,
    accountId,
});

const deleteShare = (accountId: string, shareId: string) => ({
    type: types.DELETE_SHARE,
    accountId,
    shareId,
});
const deleteShareDone = (accountId: string, shareId: string) => ({
    type: types.DELETE_SHARE_DONE,
    accountId,
    shareId,
});

const deleteJobFromShare = (accountId: string, shareId: string, jobId: string) => ({
    type: types.DELETE_JOB_FROM_SHARE,
    accountId,
    shareId,
    jobId,
});

const createLoginForSubAccount = (accountId: string, shareId: string) => ({
    type: types.CREATE_LOGIN_FOR_SUBACCOUNT,
    accountId,
    shareId,
});

const createLoginForSubAccountDone = (login: ShareJobsResultType) => ({
    type: types.CREATE_LOGIN_FOR_SUBACCOUNT_DONE,
    login,
});

export default {
    createSubAccount,
    createSubAccountDone,
    createSubAccountError,
    createSubAccountReset,
    sendSubAccountEmails,
    sendSubAccountEmailsDone,
    sendSubAccountEmailsError,
    loadAllSubAccounts,
    loadSubAccountsForAccount,
    loadSubAccountDoneForAccount,
    deleteShare,
    deleteShareDone,
    deleteJobFromShare,
    createLoginForSubAccount,
    createLoginForSubAccountDone,
} as const;
