export const EMAIL_REGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

const NON_ALPHA_NUMERIC_REGEX = /[^a-zA-Z0-9]*$/g;

const NON_TEXT_EXTENDED_REGEX = /[^a-zA-Z\x7f-\xff-().\s]*$/g;
const NON_TEXT_EXTENDED_WITH_NUMBERS_REGEX = /[^0-9a-zA-Z\x7f-\xff-().\s]*$/g;

const NON_HOUSE_NUMBER_REGEX = /[^0-9a-zA-Z-/\s]*$/g;
const NON_ZIP_REGEX = /[^0-9]*$/g;

export default {
    NON_ALPHA_NUMERIC_REGEX,
    NON_TEXT_EXTENDED_REGEX,
    NON_TEXT_EXTENDED_WITH_NUMBERS_REGEX,
    NON_HOUSE_NUMBER_REGEX,
    NON_ZIP_REGEX,
} as const;
