import React from 'react';
import './ListSeparator.less';
import classNames from 'classnames';

interface IListSeparatorProps {
    text: string;
    className?: string;
    isActive?: boolean;
}

const ListSeparator = ({ text, className, isActive = true }: IListSeparatorProps) => {
    return (
        <div
            className={classNames('list-separator text-sm px-2 py-1 rounded', className, {
                'bg-accent': isActive,
                'bg-gray-500': !isActive,
            })}
        >
            <span>{text}</span>
        </div>
    );
};

export default ListSeparator;
