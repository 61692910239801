export const types = {
    SET_SEARCH_VALUE: 'SET_SEARCH_VALUE',
};

const setSearchValue = (searchValue: string) => ({ type: types.SET_SEARCH_VALUE, searchValue });

const searchActions = {
    setSearchValue,
};

export default searchActions;
