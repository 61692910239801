import { createSelector } from 'reselect';
import { loginStateType, RoleType } from './login.types';

const getSubState = (state: any) => state.login as loginStateType;

const getLoginIds = createSelector(getSubState, (state) => Object.keys(state.logins));

const getLoginById = (accountId: string) => createSelector(getSubState, (state) => state.logins[accountId]);

const getTokenForAccount = (accountId: string) => createSelector(getSubState, (state) => state.logins[accountId].token);
const getTokenInfoForAccount = (accountId: string) => createSelector(getSubState, (state) => state.logins[accountId].tokenInfo);
const getAccountInfoForAccount = (accountId: string) => createSelector(getSubState, (state) => state.logins[accountId].info);

const isAccountLoading = (accountId: string) => createSelector(getLoginById(accountId), (account) => account.loading);

const getLogins = createSelector(getSubState, (state) => Object.entries(state.logins));

const getLoginCount = createSelector(getLogins, (logins) => logins.length);

const hasError = createSelector(getSubState, (state) => state.error);

const isLoggedIn = createSelector(getLoginIds, (ids) => ids && ids.length > 0);

const isLoading = createSelector(getSubState, (state) => state.loading);

const isInitializationDone = createSelector(getSubState, (state) => state.initializationDone);

const hasAccountEveryRole = (accountId: string, roles: Array<RoleType>) =>
    createSelector(getTokenInfoForAccount(accountId), (tokenInfo) => {
        const accountRoles = tokenInfo?.['envago:roles'];

        return roles?.every((r) => accountRoles?.includes(r));
    });

export default {
    isLoggedIn,
    getSubState,
    getLogins,
    getLoginCount,
    getLoginById,
    getLoginIds,
    getTokenForAccount,
    getTokenInfoForAccount,
    getAccountInfoForAccount,
    hasError,
    isLoading,
    isAccountLoading,
    isInitializationDone,

    hasAccountEveryRole,
};
