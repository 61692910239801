import React, { ReactNode, useEffect, useMemo } from 'react';
import Button, { ButtonProps } from '../../../components/envago/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { communicationActions, communicationSelectors } from '../../../redux/communication';
import { applicationSelectors } from '../../../redux/application';
import { jobsSelectors } from '../../../redux/jobs';
import { loginSelectors } from '../../../redux/login';
import { offlineSelectors } from '../../../redux/offline';

export type CommunicationActionPlacement = 'HOME' | 'JOB' | 'MENU';

interface CommunicationActionProps {
    triggerButtonProps: ButtonProps & { icon?: ReactNode };
    placement: CommunicationActionPlacement;
    onVisibilityChange?: (isVisible: boolean) => void;
}

export const CommunicationTrigger = ({ placement, triggerButtonProps, onVisibilityChange }: CommunicationActionProps) => {
    const dispatch = useDispatch();

    const isRegistered = useSelector(communicationSelectors.isRegisteredForUserReadingReceiptViaCRM);

    const crmRegistrationInfo = useSelector(communicationSelectors.getCRMRegistrationInfo);
    const isSendingPending = useSelector(communicationSelectors.isSendingPending);
    const isSendingBlocked = useSelector(communicationSelectors.isSendingBlocked);

    const isSendingAllowed = useSelector(applicationSelectors.isSendingCrmRegisteredUserReadingReceiptAllowed);
    const isSendingVisible = useSelector(applicationSelectors.isSendingCrmRegisteredUserReadingReceiptVisible(placement));

    const isRegistrationAllowed = useSelector(applicationSelectors.isCRMRegistrationAllowed);
    const isRegistrationVisible = useSelector(applicationSelectors.isCRMRegistrationVisible(placement));

    const doneJobs = useSelector(jobsSelectors.getJobsDone);

    const isOnline = useSelector(offlineSelectors.isOnline);
    const loginIds = useSelector(loginSelectors.getLoginIds);
    const hasReadingReceiptRoles = useSelector(
        loginSelectors.hasAccountEveryRole(loginIds[0], ['ROLE_KSA_PRO_USER', 'ROLE_KSA_SEND_CRMREGISTEREDUSER_READINGRECEIPT']),
    );

    const handleClick = () => {
        if (isRegistered) {
            dispatch(communicationActions.sendCRMReadingReceiptRequest());
        } else {
            dispatch(communicationActions.manualTriggerCRMDialog());
        }
    };

    const isVisible = useMemo(() => {
        if (!isOnline) {
            return false;
        } else if (!loginIds || loginIds.length > 1) {
            return false;
        } else if (!hasReadingReceiptRoles) {
            return false;
        } else if (crmRegistrationInfo && !isRegistered && isRegistrationAllowed && isRegistrationVisible) {
            return true;
        } else if (crmRegistrationInfo && isRegistered && isSendingAllowed && isSendingVisible) {
            return true;
        }

        return false;
    }, [
        isOnline,
        loginIds,
        hasReadingReceiptRoles,
        crmRegistrationInfo,
        isRegistered,
        isRegistrationAllowed,
        isRegistrationVisible,
        isSendingAllowed,
        isSendingVisible,
    ]);

    const isDisabled = useMemo(() => {
        if (isSendingPending) {
            return true;
        } else if (isRegistered && (!doneJobs || doneJobs?.length === 0)) {
            return true;
        }

        return false;
    }, [isRegistered, doneJobs, isSendingPending]);

    useEffect(() => {
        onVisibilityChange && onVisibilityChange(isVisible);
    }, [isVisible]);

    if (!isVisible || (isRegistered && isSendingBlocked)) return null;

    return (
        <>
            {triggerButtonProps.iconButton && triggerButtonProps.icon ? (
                <div
                    className={`cursor-pointer ${isDisabled ? 'cursor-not-allowed' : ''}`}
                    onClick={() => {
                        if (isDisabled) return;

                        handleClick();
                    }}
                >
                    {triggerButtonProps.icon}
                </div>
            ) : (
                <Button
                    disabled={isDisabled}
                    onClick={() => {
                        handleClick();
                    }}
                    {...triggerButtonProps}
                >
                    {triggerButtonProps.children}
                </Button>
            )}
        </>
    );
};

export default CommunicationTrigger;
