import React, { ReactNode } from 'react';
import './Loading.less';
import Loader from "react-loader-spinner";


const Loading = ({text, size = 100} : {text: string | ReactNode, size?: number}) => {
    return (
        <div className='loading'>
            <div className={"text-primary"}>
                <Loader
                    color={'currentColor'}
                    type="TailSpin"
                    height={size}
                    width={size}
                />
            </div>
            <div className={'loading-text'}>
                {text}
            </div>
        </div>
    );
};

export default Loading;
