import { AnyAction } from 'redux';
import { JobType } from './jobs.types';
import { jobsTypes } from './index';
import dotProp from 'dot-prop-immutable';
import { loginTypes } from '../login';

export interface jobsStateType {
    entities: {
        jobs: {
            [key: string]: JobType;
        };
    };
    jobs: string[];
    loading: boolean;
    sending: boolean;
    blocked: boolean;
}

const initialState: jobsStateType = {
    entities: {
        jobs: {},
    },
    jobs: [],
    loading: false,
    sending: false,
    blocked: false,
};

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case jobsTypes.LOAD_JOBS_STATUS: {
            return {
                ...state,
                blocked: action.loading,
            };
        }
        case jobsTypes.JOBS_ADD_ENTITIES: {
            let {
                data: { entities, result },
            } = action;

            let uniqueJobIds = Array.from(new Set<string>([...state.jobs, ...result]));
            return {
                ...state,
                loading: false,
                entities: {
                    ...state.entities,
                    jobs: {
                        ...state.entities.jobs,
                        ...entities.jobs,
                    },
                },
                jobs: uniqueJobIds,
            };
        }

        case jobsTypes.JOBS_REMOVE_BY_IDS: {
            const { jobIds } = action;

            return {
                ...state,
                jobs: state.jobs.filter((id) => !jobIds.includes(id)),
                entities: {
                    ...state.entities,
                    jobs: Object.entries(state.entities.jobs).reduce((res: any, [k, j]) => {
                        if (!jobIds.includes(k)) {
                            res[k] = j;
                        }
                        return res;
                    }, {}),
                },
            };
        }
        case jobsTypes.LOAD_JOBS: {
            return {
                ...state,
                loading: true,
            };
        }
        case jobsTypes.LOAD_JOBS_DONE: {
            const { jobs } = action;
            return {
                ...state,
                jobs: jobs,
                loading: false,
            };
        }
        case jobsTypes.SEND_METER_TASK_RESULT: {
            return {
                ...state,
                sending: true,
            };
        }
        case jobsTypes.SEND_METER_TASK_RESULT_DONE: {
            let { job } = action;
            job = job as JobType;

            let newState = dotProp.set(state, `sending`, false);
            return dotProp.set(newState, `entities.jobs.${job.id}`, job);
        }
        case loginTypes.LOGOUT: {
            return initialState;
        }
        default:
            return state;
    }
};

export default reducer;
