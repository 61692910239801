import React from 'react';
import './DatePicker.less';
import { isMobile } from 'react-device-detect';
import DesktopDatePicker from '../DesktopDatePicker/DesktopDatePicker';
import MobileDatePicker from '../MobileDatePicker/MobileDatePicker';

interface Props {
    value: Date;
    minDate: Date;
    maxDate: Date;
    onChange: (date: Date) => void;
}

const DatePicker = ({ value, minDate, maxDate, onChange }: Props) => {

    return isMobile ? (
        <MobileDatePicker value={value} onChange={onChange} minDate={minDate} maxDate={maxDate} />
    ) : (
        <DesktopDatePicker value={value} onChange={onChange} minDate={minDate} maxDate={maxDate} />
    );
};

export default DatePicker;
