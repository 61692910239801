import { AnyAction } from 'redux';

export const types = {
    SET_ONLINE_STATUS: 'SET_ONLINE_STATUS',
    QUEUE_ACTION: 'QUEUE_ACTION',

    QUEUE_ACTION_SENT: 'QUEUE_ACTION_SENT',
    QUEUE_TRY_SEND: 'QUEUE_TRY_SEND',
    QUEUE_TRY_SEND_DONE: 'QUEUE_TRY_SEND_DONE',
    QUEUE_TRY_SEND_ERROR: 'QUEUE_TRY_SEND_ERROR',
    OFFLINE_MESSAGE_SHOWN: 'OFFLINE_MESSAGE_SHOWN',
};

const setOnlineStatus = (isOnline: boolean) => ({ type: types.SET_ONLINE_STATUS, isOnline });
const queueAction = (action: AnyAction) => ({ type: types.QUEUE_ACTION, action });

const queueActionSent = (action: AnyAction) => ({ type: types.QUEUE_ACTION_SENT, action });

const queueTrySend = () => ({ type: types.QUEUE_TRY_SEND });
const queueTrySendDone = () => ({ type: types.QUEUE_TRY_SEND_DONE });
const queueTrySendError = () => ({ type: types.QUEUE_TRY_SEND_ERROR });

const offlineMessageShown = () => ({type: types.OFFLINE_MESSAGE_SHOWN})

export default {
    setOnlineStatus,
    queueAction,

    offlineMessageShown,

    queueActionSent,
    queueTrySend,
    queueTrySendDone,
    queueTrySendError,
};
