export const types = {
    LOAD_APPLICATION: 'LOAD_APPLICATION',
    LOAD_APPLICATION_DONE: 'LOAD_APPLICATION_DONE',
    WINDOW_FOCUS: 'WINDOW_FOCUS',
    WINDOW_BLUR: 'WINDOW_BLUR',
};

const loadApplication = () => ({ type: types.LOAD_APPLICATION });
const loadApplicationDone = (applicationInfo: any) => ({
    type: types.LOAD_APPLICATION_DONE,
    payload: applicationInfo,
});

const windowFocus = () => ({
    type: types.WINDOW_FOCUS,
});
const windowBlur = () => ({
    type: types.WINDOW_BLUR,
});

export default {
    loadApplication,
    loadApplicationDone,
    windowFocus,
    windowBlur
} as const;
