import React, { ReactChild, ReactNode, useState } from 'react';
import './PopConfirmButton.less';
import { Dialog, Transition } from '@headlessui/react';
import Icon from '../Icon/Icon';
import Button, { ButtonProps } from '../Button/Button';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export enum PopConfirmType {
    INFO,
    WARNING,
    ERROR,
    SUCCESS,
}

interface PopConfirmButtonPropsType {
    children: ReactChild | ReactChild[] | ReactNode | string;
    isConfirmed?: boolean;
    okText?: string;
    cancelText?: string;
    type?: PopConfirmType;
    title?: ReactNode | string;
    content?: ReactNode | string;
    onClick?: () => void;
    showIcon?: boolean;
}

interface ConfirmDialogPropsType {
    title: ReactNode | string;
    content: ReactNode | string;
    okText?: string;
    cancelText?: string;
    onOk?: () => void;
    onCancel?: () => void;
    open: boolean;
    type?: PopConfirmType;
    onClose: () => void;
    showIcon?: boolean;
}

const ConfirmDialog = ({
    title,
    content,
    open,
    onClose,
    onOk,
    onCancel,
    okText = 'OK',
    // type,
    showIcon,
    cancelText = 'Abbrechen',
}: ConfirmDialogPropsType) => {
    return (
        <div className={'relative'}>
            <Transition
                show={open}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <Dialog
                    as={'div'}
                    static={true}
                    className={'fixed inset-0 z-50 flex justify-center items-center'}
                    onClose={onClose}
                >
                    <Dialog.Overlay className={'fixed inset-0 bg-black opacity-40 flex;'} />
                    <div
                        className={
                            'z-50 bg-white m-4 min-w-40 shadow-xl rounded-md p-4 flex flex-row max-w-lg'
                        }
                    >
                        {showIcon && (
                            <div className={'pr-6 pl-2'}>
                                <Icon
                                    path={Icon.Path.mdiAlertOutline}
                                    className={'h-12  text-white bg-yellow-400 rounded-full p-2'}
                                />
                            </div>
                        )}
                        <div>
                            <h2 className={'mb-2 '}>{title}</h2>
                            <div className={'text-sm text-gray-400'}>{content}</div>
                            <div className={'flex space-x-1 mt-4'}>
                                <Button
                                    className={'flex-1 items-center'}
                                    block={true}
                                    ghost={true}
                                    onClick={onCancel}
                                >
                                    {cancelText}
                                </Button>
                                <Button
                                    className={'flex-1 items-center'}
                                    block={true}
                                    primary={true}
                                    onClick={onOk}
                                >
                                    {okText}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};

const PopConfirmButton = ({
    children,
    title,
    content,
    isConfirmed,
    onClick,
    showIcon = true,
    okText,
    cancelText,
    type = PopConfirmType.WARNING,
    ...buttonProps
}: PopConfirmButtonPropsType & ButtonProps) => {
    const [isOpen, setOpen] = useState(false);
    const { t } = useTranslation();

    return (
        <div
            className={classNames('pop-confirm-button', {
                'cursor-pointer': !buttonProps.disabled,
                'cursor-not-allowed': buttonProps.disabled,
            })}
        >
            <ConfirmDialog
                type={type}
                okText={okText || t('common.dialog.ok')}
                cancelText={cancelText || t('common.dialog.abort')}
                onClose={() => {
                    setOpen(false);
                }}
                open={isOpen}
                title={title}
                onCancel={() => {
                    setOpen(false);
                }}
                showIcon={showIcon}
                onOk={() => {
                    setOpen(false);
                    if (onClick) onClick();
                }}
                content={content}
            />
            <Button
                {...buttonProps}
                onClick={() => {
                    if (isConfirmed) {
                        if (onClick) onClick();
                        setOpen(false);
                    } else {
                        setOpen(true);
                    }
                }}
            >
                {children}
            </Button>
        </div>
    );
};

export default PopConfirmButton;
