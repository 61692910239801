import React, { useEffect, useMemo, useState } from 'react';
import OptionalLabel from '../../../../../components/envago/OptionalLabel/OptionalLabel';
import { useTranslation } from 'react-i18next';
import Input from '../../../../../components/envago/Input/Input';
import Information, { InformationType } from '../../../../../components/envago/Information/Information';
import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';
import { ReadingReasonConfig } from '../../../../../redux/application/application.types';
import RegEx from '../../../../../constants/RegEx';
import Description from '../../Description';
import Icon from '@mdi/react';
import { mdiCheckCircle, mdiCircle } from '@mdi/js';
import { ReadingReason, Contact } from '../../../../../api/unknownUserReading.api.types';

interface ContactFormData {
    contact?: Partial<Contact>;
    readingReason?: ReadingReason | string;
}

interface ContactFormProps {
    readingReasons: Array<ReadingReasonConfig<ReadingReason | string>>;
    onChange: (data: ContactFormData, isValid: boolean) => void;
}

const ContactForm = ({ readingReasons, onChange }: ContactFormProps) => {
    const [t, i18n] = useTranslation();

    const language = useMemo(() => i18n.language, [i18n.language]);

    const [data, setData] = useState<ContactFormData>({
        contact: {
            firstname: undefined,
            lastname: undefined,
            street: undefined,
            houseNumber: undefined,
            zip: undefined,
            city: undefined,
        },
        readingReason: readingReasons[0].value,
    });

    const isValid = useMemo(() => {
        if (data.contact == null) return false;

        const isContactValid = Object.entries(data.contact).every(([key, value]) => {
            if (!value) return false;

            if (key === 'zip') {
                return value.length === 5;
            } else if (key === 'houseNumber') {
                return value.length <= 10;
            } else {
                return value.length <= 50;
            }
        });

        return !!data.readingReason && isContactValid;
    }, [data]);

    const onTextChange = (value: string, replaceRegex: RegExp) => {
        if (!value) return;

        return value?.replaceAll(replaceRegex, '');
    };

    useEffect(() => {
        onChange(data, isValid);
    }, [data, isValid]);

    return (
        <div>
            <div className={'my-2'}>
                <h3>{t('unknownUserReading.contactStep.title')}</h3>
                <Description>{t('unknownUserReading.contactStep.description')}</Description>
            </div>
            <h4 className={'my-4'}>{t('unknownUserReading.subTitle.contact')}</h4>
            <div className={'flex flex-row gap-2'}>
                <div className={'flex-1'}>
                    <OptionalLabel
                        label={
                            <span>
                                {t('unknownUserReading.label.firstname')}
                                <i className={'required-field-asterix text-sm text-accent ml-1'}>{'*'}</i>
                            </span>
                        }
                    >
                        <Input
                            autoFocus={true}
                            placeholder={t('unknownUserReading.placeholder.firstname')}
                            value={data.contact?.firstname}
                            onChange={(value) => {
                                setData((prevState) => ({
                                    ...prevState,
                                    contact: {
                                        ...prevState.contact,
                                        firstname: onTextChange(value, RegEx.NON_TEXT_EXTENDED_REGEX),
                                    },
                                }));
                            }}
                            maxLength={50}
                        />
                    </OptionalLabel>
                </div>
                <div className={'flex-1'}>
                    <OptionalLabel
                        label={
                            <span>
                                {t('unknownUserReading.label.lastname')}
                                <i className={'required-field-asterix text-sm text-accent ml-1'}>{'*'}</i>
                            </span>
                        }
                    >
                        <Input
                            placeholder={t('unknownUserReading.placeholder.lastname')}
                            value={data.contact?.lastname}
                            onChange={(value) => {
                                setData((prevState) => ({
                                    ...prevState,
                                    contact: {
                                        ...prevState.contact,
                                        lastname: onTextChange(value, RegEx.NON_TEXT_EXTENDED_REGEX),
                                    },
                                }));
                            }}
                            maxLength={50}
                        />
                    </OptionalLabel>
                </div>
            </div>

            <h4>{t('unknownUserReading.subTitle.meterAddress')}</h4>
            <div className={'flex flex-row gap-2'}>
                <div className={'flex-grow'}>
                    <OptionalLabel
                        label={
                            <span>
                                {t('unknownUserReading.label.street')}
                                <i className={'required-field-asterix text-sm text-accent ml-1'}>{'*'}</i>
                            </span>
                        }
                    >
                        <Input
                            placeholder={t('unknownUserReading.placeholder.street')}
                            value={data.contact?.street}
                            onChange={(value) => {
                                setData((prevState) => ({
                                    ...prevState,
                                    contact: {
                                        ...prevState.contact,
                                        street: onTextChange(value, RegEx.NON_TEXT_EXTENDED_WITH_NUMBERS_REGEX),
                                    },
                                }));
                            }}
                            maxLength={50}
                        />
                    </OptionalLabel>
                </div>
                <div className={'max-w-xs flex-shrink'}>
                    <OptionalLabel
                        label={
                            <span>
                                {t('unknownUserReading.label.houseNumber')}
                                <i className={'required-field-asterix text-sm text-accent ml-1'}>{'*'}</i>
                            </span>
                        }
                    >
                        <Input
                            placeholder={t('unknownUserReading.placeholder.houseNumber')}
                            value={data.contact?.houseNumber}
                            onChange={(value) => {
                                setData((prevState) => ({
                                    ...prevState,
                                    contact: {
                                        ...prevState.contact,
                                        houseNumber: onTextChange(value, RegEx.NON_HOUSE_NUMBER_REGEX),
                                    },
                                }));
                            }}
                            maxLength={10}
                        />
                    </OptionalLabel>
                </div>
            </div>

            <div className={'flex flex-row gap-2'}>
                <div className={'w-24 flex-shrink'}>
                    <OptionalLabel
                        label={
                            <span>
                                {t('unknownUserReading.label.zip')}
                                <i className={'required-field-asterix text-sm text-accent ml-1'}>{'*'}</i>
                            </span>
                        }
                    >
                        <Input
                            type={'tel'}
                            name={'zip'}
                            placeholder={t('unknownUserReading.placeholder.zip')}
                            value={data.contact?.zip}
                            onChange={(value) => {
                                setData((prevState) => ({
                                    ...prevState,
                                    contact: {
                                        ...prevState.contact,
                                        zip: onTextChange(value, RegEx.NON_ZIP_REGEX),
                                    },
                                }));
                            }}
                            maxLength={5}
                        />
                    </OptionalLabel>
                </div>
                <div className={'flex-grow'}>
                    <OptionalLabel
                        label={
                            <span>
                                {t('unknownUserReading.label.city')}
                                <i className={'required-field-asterix text-sm text-accent ml-1'}>{'*'}</i>
                            </span>
                        }
                    >
                        <Input
                            placeholder={t('unknownUserReading.placeholder.city')}
                            value={data.contact?.city}
                            onChange={(value) => {
                                setData((prevState) => ({
                                    ...prevState,
                                    contact: {
                                        ...prevState.contact,
                                        city: onTextChange(value, RegEx.NON_TEXT_EXTENDED_WITH_NUMBERS_REGEX),
                                    },
                                }));
                            }}
                            maxLength={50}
                        />
                    </OptionalLabel>
                </div>
            </div>

            <h3 className={'my-6'}>{t('unknownUserReading.subTitle.readingReason')}</h3>
            <RadioGroup
                value={data.readingReason}
                onChange={(value) => {
                    setData((prevState) => ({
                        ...prevState,
                        readingReason: value as ReadingReason | string,
                    }));
                }}
            >
                {readingReasons?.map((r, index) => (
                    <div key={index}>
                        <RadioGroup.Option value={r.value} className={'flex mb-1'}>
                            {({ checked }) => (
                                <div
                                    className={classNames(
                                        'flex flex-1 rounded p-2 cursor-pointer items-center hover:bg-gray-100 transition-colors duration-300 hover:text-gray-800',
                                        checked && 'bg-gray-100',
                                    )}
                                >
                                    <span>
                                        {checked ? (
                                            <Icon path={mdiCheckCircle} size={1.1} className={'text-accent'} />
                                        ) : (
                                            <Icon path={mdiCircle} size={1.1} className={'text-gray-200'} />
                                        )}
                                    </span>
                                    <span className={classNames('ml-4 cursor-pointer flex-1')}>
                                        {t(`unknownUserReading.readingReason.${r.value}`, language.includes('en') ? r.labelEN : r.labelDE)}
                                    </span>
                                </div>
                            )}
                        </RadioGroup.Option>
                        {data.readingReason === r.value && (r.hintDE || r.hintEN) && (
                            <Information type={InformationType.INFO}>{language.includes('en') ? r.hintEN || r.hintDE : r.hintDE || r.labelEN}</Information>
                        )}
                    </div>
                ))}
            </RadioGroup>

            {!isValid && (
                <Information className={'mt-8'} showIcon={false} type={InformationType.ERROR}>
                    {t('unknownUserReading.contactStep.validationError')}
                </Information>
            )}
        </div>
    );
};

export default ContactForm;
