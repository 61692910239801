import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

const Description = ({children, className, ...props}: React.ComponentProps<'p'>) => {
    return <p className={classNames('opacity-80 leading-7 text-base', className)} {...props}>
        {children}
    </p>
}

export default Description
