import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { languageActions, languageSelectors } from '../../../redux/language';

const LanguageButton = () => {
    const nextLanguage = useSelector(languageSelectors.getNextLanguage);
    const enabledLanguages = useSelector(languageSelectors.getEnabledLanguages)
    const dispatch = useDispatch();

    if (!enabledLanguages || enabledLanguages.length < 2)
        return null;

    return (
        <div
            onClick={() => {
                dispatch(languageActions.changeLanguage(nextLanguage?.name || ''));
            }}
            className="w-8 h-8 cursor-pointer bg-opacity-20 text-center bg-gray-400 text-gray-400 align-middle justify-center items-center flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
        >
            <span className={'h-6 w-6 m-1 leading-relaxed'}>{nextLanguage?.readableName}</span>
        </div>
    );
};

export default LanguageButton;
