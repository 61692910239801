import React, { useEffect, useRef, useState } from 'react';
import './Input.less';
import classNames from 'classnames';
import { useDebounce } from '../../../helper/Hooks';

export interface InputType {
    className?: string;
    placeholder?: string;
    value?: string;
    autoFocus?: boolean;
    onFocusChange?: (hasFocus: boolean) => void;
    maxLength?: number;
    name?: string;
    type?:
        | 'button'
        | 'checkbox'
        | 'color'
        | 'date'
        | 'datetime-local'
        | 'email'
        | 'file'
        | 'hidden'
        | 'image'
        | 'month'
        | 'number'
        | 'password'
        | 'radio'
        | 'range'
        | 'reset'
        | 'search'
        | 'submit'
        | 'tel'
        | 'text'
        | 'time'
        | 'url'
        | 'week';
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const Input = ({
    className,
    placeholder,
    value,
    onChange,
    type,
    autoFocus = false,
    onKeyDown,
    onFocusChange,
    maxLength,
    name
}: InputType & {
    onChange?: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [focused, setFocused] = useState(false);
    const debouncedFocus = useDebounce(focused, 100);

    useEffect(() => {
        if (autoFocus && inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        if (onFocusChange) onFocusChange(debouncedFocus);
    }, [debouncedFocus]);

    return (
        <input
            name={name}
            onFocus={() => {
                setFocused(true);
            }}
            onBlur={() => {
                setFocused(false);
            }}
            type={type}
            ref={inputRef}
            maxLength={maxLength}
            onChange={(e) => (onChange ? onChange(e.currentTarget.value, e) : undefined)}
            value={value}
            placeholder={placeholder}
            className={classNames(
                'form-input rounded-md border w-full h-8 text-black placeholder-gray-400 p-2 mt-2 mb-2',
                className,
            )}
            onKeyDown={onKeyDown}
        />
    );
};

export default Input;
