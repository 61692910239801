import { AnyAction } from 'redux';
import { loginStateType } from './login.types';
import { loginTypes } from './index';
import dotProp from 'dot-prop-immutable';

const initialState: loginStateType = {
    logins: {},
    error: false,
    loading: false,
    initializationDone: false
};

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case loginTypes.LOGIN_INITIALIZATION_DONE: {
            return {
                ...state,
                initializationDone: true
            }
        }
        case loginTypes.LOGIN_RESET: {
            return {
                ...state,
                error: false,
                loading: false,
            };
        }
        case loginTypes.FETCH_ACCOUNT_INFO: {
            const { account } = action;
            return {
                ...state,
                logins: {
                    ...state.logins,
                    [account]: {
                        ...state.logins[account],
                        loading: true,
                    },
                },
            };
        }
        case loginTypes.ACCOUNT_INFO_RECEIVED: {
            const { account, accountInfo } = action;
            return {
                ...state,
                logins: {
                    ...state.logins,
                    [account]: {
                        ...state.logins[account],
                        loading: false,
                        info: accountInfo,
                    },
                },
            };
        }
        case loginTypes.LOGIN_JWT_TOKEN_RECEIVED: {
            const { account, token, tokenInfo, refreshToken } = action;
            /**
             * we refresh the account / accountInfo if it exists
             * if it does not exists, it must be added explicit
             * (due to self-parent check)
             */
            if (state.logins[account]) {
                return {
                    ...state,
                    loading: false,
                    logins: {
                        ...state.logins,
                        [account]: {
                            ...state.logins[account],
                            token: token,
                            tokenInfo: tokenInfo,
                            refreshToken: refreshToken,
                        },
                    },
                };
            } else {
                return state;
            }
        }
        case loginTypes.REMOVE_ACCOUNT: {
            let { accountId } = action;

            let newState = dotProp.delete(state, `logins.${accountId}`);
            return newState;
        }
        case loginTypes.ADD_ACCOUNT: {
            const { account, token, tokenInfo, refreshToken } = action;

            return {
                ...state,
                loading: false,
                logins: {
                    ...state.logins,
                    [account]: {
                        ...state.logins[account],
                        token: token,
                        tokenInfo: tokenInfo,
                        refreshToken: refreshToken,
                    },
                },
            };
        }
        case loginTypes.TOKEN_LOGIN: {
            return {
                ...state,
                loading: true,
                error: false,
            };
        }
        case loginTypes.TOKEN_LOGIN_ERROR: {
            return {
                ...state,
                loading: false,
                error: true,
            };
        }
        case loginTypes.USER_PASSWORD_LOGIN: {
            return {
                ...state,
                loading: true,
                error: false,
            };
        }
        case loginTypes.LOGIN_WRONG_CREDENTIALS: {
            return {
                ...state,
                error: true,
                loading: false,
            };
        }
        case loginTypes.LOGOUT: {
            return initialState;
        }
        default:
            return state;
    }
};

export default reducer;
