import { AccountCrmRegistrationInfoResponseDTO } from '../../api/communication.api.types';

export const communicationActionTypes = {
    COMMUNICATION_GET_CRM_REGISTRATION_INFO: 'COMMUNICATION_GET_CRM_REGISTRATION_INFO',
    COMMUNICATION_GET_CRM_REGISTRATION_INFO_CANCELLED: 'COMMUNICATION_GET_CRM_REGISTRATION_INFO_CANCELLED',
    COMMUNICATION_GET_CRM_REGISTRATION_INFO_DONE: 'COMMUNICATION_GET_CRM_REGISTRATION_INFO_DONE',
    COMMUNICATION_GET_CRM_REGISTRATION_INFO_ERROR: 'COMMUNICATION_GET_CRM_REGISTRATION_INFO_ERROR',

    COMMUNICATION_SEND_CRM_READING_RECEIPT_REQUEST: 'COMMUNICATION_SEND_CRM_READING_RECEIPT_REQUEST',
    COMMUNICATION_SEND_CRM_READING_RECEIPT_REQUEST_DONE: 'COMMUNICATION_SEND_CRM_READING_RECEIPT_REQUEST_DONE',
    COMMUNICATION_SEND_CRM_READING_RECEIPT_REQUEST_ERROR: 'COMMUNICATION_SEND_CRM_READING_RECEIPT_REQUEST_ERROR',

    COMMUNICATION_MANUAL_TRIGGER_CRM_DIALOG: 'COMMUNICATION_MANUAL_TRIGGER_CRM_DIALOG',
    COMMUNICATION_AUTOMATIC_TRIGGER_CRM_DIALOG: 'COMMUNICATION_AUTOMATIC_TRIGGER_CRM_DIALOG',

    COMMUNICATION_CLEAR_LAST_ACTION_TYPE: 'COMMUNICATION_CLEAR_LAST_ACTION_TYPE',
    COMMUNICATION_UNBLOCK_SEND_CRM_READING_RECEIPT_REQUEST: 'COMMUNICATION_UNBLOCK_SEND_CRM_READING_RECEIPT_REQUEST',
};

const getCRMRegistrationInfo = () => ({ type: communicationActionTypes.COMMUNICATION_GET_CRM_REGISTRATION_INFO });
const getCRMRegistrationInfoCancelled = () => ({
    type: communicationActionTypes.COMMUNICATION_GET_CRM_REGISTRATION_INFO_CANCELLED,
});

const getCRMRegistrationInfoDone = (payload: AccountCrmRegistrationInfoResponseDTO) => ({
    type: communicationActionTypes.COMMUNICATION_GET_CRM_REGISTRATION_INFO_DONE,
    payload: payload,
});
const getCRMRegistrationInfoError = () => ({ type: communicationActionTypes.COMMUNICATION_GET_CRM_REGISTRATION_INFO_ERROR });

const sendCRMReadingReceiptRequest = () => ({ type: communicationActionTypes.COMMUNICATION_SEND_CRM_READING_RECEIPT_REQUEST });
const sendCRMReadingReceiptRequestDone = () => ({ type: communicationActionTypes.COMMUNICATION_SEND_CRM_READING_RECEIPT_REQUEST_DONE });
const sendCRMReadingReceiptRequestError = () => ({ type: communicationActionTypes.COMMUNICATION_SEND_CRM_READING_RECEIPT_REQUEST_ERROR });

const automaticTriggerCRMDialog = () => ({ type: communicationActionTypes.COMMUNICATION_AUTOMATIC_TRIGGER_CRM_DIALOG });
const manualTriggerCRMDialog = () => ({ type: communicationActionTypes.COMMUNICATION_MANUAL_TRIGGER_CRM_DIALOG });

const clearLastActionType = () => ({ type: communicationActionTypes.COMMUNICATION_CLEAR_LAST_ACTION_TYPE });
const unblockSendCRMReadingReceiptRequest = () => ({ type: communicationActionTypes.COMMUNICATION_UNBLOCK_SEND_CRM_READING_RECEIPT_REQUEST });

export default {
    getCRMRegistrationInfo,
    getCRMRegistrationInfoDone,
    getCRMRegistrationInfoError,
    sendCRMReadingReceiptRequest,
    getCRMRegistrationInfoCancelled,
    sendCRMReadingReceiptRequestDone,
    sendCRMReadingReceiptRequestError,
    automaticTriggerCRMDialog,
    manualTriggerCRMDialog,
    clearLastActionType,
    unblockSendCRMReadingReceiptRequest,
} as const;
