import React from 'react';
import './MeterReadingTarif.less';
import { shortenObis } from '../../../helper/MeterHelper';

const MeterReadingTarif = ({ obis, rateName }: { obis?: string, rateName?: string }) => {

    if(!obis && !rateName)
        return null;

    // rateName = undefined;
    if (!rateName) {
        return (
            <div className='meter-reading-tarif inline-block mr-6'>
                <div className={'flex flex-col leading-none'}>
                    <span className={'text-2xl font-medium leading-none'}>{shortenObis(obis)}</span>
                </div>
            </div>
        );
    }

    return (
        <div className='meter-reading-tarif inline-block mr-6'>
            <div className={'flex flex-col leading-none'}>
                <span className={'text-2xl font-medium leading-none'}>{rateName}</span>
                <span className={'text-sm leading-none'}>{shortenObis(obis)}</span>
            </div>
        </div>
    );
};

export default MeterReadingTarif;
