import React, { useEffect, useState, useMemo } from 'react';
import './EvaluatedJobList.less';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import ListSeparator from '../../../../components/envago/ListSeparator/ListSeparator';
import { JobType } from '../../../../redux/jobs/jobs.types';
import { applicationSelectors } from '../../../../redux/application';
import JobListItemWrapper from '../../../common/JobListItemWrapper';
import { isJobEvaluable } from '../../../../helper/EvaluationHelper';
import { searchSelectors } from '../../../../redux/search';

interface IEvaluatedJobListProps {
    jobs: JobType[];
    className?: string;
}

const EvaluatedJobList = ({ jobs, className }: IEvaluatedJobListProps) => {
    const { t } = useTranslation();

    const { jobId }: { jobId: string } = useParams();
    const dispatch = useDispatch();

    const isEvaluationShowOnlyDoneJobsAllowed = useSelector(
        applicationSelectors.isEvaluationShowOnlyDoneJobsAllowed,
    );
    const searchValue = useSelector(searchSelectors.getSearchValue);

    const [jobsDoneWithEvaluation, setJobsDoneWithEvaluation] = useState<JobType[]>([]);
    const [jobsOpenWithEvaluation, setJobsOpenWithEvaluation] = useState<JobType[]>([]);
    const [jobsWithoutEvaluation, setJobsWithoutEvaluation] = useState<JobType[]>([]);

    const hasJobs = useMemo(
        () =>
            !(
                jobsOpenWithEvaluation.length === 0 &&
                jobsDoneWithEvaluation.length === 0 &&
                jobsWithoutEvaluation.length === 0 &&
                !searchValue
            ),
        [jobsOpenWithEvaluation, jobsDoneWithEvaluation, jobsWithoutEvaluation, searchValue],
    );

    useEffect(() => {
        setJobsDoneWithEvaluation(jobs.filter((j) => j.done && isJobEvaluable(j)));
        setJobsOpenWithEvaluation(
            jobs.filter(
                (j) => !isEvaluationShowOnlyDoneJobsAllowed && !j.done && isJobEvaluable(j),
            ),
        );
        setJobsWithoutEvaluation(
            jobs.filter((j) => !isEvaluationShowOnlyDoneJobsAllowed && !isJobEvaluable(j)),
        );
    }, [jobs, isEvaluationShowOnlyDoneJobsAllowed]);

    return (
        <div className={classNames('evaluated-job-list flex flex-col flex-1 gap-2', className)}>
            {!hasJobs && (
                <div className={'bg-gray-200 p-4 rounded text-gray-700 text-sm text-center '}>
                    {t('evaluation.empty.noJobs')}
                </div>
            )}
            {jobsDoneWithEvaluation.length > 0 && (
                <ListSeparator text={t('jobs.labels.readingsDone')} />
            )}
            {jobsDoneWithEvaluation.map((job) => (
                <JobListItemWrapper
                    key={job.id}
                    job={job}
                    onClick={() => dispatch(push(`/auswertung/${job.id}`))}
                    isActive={jobId === job.id}
                    iconRight={'ARROW'}
                />
            ))}
            {!isEvaluationShowOnlyDoneJobsAllowed && (
                <>
                    {jobsOpenWithEvaluation.length > 0 && (
                        <ListSeparator text={t('evaluation.labels.readingsNotDone')} />
                    )}
                    {jobsOpenWithEvaluation.map((job) => (
                        <JobListItemWrapper
                            key={job.id}
                            job={job}
                            onClick={() => dispatch(push(`/auswertung/${job.id}`))}
                            isActive={jobId === job.id}
                            iconRight={'ARROW'}
                            isGhost={!job.done}
                        />
                    ))}
                </>
            )}

            {!isEvaluationShowOnlyDoneJobsAllowed && (
                <>
                    {jobsWithoutEvaluation.length > 0 && (
                        <ListSeparator
                            text={t('evaluation.labels.readingsWithoutEvaluation')}
                            isActive={false}
                        />
                    )}
                    {jobsWithoutEvaluation.map((job) => (
                        <JobListItemWrapper
                            key={job.id}
                            job={job}
                            onClick={() => dispatch(push(`/auswertung/${job.id}`))}
                            isActive={jobId === job.id}
                            iconRight={'ARROW'}
                            isGhost={true}
                        />
                    ))}
                </>
            )}

            {searchValue.length > 0 && jobs.length === 0 && (
                <div className={'bg-gray-200 p-4 rounded text-gray-700 text-sm text-center '}>
                    <Trans
                        i18nKey={'jobs.search.noResults'}
                        values={{ searchValue }}
                        tOptions={{ interpolation: { escapeValue: true } }}
                    >
                        Es konnten keine Ergebnisse für die Suche nach <br /> <b>"{searchValue}"</b>{' '}
                        <br /> gefunden werden.
                    </Trans>
                    <br />
                </div>
            )}
        </div>
    );
};

export default EvaluatedJobList;
