import { schema } from 'normalizr';

export const loginSchema = new schema.Entity('logins');

// export const meterRateTaskSchema = new schema.Entity('meterRateTasks', {}, {
//     idAttribute: 'taskId',
// });

export const jobSchema = new schema.Entity('jobs', {

    // meter: {
    //     meterRateTasks: [meterRateTaskSchema]
    // },
}, {
    idAttribute: 'id',
});

// meterRateTaskSchema.define({
//     job: jobSchema,
// });


export const jobListSchema = [jobSchema];


export const shareSchema = new schema.Entity('shares', {
    jobs: [jobSchema],
}, {
    idAttribute: 'id',
});
