import React, {useEffect, useState} from 'react';
import './IntroductionScreen.less';
import Button from "../../components/envago/Button/Button";
import Dialog from "../../components/envago/Dialog/Dialog";
import {push} from "connected-react-router";
import {useDispatch} from "react-redux";

const IntroductionScreen = ({}) => {

    const dispatch = useDispatch();
    const [open, setIsOpen] = useState(true);

    useEffect(()=>{
        if(!open){
            dispatch(push('/'))
        }
    }, [open])

    return (
        <div className='introduction-screen'>
            <Dialog title={"Willkommen, Robert Kunkel!"}
                    visible={true}
                    actions={[
                        <Button key={'1'} onClick={() => {
                            setIsOpen(false)
                        }}>Nein</Button>,
                        <Button primary={true} onClick={() => {
                            // setIsOpen(false)
                        }}>Ja, let's go!</Button>
                    ]}
                    description={"Die 'Hausverwaltung Dunkel' hat 12 Ableseaufträge mit dir geteilt."}
                    content={<>
                        <p>
                            Wenn du möchtest, dann zeige ich dir kurz, wie du die Aufträge bearbeitest oder
                            weiter teilen kannst.
                        </p>
                    </>}

            />
        </div>
    );
};

export default IntroductionScreen;
