import { AnyAction } from 'redux';
import { offlineStateType } from './offline.types';
import { offlineTypes } from './index';

export interface QueueStateType {
    queue: AnyAction[],
}


const initialState: QueueStateType = {
    queue: []
};

const queueReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case offlineTypes.QUEUE_ACTION_SENT: {
            return {
                ...state,
                queue: [...state.queue.slice(1)],
            };
        }
        case offlineTypes.QUEUE_ACTION: {
            let { action: queueAction } = action;
            return {
                ...state,
                queue: [...state.queue, queueAction],
            };
        }
        default:
            return state;
    }
}

export default queueReducer;
