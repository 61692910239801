import dayjs from 'dayjs'
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)
require('dayjs/locale/de')
dayjs.locale('de')

export const formatDate = (dateString: string | undefined, format: string = 'DD.MM.YYYY') => dateString &&  dayjs(dateString).format(format);
export const formatDateTime = (dateString: string | undefined) => dateString && dayjs(dateString).format("DD.MM.YYYY HH:mm");


// @ts-ignore
export const toNow = (dateString: string) => dayjs(dateString).fromNow();


export const getLatest = (d1: dayjs.Dayjs, d2: dayjs.Dayjs) => {
    if(d1.isAfter(d2))
        return d1;
    return d2;
}

export const getEarliest = (d1: dayjs.Dayjs, d2: dayjs.Dayjs) => {
    if(d1.isBefore(d2))
        return d1;
    return d2;
}

export const isInFuture = (date: string) => dayjs(date).isAfter(dayjs());
