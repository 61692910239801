import { spawn, call } from 'redux-saga/effects';
import loginSaga from './login/login.saga';
import jobsSaga from './jobs/jobs.saga';
import applicationSaga from './application/application.saga';
import introductionSaga from './sagas/introduction.saga';
import { shareSaga } from './shares/shares.saga';
import helpSaga from './help/help.saga';
import offlineSaga from './offline/offline.saga';
import languageSaga from './language/language.saga';
import contactSaga from './contact/contact.saga';
import unknownReadingSaga from './unknownReading/unknownReading.saga';
import notificationSaga from './notification/notification.saga';
import communicationSaga from './communication/communication.saga';

export default function* rootSaga() {
    yield call(applicationSaga);
    console.log('Application loaded, continue with spawning sagas');

    yield spawn(contactSaga);
    yield spawn(languageSaga);
    yield spawn(loginSaga);
    yield spawn(jobsSaga);
    yield spawn(communicationSaga);
    yield spawn(introductionSaga);
    yield spawn(shareSaga);
    yield spawn(helpSaga);
    yield spawn(offlineSaga);
    yield spawn(unknownReadingSaga);
    yield spawn(notificationSaga);
}
