import React, { ReactElement } from 'react';
import './MarkdownHelpContent.less';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type Props = {
    children: ReactElement;
    className?: string;
};

const MarkdownHelpContent = ({ children, className }: Props) => {
    const { t } = useTranslation();

    return (
        <div className={classNames('markdown-help-content', className)}>
            <h3 className={'mt-2'}>{t('help.global.contentTitle')}</h3>
            {children}
        </div>
    );
};

export default MarkdownHelpContent;
