import React, { forwardRef, createElement } from 'react';
import classNames from 'classnames';
import DatePicker, { registerLocale } from 'react-datepicker';
import de from 'date-fns/locale/de';

import 'react-datepicker/dist/react-datepicker.css';
import './DesktopDatePicker.less';

import Icon from '../Icon/Icon';
import Button from '../Button/Button';

registerLocale('de', de);

interface Props {
    className?: string;
    value: Date;
    onChange: (date: Date) => void;
    minDate: Date;
    maxDate: Date;
}

const CustomInput = (props: React.HTMLProps<HTMLDivElement>, ref: React.Ref<HTMLDivElement>) => {
    return (
        <div className={'items-center '} onClick={props.onClick}>
            <Button ghost={true} block={true}>
                {props.value}
            </Button>
            <div className={'absolute right-2 top-3'}>
                <Icon path={Icon.Path.mdiCalendar} className={'w-6 text-gray-400'} />
            </div>
        </div>
    );
};

const DesktopDatePicker = ({ className, minDate, maxDate, value, onChange }: Props) => {
    return (
        <div className={classNames('envago-date-picker', className)}>
            <DatePicker
                dateFormat={'dd.MM.yyyy'}
                locale={'de'}
                selected={value}
                onChange={(date: Date | null) => onChange(date || new Date())}
                minDate={minDate}
                maxDate={maxDate}
                showDisabledMonthNavigation={false}
                popperPlacement={'auto'}
                customInput={createElement(forwardRef(CustomInput))}
            />
        </div>
    );
};

export default DesktopDatePicker;
