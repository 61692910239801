import Icon from '../../../../../components/envago/Icon/Icon';
import { mdiCheckCircleOutline } from '@mdi/js';
import Description from '../../Description';

import { useTranslation } from 'react-i18next';

const DoneScreen = () => {
    const [t] = useTranslation();

    return (
        <div className={'flex flex-col'}>
            <h3>{t('unknownUserReading.doneScreen.title')}</h3>

            <div className={'flex flex-col gap-4 m-4 items-center self-center'}>
                <Icon className={'w-24 h-24 text-green-500'} path={mdiCheckCircleOutline} />
                <Description>{t('unknownUserReading.doneScreen.message')}</Description>
            </div>
        </div>
    );
};

export default DoneScreen;
