import { CommunicationState } from './communication.types';
import { createSelector } from 'reselect';

const getSubState = (state: any) => state.communication as CommunicationState;

const isRegistrationInfoPending = createSelector(getSubState, (state) => state.isRegistrationInfoPending);
const isSendingPending = createSelector(getSubState, (state) => state.isSendingPending);
const isSendingBlocked = createSelector(getSubState, (state) => state.isSendingBlocked);

const getCRMRegistrationInfo = createSelector(getSubState, (state) => state.crmRegistrationInfo);
const getSelfServiceToken = createSelector(getCRMRegistrationInfo, (info) => info?.selfServiceToken);

const isRegisteredForUserReadingReceiptViaCRM = createSelector(getCRMRegistrationInfo, (info) => {
    if (!info?.registered) {
        return false;
    } else if (!info.currentConfirmedCommunicationTypes?.includes('EMAIL')) {
        return false;
    } else if (!info.selfServiceToken) {
        return false;
    }

    return true;
});

export default {
    isRegistrationInfoPending,

    isSendingPending,
    isSendingBlocked,

    getCRMRegistrationInfo,
    getSelfServiceToken,
    isRegisteredForUserReadingReceiptViaCRM,
} as const;
