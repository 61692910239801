import React, { useEffect, useRef, useState } from 'react';
import './MeterReadingInput.less';
import classNames from 'classnames';
import { useDebounce } from '../../../helper/Hooks';

interface MeterReadingInputType {
    preDecimals: number;
    postDecimals: number;
    maskPostDecimals?: boolean;
    onChange: (value: string) => void;
    value?: string;
    onFocusChange?: (hasFocus: boolean) => void;
    autofocus?: boolean;
}

const MeterReadingInput = ({
    preDecimals,
    postDecimals,
    maskPostDecimals = false,
    onChange,
    value,
    onFocusChange,
    autofocus,
}: MeterReadingInputType) => {
    const [valuePre, setValuePre] = useState('');
    const [valuePost, setValuePost] = useState('');

    useEffect(() => {
        if (value) {
            let valueSplit = value.split('.');
            setValuePre(valueSplit[0] || '');
            setValuePost(valueSplit[1] || '');
        }
    }, [value]);

    useEffect(() => {
        setValuePost('');
        setValuePre('');
    }, []);

    const preInputRef = useRef<HTMLInputElement>(null);
    const postInputRef = useRef<HTMLInputElement>(null);

    const [focused, setFocused] = useState(false);

    const debouncedFocus = useDebounce(focused, 100);

    useEffect(() => {
        if (onFocusChange) onFocusChange(debouncedFocus);
    }, [debouncedFocus]);

    const placeholderPre = Array.from(Array(preDecimals)).reduce((res, x) => res + '0', '');
    const placeholderPost = Array.from(Array(postDecimals)).reduce((res, x) => res + '0', '');
    const maskPlaceholderPost = Array.from(Array(postDecimals)).reduce((res, x) => res + 'X', '');

    useEffect(() => {
        if (valuePost && valuePost.length > 0) {
            onChange(valuePre + '.' + valuePost);
        } else {
            onChange(valuePre);
        }
    }, [valuePre, valuePost]);

    return (
        <div className="meter-reading-input inline-block whitespace-nowrap	">
            <input
                onFocus={() => {
                    setFocused(true);
                }}
                onBlur={() => {
                    setFocused(false);
                }}
                autoFocus={autofocus}
                pattern={'[0-9]*'}
                inputMode={postDecimals > 0 ? 'decimal' : 'numeric'}
                ref={preInputRef}
                placeholder={placeholderPre}
                style={{
                    width: postDecimals
                        ? `${Math.ceil(preDecimals * 1.25)}rem`
                        : `${Math.ceil(preDecimals * 1.3)}rem`,
                }}
                className={classNames('pre-decimal-input', {
                    'only-predecimals': postDecimals === 0,
                })}
                value={valuePre}
                maxLength={preDecimals}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        e.currentTarget.blur();
                    }

                    if (e.key === '.' || e.key === ',') {
                        e.preventDefault();
                        if (postInputRef.current) postInputRef.current.focus();
                    } else if (
                        e.metaKey ||
                        e.ctrlKey ||
                        e.key === 'Tab' ||
                        e.key === 'Backspace' ||
                        e.key === 'Delete' ||
                        e.key.indexOf('Arrow') === 0
                    ) {
                        return;
                    } else if (!e.key.match(/[0-9]/)) {
                        e.preventDefault();
                    }
                }}
                onChange={(e) => {
                    if (e.currentTarget.value && e.currentTarget.value.length > 0) {
                        let lastChar = e.currentTarget.value[e.currentTarget.value.length - 1];
                        if (lastChar === '.' || lastChar === ',') {
                            setValuePre(e.currentTarget.value.replace(/\D/g, ''));
                            if (postInputRef.current) postInputRef.current.focus();
                        }
                    }
                    setValuePre(e.currentTarget.value.replace(/\D/g, ''));
                }}
            />
            {postDecimals > 0 && (
                <>
                    <span className={'decimal-separator bg-white'}>,</span>
                    {maskPostDecimals ? (
                        <input
                            className={'post-decimal-input'}
                            disabled={true}
                            placeholder={maskPlaceholderPost}
                            style={{
                                paddingLeft: '.2em',
                                width: `${Math.ceil(postDecimals * 1.25)}rem`,
                            }}
                        />
                    ) : (
                        <input
                            onFocus={() => {
                                setFocused(true);
                            }}
                            onBlur={() => {
                                setFocused(false);
                            }}
                            inputMode={'numeric'}
                            pattern={'[0-9]*'}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    e.currentTarget.blur();
                                }

                                if (e.key === 'Backspace') {
                                    console.log('Backspace', e.currentTarget.value.length);
                                    if (e.currentTarget.value.length === 0 && preInputRef.current) {
                                        preInputRef.current.focus();
                                        e.preventDefault();
                                    }
                                } else if (
                                    e.metaKey ||
                                    e.ctrlKey ||
                                    e.key === 'Tab' ||
                                    e.key === 'Delete' ||
                                    e.key.indexOf('Arrow') === 0
                                ) {
                                    return;
                                } else if (e.key === '.' || e.key === ',') {
                                    e.preventDefault();
                                } else if (!e.key.match(/[0-9]/)) {
                                    e.preventDefault();
                                }
                            }}
                            ref={postInputRef}
                            placeholder={placeholderPost}
                            style={{
                                paddingLeft: '.2em',
                                width: `${Math.ceil(postDecimals * 1.25)}rem`,
                            }}
                            className={'post-decimal-input'}
                            value={valuePost}
                            maxLength={postDecimals}
                            onChange={(e) => {
                                setValuePost(e.currentTarget.value.replace(/\D/g, ''));
                            }}
                        />
                    )}
                </>
            )}
            <div className={'flex-1'} />
        </div>
    );
};

export default MeterReadingInput;
