import { MeterRateTaskType } from '../redux/jobs/jobs.types';
import { shortenObis } from './MeterHelper';

export const sortMeterRates = (meterRates: MeterRateTaskType[]) => {
    return meterRates.sort((a, b) => {

        if(!a.obis || !b.obis){
            if(a.rateName && b.rateName)
                return a.rateName.localeCompare(b.rateName)
        }

        if (a.obis === b.obis) {
            if (a.targetReadingDate && b.targetReadingDate) {
                return b.targetReadingDate.localeCompare(a.targetReadingDate);
            }
        }

        if (shortenObis(a.obis).startsWith('1.6')) {
            return 1;
        }

        if (shortenObis(b.obis).startsWith('1.6')) {
            return -1;
        }

        if (a.obis && b.obis) return a.obis.localeCompare(b.obis);


        return 0;
    });
};
