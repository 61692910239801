export const roundNumberToLocaleString = (number?: number) => {
    if (number == null) return undefined;

    if (number === 0) return '0';

    if ((number < 1 && number > 0) || (number < 0 && number > -1)) {
        return parseFloat(
            number.toFixed(3 - Math.floor(Math.log(Math.abs(number)) / Math.log(10))),
        ).toLocaleString(undefined);
    }

    if ((number < 100 && number >= 10) || (number > -100 && number <= -10)) {
        return number.toLocaleString(undefined, {
            maximumFractionDigits: 1,
            minimumFractionDigits: 1,
        });
    } else if ((number < 10 && number >= 1) || (number > -10 && number <= -1)) {
        return number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        });
    }

    return number.toLocaleString(undefined, {
        maximumFractionDigits: 0,
    });
};
