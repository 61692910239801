import { JobType, MeterTaskResultType } from './jobs.types';

export const types = {
    JOBS_ADD_ENTITIES: 'JOBS_ADD_ENTITIES',

    JOBS_REMOVE_BY_IDS: 'JOBS_REMOVE_BY_IDS',

    LOAD_JOBS_STATUS: 'LOAD_JOBS_STATUS',

    LOAD_JOBS: 'LOAD_JOBS',
    LOAD_JOBS_DONE: 'LOAD_JOBS_DONE',

    SEND_METER_TASK_RESULT: 'SEND_METER_TASK_RESULT',
    SEND_METER_TASK_RESULT_DONE: 'SEND_METER_TASK_RESULT_DONE',

    SEND_METER_TASK_RESULTS: 'SEND_METER_TASK_RESULTS',
};

const addEntities = (data: any) => ({ type: types.JOBS_ADD_ENTITIES, data });

const removeJobsByIds = (jobIds: string[]) => ({ type: types.JOBS_REMOVE_BY_IDS, jobIds });

const loadJobsSetLoading = (loading: boolean) => ({ type: types.LOAD_JOBS_STATUS, loading });

const loadJobs = () => ({ type: types.LOAD_JOBS });
const loadJobsDone = (jobs: JobType[]) => ({ type: types.LOAD_JOBS_DONE, jobs });

export interface MeterReadingTaskResultAction {
    type: 'SEND_METER_TASK_RESULTS';
    jobId: string;
    readingDate: string;
    taskResults: Array<MeterTaskResultType>;
}

const sendMeterTaskResults = (jobId: string, readingDate: string, taskResults: Array<MeterTaskResultType>) => ({
    type: types.SEND_METER_TASK_RESULTS,
    jobId,
    readingDate,
    taskResults,
});

const sendMeterTaskResultsDone = (jobId: string, job?: JobType) => ({
    type: types.SEND_METER_TASK_RESULT_DONE,
    job,
    jobId,
});

export default {
    addEntities,
    removeJobsByIds,
    loadJobsSetLoading,
    loadJobs,
    loadJobsDone,
    sendMeterTaskResults,
    sendMeterTaskResultsDone,
} as const;
