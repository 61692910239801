import React from 'react';
import './JobFilterToolbar.less';
import Input from '../../../../components/envago/Input/Input';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { searchActions, searchSelectors } from '../../../../redux/search';

const JobFilterToolbar = () => {
    const { t } = useTranslation();
    const searchValue = useSelector(searchSelectors.getSearchValue);
    const dispatch = useDispatch();

    return (
        <div className={'h-12 mb-3 flex items-center justify-center'}>
            <div className="flex flex-1 items-baseline">
                <Input
                    placeholder={t('common.search')}
                    value={searchValue}
                    onChange={(value) => dispatch(searchActions.setSearchValue(value))}
                    className={'flex-1 h-10 focus:ring focus:border-outline-none'}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') e.currentTarget.blur();
                    }}
                />
            </div>
        </div>
    );
};

export default JobFilterToolbar;
