import { Tab } from '@headlessui/react';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Button from '../../../../components/envago/Button/Button';
import dotProp from 'dot-prop-immutable';
import { Meter, UnknownUserReadingConfigType } from '../../../../redux/application/application.types';
import MeterForm, { MeterFormData } from './components/MeterForm';
import MeterReadingForm, { MeterReadingFormReadingValue } from './components/MeterReadingForm';
import dayjs from 'dayjs';
import unknownUserReadingApi from '../../../../api/unknownUserReading.api';
import ContactForm from './components/ContactForm';
import SummaryForm from './components/SummaryForm';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { notificationActions } from '../../../../redux/notification';
import Icon from '../../../../components/envago/Icon/Icon';
import { mdiChevronRight } from '@mdi/js';
import Card from '../../../../components/envago/Card/Card';
import { applicationSelectors } from '../../../../redux/application';
import DoneScreen from './components/DoneScreen';
import { MeterType, UnknownUserReadingRequestDTO } from '../../../../api/unknownUserReading.api.types';
import communicationApi from '../../../../api/communication.api';
import { EMAIL_REGEX } from '../../../../constants/RegEx';
import { UnknownUserReadingResultReceiptRequestDTO } from '../../../../api/communication.api.types';
import jwtDecode from 'jwt-decode';
import { RoleType } from '../../../../redux/login/login.types';
import { NotificationType } from '../../../MainScreen/NotificationWrapper/NotificationWrapper';

const STEPS = ['METER', 'METER_READING', 'CONTACT', 'SUMMARY', 'DONE'] as const;
type Step = typeof STEPS[number];

interface UnknownUserReadingData {
    data: {
        [step in Step]: {
            values: any;
            isValid: boolean;
        };
    };
}

interface UnknownUserReadingProps {
    token: any;
    onDone?: () => void;
    className?: string;
}

const UnknownUserReading = ({ token, onDone, className = '' }: UnknownUserReadingProps) => {
    const dispatch = useDispatch();

    const isSendingUnknownUserReadingReceiptAllowed = useSelector(applicationSelectors.isSendingUnknownUserReadingReceiptAllowed);
    const hasSendingUnknownUserReadingReceiptRole = useMemo(() => {
        if (!token) return false;

        const decoded: { ['envago:roles']: Array<RoleType>; [key: string]: any } = jwtDecode(token);

        const requiredRoles: Array<RoleType> = ['ROLE_KSA_UNKNOWN_READINGS', 'ROLE_KSA_SEND_UNKNOWNUSER_READINGRECEIPT'];

        return requiredRoles.every((r) => decoded?.['envago:roles']?.includes(r));
    }, [token]);

    const [isLoading, setIsLoading] = useState(false);

    const config = useSelector(applicationSelectors.getUnknownUserReadingConfig);

    const initialData = STEPS.reduce(
        (acc, curr) => ({
            ...acc,
            [curr]: {
                values: resolveInitialData(curr, config),
                isValid: curr === 'DONE',
            },
        }),
        {} as any,
    );

    const [t] = useTranslation();
    const [step, setStep] = useState<Step>(STEPS[0]);
    const [state, setState] = useState<UnknownUserReadingData>({
        data: initialData,
    });

    const request = useMemo(() => {
        const r: Partial<UnknownUserReadingRequestDTO> = {
            meterType: state.data.METER.values?.meterType,
            meterNumber: state.data.METER.values?.meterNumber,
            readingValues:
                state.data.METER_READING.values?.readingValues &&
                Object.values(state.data.METER_READING.values.readingValues).map((r) => ({
                    ...(r as MeterReadingFormReadingValue),
                    readingImages: (r as MeterReadingFormReadingValue).readingImages
                        ?.map((i) => {
                            const imageMatch = i.match(/.*data:(.*);.*,(.*)/m);

                            if (imageMatch) {
                                return {
                                    encodedImage: imageMatch[2],
                                    mimeType: imageMatch[1],
                                };
                            }

                            return null;
                        })
                        .filter((i) => !!i),
                })),
            readingDate: state.data.METER_READING.values?.readingDate,
            readingReason: state.data.CONTACT.values?.readingReason,

            contact: state.data.CONTACT.values?.contact,
            email: state.data.SUMMARY.values?.email,
        };

        return r;
    }, [state.data]);

    const isValid = useMemo(() => {
        return Object.values(state.data).every((data) => data.isValid);
    }, [state.data]);

    const meterConfig = useMemo(() => {
        const meterType: MeterType = state.data['METER']?.values?.meterType;

        if (!config || !meterType) return undefined;

        return dotProp.get(config?.meters, meterType) as Meter<typeof meterType>;
    }, [state?.data, config]);

    const meterTypeConfig = useMemo(() => {
        return (state.data['METER'].values as MeterFormData)?.subTypeMeterConfig
            ? (state.data['METER'].values as MeterFormData)?.subTypeMeterConfig
            : meterConfig?.defaultMeterConfig;
    }, [meterConfig, state]);

    const submit = async () => {
        try {
            if (token) {
                setIsLoading(true);

                await unknownUserReadingApi.sendUnknownUserReadingResult(request as UnknownUserReadingRequestDTO, token);

                if (request.email && EMAIL_REGEX.test(request.email) && isSendingUnknownUserReadingReceiptAllowed && hasSendingUnknownUserReadingReceiptRole) {
                    try {
                        await communicationApi.sendUnknownUserReadingReceipt(
                            {
                                ...request,
                                email: request.email,
                            } as UnknownUserReadingResultReceiptRequestDTO,
                            token,
                        );

                        dispatch(
                            notificationActions.showNotification(
                                t('unknownUserReading.notification.readingReceipt.success.title'),
                                t('unknownUserReading.notification.readingReceipt.success.description'),
                                NotificationType.SUCCESS,
                            ),
                        );
                    } catch (err) {
                        dispatch(
                            notificationActions.showErrorNotification(
                                t('unknownUserReading.notification.readingReceipt.error.title'),
                                t('unknownUserReading.notification.readingReceipt.error.description'),
                            ),
                        );
                    }
                }

                reset();

                dispatch(
                    notificationActions.showNotification(
                        t('unknownUserReading.notification.readingRequest.success.title'),
                        t('unknownUserReading.notification.readingRequest.success.description'),
                        NotificationType.SUCCESS,
                    ),
                );

                window.scrollTo({ top: 0, left: 0 });

                setStep(STEPS[STEPS.findIndex((s) => s === step) + 1]);
            }
        } catch (err) {
            dispatch(
                notificationActions.showErrorNotification(
                    t('unknownUserReading.notification.readingRequest.error.title'),
                    t('unknownUserReading.notification.readingRequest.error.description'),
                ),
            );
        } finally {
            setIsLoading(false);
        }
    };

    const reset = () => {
        setStep('METER');
        setState({ data: initialData });
    };

    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    if (!config || !config.meters || !config.readingReasons) return null;

    return (
        <div className={className}>
            <Tab.Group manual selectedIndex={STEPS.findIndex((s) => s === step)}>
                <Tab.List className={'w-full flex flex-row align-middle'}>
                    {STEPS.map((_s, index) => {
                        return (
                            <Tab as={Fragment} key={index}>
                                <div />
                            </Tab>
                        );
                    })}
                </Tab.List>
                <Card>
                    <Tab.Panels>
                        <Tab.Panel className={'tab-meter-form'}>
                            <MeterForm
                                initialValues={state.data['METER'].values}
                                onChange={(data, isValid) => {
                                    setState((prevState) => ({
                                        data: {
                                            ...prevState.data,
                                            METER: {
                                                values: data,
                                                isValid: isValid,
                                            },
                                        },
                                    }));
                                }}
                                meterTypes={config?.meters && (Object.keys(config?.meters) as MeterType[])}
                                subTypeMeterConfigs={meterConfig?.subTypeMeterConfigs}
                                customMeterConfig={meterConfig?.customMeterConfig}
                                validation={config?.validation?.meterNumber}
                            />
                        </Tab.Panel>
                        <Tab.Panel className={'tab-meterreading-form'}>
                            <MeterReadingForm
                                initialValues={state.data['METER_READING'].values}
                                onChange={(data, isValid) => {
                                    setState((prevState) => ({
                                        data: {
                                            ...prevState.data,
                                            METER_READING: {
                                                values: data,
                                                isValid: isValid,
                                            },
                                        },
                                    }));
                                }}
                                isQueryTransformerOrMWhPostDecimalsEnabled={
                                    meterTypeConfig?.meterType === 'ELECTRIC_METER' && 'defaultSelectedMeterRatesByOBIS' in meterTypeConfig
                                }
                                meterConfig={meterTypeConfig}
                            />
                        </Tab.Panel>
                        <Tab.Panel className={'tab-contact-form'}>
                            <ContactForm
                                readingReasons={config.readingReasons && Object.values(config.readingReasons)}
                                onChange={(data, isValid) => {
                                    setState((prevState) => ({
                                        data: {
                                            ...prevState.data,
                                            CONTACT: {
                                                values: data,
                                                isValid: isValid,
                                            },
                                        },
                                    }));
                                }}
                            />
                        </Tab.Panel>
                        <Tab.Panel className={'tab-summary-form'}>
                            <SummaryForm
                                emailEnabled={hasSendingUnknownUserReadingReceiptRole && isSendingUnknownUserReadingReceiptAllowed}
                                config={{
                                    meterConfig: meterTypeConfig,
                                }}
                                summary={request}
                                onChange={(data, isValid) => {
                                    setState((prevState) => ({
                                        data: {
                                            ...prevState.data,
                                            SUMMARY: {
                                                values: data,
                                                isValid: isValid,
                                            },
                                        },
                                    }));
                                }}
                            />
                        </Tab.Panel>
                        <Tab.Panel>
                            <DoneScreen />
                        </Tab.Panel>
                    </Tab.Panels>
                    <div className={'flex flex-1 flex-row items-center justify-end gap-2 mt-4'}>
                        {['METER', 'METER_READING', 'CONTACT'].includes(step) && (
                            <Button
                                primary={state.data[step].isValid}
                                disabled={!state.data[step].isValid}
                                block={true}
                                onClick={() => {
                                    setStep(STEPS[STEPS.findIndex((s) => s === step) + 1]);
                                    window.scrollTo({ top: 0, left: 0 });
                                }}
                            >
                                {t('unknownUserReading.button.continue')} <Icon path={mdiChevronRight} className={''} size={'1em'} />
                            </Button>
                        )}
                        {step === 'SUMMARY' && (
                            <Button
                                block={true}
                                primary={isValid}
                                isLoading={isLoading}
                                disabled={!isValid}
                                onClick={async () => {
                                    if (!isLoading) {
                                        await submit();
                                    }
                                }}
                            >
                                {t('unknownUserReading.button.submit')}
                            </Button>
                        )}
                    </div>
                </Card>
            </Tab.Group>
        </div>
    );
};

export default UnknownUserReading;

const resolveInitialData = (step: Step, config?: UnknownUserReadingConfigType) => {
    if (!config?.meters) return undefined;

    const initialMeter: Meter<any> = dotProp.get(config.meters, Object.keys(config?.meters)[0]);

    switch (step) {
        case 'METER': {
            return {
                meterType: initialMeter.meterType,
                subTypeMeterConfig: initialMeter.subTypeMeterConfigs?.[0],
            } as MeterFormData;
        }
        case 'METER_READING': {
            return {
                readingDate: dayjs().toISOString(),
            };
        }
        case 'CONTACT': {
            break;
        }
        case 'SUMMARY': {
            break;
        }
        default:
            return undefined;
    }
};
