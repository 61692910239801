import React, { HTMLProps } from 'react';
import classNames from 'classnames';

const IndeterminateCheckbox = ({
    indeterminate,
    className = '',
    ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) => {

    // console.log("IndeterminateCheckbox")
    const ref = React.useRef<HTMLInputElement>(null!);

    React.useEffect(() => {
        // console.log("IndeterminateCheckbox", indeterminate, rest.checked)
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate;
        }
    }, [ref, indeterminate, rest.checked]);


    return (
        <input
            type="checkbox"
            ref={ref}
            style={{

            }}
            className={classNames(
                'cursor-pointer h-4 w-4',
                className,
            )}
            {...rest}
        />
    );
};

export default IndeterminateCheckbox;
