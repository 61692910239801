import React, { useEffect, useMemo, useState } from 'react';
import OptionalLabel from '../../../../../components/envago/OptionalLabel/OptionalLabel';
import Input from '../../../../../components/envago/Input/Input';
import Checkbox from '../../../../../components/envago/Checkbox/Checkbox';
import { splitJoin } from '../../../../../helper/TextHelper';
import { MeterConfig } from '../../../../../redux/application/application.types';
import ReadingValue from '../../../../../components/envago/ReadingValue/ReadingValue';
import { Trans, useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { EMAIL_REGEX } from '../../../../../constants/RegEx';
import Information, { InformationType } from '../../../../../components/envago/Information/Information';
import Description from '../../Description';
import LabeledList from '../../../../../components/envago/LabeledList/LabeledList';
import { useSelector } from 'react-redux';
import { applicationSelectors } from '../../../../../redux/application';
import { UnknownUserReadingRequestDTO } from '../../../../../api/unknownUserReading.api.types';

interface SummaryFormData {
    isPrivacyPolicyChecked: boolean;
    email?: string;
}

interface SummaryFormProps {
    emailEnabled: boolean;
    config: {
        meterConfig?: MeterConfig<any>;
    };
    summary: Partial<UnknownUserReadingRequestDTO>;
    onChange: (data: SummaryFormData, isValid: boolean) => void;
}

const SummaryForm = ({ emailEnabled, config, summary, onChange }: SummaryFormProps) => {
    const [t] = useTranslation();
    const privacyConfig = useSelector(applicationSelectors.getPrivacyConfig);
    const applicationName = useSelector(applicationSelectors.getApplicationName);

    const [data, setData] = useState<SummaryFormData>({ isPrivacyPolicyChecked: false });

    const isValid = useMemo(() => {
        if (!!data.email) {
            const isEmailValid = data.email.length <= 120 && EMAIL_REGEX.test(data.email);

            if (!isEmailValid) {
                return false;
            }
        }

        return data.isPrivacyPolicyChecked;
    }, [data]);

    useEffect(() => {
        onChange(data, isValid);
    }, [data, isValid]);

    return (
        <div>
            <div className={'my-2'}>
                <h3>{t('unknownUserReading.summaryStep.title')}</h3>
                <Description>{t('unknownUserReading.summaryStep.description')}</Description>
            </div>
            <h4 className={'mt-6'}>{t('unknownUserReading.subTitle.contact')}</h4>
            <LabeledList className={'mt-2'}>
                <OptionalLabel label={t('unknownUserReading.label.firstname')}>{summary.contact?.firstname}</OptionalLabel>
                <OptionalLabel label={t('unknownUserReading.label.lastname')}>{summary.contact?.lastname}</OptionalLabel>
            </LabeledList>

            <h4 className={'mt-4'}>{t('unknownUserReading.subTitle.meter')}</h4>
            <LabeledList className={'mt-2'}>
                <OptionalLabel label={t('unknownUserReading.label.meterNumber')}>{summary.meterNumber}</OptionalLabel>
                <OptionalLabel label={t('unknownUserReading.label.meterType')}>
                    <div>
                        {config.meterConfig?.subType
                            ? t(`unknownUserReading.meterSubType.${config.meterConfig?.subType}`, config.meterConfig?.subType)
                            : t(`unknownUserReading.meterType.${summary.meterType}`, summary.meterType)}
                    </div>
                </OptionalLabel>
                <OptionalLabel label={t('unknownUserReading.label.readingReason')}>
                    {t(`unknownUserReading.readingReason.${summary.readingReason}`, summary.readingReason)}
                </OptionalLabel>
                <OptionalLabel label={t('unknownUserReading.label.readingDate')}>{dayjs(summary.readingDate).format('dddd, DD.MM.YYYY')}</OptionalLabel>
                <OptionalLabel label={t('unknownUserReading.label.meterAddress')}>
                    <div>
                        <div>{splitJoin(' ', summary.contact?.street, summary.contact?.houseNumber)}</div>
                        <div>{splitJoin(' ', summary.contact?.zip, summary.contact?.city)}</div>
                    </div>
                </OptionalLabel>
            </LabeledList>
            <h4 className={'mt-4'}>{t('unknownUserReading.subTitle.readingValues')}</h4>
            {summary.readingValues?.map((r, index, arr) => {
                const meterRateConfig = config.meterConfig?.meterRateConfigs.find((c) => c.obis === r.obis);

                const preDecimals = arr.reduce((acc, curr) => {
                    const n = curr.value?.toString().split('.')[0].length;

                    if (acc < n) {
                        return n;
                    }

                    return acc;
                }, 0);

                return (
                    <div className={'flex gap-2'} key={index}>
                        <LabeledList className={'mt-2'}>
                            <OptionalLabel label={splitJoin(' ,', r.obis, r.rateName)}>
                                <ReadingValue
                                    value={r.value}
                                    preDecimals={preDecimals}
                                    postDecimals={meterRateConfig?.readingConfig?.postDecimals || 0}
                                    maskPostDecimals={!meterRateConfig?.readingConfig?.queryPostDecimals}
                                />
                            </OptionalLabel>
                        </LabeledList>
                    </div>
                );
            })}

            <h4 className={'mt-8'}>{t('unknownUserReading.subTitle.privacyPolicy')}</h4>
            <div className={'flex flex-row gap-2 items-start my-2'}>
                <Checkbox
                    className={'mt-0'}
                    value={data.isPrivacyPolicyChecked}
                    onChange={(value) => {
                        setData((prevState) => ({
                            ...prevState,
                            isPrivacyPolicyChecked: value,
                        }));
                    }}
                />
                <div className={'text-sm text-gray-600 ml-4'}>
                    <span>
                        <Trans i18nKey={'unknownUserReading.information.privacyPolicy'} values={{ applicationName }}>
                            Diese Seite wird von {applicationName} bereitgestellt. Ich habe die
                            <a target={'_blank'} href={privacyConfig?.url}>
                                Datenschutzbedingungen
                            </a>{' '}
                            gemäß Art. 13 und 14 DS-GVO zur Kenntnis genommen.
                        </Trans>

                        <i className={'required-field-asterix text-accent mx-2'}>{t('common.requiredField')}</i>
                    </span>
                </div>
            </div>
            {emailEnabled && (
                <div className={'my-8'}>
                    <h3>{t('unknownUserReading.subTitle.email')}</h3>
                    <OptionalLabel label={t('unknownUserReading.label.email')}>
                        <Input
                            type={'email'}
                            placeholder={t('unknownUserReading.placeholder.email')}
                            value={data.email}
                            onChange={(value) => {
                                setData((prevState) => ({
                                    ...prevState,
                                    email: value,
                                }));
                            }}
                        />
                        <span className={'text-sm text-gray-400'}>{t('unknownUserReading.information.emailProcessing')}</span>
                    </OptionalLabel>
                </div>
            )}
            {!data.isPrivacyPolicyChecked && (
                <Information type={InformationType.ERROR} showIcon={false}>
                    {t('unknownUserReading.description.dataPrivacyRequired')}
                </Information>
            )}
        </div>
    );
};

export default SummaryForm;
