import 'react-customize-token-input/dist/react-customize-token-input.css';

import React, { useEffect, useMemo, useState } from 'react';
import './InputEmails.less';
import TokenInput, { DEFAULT_SEPARATORS } from 'react-customize-token-input';
import { EMAIL_REGEX } from '../../../constants/RegEx';

interface InputEmailProps {
    values: Array<string>;
    onChange: (emails: Array<string>, isValid: boolean) => void;
    invalidText?: string;
    placeholder?: string;
    autoFocus?: boolean;
}

const InputEmails = ({
    values,
    onChange,
    invalidText,
    placeholder,
    autoFocus = true,
}: InputEmailProps) => {
    const [internalValues, setInternalValues] = useState<Array<string>>(values);

    const handleEmailValidation = (value: string): boolean => {
        return EMAIL_REGEX.test(value);
    };

    const handleEmailsChange = (values: Array<string>) => {
        setInternalValues(values);
    };

    const isValid = useMemo(() => {
        if (internalValues.length === 0) return false;

        return internalValues.every((v) => handleEmailValidation(v));
    }, [internalValues]);

    useEffect(() => {
        onChange(internalValues, isValid);
    }, [internalValues, isValid]);

    return (
        <div className={'input-emails'}>
            <TokenInput
                className={'align-bottom flex'}
                separators={[...DEFAULT_SEPARATORS, ' ']}
                tokenValues={internalValues}
                onTokenValuesChange={handleEmailsChange}
                onTokenValueValidate={(value) => {
                    const isValid = handleEmailValidation(value);

                    return !isValid;
                }}
                onGetIsTokenEditable={() => true}
                autoFocus={autoFocus}
                placeholder={placeholder}
            />
            {!isValid && internalValues.length >= 1 && (
                <div className={'text-sm mt-1 text-yellow-700'}>
                    {invalidText ? invalidText : '\u00A0'}
                </div>
            )}
        </div>
    );
};

export default InputEmails;
