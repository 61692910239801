import Dialog from '../../../../components/envago/Dialog/Dialog';
import Button from '../../../../components/envago/Button/Button';
import './CommunicationDialog.styles.less';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Information, { InformationType } from '../../../../components/envago/Information/Information';

interface CommunicationDialogProps {
    isOpen: boolean;
    isPending: boolean;
    isRegistered: boolean;

    isNoUrlError: boolean;

    onClose: () => void;
    onSubmit: () => void;

    doneJobIds: Array<string>;
}

const CommunicationDialog = ({ isOpen, isPending, isRegistered, isNoUrlError, onSubmit, onClose, doneJobIds }: CommunicationDialogProps) => {
    const { t } = useTranslation();

    const texts: { title: string; description: string; cancelButton: string; submitButton: string; content: ReactNode } = useMemo(() => {
        return {
            title: isRegistered ? t('communication.dialog.communication.title') : t('communication.dialog.registration.title'),
            description: isRegistered ? t('communication.dialog.communication.description') : t('communication.dialog.registration.description'),
            cancelButton: isRegistered ? t('communication.dialog.communication.cancelButtonText') : t('communication.dialog.registration.cancelButtonText'),
            submitButton: isRegistered ? t('communication.dialog.communication.submitButtonText') : t('communication.dialog.registration.submitButtonText'),
            content: isRegistered ? (
                t('communication.dialog.communication.content', { count: doneJobIds.length })
            ) : (
                <div>
                    <div>{t('communication.dialog.registration.content')}</div>
                    {isNoUrlError && (
                        <Information className={'mt-2'} type={InformationType.ERROR}>
                            {t('communication.dialog.registration.missingURL')}
                        </Information>
                    )}
                </div>
            ),
        };
    }, [isRegistered, isNoUrlError, doneJobIds, t]);

    return (
        <Dialog
            classNameContent={'communication-dialog'}
            visible={isOpen}
            title={texts.title}
            description={texts.description}
            content={texts.content}
            onClose={onClose}
            closable={!isPending}
            actions={[
                <Button
                    key={'cancel'}
                    ghost
                    disabled={isPending}
                    onClick={() => {
                        onClose();
                    }}
                >
                    {texts.cancelButton}
                </Button>,
                <Button
                    key={'submit'}
                    primary
                    isLoading={isPending}
                    onClick={() => {
                        onSubmit();
                    }}
                >
                    {texts.submitButton}
                </Button>,
            ]}
        />
    );
};

export default CommunicationDialog;
