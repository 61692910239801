export const deepReplaceKeys = (
    obj: { [key: string]: any },
    searchValue: string = ' ',
    replaceValue: string = '_',
) => {
    if (obj == null) return undefined;
    if (isObjectEmpty(obj)) return undefined;

    for (const key in obj) {
        if (typeof obj[key] === 'string') {
            Object.keys(obj).forEach((k) => {
                if (typeof obj[k] === 'object') {
                    deepReplaceKeys(obj[k], searchValue, replaceValue);
                }

                if (k.includes(searchValue)) {
                    Object.assign(obj, { [k.replace(searchValue, replaceValue)]: obj[k] });
                    delete obj[k];
                }
            });

            return obj;
        } else if (typeof obj[key] === 'object') {
            deepReplaceKeys(obj[key], searchValue, replaceValue);
        }
    }

    return obj;
};

export const isObjectEmpty = (obj: object) => {
    for (let i in obj) {
        return false;
    }

    return true;
};
