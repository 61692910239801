import { JobType } from '../redux/jobs/jobs.types';

export const isJobEvaluable = (job: JobType) =>
    job.meter.meterRateTasks.some((task) => {
        if (!task.readingEvents) return false;

        return (
            (job.done && task.readingEvents.length > 0) ||
            (!job.done && task.readingEvents.length > 1)
        );
    });
