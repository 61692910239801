import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    'de-FORMAL': {
        envago: require('./i18n/de/formal').default,
    },
    'de-INFORMAL': {
        envago: require('./i18n/de/informal').default,
    },
    'en-FORMAL': {
        envago: require('./i18n/en/formal').default,
    },
};

i18n.use(initReactI18next).init({
    debug: process.env.NODE_ENV === 'development',
    resources: resources,
    lng: 'de-FORMAL',
    ns: ['envago'],
    defaultNS: 'envago',
    interpolation: {
        escapeValue: false,
    },
    returnObjects: true,
});

export default i18n;
