import React, { ComponentType, ReactNode } from 'react';
import './ContactInformation.less';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { languageSelectors } from '../../../redux/language';
import { contactSelectors } from '../../../redux/contact';
import MarkdownViewer from '../MarkdownViewer/MarkdownViewer';

interface Props {
    wrapper?: ComponentType<any>;
    wrapperProps?: {};
    className?: string;
    path: string;
}

interface DefaultProps {
    children?: ReactNode;
    className?: string;
}

const DefaultWrapper = ({ children, className }: DefaultProps) => {
    return <div className={classNames('default-wrapper', className)}>{children}</div>;
};

const ContactInformation = ({ wrapper, wrapperProps ,className, path }: Props) => {
    const Wrapper = wrapper ? wrapper : DefaultWrapper;
    const currentLanguage = useSelector(languageSelectors.getCurrentLanguage);
    const text = useSelector(contactSelectors.getContactText(path, currentLanguage));

    if(!text) return null;

    return (
        <Wrapper {...wrapperProps}>
            <div className={classNames('contact-information', className)}>
                <MarkdownViewer text={text} light />
            </div>
        </Wrapper>
    );
};

export default ContactInformation;
