import { AnyAction } from 'redux';
import { helpStateType, MeterReadingHelpType } from './help.types';
import { helpTypes } from './index';

const initialState: helpStateType = {
    meterReadingInstructions: [],
    globalHelpEnabled: false,
};

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case helpTypes.LOAD_METERREADING_INSTRUCTIONS_DONE: {
            let { data } = action;
            return {
                ...state,
                meterReadingInstructions: data,
            };
        }
        case helpTypes.GET_GLOBAL_HELP_LANGUAGES_DONE: {
            const { payload } = action;
            return {
                ...state,
                globalHelpEnabled: payload,
            };
        }
        default:
            return state;
    }
};

export default reducer;
