import React, { useEffect } from 'react';
import './TokenLoginScreen.less';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { loginActions, loginSelectors } from '../../redux/login';
import Loading from '../../components/common/Loading/Loading';
import { applicationSelectors } from '../../redux/application';
import LoginErrorDialog from '../ApplicationLoadingScreen/components/LoginErrorDialog/LoginErrorDialog';


const TokenLoginScreen = ({}) => {

    const dispatch = useDispatch();

    const hasError = useSelector(loginSelectors.hasError);
    const applicationIsLoaded = useSelector(applicationSelectors.isLoaded);
    const loginInitializationDone = useSelector(loginSelectors.isInitializationDone);

    let { token }: { token: string } = useParams();
    useEffect(() => {
        if (loginInitializationDone && token && applicationIsLoaded) {
            console.log('Params', token);
            dispatch(loginActions.loginWithToken(token));
        }
    }, [token, loginInitializationDone, applicationIsLoaded]);


    return (
        <div className='token-login-screen'>
            <div className={'py-20'}>
                {!hasError && <Loading text={'Anmeldung wird geprüft...'} />}
                {hasError && <LoginErrorDialog />}
            </div>
        </div>
    );

};

export default TokenLoginScreen;
