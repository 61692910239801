import { createSelector } from 'reselect';
import { ApplicationConfig, ApplicationStateType } from './application.types';
import { CommunicationActionPlacement } from '../../screens/common/Communication/CommunicationTrigger';

const getSubState = (state: any) => state.application as ApplicationStateType;

const isFocused = createSelector(getSubState, (state) => state.isFocused);
const isLoaded = createSelector(getSubState, (state) => state.loaded);
const getApplicationName = createSelector(getSubState, (state) => state.name);
const getApplicationId = createSelector(getSubState, (state) => state.applicationId);

const getApplicationConfig = createSelector(getSubState, (state) => state.config as ApplicationConfig);

const getMeterReadingConfig = createSelector(getApplicationConfig, (config) => config?.meterReading);

const getUnknownUserReadingConfig = createSelector(getApplicationConfig, (config) => config?.unknownUserReading);

const isQueryPostDecimalsEnabled = createSelector(getMeterReadingConfig, (config) => config?.queryPostDecimals ?? false);

const isMaskPostDecimalsEnabled = createSelector(getMeterReadingConfig, (config) => config?.maskPostDecimals ?? false);

const getCustomComments = createSelector(getMeterReadingConfig, (config) => config?.defaultComments);

const getImprintConfig = createSelector(getApplicationConfig, (config) => config?.legal?.imprint);
const getPrivacyConfig = createSelector(getApplicationConfig, (config) => config?.legal?.privacy);
const getFeaturesConfig = createSelector(getApplicationConfig, (config) => config?.features);
const getCommunicationSettingsConfig = createSelector(getApplicationConfig, (config) => config?.communicationSettings);

const isSendingCrmRegisteredUserReadingReceiptAllowed = createSelector(
    getCommunicationSettingsConfig,
    (config) => config?.sendingCrmRegisteredUserReadingReceiptAllowed ?? false,
);

const isSendingCrmRegisteredUserReadingReceiptVisible = (placement: CommunicationActionPlacement) =>
    createSelector(getCommunicationSettingsConfig, (config) => {
        switch (placement) {
            case 'HOME': {
                return config?.showSendingReadingReceiptOnHomeScreen ?? false;
            }
            case 'JOB': {
                return config?.showSendingReadingReceiptOnJobScreen ?? false;
            }
            case 'MENU': {
                return config?.showSendingReadingReceiptOnMainMenu ?? false;
            }
            default:
                return false;
        }
    });

const getCRMSettingsConfig = createSelector(getApplicationConfig, (config) => config?.crmSettings);

const isCRMRegistrationAllowed = createSelector(getCRMSettingsConfig, (state) => state?.registrationEnabled ?? false);

const isCRMRegistrationVisible = (placement: CommunicationActionPlacement) =>
    createSelector(getCRMSettingsConfig, (config) => {
        switch (placement) {
            case 'HOME': {
                return config?.showRegistrationOnHomeScreen ?? false;
            }
            case 'JOB': {
                return config?.showRegistrationOnJobScreen ?? false;
            }
            case 'MENU': {
                return config?.showRegistrationOnMainMenu ?? false;
            }
            default:
                return false;
        }
    });

const isShowModalAfterLastJobCompletedEnabled = createSelector(getCRMSettingsConfig, (config) => config?.showModalAfterLastJobCompleted ?? false);

const getCRMRegistrationURL = createSelector(getCRMSettingsConfig, (config) => config?.externalRegistrationOptInBaseUrl);

const isGlobalReshareAllowed = createSelector(getFeaturesConfig, (state) => state?.reshareAllowed);

const isUnknownReadingsEnabled = createSelector(getFeaturesConfig, (features) => features?.unknownReadingsAllowed);

const isEvaluationEnabled = createSelector(getFeaturesConfig, (features) => features?.evaluationEnabled);

const isEvaluationUseHistoricReadingsAllowed = createSelector(getFeaturesConfig, (features) => features?.evaluationUseHistoricReadings ?? true);

const isEvaluationShowCurrentReadingsAllowed = createSelector(getFeaturesConfig, (features) => features?.evaluationShowCurrentReadings ?? true);

const isEvaluationShowOnlyDoneJobsAllowed = createSelector(getFeaturesConfig, (features) => features?.evaluationShowOnlyDoneJobs ?? true);

const evaluationIgnoreEstimatedReadingIfOtherReadingIsWithinDays = createSelector(getFeaturesConfig, (features) => {
    return features?.evaluationIgnoreEstimatedReadingIfOtherReadingIsWithinDays === undefined
        ? 14
        : features?.evaluationIgnoreEstimatedReadingIfOtherReadingIsWithinDays;
});

const isUnknownReadingsImageRequired = createSelector(getFeaturesConfig, (features) => features?.unknownReadingsImageRequired ?? false);

const loginUsernameMinLength = createSelector(getFeaturesConfig, (features) => features?.loginUsernameMinLength ?? 5);
const loginPasswordMinLength = createSelector(getFeaturesConfig, (features) => features?.loginPasswordMinLength ?? 5);
const isLoginPasswordInputAsCleartextEnabled = createSelector(getFeaturesConfig, (features) => features?.loginPasswordInputAsCleartext);

const isRedirectToJobsScreenOnLoginEnabled = createSelector(getFeaturesConfig, (features) => features?.redirectToJobsScreenOnLoginEnabled ?? false);

const isMultipleAccountsLoginEnabled = createSelector(getFeaturesConfig, (features) => features?.multipleAccountsLoginEnabled ?? true);

const isPreselectJobIfSingleEnabled = createSelector(getFeaturesConfig, (features) => features?.preselectJobIfSingleEnabled ?? false);

const isSendSubaccountReminderMailtoLinkEnabled = createSelector(getFeaturesConfig, (features) => features.sendSubaccountReminderMailtoLinkEnabled ?? false);

const isUnknownUserReadingAllowed = createSelector(getApplicationConfig, (config) => config?.unknownUserReading?.unknownUserReadingAllowed || false);

const isSendingUnknownUserReadingReceiptAllowed = createSelector(
    getCommunicationSettingsConfig,
    (config) => config?.sendingUnknownUserReadingReceiptAllowed ?? false,
);

export default {
    getApplicationConfig,

    isFocused,
    isLoaded,
    getApplicationName,
    getApplicationId,

    getImprintConfig,
    getPrivacyConfig,
    getFeaturesConfig,
    getUnknownUserReadingConfig,

    isEvaluationEnabled,
    isEvaluationUseHistoricReadingsAllowed,
    isEvaluationShowCurrentReadingsAllowed,
    isEvaluationShowOnlyDoneJobsAllowed,
    evaluationIgnoreEstimatedReadingIfOtherReadingIsWithinDays,

    isPreselectJobIfSingleEnabled,
    isUnknownReadingsEnabled,
    isUnknownReadingsImageRequired,

    loginUsernameMinLength,
    loginPasswordMinLength,
    isLoginPasswordInputAsCleartextEnabled,

    isMultipleAccountsLoginEnabled,
    isRedirectToJobsScreenOnLoginEnabled,

    isGlobalReshareAllowed,

    getCustomComments,
    isQueryPostDecimalsEnabled,
    isMaskPostDecimalsEnabled,

    isSendSubaccountReminderMailtoLinkEnabled,

    isUnknownUserReadingAllowed,

    isCRMRegistrationAllowed,
    isCRMRegistrationVisible,
    getCRMRegistrationURL,

    isShowModalAfterLastJobCompletedEnabled,

    isSendingCrmRegisteredUserReadingReceiptAllowed,
    isSendingCrmRegisteredUserReadingReceiptVisible,
    isSendingUnknownUserReadingReceiptAllowed,
} as const;
