import { helpActions, helpTypes } from './index';
import { put, select, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { applicationSelectors } from '../application';

function* loadMeterReadingInstructions() {
    let applicationId: string = yield select(applicationSelectors.getApplicationId);
    try {
        const { data } = yield axios.get(`/api/public/meterReadingHelp/${applicationId}`);

        yield put(helpActions.loadMeterReadingInstructionsDone(data.instructions));
    } catch (e) {
        yield put(helpActions.loadMeterReadingInstructionsError());
    }
}

function* getGlobalHelpLanguagesSaga() {
    const applicationId: string = yield select(applicationSelectors.getApplicationId);
    try {
        const { status } = yield axios.get(`/api/public/help/${applicationId}`);
        yield put(helpActions.getGlobalHelpLanguagesDone(status === 200));
    } catch (e) {
        yield put(helpActions.getGlobalHelpLanguagesError());
    }
}

export default function* helpSaga() {


    yield takeLatest(helpTypes.LOAD_METERREADING_INSTRUCTIONS, loadMeterReadingInstructions);
    yield takeLatest(helpTypes.GET_GLOBAL_HELP_LANGUAGES, getGlobalHelpLanguagesSaga);

    yield put(helpActions.loadMeterReadingInstructions());
    yield put(helpActions.getGlobalHelpLanguages());
}
