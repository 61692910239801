import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { communicationSelectors } from '../../../redux/communication';
import CommunicationTrigger from './CommunicationTrigger';

interface CommunicationJobDetailsSectionProps {}

const CommunicationJobDetailsSection = ({}: CommunicationJobDetailsSectionProps) => {
    const { t } = useTranslation();
    const isRegistered = useSelector(communicationSelectors.isRegisteredForUserReadingReceiptViaCRM);

    const [isVisible, setIsVisible] = useState(false);

    const texts: { description: string; triggerButton: string } = useMemo(() => {
        return {
            description: isRegistered ? t('communication.jobDetails.communication.description') : t('communication.jobDetails.registration.description'),
            triggerButton: isRegistered ? t('communication.triggerButton.communication') : t('communication.triggerButton.registration'),
        };
    }, [isRegistered, t]);

    return (
        <div className={`flex lg:flex-row flex-col items-start mt-2 pt-2 border-t border-black border-opacity-30 gap-2 ${isVisible ? '' : 'hidden'}`}>
            <p className={'flex-grow'}>{texts.description}</p>
            <CommunicationTrigger
                triggerButtonProps={{
                    small: true,
                    block: true,
                    className: 'lg:w-max whitespace-nowrap',
                    children: texts.triggerButton,
                }}
                placement={'JOB'}
                onVisibilityChange={(isVisible) => setIsVisible(isVisible)}
            />
        </div>
    );
};

export default CommunicationJobDetailsSection;
