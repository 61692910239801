import React from 'react';
import './JobListItemWrapper.less';
import { JobType } from '../../../redux/jobs/jobs.types';
import { resolveMeterColorTailwindClass, resolveMeterType } from '../../../helper/MeterHelper';
import { splitJoin } from '../../../helper/TextHelper';
import { useTranslation } from 'react-i18next';
import EnergyIcon from '../../../components/envago/EnergyIcon/EnergyIcon';
import JobListItem, {
    JobListItemIconType,
} from '../../../components/envago/JobListItem/JobListItem';
import { useSelector } from 'react-redux';
import { jobsSelectors } from '../../../redux/jobs';

interface IJobListItemWrapperProps {
    job: JobType;
    onClick: () => void;
    isActive?: boolean;
    isGhost?: boolean;
    isDone?: boolean;
    iconRight?: JobListItemIconType;
}

const JobListItemWrapper = ({
    job,
    onClick,
    isGhost = false,
    isActive = false,
    iconRight = false,
}: IJobListItemWrapperProps) => {
    const { t } = useTranslation();

    const meterType = resolveMeterType(job.meter);
    const meterColor = resolveMeterColorTailwindClass(meterType);

    const hasObjectKeyMultipleValues = useSelector(
        jobsSelectors.haveJobsMultipleValues('meter.meterAddress.objectKey'),
    );
    const hasLocationDescriptionMultipleValues = useSelector(
        jobsSelectors.haveJobsMultipleValues('meter.meterAddress.locationDescription'),
    );

    return (
        <JobListItem
            key={job.id}
            firstLine={
                splitJoin(
                    ', ',
                    job.meter.meterAddress?.city,
                    splitJoin(
                        ' ',
                        job.meter.meterAddress?.street,
                        job.meter.meterAddress?.houseNumber,
                    ),
                ) || ''
            }
            title={job.meter.meterNumber}
            secondLine={
                (hasObjectKeyMultipleValues && job.meter.meterAddress?.objectKey) ||
                (hasLocationDescriptionMultipleValues &&
                    job.meter.meterAddress?.locationDescription) ||
                job.meter.customMeterType ||
                t(`common.metertypes.${resolveMeterType(job.meter)}`)
            }
            onClick={onClick}
            icon={<EnergyIcon iconClassName={'h-7'} meterType={meterType} />}
            color={meterColor}
            isActive={isActive}
            isGhost={isGhost}
            iconRight={iconRight}
        />
    );
};

export default JobListItemWrapper;
