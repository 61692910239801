import { createSelector } from 'reselect';
import { languageStateType } from './language.types';

const getSubState = (state: any) => state.language as languageStateType;

const getLanguageConfig = createSelector(getSubState, (state) => state.languageConfig);
const getCurrentLanguage = createSelector(getSubState, (state) => state.currentLanguage);
const getEnabledLanguages = createSelector(getLanguageConfig, (state) => state?.enabledLanguages);
const getNextLanguage = createSelector(
    getCurrentLanguage,
    getEnabledLanguages,
    (currentLanguage, enabledLangs) => {
        if (!enabledLangs) return null;
        const currIdx = enabledLangs?.findIndex((l) => l.name === currentLanguage);
        return enabledLangs[(currIdx + 1) % enabledLangs?.length];
    },
);

export default {
    getLanguageConfig,
    getCurrentLanguage,
    getEnabledLanguages,
    getNextLanguage,
};
