import React from 'react';
import './ProgressBar.less';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const ProgressBar = ({
                         progress,
                         showPercentage = false,
                         showNumbers = false,
                         total,
                         value,
                         barClassName = 'bg-gray-200',
                         activeBarClassName = 'bg-primary',
                     }: {
    progress: number, showPercentage?: boolean, value?: number, total?: number, showNumbers?: boolean,
    barClassName?: string,
    activeBarClassName?: string,
}) => {
    const { t, i18n } = useTranslation();

    if(isNaN(progress))
        progress = 0;

    return (
        <div className='progress-bar z'>
            <div className=' flex pt-1 items-center h-4 '>
                <div className={classNames('flex-1 overflow-hidden h-2 text-xs flex rounded', barClassName)}>
                    <div style={{ width: `${(progress * 100)}%` }}
                         className={classNames('shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center',
                             activeBarClassName,
                         )}>
                    </div>
                </div>
            </div>
            <div className={'flex'}>
                {showNumbers && (
                    <div className='flex mb-2 items-center justify-between'>
                        <div>

                        </div>
                        <div className='text-right'>
                      <span className='text-xs font-semibold inline-block opacity-60'>
                          {value} {t('common.of')} {total}
                      </span>
                        </div>
                    </div>
                )}
                <div className={'flex-1'}></div>
                {showPercentage && (
                    <div className='flex ml-2 mb-2 items-center justify-between'>
                        <div>

                        </div>
                        <div className='text-right'>
                      <span className='text-xs font-semibold inline-block opacity-60'>
                          {showNumbers && `(`}
                          {Math.floor(progress * 1000) / 10}%
                          {showNumbers && `)`}
                      </span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProgressBar;
