import { createSelector } from 'reselect';
import { sharesStateType } from './shares.reducer';
import { SharedAccountType } from './shares.types';
import { jobsSelectors } from '../jobs';

const getSubState = (state: any) => state.shares as sharesStateType;

const getShares = createSelector(getSubState, (state) => state.shares);

const getSharesForAccount = (accountId: string) => createSelector(getShares, (shares) => shares[accountId] as SharedAccountType[] | undefined);

const getCreatedSubState = createSelector(getSubState, (state) => state.create);

const createIsSending = createSelector(getCreatedSubState, (create) => create.sending);

const createGetAccount = createSelector(getCreatedSubState, (create) => create.account);
const errorCreateSubAccount = createSelector(getCreatedSubState, (create) => create?.error);

const getSharedJobIdsForAccount = (accountId: string) =>
    createSelector(getSharesForAccount(accountId), (shares) => {
        if (!shares) return [];
        const jobIds = shares.flatMap((share) => {
            return share.jobs.map((job) => job.id);
        }) as string[];

        return Array.from(new Set(jobIds));
    });

const isJobInAccountShared = (accountId: string, jobId: string) => createSelector(getSharedJobIdsForAccount(accountId), (ids) => ids.includes(jobId));

const emailsSelector = createSelector(getSubState, (state) => state.emails);

const emailsIsSending = createSelector(emailsSelector, (email) => email?.sending || false);
const emailsIsDone = createSelector(emailsSelector, (email) => email?.done || false);
const emailsHasError = createSelector(emailsSelector, (email) => email?.error || false);
const emailsGetMails = createSelector(emailsSelector, (email) => email?.emails);

const showShareButtonForAccount = (accountId: string) =>
    createSelector(jobsSelectors.isAnyJobReshareAllowed, jobsSelectors.hasShareableJobsByAccountId(accountId), (reshareAllowed, hasJobs) => {
        return reshareAllowed && hasJobs;
    });

const showShareButtonForJob = (jobId: string) =>
    createSelector(jobsSelectors.isAnyJobReshareAllowed, jobsSelectors.getJobById(jobId), (reshareAllowed, job) => {
        return reshareAllowed && job.jobPermissions?.reShare === true;
    });

export default {
    getSharesForAccount,
    createIsSending,
    createGetAccount,
    errorCreateSubAccount,
    getSharedJobIdsForAccount,
    isJobInAccountShared,
    emailsIsSending,
    emailsIsDone,
    emailsHasError,
    emailsGetMails,
    showShareButtonForAccount,
    showShareButtonForJob,
} as const;
