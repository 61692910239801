import { AccountInfoType, JWTTokenInfoType } from './login.types';

export const types = {
    USER_PASSWORD_LOGIN: 'USER_PASSWORD_LOGIN',
    USER_PASSWORD_LOGIN_ERROR: 'USER_PASSWORD_LOGIN_ERROR',

    TOKEN_LOGIN: 'TOKEN_LOGIN',
    TOKEN_LOGIN_ERROR: 'TOKEN_LOGIN_ERROR',
    LOGIN_JWT_TOKEN_RECEIVED: 'LOGIN_JWT_TOKEN_RECEIVED',

    LOGIN_WRONG_CREDENTIALS: 'LOGIN_WRONG_CREDENTIALS',

    ACCOUNT_INFO_RECEIVED: 'ACCOUNT_INFO_RECEIVED',
    FETCH_ACCOUNT_INFO: 'FETCH_ACCOUNT_INFO',

    LOGOUT: 'LOGOUT',

    ADD_ACCOUNT: 'ADD_ACCOUNT',

    LOGIN_RESET: 'LOGIN_RESET',
    REMOVE_ACCOUNT: 'REMOVE_ACCOUNT',
    LOGIN_INITIALIZATION_DONE: 'LOGIN_INITIALIZATION_DONE',
};

const loginWithToken = (token: string) => ({ type: types.TOKEN_LOGIN, token });

const loginWithTokenError = (message: string) => ({ type: types.TOKEN_LOGIN_ERROR, message });
const loginJwtTokenReceived = (
    account: string,
    token: string,
    refreshToken: string,
    tokenInfo: JWTTokenInfoType,
) => ({
    type: types.LOGIN_JWT_TOKEN_RECEIVED,
    token,
    account,
    tokenInfo,
    refreshToken,
});

const addAccount = (
    account: string,
    token: string,
    refreshToken: string,
    tokenInfo: JWTTokenInfoType,
) => ({
    type: types.ADD_ACCOUNT,
    token,
    account,
    tokenInfo,
    refreshToken,
});

const loginWithUsername = (username: string, password: string) => ({
    type: types.USER_PASSWORD_LOGIN,
    username,
    password,
});
const loginWithUsernameError = (message: string) => ({
    type: types.USER_PASSWORD_LOGIN_ERROR,
    message,
});

const logout = () => ({ type: types.LOGOUT });

const fetchAccountInformation = (account: string) => ({ type: types.FETCH_ACCOUNT_INFO, account });
const accountInformationReceived = (account: string, accountInfo: AccountInfoType) => ({
    type: types.ACCOUNT_INFO_RECEIVED,
    account,
    accountInfo,
});

const loginWrongCredentials = () => ({ type: types.LOGIN_WRONG_CREDENTIALS });

const loginReset = () => ({ type: types.LOGIN_RESET });

const removeAccount = (accountId: string) => ({ type: types.REMOVE_ACCOUNT, accountId });

const loginInitializationDone = () => ({ type: types.LOGIN_INITIALIZATION_DONE });

export default {
    loginWithToken,
    loginWithTokenError,
    loginJwtTokenReceived,
    loginWrongCredentials,
    loginWithUsername,
    loginWithUsernameError,
    loginReset,

    logout,
    removeAccount,
    addAccount,

    fetchAccountInformation,
    accountInformationReceived,

    loginInitializationDone
};
