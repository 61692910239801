import { UnknownMeterReadingResultAction } from './unknownReading.actions';
import { getToken } from '../login/login.saga';
import axios from 'axios';
import { put, select, takeLatest } from 'redux-saga/effects';
import { notificationActions } from '../notification';
import { NotificationType } from '../../screens/MainScreen/NotificationWrapper/NotificationWrapper';
import { offlineActions, offlineSelectors } from '../offline';
import { unknownReadingTypes } from './index';

function* sendUnknownMeterReading(action: UnknownMeterReadingResultAction) {
    let token: string = yield getToken(action.payload.accountId);
    try {
        yield axios.post(
            '/api/pro/unknown-reading',
            {
                ...action.payload.result,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );
        yield put(notificationActions.scrollToTop());
        yield put(
            notificationActions.showNotification(
                'Senden erfolgreich',
                'Die Ergebnisse wurden erfolgreich gesendet.',
                NotificationType.SUCCESS,
            ),
        );
    } catch (err) {
        yield put(notificationActions.scrollToTop());
        let isOnline: boolean = yield select(offlineSelectors.isOnline);
        if (!isOnline || err.status !== undefined) {
            yield put(offlineActions.queueAction(action));
        } else {
            yield put(
                notificationActions.showErrorNotification(
                    'Senden fehlgeschlagen',
                    'Die Ergebnisse konnten nicht gesendet werden. Bitte versuche es später erneut.',
                ),
            );
        }
    }
}

export default function* unknownReadingSaga() {
    yield takeLatest(unknownReadingTypes.SEND_UNKNOWN_METER_RESULT, sendUnknownMeterReading);
}
