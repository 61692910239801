const isInRange = (
    value: number,
    minimumValue: number,
    maximumValue: number,
    postDecimals: number,
) => {
    const factor = Math.pow(10, postDecimals);
    return (
        Math.floor(minimumValue * factor) / factor <= value &&
        Math.ceil(maximumValue * factor) / factor >= value
    );
};

const isBigger = (value: number, compareValue: number, postDecimals: number) => {
    const factor = Math.pow(10, postDecimals);
    return Math.floor(compareValue * factor) / factor <= value;
};

export { isInRange, isBigger };
