import { AnyAction } from 'redux';
import { notificationStateType } from './notification.types';
import { notificationTypes } from './index';

const initialState: notificationStateType = {
    notification: undefined,
};

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case notificationTypes.SHOW_NOTIFICATION: {
            return {
                ...state,
                notification: {
                    title: action.title,
                    message: action.message,
                    id: action.id,
                    type: action.notificationType,
                },
            };
        }
        case notificationTypes.CLEAR_NOTIFICATION: {
            return {
                ...state,
                notification: undefined,
            };
        }
        default:
            return state;
    }
};

export default reducer;
