import { NotificationType } from '../../screens/MainScreen/NotificationWrapper/NotificationWrapper';

export const types = {
    SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
    SCROLL_TO_TOP: 'SCROLL_TO_TOP',
    CLEAR_NOTIFICATION: 'CLEAR_NOTIFICATION',
};

const showNotification = (title: string, message: string, notificationType?: NotificationType | undefined) => ({
    type: types.SHOW_NOTIFICATION,
    title,
    message,
    notificationType: notificationType || NotificationType.INFO,
    id: new Date().getTime(),
});

const showErrorNotification = (title: string, message: string) => ({
    type: types.SHOW_NOTIFICATION,
    title,
    message,
    notificationType: NotificationType.ERROR,
    id: new Date().getTime(),
});

const showInfoNotification = (title: string, message: string) => ({
    type: types.SHOW_NOTIFICATION,
    title,
    message,
    notificationType: NotificationType.INFO,
    id: new Date().getTime(),
});

const scrollToTop = () => ({
    type: types.SCROLL_TO_TOP,
});

const clearNotification = () => ({
    type: types.CLEAR_NOTIFICATION,
});

export default {
    showNotification,
    showErrorNotification,
    showInfoNotification,
    scrollToTop,
    clearNotification,
} as const;
