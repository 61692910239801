import React, { ReactNode, useEffect, useLayoutEffect, useMemo } from 'react';
import './Table.less';
import {
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    flexRender,
    Row,
    GroupingState,
    getGroupedRowModel,
    getExpandedRowModel,
    RowSelectionState,
    ColumnDef,
} from '@tanstack/react-table';
import { useVirtual } from 'react-virtual';
import Icon from '../Icon/Icon';
import classNames from 'classnames';
import { IndeterminateCheckbox } from '../IndeterminateCheckbox';

export const AggregateValue = ({ children }: { children: ReactNode }) => <span className={'italic opacity-60'}>{children}</span>;

interface TableProps {
    className?: string;
    columns: ColumnDef<any>[];
    data: any[];
    selectable?: boolean;
    groupable?: boolean;
    empty?: ReactNode;
    fullWidth?: boolean;
    onSelectionChange?: (ids: string[], rows: any[], groups: { name: string; data: any[] }[]) => void;
}

const Table = ({ className = undefined, columns, data, groupable = false, selectable = false, onSelectionChange, fullWidth }: TableProps) => {
    const tableContainerRef = React.useRef<HTMLDivElement>(null);

    const [sorting, setSorting] = React.useState<SortingState>([]);
    const [grouping, setGrouping] = React.useState<GroupingState>([]);
    const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});

    const table = useReactTable({
        data,
        columns: columns,
        state: {
            sorting,
            grouping,
            rowSelection,
        },
        enableGrouping: groupable,
        enableRowSelection: selectable,
        enableMultiRowSelection: selectable,
        enableSubRowSelection: selectable,
        onRowSelectionChange: setRowSelection,
        onGroupingChange: setGrouping,
        onSortingChange: setSorting,
        getExpandedRowModel: getExpandedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        debugTable: false,
    });

    const { rows } = table.getRowModel();

    const { virtualItems: virtualRows, totalSize } = useVirtual({
        parentRef: tableContainerRef,
        size: rows.length,
        overscan: 10,
    });

    const paddingTop = useMemo(() => {
        return virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
    }, [virtualRows]);

    const paddingBottom = useMemo(() => {
        return virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;
    }, [virtualRows, totalSize]);

    useLayoutEffect(() => {
        if (tableContainerRef && tableContainerRef.current) {
            const parentHeight = tableContainerRef?.current?.parentElement?.clientHeight || 200;
            tableContainerRef.current.style.height = parentHeight + 'px';
        }
    });

    useEffect(() => {
        if (onSelectionChange) {
            const selectedJobIds = table.getSelectedRowModel().flatRows.map((r) => r.original.id);
            const selectedJobs = table.getSelectedRowModel().flatRows.map((r) => r.original);

            onSelectionChange(selectedJobIds, selectedJobs, []);
        }
    }, [rowSelection]);

    return (
        <div className={classNames('envago-table', className)}>
            <div ref={tableContainerRef} className={classNames('table-container', { 'full-width': fullWidth })}>
                <table>
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            className={classNames({
                                                'font-bold': true,
                                            })}
                                            style={{ width: header.colSpan > 1 ? undefined : header.getSize() !== 150 ? header.getSize() : undefined }}
                                        >
                                            {header.isPlaceholder ? null : (
                                                <div
                                                    {...{
                                                        className: header.column.getCanSort() ? 'cursor-pointer select-none flex items-center gap-1' : '',
                                                        onClick: header.column.getToggleSortingHandler(),
                                                    }}
                                                >
                                                    {header.column.getCanGroup() ? (
                                                        // If the header can be grouped, let's add a toggle
                                                        <button
                                                            {...{
                                                                onClick: header.column.getToggleGroupingHandler(),
                                                                style: {
                                                                    cursor: 'pointer',
                                                                },
                                                            }}
                                                        >
                                                            {header.column.getIsGrouped() ? (
                                                                <Icon className={'h-4 text-accent'} path={Icon.Path.mdiSelectGroup} />
                                                            ) : (
                                                                <Icon className={'h-4 text-accent'} path={Icon.Path.mdiSelectGroup} />
                                                            )}
                                                        </button>
                                                    ) : null}
                                                    <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                                                    <div>
                                                        {{
                                                            asc: <Icon className={'h-4'} path={Icon.Path.mdiSortAscending} />,
                                                            desc: <Icon className={'h-4'} path={Icon.Path.mdiSortDescending} />,
                                                        }[header.column.getIsSorted() as string] ?? null}
                                                    </div>
                                                </div>
                                            )}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {paddingTop > 0 && (
                            <tr>
                                <td style={{ height: `${paddingTop}px` }} />
                            </tr>
                        )}
                        {virtualRows.map((virtualRow) => {
                            const row = rows[virtualRow.index] as Row<any>;

                            return (
                                <tr
                                    key={row.id}
                                    style={{
                                        minHeight: 40,
                                    }}
                                >
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <td
                                                {...{
                                                    key: cell.id,
                                                    className: classNames({
                                                        'whitespace-nowrap': true,
                                                        flex: cell.getIsGrouped(),
                                                        'bg-gray-100': cell.getIsGrouped() || cell.getIsPlaceholder(),
                                                    }),
                                                    style: {},
                                                }}
                                            >
                                                {cell.getIsGrouped() ? (
                                                    // If it's a grouped cell, add an expander and row count
                                                    <div className={'flex items-center gap-1'}>
                                                        <IndeterminateCheckbox
                                                            {...{
                                                                checked: row.getIsSelected(),
                                                                indeterminate: row.getIsSomeSelected(),
                                                                onChange: row.getToggleSelectedHandler(),
                                                            }}
                                                        />
                                                        <button
                                                            className={classNames({
                                                                'ml-2': true,
                                                                flex: true,
                                                                'items-center': true,
                                                            })}
                                                            {...{
                                                                onClick: row.getToggleExpandedHandler(),
                                                                style: {
                                                                    cursor: row.getCanExpand() ? 'pointer' : 'normal',
                                                                },
                                                            }}
                                                        >
                                                            <div>
                                                                {row.getIsExpanded() ? (
                                                                    <Icon className={'h-4 mr-1'} path={Icon.Path.mdiMinusBoxOutline} />
                                                                ) : (
                                                                    <Icon className={'h-4 mr-1'} path={Icon.Path.mdiPlusBoxOutline} />
                                                                )}{' '}
                                                            </div>
                                                            <div>
                                                                {flexRender(cell.column.columnDef.cell, cell.getContext())} ({row.subRows.length})
                                                            </div>
                                                        </button>
                                                    </div>
                                                ) : cell.getIsAggregated() ? (
                                                    // If the cell is aggregated, use the Aggregated
                                                    // renderer for cell
                                                    <div>
                                                        {flexRender(cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell, cell.getContext())}
                                                    </div>
                                                ) : cell.getIsPlaceholder() ? null : ( // For cells with repeated values, render null
                                                    // Otherwise, just render the regular cell
                                                    <div>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                        {paddingBottom > 0 && (
                            <tr>
                                <td style={{ height: `${paddingBottom}px` }} />
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Table;
