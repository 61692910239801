import { AnyAction } from 'redux';
import { ISearchState } from './search.types';
import { searchTypes } from './index';

const initialState: ISearchState = {
    search: {
        value: '',
    },
};

const searchReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case searchTypes.SET_SEARCH_VALUE: {
            const { searchValue } = action;
            return {
                ...state,
                search: {
                    ...state.search,
                    value: searchValue,
                },
            };
        }
        default:
            return state;
    }
};

export default searchReducer;
