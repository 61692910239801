import { AnyAction } from 'redux';
import { unknownReadingStateType } from './unknownReading.types';

const initialState: unknownReadingStateType = {};

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default reducer;
