import React from 'react';
import './MeterMeta.less';
import classNames from 'classnames';
import LabeledList from '../LabeledList/LabeledList';
import OptionalLabel from '../OptionalLabel/OptionalLabel';
import { splitJoin } from '../../../helper/TextHelper';
import { Meter } from '../../../redux/jobs/jobs.types';
import { useTranslation } from 'react-i18next';
import { resolveMeterType } from '../../../helper/MeterHelper';

interface IMeterMetaProps {
    meter: Meter;
    className?: string;
}

const MeterMeta = ({ meter, className }: IMeterMetaProps) => {
    const { t } = useTranslation();

    if (!meter) return null;

    return (
        <div className={classNames('meter-meta', className)}>
            <LabeledList>
                <OptionalLabel label={t('jobs.details.labels.meterNumber')}>
                    {meter.meterNumber}
                </OptionalLabel>

                <OptionalLabel label={t('jobs.details.labels.meterType')}>
                    {meter.customMeterType || t(`common.metertypes.${resolveMeterType(meter)}`)}
                </OptionalLabel>

                <OptionalLabel label={t('evaluation.details.labels.meterAddress')}>
                    {(meter.meterAddress?.firstname || meter.meterAddress?.lastname) && (
                        <div>
                            {splitJoin(
                                ' ',
                                meter.meterAddress?.firstname,
                                meter.meterAddress?.lastname,
                            )}
                        </div>
                    )}
                    {(meter.meterAddress?.street || meter.meterAddress?.houseNumber) && (
                        <div>
                            {splitJoin(
                                ' ',
                                meter.meterAddress?.street,
                                meter.meterAddress?.houseNumber,
                            )}
                        </div>
                    )}
                    {(meter.meterAddress?.zip || meter.meterAddress?.city) && (
                        <div>
                            {splitJoin(
                                ' ',
                                meter.meterAddress?.zip,
                                splitJoin(
                                    ', ',
                                    meter.meterAddress?.city,
                                    meter.meterAddress?.cityDistrict,
                                ),
                            )}
                        </div>
                    )}
                </OptionalLabel>

                <OptionalLabel label={t('jobs.details.labels.objectKey')}>
                    {meter.meterAddress?.objectKey}
                </OptionalLabel>

                <OptionalLabel label={t('jobs.details.labels.locationDescription')}>
                    {meter.meterAddress?.locationDescription}
                </OptionalLabel>

                <OptionalLabel label={t('jobs.details.labels.hint')}>
                    {meter.meterAddress?.hint}
                </OptionalLabel>
            </LabeledList>
        </div>
    );
};

export default MeterMeta;
