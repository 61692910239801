import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './root.reducers';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import AsyncStorage from '@react-native-community/async-storage';
import { persistStore, persistReducer } from 'redux-persist';

const persistConfig = {
    key: 'envago:ksa:pro',
    storage: AsyncStorage,
    whitelist: ['login', 'queue', 'jobs', 'help'],
};

export const history = createBrowserHistory();

function configureStore(preloadedState) {
    const sagaMiddleware = createSagaMiddleware();

    const persistedReducer = persistReducer(persistConfig, createRootReducer(history));

    let store;
    if (process.env.NODE_ENV === 'production') {
        store = createStore(
            persistedReducer, // root reducer with router state
            preloadedState,
            compose(
                applyMiddleware(
                    routerMiddleware(history), // for dispatching history actions
                    // ... other middlewares ...
                    sagaMiddleware,
                ),
            ),
        );
    } else {
        store = createStore(
            persistedReducer, // root reducer with router state
            preloadedState,
            composeWithDevTools(
                applyMiddleware(
                    logger,
                    routerMiddleware(history), // for dispatching history actions
                    // ... other middlewares ...
                    sagaMiddleware,
                ),
            ),
        );
    }

    const persistor = persistStore(store);

    return {
        store,
        persistor,
        runSaga: sagaMiddleware.run,
    };
}

export const { store, runSaga, persistor } = configureStore();
