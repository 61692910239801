import React, { ReactChild, useEffect, useState } from 'react';
import './MainScreen.less';
import NavigationBar from './NavigationBar/NavigationBar';
import { useSelector } from 'react-redux';
import { applicationSelectors } from '../../redux/application';
import ApplicationLoadingScreen from '../ApplicationLoadingScreen/ApplicationLoadingScreen';
import Footer from '../common/Footer/Footer';
import { Helmet } from 'react-helmet';
import NotificationWrapper from './NotificationWrapper/NotificationWrapper';
import OfflinePanel from '../common/OfflinePanel/OfflinePanel';
import QueueStatusPanel from '../common/QueueStatusPanel/QueueStatusPanel';
import { useLocation } from 'react-router';
import classNames from 'classnames';
import { loginSelectors } from '../../redux/login';


const MainScreen = ({ children, showNavBarIfNotLoggedIn = false }: { children: any, showNavBarIfNotLoggedIn?: boolean }) => {

    const isLoaded = useSelector(applicationSelectors.isLoaded);

    const applicationName = useSelector(applicationSelectors.getApplicationName);

    const [loaded, setLoaded] = useState(false);

    const { pathname } = useLocation();
    console.log('location', pathname)



    useEffect(() => {
        if (isLoaded) {
            setTimeout(() => {
                setLoaded(true);
            }, 500);
        }
    }, [isLoaded]);

    if (!loaded) {
        return <>
            <ApplicationLoadingScreen />
            <div className={'hidden'}>

            </div>
        </>;
    }

    return (
        <div className='main-screen flex flex-col'>
            <Helmet>
                <title>{applicationName} | Zählerstandserfassung</title>
                <meta name='description' content={`Online Zählerstandserfassung der ${applicationName}`} />
                <link rel='manifest' href={`/api/public/application/manifest/manifest.json`}/>
            </Helmet>


            <NavigationBar showIfNotLoggedIn={showNavBarIfNotLoggedIn} />

            <div className={classNames('main--content-wrapper flex-1 flex-col flex justify-center')}
                 >
                <QueueStatusPanel/>
                <NotificationWrapper />
                {children}
            </div>


            <Footer />
        </div>
    );
};


export default MainScreen;
