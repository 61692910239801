import React, { useEffect } from 'react';
import './ExportExcel.less';
import Button from '../../../components/envago/Button/Button';
import classNames from 'classnames';
import { JobType } from '../../../redux/jobs/jobs.types';
import dotProp from 'dot-prop-immutable';
import { formatDate } from '../../../helper/DateHelper';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { sharesSelectors } from '../../../redux/shares';
import dayjs from 'dayjs';
import { revolveMeterTypeByObis } from '../../../helper/MeterHelper';
import { notificationActions } from '../../../redux/notification';

const importXlsx = () => {
    // @ts-ignore
    if (!window['XLSX']) {
        const xlsxScript = document.createElement('script');
        xlsxScript.setAttribute('src', 'https://static.ableseportal.de/js/xlsx.mini.min.js');
        xlsxScript.async = true;
        document.head.appendChild(xlsxScript);
    }
};

interface ExportExcelProps {
    jobs: JobType[];
    className?: string;
}

const ExportExcel = ({ jobs, className }: ExportExcelProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const createdAccount = useSelector(sharesSelectors.createGetAccount);

    useEffect(() => {
        importXlsx();
    }, []);

    const translateMeterTypes = (meterType: string) => {
        return t(`common.metertypes.${meterType}`) || meterType;
    };

    const createData = (jobs: JobType[]) => {
        const headers = [
            {
                name: t('management.accounts.share.exportXlsx.headers.partnerIdentifier'),
                path: 'partner.identifier',
            },
            {
                name: t('management.accounts.share.exportXlsx.headers.partnerCompany'),
                path: 'partner.company',
            },
            {
                name: t('management.accounts.share.exportXlsx.headers.customerNumber'),
                path: 'customer.customerNumber',
            },
            {
                name: t('management.accounts.share.exportXlsx.headers.customerCompany'),
                path: 'customer.company',
            },
            {
                name: t(
                    'management.accounts.share.exportXlsx.headers.customerMailingAddressStreet',
                ),
                path: 'customer.mailingAddress.street',
            },
            {
                name: t(
                    'management.accounts.share.exportXlsx.headers.customerMailingAddressHouseNumber',
                ),
                path: 'customer.mailingAddress.houseNumber',
            },
            {
                name: t('management.accounts.share.exportXlsx.headers.customerMailingAddressZip'),
                path: 'customer.mailingAddress.zip',
            },
            {
                name: t('management.accounts.share.exportXlsx.headers.customerMailingAddressCity'),
                path: 'customer.mailingAddress.city',
            },
            {
                name: t('management.accounts.share.exportXlsx.headers.maLoId'),
                path: 'meter.maLoId',
            },
            {
                name: t('management.accounts.share.exportXlsx.headers.meterAddressStreet'),
                path: 'meter.meterAddress.street',
            },
            {
                name: t('management.accounts.share.exportXlsx.headers.meterAddressHouseNumber'),
                path: 'meter.meterAddress.houseNumber',
            },
            {
                name: t('management.accounts.share.exportXlsx.headers.meterAddressZip'),
                path: 'meter.meterAddress.zip',
            },
            {
                name: t('management.accounts.share.exportXlsx.headers.meterAddressCity'),
                path: 'meter.meterAddress.city',
            },
            {
                name: t('management.accounts.share.exportXlsx.headers.meterAddressObjectKey'),
                path: 'meter.meterAddress.objectKey',
            },
            {
                name: t(
                    'management.accounts.share.exportXlsx.headers.meterAddressLocationDescription',
                ),
                path: 'meter.meterAddress.locationDescription',
            },
            {
                name: t('management.accounts.share.exportXlsx.headers.meterNumber'),
                path: 'meter.meterNumber',
            },
            {
                name: t('management.accounts.share.exportXlsx.headers.meterType'),
                path: 'meter.meterType',
            },
            {
                name: t('management.accounts.share.exportXlsx.headers.rateName'),
                path: 'meterRateTask.rateName',
            },
            {
                name: t('management.accounts.share.exportXlsx.headers.obis'),
                path: 'meterRateTask.obis',
            },
            {
                name: t('management.accounts.share.exportXlsx.headers.targetReadingDate'),
                path: 'meterRateTask.targetReadingDate',
            },
            {
                name: t('management.accounts.share.exportXlsx.headers.latestExecution'),
                path: 'jobConstraints.latestExecution',
            },
            {
                name: t('management.accounts.share.exportXlsx.headers.meterAddressHint'),
                path: 'meter.meterAddress.hint',
            },
            {
                name: t('management.accounts.share.exportXlsx.headers.lastMeterReadingDate'),
                path: 'meterRateTask.readingEvents.readingDate',
            },
            {
                name: t('management.accounts.share.exportXlsx.headers.lastMeterReadingValue'),
                path: 'meterRateTask.readingEvents.readingValue',
            },
            {
                name: t('management.accounts.share.exportXlsx.headers.meterReadingDate'),
                path: 'meterRateTask.readingResults.readingDate',
            },
            {
                name: t('management.accounts.share.exportXlsx.headers.meterReadingValue'),
                path: 'meterRateTask.readingResults.readingValue',
            },
        ];

        const data: [string[]] = [
            headers.map((headerItem) => {
                return headerItem.name;
            }),
        ];

        const rows = jobs.flatMap((job) => {
            return job.meter.meterRateTasks.map((task) => {
                return {
                    partner: job.partner,
                    customer: job.customer,
                    meter: job.meter,
                    meterRateTask: task,
                    jobConstraints: job.jobConstraints,
                };
            });
        });

        rows.forEach((row) => {
            const exportRow = headers.map((rowItem) => {
                if (
                    rowItem.path === 'meterRateTask.targetReadingDate' ||
                    rowItem.path === 'jobConstraints.latestExecution'
                ) {
                    return formatDate(dotProp.get(row, rowItem.path, ''));
                } else if (rowItem.path === 'meter.meterType') {
                    if (row.meter.customMeterType) {
                        return row.meter.customMeterType;
                    } else if (row.meter.meterType) {
                        return translateMeterTypes(row.meter.meterType);
                    } else if (row.meterRateTask.obis) {
                        return translateMeterTypes(revolveMeterTypeByObis(row.meterRateTask.obis));
                    }
                } else if (
                    rowItem.path === 'meterRateTask.readingEvents.readingDate' ||
                    rowItem.path === 'meterRateTask.readingEvents.readingValue'
                ) {

                    if(!row.meterRateTask.readingEvents || row.meterRateTask.readingEvents.length === 0){
                        return "";
                    } else {
                        const lastReadingEvent = row.meterRateTask.readingEvents?.reduce(
                          (prev, current) =>
                            dayjs(prev.readingDate).valueOf() > dayjs(current.readingDate).valueOf()
                              ? prev
                              : current,
                        );

                        if (rowItem.path === 'meterRateTask.readingEvents.readingDate') {
                            return formatDate(lastReadingEvent?.readingDate);
                        } else {
                            return lastReadingEvent?.readingValue;
                        }
                    }
                } else if (
                    rowItem.path === 'meterRateTask.readingResults.readingDate' ||
                    rowItem.path === 'meterRateTask.readingResults.readingValue'
                ) {
                    if(!row.meterRateTask.readingResults || row.meterRateTask.readingResults.length === 0){
                        return ""
                    } else {
                        const lastReadingResult = row.meterRateTask.readingResults?.reduce(
                          (prev, current) =>
                            dayjs(prev.readingDate).valueOf() > dayjs(current.readingDate).valueOf()
                              ? prev
                              : current,
                        );

                        if (rowItem.path === 'meterRateTask.readingResults.readingDate') {
                            return formatDate(lastReadingResult?.readingDate);
                        } else {
                            return lastReadingResult?.readingValue;
                        }
                    }
                }
                return dotProp.get(row, rowItem.path, '');
            });
            data.push(exportRow);
        });

        return data;
    };

    const fitToColumn = (aoa: [string[]]) => {
        return aoa[0].map((a, i) => ({
            wch: Math.max(...aoa.map((a2) => (a2[i] ? a2[i].toString().length + 1 : 0))),
        }));
    };

    const handleExport = () => {
        // @ts-ignore
        const xlsx = window['XLSX'];

        if (!xlsx) {
            dispatch(
                notificationActions.showErrorNotification(
                    t('management.accounts.share.exportXlsx.error.title'),
                    t('management.accounts.share.exportXlsx.error.message'),
                ),
            );
            return;
        }
        const data = createData(jobs);
        const workbook = xlsx.utils.book_new();
        const worksheet = xlsx.utils.aoa_to_sheet(data);
        worksheet['!cols'] = fitToColumn(data);
        xlsx.utils.book_append_sheet(workbook, worksheet, 'Excel Download');
        xlsx.writeFile(workbook, `${dayjs().format('YYYY-MM-DD')} - ${createdAccount?.name}.xlsx`);
    };

    return (
        <div className={classNames('export-excel', className)}>
            <Button small ghost onClick={handleExport} className={'block w-full'}>
                {t('management.accounts.share.exportXlsx.button')}
            </Button>
        </div>
    );
};

export default ExportExcel;
