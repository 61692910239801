import React from 'react';
import './Checkbox.less';
import classNames from 'classnames';

const Checkbox = ({
    value,
    onChange,
    label,
    className,
}: {
    label?: string;
    value?: boolean;
    onChange?: (value: boolean) => void;
    className?: string;
}) => {
    return (
        <label className={classNames('inline-flex items-center mt-3', className)}>
            <input
                className={'form-checkbox h-5 w-5 text-primary border'}
                type={'checkbox'}
                checked={value}
                onChange={(e) => {
                    if (onChange) onChange(e.target.checked);
                }}
            />
            {label && <span className={'ml-2 text-sm'}>{label}</span>}
        </label>
    );
};

export default Checkbox;
