import React, { ReactNode, useEffect, useState } from 'react';
import './SidePopover.less';
import Icon from '../Icon/Icon';
import classNames from 'classnames';
import BodyEnd from './BodyEnd';

type Props = {
    children: ReactNode;
    text?: string | ReactNode;
    hidden?: boolean;
    contentClassName?: string;
    title?: string;
};

const SidePopover = ({ children, text, contentClassName, title, hidden = false }: Props) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            console.log('event', event);
            if (event.code === 'Escape') {
                setVisible(false);
            }
        };
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);

    if (hidden) return null;

    return (
        <div
            className={'transition-all'}
            style={{
                position: 'absolute',
                right: 0,
            }}
        >
            <div
                onClick={() => {
                    setVisible(!visible);
                }}
                style={{}}
                className={'cursor-pointer p-4 bg-accent rounded-l-lg text-white'}
            >
                <div className={'inline-flex flex-col text-white gap-1'}>{text}</div>
            </div>

            <BodyEnd>
                <SidePopoverContent
                    title={title}
                    className={contentClassName}
                    visible={visible}
                    setVisible={setVisible}
                >
                    {children}
                </SidePopoverContent>
            </BodyEnd>
        </div>
    );
};

const SidePopoverContent = ({
    visible,
    setVisible,
    children,
    className,
    title,
}: {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    children: ReactNode;
    className?: string;
    title?: string;
}) => {
    return (
        <div
            style={{ zIndex: 999, right: visible ? 0 : '-100%', minWidth: 320 }}
            className={classNames(
                className,
                'fixed duration-500 transition-all md:max-w-md shadow-xl flex flex-col z-9 bg-white max-w-full top-0 bottom-0 right-0',
            )}
        >
            <div className={'flex w-full items-center mt-2'}>
                <h2 className={'ml-4'}>{title}</h2>
                <div className={'flex-1'}/>
                <div
                    className={'self-end p-2 cursor-pointer'}
                    onClick={() => {
                        setVisible(!visible);
                    }}
                >
                    <Icon
                        path={Icon.Path.mdiClose}
                        className={'w-8 text-gray-300 hover:text-gray-200 transition'}
                    />
                </div>
            </div>

            <div className={'flex-1 h-full max-h-full overflow-y-auto'}>
                <div className={''}>{children}</div>
            </div>
        </div>
    );
};

export default SidePopover;
