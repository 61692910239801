import { CustomMeterConfig, MeterConfig, ReadingDateValidation } from '../../../../../redux/application/application.types';
import React, { useEffect, useMemo, useState } from 'react';
import Checkbox from '../../../../../components/envago/Checkbox/Checkbox';
import MeterReadingInput from '../../../../../components/envago/MeterReadingInput/MeterReadingInput';
import MeterReadingTarif from '../../../../../components/envago/MeterReadingTarif/MeterReadingTarif';
import UploadImage from '../../../../../components/envago/UploadImage/UploadImage';
import { Trans, useTranslation } from 'react-i18next';
import DatePicker from '../../../../../components/envago/DatePicker/DatePicker';
import dayjs from 'dayjs';
import MeterReadingUnit from '../../../../../components/envago/MeterReadingUnit/MeterReadingUnit';
import Dropdown from '../../../../../components/envago/Dropdown/Dropdown';
import Button from '../../../../../components/envago/Button/Button';
import classNames from 'classnames';
import Description from '../../Description';
import Information, { InformationType } from '../../../../../components/envago/Information/Information';
import { MeterType, ReadingValue } from '../../../../../api/unknownUserReading.api.types';

export interface MeterReadingFormReadingValue extends Omit<ReadingValue, 'value' | 'readingImages'> {
    value?: string;
    readingImages?: Array<string>;
}

interface MeterReadingFormData {
    readingValues?: {
        [obis: string]: MeterReadingFormReadingValue;
    };
    readingDate?: string;
}

interface MeterReadingFormProps {
    initialValues: MeterReadingFormData;
    onChange: (data: MeterReadingFormData, isValid: boolean) => void;

    meterConfig?: MeterConfig<MeterType>;
    isQueryTransformerOrMWhPostDecimalsEnabled: boolean;
    validation?: ReadingDateValidation;
}

const MeterReadingForm = ({ initialValues, onChange, meterConfig, isQueryTransformerOrMWhPostDecimalsEnabled, validation }: MeterReadingFormProps) => {
    const [t] = useTranslation();

    const [data, setData] = useState<MeterReadingFormData>(initialValues);

    const [queryTransformerOrMWhPostDecimals, setQueryTransformerOrMWhPostDecimals] = useState(false);

    const hasPostDecimals = useMemo(() => {
        if (meterConfig?.meterRateConfigs?.some((c) => !!c.readingConfig?.postDecimals)) {
            return true;
        } else if (!!meterConfig?.defaultMeterReadingConfig?.postDecimals) {
            return true;
        }

        return false;
    }, [meterConfig]);

    const queryPostDecimals = useMemo(() => {
        if (meterConfig?.meterRateConfigs?.some((c) => c.readingConfig?.queryPostDecimals === true)) {
            return true;
        } else if (meterConfig?.defaultMeterReadingConfig?.queryPostDecimals === true) {
            return true;
        }

        return false;
    }, [meterConfig]);

    const [activeMeterRateConfigs, setActiveMeterRateConfigs] = useState(() => {
        if (meterConfig && 'defaultSelectedMeterRatesByOBIS' in meterConfig) {
            return meterConfig?.meterRateConfigs
                .filter((c) => (meterConfig as CustomMeterConfig<any>).defaultSelectedMeterRatesByOBIS.includes(c.obis))
                .map((c) => c.obis);
        }

        return meterConfig?.meterRateConfigs.map((c) => c.obis);
    });

    const isMeterRateEditable = useMemo(() => {
        return meterConfig && 'defaultSelectedMeterRatesByOBIS' in meterConfig;
    }, [meterConfig]);

    const canAddMeterRate = useMemo(() => {
        return activeMeterRateConfigs?.length !== meterConfig?.meterRateConfigs?.length;
    }, [activeMeterRateConfigs, meterConfig?.meterRateConfigs]);

    const canRemoveMeterRate = useMemo(() => {
        return activeMeterRateConfigs && activeMeterRateConfigs?.length > 1;
    }, [activeMeterRateConfigs]);

    const isReadingValuesValid = useMemo(() => {
        if (!data.readingValues) {
            return false;
        }

        if (Object.values(data.readingValues).some((readingValue) => !readingValue.value)) {
            return false;
        }

        const postDecimalsMatched = Object.values(data.readingValues).every((readingValue) => {
            const postDecimals =
                meterConfig?.meterRateConfigs?.filter((c) => activeMeterRateConfigs?.includes(c.obis)).find((c) => c.readingConfig?.postDecimals) ||
                meterConfig?.defaultMeterReadingConfig?.postDecimals ||
                0;

            const enteredPostDecimals = readingValue.value?.split('.')[1]?.length || 0;

            return postDecimals === enteredPostDecimals;
        });

        if (queryTransformerOrMWhPostDecimals && !postDecimalsMatched) {
            return false;
        }

        if (queryPostDecimals && !postDecimalsMatched) {
            return false;
        }

        return true;
    }, [data.readingValues, meterConfig, queryTransformerOrMWhPostDecimals, queryPostDecimals, activeMeterRateConfigs]);

    const isReadingImagesValid = useMemo(() => {
        const hasRequiredImageError =
            data.readingValues &&
            Object.values(data.readingValues).every((value) => {
                const isReadingImageRequired =
                    meterConfig?.meterRateConfigs
                        .filter((c) => activeMeterRateConfigs?.includes(c.obis))
                        .find((c) => c.readingConfig?.isReadingImageRequired) || meterConfig?.defaultMeterReadingConfig?.isReadingImageRequired;

                if (!isReadingImageRequired) {
                    return false;
                }

                return value.readingImages == null || value.readingImages?.length === 0;
            });

        return !hasRequiredImageError;
    }, [data.readingValues, activeMeterRateConfigs, meterConfig]);

    const isValid = useMemo(() => {
        return isReadingValuesValid && isReadingImagesValid;
    }, [isReadingImagesValid, isReadingValuesValid]);

    useEffect(() => {
        onChange(data, isValid);
    }, [data, isValid]);

    return (
        <div>
            <div className={'my-2'}>
                <h3>{t('unknownUserReading.meterReadingStep.title')}</h3>
                <Description>
                    <Trans
                        i18nKey={'unknownUserReading.meterReadingStep.description'}
                        values={{
                            preposition:
                                queryTransformerOrMWhPostDecimals || queryPostDecimals
                                    ? t('unknownUserReading.preposition.with')
                                    : t('unknownUserReading.preposition.without'),
                        }}
                    >
                        {'Bitte Tragen Sie nun die Zählerstände Ihres Zählers '}
                        <i>{'mit Nachkommastellen'}</i>
                        {' ein.'}
                    </Trans>
                </Description>
            </div>
            {isQueryTransformerOrMWhPostDecimalsEnabled && hasPostDecimals && (
                <div>
                    <Checkbox
                        label={t('unknownUserReading.label.queryTransformerOrMWhPostDecimals')}
                        value={queryTransformerOrMWhPostDecimals}
                        onChange={(value) => {
                            setQueryTransformerOrMWhPostDecimals(value);
                        }}
                    />
                </div>
            )}
            <h3 className={'mt-8 mb-4'}>{t('unknownUserReading.subTitle.meterReading')}</h3>
            <div className={'flex flex-col gap-2'}>
                {meterConfig?.meterRateConfigs
                    ?.filter((c) => activeMeterRateConfigs?.includes(c.obis))
                    .map((mr) => (
                        <div className={'flex flex-col gap-2'} key={mr.obis}>
                            <div className={'flex flex-row justify-between'}>
                                <div className={'w-full gap-2 flex flex-col sm:flex-row items-start md:w-max justify-start'}>
                                    <div className={'w-52'}>
                                        {isMeterRateEditable ? (
                                            <Dropdown
                                                isAutoScrollToLastOptionEnabled={false}
                                                values={meterConfig?.meterRateConfigs
                                                    .filter((c) => {
                                                        if (c.obis === mr.obis) return true;

                                                        return !activeMeterRateConfigs?.includes(c.obis);
                                                    })
                                                    .map((c) => ({
                                                        id: c.obis,
                                                        value: c.obis,
                                                        name: c.obisLabel || c.obis,
                                                    }))
                                                    .concat({
                                                        id: 'REMOVE',
                                                        value: 'REMOVE',
                                                        name: (
                                                            <>
                                                                <Button
                                                                    small
                                                                    block
                                                                    disabled={!canRemoveMeterRate}
                                                                    className={classNames(canRemoveMeterRate && 'bg-red-500')}
                                                                    onClick={() => {
                                                                        setActiveMeterRateConfigs((prevState) => prevState?.filter((o) => o !== mr.obis));

                                                                        setData((prevState) => {
                                                                            if (!prevState.readingValues)
                                                                                return {
                                                                                    ...prevState,
                                                                                };

                                                                            const readingValues = Object.entries(prevState.readingValues)
                                                                                .filter(([obis]) => obis !== mr.obis)
                                                                                .reduce((acc, [obis, value]) => {
                                                                                    return {
                                                                                        ...acc,
                                                                                        [obis]: value,
                                                                                    };
                                                                                }, {});

                                                                            return {
                                                                                ...prevState,
                                                                                readingValues: {
                                                                                    ...readingValues,
                                                                                },
                                                                            };
                                                                        });
                                                                    }}
                                                                >
                                                                    {t('unknownUserReading.button.removeMeterRate', '-')}
                                                                </Button>
                                                            </>
                                                        ) as any,
                                                    })}
                                                value={mr.obis}
                                                onChange={(value) => {
                                                    if (value === 'REMOVE') return;

                                                    setActiveMeterRateConfigs((prevState) => {
                                                        const clone = [...(prevState || [])];

                                                        const i = clone?.indexOf(mr.obis);

                                                        if (i !== -1) {
                                                            clone[i] = value;
                                                        }

                                                        return clone;
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <MeterReadingTarif obis={mr.obis} rateName={t(`unknownUserReading.rateName.${mr.rateName}`, mr.rateName)} />
                                        )}
                                    </div>
                                    <div className={'flex leading-6'}>
                                        <MeterReadingInput
                                            value={data?.readingValues?.[mr.obis]?.value}
                                            preDecimals={mr.readingConfig?.preDecimals ?? 9}
                                            postDecimals={
                                                queryTransformerOrMWhPostDecimals || queryPostDecimals
                                                    ? mr.readingConfig?.postDecimals || meterConfig?.defaultMeterReadingConfig?.postDecimals || 0
                                                    : 0
                                            }
                                            maskPostDecimals={!queryTransformerOrMWhPostDecimals && !queryPostDecimals}
                                            onChange={(value) => {
                                                let adjustedValue = value;

                                                if (!queryTransformerOrMWhPostDecimals && !queryPostDecimals && adjustedValue?.includes('.')) {
                                                    adjustedValue = adjustedValue?.split('.')?.[0];
                                                }

                                                setData((prevState) => {
                                                    if (!prevState.readingValues) return { ...prevState };

                                                    const readingValues: {
                                                        [obis: string]: MeterReadingFormReadingValue;
                                                    } = Object.entries(prevState.readingValues).reduce((acc, [obis, v]) => {
                                                        if (!activeMeterRateConfigs?.includes(obis)) {
                                                            return acc;
                                                        }

                                                        return {
                                                            ...acc,
                                                            [obis]: v,
                                                        };
                                                    }, {});

                                                    return {
                                                        ...prevState,
                                                        readingValues: {
                                                            ...readingValues,
                                                            [mr.obis]: {
                                                                ...readingValues[mr.obis],
                                                                obis: mr.obis,
                                                                value: adjustedValue,
                                                            },
                                                        },
                                                    };
                                                });
                                            }}
                                        />
                                        {isQueryTransformerOrMWhPostDecimalsEnabled && queryTransformerOrMWhPostDecimals ? null : (
                                            <MeterReadingUnit className={'text-gray-600 content-center'} obis={mr.obis} />
                                        )}
                                    </div>
                                </div>
                            </div>
                            {mr.readingConfig?.isReadingImageAllowed ||
                                (meterConfig?.defaultMeterReadingConfig?.isReadingImageAllowed && (
                                    <div>
                                        <UploadImage
                                            primary={
                                                !!(
                                                    (mr.readingConfig?.isReadingImageRequired ||
                                                        meterConfig.defaultMeterReadingConfig.isReadingImageRequired) &&
                                                    data.readingValues?.[mr.obis]?.readingImages?.length === 0
                                                )
                                            }
                                            onChange={(images) => {
                                                setData((prevState) => ({
                                                    ...prevState,
                                                    readingValues: {
                                                        ...prevState.readingValues,
                                                        [mr.obis]: {
                                                            ...prevState.readingValues?.[mr.obis],
                                                            readingImages: images,
                                                            obis: mr.obis,
                                                        },
                                                    },
                                                }));
                                            }}
                                            text={t('jobs.details.buttons.uploadImage')}
                                        />
                                    </div>
                                ))}
                        </div>
                    ))}
            </div>
            {isMeterRateEditable && (
                <Button
                    ghost
                    disabled={!canAddMeterRate}
                    onClick={() => {
                        const nextInactiveMeterRateConfig = meterConfig?.meterRateConfigs.filter((c) => !activeMeterRateConfigs?.includes(c.obis))?.[0];

                        if (nextInactiveMeterRateConfig) {
                            setActiveMeterRateConfigs((prevState) => prevState?.concat(nextInactiveMeterRateConfig.obis));
                            setData((prevState) => ({
                                ...prevState,
                                readingValues: {
                                    ...prevState.readingValues,
                                    [nextInactiveMeterRateConfig.obis]: {
                                        obis: nextInactiveMeterRateConfig.obis,
                                        rateName: nextInactiveMeterRateConfig.rateName,
                                        value: undefined,
                                        readingImages: undefined,
                                    },
                                },
                            }));
                        }
                    }}
                >
                    {'+ Tarif hinzufügen'}
                </Button>
            )}
            <div>
                <h4>{t('unknownUserReading.subTitle.readingDate')}</h4>
                <Description>{t('unknownUserReading.description.readingDate')}</Description>
                <DatePicker
                    value={dayjs(data.readingDate).toDate()}
                    minDate={dayjs()
                        .subtract(validation?.readingDateMaxDaysInPast ?? 30, 'days')
                        .startOf('day')
                        .toDate()}
                    maxDate={dayjs()
                        .add(validation?.readingDateMaxDaysInFuture ?? 0)
                        .endOf('day')
                        .toDate()}
                    onChange={(value) => {
                        setData((prevState) => ({
                            ...prevState,
                            readingDate: dayjs(value).toISOString(),
                        }));
                    }}
                />
            </div>
            {!isReadingValuesValid && (
                <Information showIcon={false} className={'mt-8'} type={InformationType.ERROR}>
                    {t('unknownUserReading.meterReadingStep.validationError')}
                </Information>
            )}
        </div>
    );
};

export default MeterReadingForm;
