import React, { useEffect } from 'react';
import './ManagementScreen.less';
import SideBarLayout from '../MainScreen/SideBarLayout';
import AccountList from './components/AccountList/AccountList';
import Toolbar from '../../components/envago/Toolbar/Toolbar';
import { Route, Switch } from 'react-router';
import SubAccountShareList from './components/SubAccountShareList/SubAccountShareList';
import { useDispatch, useSelector } from 'react-redux';
import { jobsActions, jobsSelectors } from '../../redux/jobs';
import { useIsMobile } from '../../helper/LayoutHelper';
import BasicScreen from '../common/BasicScreen/BasicScreen';
import { useTranslation } from 'react-i18next';
import Empty from '../../components/envago/Empty/Empty';

const ManagementScreen = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const hasJobs = useSelector(jobsSelectors.hasJobs);

    const isMobile = useIsMobile();

    useEffect(() => {
        if (!hasJobs) {
            dispatch(jobsActions.loadJobs());
        }
    }, [dispatch, hasJobs]);

    if (isMobile) {
        return (
            <Switch>
                <Route path={'/management/'} exact={true}>
                    <BasicScreen>
                        <Toolbar showBackButton={false} title={t('management.accounts.title')} />
                        <AccountList />
                    </BasicScreen>
                </Route>
                <Route path={'/management/account/:accountId/'}>
                    {({ match }) => {
                        return (
                            <div className={'flex flex-col flex-1 p-2'}>
                                {match?.params?.accountId && (
                                    <SubAccountShareList accountId={match?.params?.accountId} />
                                )}
                            </div>
                        );
                    }}
                </Route>
            </Switch>
        );
    }

    return (
        <Switch>
            <Route path={'/management/account/:accountId/:subAccountId?'}>
                {({ match }) => {
                    return (
                        <SideBarLayout
                            sidebar={
                                <BasicScreen>
                                    <Toolbar title={t('management.accounts.title')} />
                                    <AccountList />
                                </BasicScreen>
                            }
                        >
                            <div>
                                {match?.params?.accountId && (
                                    <SubAccountShareList accountId={match?.params?.accountId} />
                                )}
                            </div>
                        </SideBarLayout>
                    );
                }}
            </Route>
            <Route path={'/management/'}>
                <SideBarLayout
                    sidebar={
                        <BasicScreen>
                            <Toolbar title={t('management.accounts.title')} />
                            <AccountList />
                        </BasicScreen>
                    }
                >
                    <div className={'mt-20'}>
                        <Empty showIcon={true} children={<p>{t('management.hint')}</p>} />
                    </div>
                </SideBarLayout>
            </Route>
        </Switch>
    );
};

export default ManagementScreen;
