import React, { useEffect } from 'react';
import './EvaluationScreen.less';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { jobsActions, jobsSelectors } from '../../redux/jobs';
import { useIsMobile } from '../../helper/LayoutHelper';
import { Route, Switch, useParams } from 'react-router';
import Loading from '../../components/common/Loading/Loading';
import SideBarLayout from '../MainScreen/SideBarLayout';
import BasicScreen from '../common/BasicScreen/BasicScreen';
import JobFilterToolbar from '../JobsScreen/components/JobFilterToolbar/JobFilterToolbar';
import EvaluatedMeterDetails from './components/EvaluatedMeterDetails';
import Empty from '../../components/envago/Empty/Empty';
import EvaluatedJobList from './components/EvaluatedJobList';
import { applicationSelectors } from '../../redux/application';

const EvaluationScreen = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(jobsActions.loadJobs());
    }, [dispatch]);

    const isEvaluationEnabled = useSelector(applicationSelectors.isEvaluationEnabled);
    const isLoading = useSelector(jobsSelectors.isLoading);
    const jobs = useSelector(jobsSelectors.getFilteredJobs);

    let isMobile = useIsMobile();

    const { jobId }: any = useParams();

    if (!isEvaluationEnabled) return null;

    return (
        <>
            {isLoading && (
                <div className={'flex-1 p-4'}>
                    <Loading size={50} text={t('common.loadingJobs')} />
                </div>
            )}

            {!isLoading && !isMobile && (
                <Switch>
                    <Route exact={true} path={'/auswertung'}>
                        <SideBarLayout
                            sidebar={
                                <BasicScreen>
                                    <JobFilterToolbar />
                                    <EvaluatedJobList jobs={jobs} />
                                </BasicScreen>
                            }
                        >
                            <div className={'mt-20'}>
                                <Empty showIcon>{t('evaluation.hint')}</Empty>
                            </div>
                        </SideBarLayout>
                    </Route>
                    <Route exact={true} path={'/auswertung/:jobId'}>
                        <SideBarLayout
                            sidebar={
                                <BasicScreen>
                                    <JobFilterToolbar />
                                    <EvaluatedJobList jobs={jobs} />
                                </BasicScreen>
                            }
                        >
                            <EvaluatedMeterDetails jobId={jobId} />
                        </SideBarLayout>
                    </Route>
                </Switch>
            )}

            {!isLoading && isMobile && (
                <Switch>
                    <Route exact={true} path={'/auswertung'}>
                        <BasicScreen>
                            <JobFilterToolbar />
                            <EvaluatedJobList jobs={jobs} />
                        </BasicScreen>
                    </Route>
                    <Route exact={true} path={`/auswertung/:jobId`}>
                        <EvaluatedMeterDetails jobId={jobId} />
                    </Route>
                </Switch>
            )}
        </>
    );
};

export default EvaluationScreen;
