import { CommunicationState } from './communication.types';
import { AnyAction } from 'redux';
import { communicationActionTypes } from './communication.actions';

const initialState: CommunicationState & { lastActionType?: string } = {
    isRegistrationInfoPending: false,
    isSendingPending: false,
    isSendingBlocked: false, // solution to prevent multiple user triggered requests

    crmRegistrationInfo: undefined,

    lastActionType: undefined,
};

const communicationReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case communicationActionTypes.COMMUNICATION_GET_CRM_REGISTRATION_INFO: {
            return {
                ...state,
                isRegistrationInfoPending: true,
                lastActionType: communicationActionTypes.COMMUNICATION_GET_CRM_REGISTRATION_INFO,
            };
        }
        case communicationActionTypes.COMMUNICATION_GET_CRM_REGISTRATION_INFO_CANCELLED: {
            return {
                ...state,
                isRegistrationInfoPending: false,
                lastActionType: communicationActionTypes.COMMUNICATION_GET_CRM_REGISTRATION_INFO_CANCELLED,
            };
        }
        case communicationActionTypes.COMMUNICATION_GET_CRM_REGISTRATION_INFO_DONE: {
            return {
                ...state,
                crmRegistrationInfo: action.payload,
                isRegistrationInfoPending: false,
                lastActionType: communicationActionTypes.COMMUNICATION_GET_CRM_REGISTRATION_INFO_DONE,
            };
        }
        case communicationActionTypes.COMMUNICATION_GET_CRM_REGISTRATION_INFO_ERROR: {
            return {
                ...state,
                isRegistrationInfoPending: false,
                lastActionType: communicationActionTypes.COMMUNICATION_GET_CRM_REGISTRATION_INFO_ERROR,
            };
        }
        case communicationActionTypes.COMMUNICATION_SEND_CRM_READING_RECEIPT_REQUEST: {
            return {
                ...state,
                isSendingPending: true,
                lastActionType: communicationActionTypes.COMMUNICATION_SEND_CRM_READING_RECEIPT_REQUEST,
            };
        }
        case communicationActionTypes.COMMUNICATION_SEND_CRM_READING_RECEIPT_REQUEST_DONE: {
            return {
                ...state,
                isSendingPending: false,
                isSendingBlocked: true,
                lastActionType: communicationActionTypes.COMMUNICATION_SEND_CRM_READING_RECEIPT_REQUEST_DONE,
            };
        }
        case communicationActionTypes.COMMUNICATION_SEND_CRM_READING_RECEIPT_REQUEST_ERROR: {
            return {
                ...state,
                isSendingPending: false,
                isSendingBlocked: true,
                lastActionType: communicationActionTypes.COMMUNICATION_SEND_CRM_READING_RECEIPT_REQUEST_ERROR,
            };
        }
        case communicationActionTypes.COMMUNICATION_AUTOMATIC_TRIGGER_CRM_DIALOG: {
            return { ...state, lastActionType: communicationActionTypes.COMMUNICATION_AUTOMATIC_TRIGGER_CRM_DIALOG };
        }
        case communicationActionTypes.COMMUNICATION_MANUAL_TRIGGER_CRM_DIALOG: {
            return { ...state, lastActionType: communicationActionTypes.COMMUNICATION_MANUAL_TRIGGER_CRM_DIALOG };
        }
        case communicationActionTypes.COMMUNICATION_CLEAR_LAST_ACTION_TYPE: {
            return { ...state, lastActionType: undefined };
        }
        case communicationActionTypes.COMMUNICATION_UNBLOCK_SEND_CRM_READING_RECEIPT_REQUEST: {
            return { ...state, isSendingBlocked: false };
        }
        default:
            return state;
    }
};

export default communicationReducer;
