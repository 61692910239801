import React, { useEffect, useRef, useState } from 'react';
import './Collapsable.less';
import classNames from 'classnames';
import Icon from '../Icon/Icon';

type Props = {
    triggerText: string | React.ReactNode;
    arrowPosition?: 'left' | 'right' | 'none';
    children: React.ReactNode;
    className?: string;
    onChange?: (open: boolean) => void;
    open?: boolean; // Controlled mode
    defaultOpen?: boolean; // Uncontrolled mode initial state
    showChildrenDirect?: boolean;
    hideTriggerTextIfOpen?: boolean;
};

const Collapsable = ({
    triggerText,
    children,
    className,
    onChange,
    open,
    defaultOpen = false,
    arrowPosition = 'left',
    showChildrenDirect = false,
    hideTriggerTextIfOpen = false,
}: Props) => {
    const isControlled = open != null;
    const [internalOpen, setInternalOpen] = useState(defaultOpen);

    const contentRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState(0);

    // Sync state in controlled mode
    useEffect(() => {
        if (isControlled) {
            setInternalOpen(open);
        }
    }, [open, isControlled]);

    // Handle height recalculation
    useEffect(() => {
        const updateHeight = () => {
            if (contentRef.current) {
                setHeight(contentRef.current.scrollHeight);
            }
        };

        updateHeight();

        // Optional: Observe DOM changes for dynamic content
        const observer = new MutationObserver(() => updateHeight());
        if (contentRef.current) {
            observer.observe(contentRef.current, { childList: true, subtree: true });
        }

        return () => observer.disconnect();
    }, []);

    // Notify parent when `internalOpen` changes
    useEffect(() => {
        if (onChange) {
            onChange(internalOpen);
        }
    }, [internalOpen, onChange]);

    const toggleOpen = () => {
        if (!isControlled) {
            setInternalOpen((prev) => !prev);
        }
    };

    if (showChildrenDirect) {
        return <>{children}</>;
    }

    return (
        <div className="collapsable">
            {!(internalOpen && hideTriggerTextIfOpen) && (
                <div
                    className={classNames(
                        'collapsable-trigger cursor-pointer flex items-center -ml-1',
                        {
                            'justify-between flex-row-reverse': arrowPosition === 'right',
                        },
                        className,
                    )}
                    onClick={toggleOpen}
                >
                    {arrowPosition !== 'none' && (
                        <Icon
                            path={Icon.Path.mdiChevronRight}
                            className={classNames('w-5 transform transition flex-shrink-0', {
                                'rotate-90': internalOpen,
                            })}
                        />
                    )}
                    <div className="flex-grow">{triggerText}</div>
                </div>
            )}
            <div
                ref={contentRef}
                className={classNames('collapsable-content transition-all overflow-hidden origin-top', { 'collapsable-content-open': internalOpen })}
                style={{ maxHeight: !internalOpen ? 0 : height }}
            >
                {children}
            </div>
        </div>
    );
};

export default Collapsable;
