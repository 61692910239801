import React, { useEffect, useRef } from 'react';
import './TextArea.less';
import classNames from 'classnames';

interface TextAreaPropType {
    value: string;
    onChange: (value: string) => void;
    className?: string;
    placeholder?: string;
    maxLength?: number;
    hasFocus?: boolean;
}

const TextArea = ({
    value,
    onChange,
    className,
    placeholder = 'Hier Text eingeben...',
    maxLength,
    hasFocus = false,
}: TextAreaPropType) => {
    const ref = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (hasFocus) {
            setTimeout(() => {
                if (ref.current !== null) ref.current.focus();
            }, 50);
        }
    }, [hasFocus]);
    
    return (
        <div className={'flex z-0'}>
            <div className={'flex-1 relative'}>
                <textarea
                    ref={ref}
                    value={value}
                    maxLength={maxLength}
                    onChange={(e) => {
                        if (onChange) {
                            onChange(e.currentTarget.value.substring(0, maxLength));
                        }
                    }}
                    rows={5}
                    placeholder={placeholder}
                    className={classNames(
                        'p-2 flex-1 overflow-y-scroll whitespace-pre-line rounded-md border text-black w-full h-auto',
                        className,
                    )}
                />
                {maxLength && (
                    <span
                        className={
                            'text-xs text-right absolute w-full right-6 text-gray-300 bottom-2'
                        }
                    >
                        {value.length} / {maxLength}
                    </span>
                )}
            </div>
        </div>
    );
};

export default TextArea;
