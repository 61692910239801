import axios from 'axios';
import { UnknownUserReadingRequestDTO } from './unknownUserReading.api.types';

const fetchUnknownUserReadingToken = async () => {
    const response = await axios.post<{ token: string }>(`/auth/token-unknown-readings`);
    return response?.data?.token;
};

const sendUnknownUserReadingResult = async (request: UnknownUserReadingRequestDTO, token: string) => {
    const response = await axios.post('/api/unknown-readings/results', request, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response.data;
};

export default {
    fetchUnknownUserReadingToken,
    sendUnknownUserReadingResult,
} as const;
