import { Meter, MeterRateTaskType } from '../redux/jobs/jobs.types';
import { MeterType } from '../api/unknownUserReading.api.types';

const obisRegex = /(\d)-(\d{1,2})[:](\d{1,2})\.(\d{1,2})\.(\d{1,2})/;

export enum METER_TYPES {
    ELECTRIC_METER = 'ELECTRIC_METER',
    HEAT_METER = 'HEAT_METER',
    GAS_METER = 'GAS_METER',
    WATER_METER = 'WATER_METER',
    WATER_WARM_METER = 'WATER_WARM_METER',
    GENERIC_METER = 'GENERIC_METER',
}

export const getFakeObis = (meterType: METER_TYPES | undefined) => {
    switch (meterType) {
        case METER_TYPES.ELECTRIC_METER:
            return '1-0:0.0.0';
        case METER_TYPES.GAS_METER:
            return '7-0:0.0.0';
        case METER_TYPES.HEAT_METER:
            return '5-0:0.0.0';
        case METER_TYPES.WATER_METER:
            return '8-0:0.0.0';
        case METER_TYPES.GENERIC_METER:
            return '0-0:0.0.0';
        default:
            return '0-0:0.0.0';
    }
};

export const resolveMeterColorTailwindClass = (meterType: METER_TYPES | undefined) => {
    if (!meterType) return 'gray-100';
    switch (meterType) {
        case METER_TYPES.ELECTRIC_METER:
            return 'electric-meter';
        case METER_TYPES.GAS_METER:
            return 'gas-meter';
        case METER_TYPES.GENERIC_METER:
            return 'generic-meter';
        case METER_TYPES.HEAT_METER:
            return 'heat-meter';
        case METER_TYPES.WATER_METER:
        case METER_TYPES.WATER_WARM_METER:
            return 'water-meter';
    }
};

export const resolveMeterType = (meter: Meter | undefined): METER_TYPES => {
    if (!meter) return METER_TYPES.GENERIC_METER;

    if (meter.meterType) return meter.meterType;

    let obis = meter.meterRateTasks?.find((_j) => true)?.obis;

    if (!obis) return METER_TYPES.GENERIC_METER;

    return revolveMeterTypeByObis(obis);
};

export const revolveMeterTypeByObis = (obis?: string) => {
    if (!obis) return METER_TYPES.GENERIC_METER;
    let match = obis.match(obisRegex);
    switch (Number(match ? match[1] : 0)) {
        case 1:
            return METER_TYPES.ELECTRIC_METER;
        case 6:
            return METER_TYPES.HEAT_METER;
        case 7:
            return METER_TYPES.GAS_METER;
        case 8:
            return METER_TYPES.WATER_METER;
        case 9:
            return METER_TYPES.WATER_WARM_METER;
        default:
            return METER_TYPES.GENERIC_METER;
    }
};

export const resolveMeterTypeByMeterRate = (meterRate: { obis: string }) => {
    let obis = meterRate.obis;
    if (!obis) return 'GENERIC_METER';
    let match = obis.match(obisRegex);
    switch (Number(match ? match[1] : 0)) {
        case 1:
            return METER_TYPES.ELECTRIC_METER;
        case 6:
            return METER_TYPES.HEAT_METER;
        case 7:
            return METER_TYPES.GAS_METER;
        case 8:
            return METER_TYPES.WATER_METER;
        case 9:
            return METER_TYPES.WATER_WARM_METER;
        default:
            return METER_TYPES.GENERIC_METER;
    }
};

export const resolveMeterTypeByMeterNumber = (meterNumber: string, meterTypes: Array<MeterType>, fallbackValue?: MeterType | string): MeterType | undefined => {
    if (meterTypes) {
        if (meterNumber.match(/^1[A-Z]{3}\d/) && meterTypes.includes('ELECTRIC_METER')) {
            return 'ELECTRIC_METER';
        } else if (meterNumber.match(/^8[A-Z]{3}\d/) && meterTypes.includes('WATER_METER')) {
            return 'WATER_METER';
        } else if (meterNumber.match(/^9[A-Z]{3}\d/) && meterTypes.includes('WATER_WARM_METER')) {
            return 'WATER_WARM_METER';
        } else if (meterNumber.match(/^7[A-Z]{3}\d/) && meterTypes.includes('GAS_METER')) {
            return 'GAS_METER';
        } else if (meterNumber.match(/^[456][A-Z]{3}\d/) && meterTypes.includes('HEAT_METER')) {
            return 'HEAT_METER';
        }
    }

    return fallbackValue as MeterType;
};

/* eslint-disable */
export function resolveUnitByObis(obis?: string) {
    if (obis == null) return '';

    let match = obis.match(obisRegex);
    if (match) {
        let medium = Number.parseInt(match[1]);
        // let channel = Number.parseInt(match[2]);
        let physicalValue = Number.parseInt(match[3]);
        let quantity = Number.parseInt(match[4]);
        // let measurementType = Number.parseInt(match[5]);

        switch (medium) {
            case 1: {
                //electric
                switch (physicalValue) {
                    case 1:
                    case 2:
                    case 15:
                    case 16:
                        switch (quantity) {
                            case 2:
                            case 4:
                            case 5:
                            case 6:
                                return 'kW';
                        }
                        return 'kWh';
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                        switch (quantity) {
                            case 2:
                            case 4:
                            case 5:
                            case 6:
                                return 'kvar';
                        }
                        return 'kvarh';
                    case 9:
                        switch (quantity) {
                            case 2:
                            case 4:
                            case 5:
                            case 6:
                                return 'kVA';
                        }
                        return 'kVAh';
                    case 21:
                    case 41:
                    case 61:
                    case 22:
                    case 42:
                    case 62:
                    case 35:
                    case 55:
                    case 75:
                        return 'kWh';
                    case 11:
                    case 31:
                    case 51:
                    case 71:
                    case 91:
                        switch (quantity) {
                            case 7:
                            case 6:
                                return 'A';
                        }
                        return '';
                    case 12:
                    case 32:
                    case 52:
                        return 'V';
                    case 14:
                        return 'Hz';
                    default:
                        return '';
                }
            }
            case 2: {
                // heat
                return '';
            }
            case 5: //cooling
            case 6: {
                //heat+heat/cooling
                switch (physicalValue) {
                    case 1:
                        return 'mWh';
                    case 2:
                        return 'Vol';
                    case 8:
                        return 'P';
                    case 9:
                        return 'Vol/t';
                    case 10:
                    case 11:
                        return '°C';
                }
                return '';
            }
            case 7: {
                //gas
                switch (physicalValue) {
                    case 3:
                        return 'm³';
                    case 41:
                        return '°C';
                    default:
                        return '';
                }
            }
            case 8: //cold water
            case 9: {
                //hot water
                switch (physicalValue) {
                    case 1:
                        return 'm³';
                    case 2:
                        return 'm³/h';
                    default:
                        return '';
                }
                ///water
            }
        }
    }

    return '';
} /* eslint-enable */

export const shortenObis = (obis?: string) => (obis ? obis.substring(obis.indexOf(':') + 1, obis.length) : '');

export const resolveReadableRateNameWithObis = (task: MeterRateTaskType) => {
    return task.rateName && task.obis ? `${task.rateName} (${shortenObis(task.obis)})` : task.rateName || shortenObis(task.obis);
};
