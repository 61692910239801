import React, { ReactNode } from 'react';
import './OptionalRow.less';

const OptionalRow = ({ children, className }: { children: ReactNode, className?: string }) => {

    if (!children)
        return null;

    return (
        <div className={className}>
            {children}
        </div>
    );
};

export default OptionalRow;
