import React, { useEffect, useState } from 'react';
import './QueueStatusPanel.less';
import { useDispatch, useSelector } from 'react-redux';
import { offlineActions, offlineSelectors } from '../../../redux/offline';
import Icon from '../../../components/envago/Icon/Icon';
import Button from '../../../components/envago/Button/Button';
import classNames from 'classnames';
import queueSelectors from '../../../redux/offline/queue.selectors';
import Dialog from '../../../components/envago/Dialog/Dialog';
import Information, { InformationType } from '../../../components/envago/Information/Information';
import { Trans, useTranslation } from 'react-i18next';

const QueueStatusPanel = ({}) => {

    const { t } = useTranslation();

    const queueLength = useSelector(queueSelectors.getQueueLength);
    const isQueueSending = useSelector(offlineSelectors.isQueueSending);
    const dispatch = useDispatch();
    const hasDialogBeenShown = useSelector(offlineSelectors.hasDialogBeenShown);

    if (queueLength === 0) return null;


    return (
        <>
            <Dialog
                visible={!hasDialogBeenShown}
                title={'Achtung: Keine Internetverbindung'}
                actions={[
                    <Button
                        onClick={() => {
                            dispatch(offlineActions.offlineMessageShown());
                        }}
                        key={'close-btn'}
                    >
                        {t('offline.buttons.close')}
                    </Button>,
                ]}
            >
                <Information type={InformationType.WARNING}>
                    {t('offline.warning')}
                </Information>

                <p className={'md:max-w-screen-sm mt-4'}>
                    <b>
                        {t('offline.description')}
                    </b>
                </p>

                <p className={'md:max-w-screen-sm mt-4'}>
                    {t('offline.details')}
                </p>
            </Dialog>

            <div className="queue-status-panel z-30 md:fixed md:right-1 md:bottom-1 mt-2 p-2 bg-yellow-50 shadow-xl text-sm flex md:flex-row flex-col rounded-md">
                <div className={'flex'}>
                    <Icon
                        path={Icon.Path.mdiAlertCircleOutline}
                        className={'text-yellow-600 h-6 self-center justify-self-center mr-2'}
                    />
                    <div className={'flex-1 mr-4'}>
                        <div>{t('offline.infopanel.message', {queueLength})}</div>
                        <div className={'text-xs'}>
                            {t('offline.infopanel.details')}
                        </div>
                    </div>
                </div>
                <div className={'flex justify-end'}>
                    <Button
                        className={'md:inline'}
                        primary={true}
                        small={true}
                        disabled={isQueueSending}
                        onClick={() => {
                            dispatch(offlineActions.queueTrySend());
                        }}
                    >
                        <Icon
                            path={Icon.Path.mdiRefreshCircle}
                            className={classNames('mr-2 h-6', {
                                'animate-spin': isQueueSending,
                            })}
                        />
                        {t('offline.buttons.retry')}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default QueueStatusPanel;
