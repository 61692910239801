import React, { useMemo } from 'react';
import './MeterReadingEventsHistory.less';
import { MeterRateTaskType, ReadingEventType } from '../../../../redux/jobs/jobs.types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { resolveReadableRateNameWithObis, resolveUnitByObis } from '../../../../helper/MeterHelper';
import { evaluationSelectors } from '../../../../redux/evaluation';
import { useTranslation } from 'react-i18next';
import Collapsable from '../../../../components/envago/Collapsable/Collapsable';
import { groupBy } from '../../../../helper/GroupHelper';
import dayjs from 'dayjs';
import { formatDate } from '../../../../helper/DateHelper';
import {
    formatNumberToLocaleString,
    insertArithmeticOperator,
    splitJoin,
} from '../../../../helper/TextHelper';
import Icon from '../../../../components/envago/Icon/Icon';
import { resolveReadingEventsConsumptions } from '../../../../helper/ConsumptionHelper';
import ReadingValueComposedChart from '../../../../components/envago/ReadingValueComposedChart';
import { roundNumberToLocaleString } from '../../../../helper/NumberHelper';
import { applicationSelectors } from '../../../../redux/application';

interface IMeterReadingEventsHistoryProps {
    task: MeterRateTaskType;
    className?: string;
    defaultOpen?: boolean;
}

const MeterReadingEventsHistory = ({
    task,
    className,
    defaultOpen = false,
}: IMeterReadingEventsHistoryProps) => {
    const { t } = useTranslation();
    const readings = useSelector(evaluationSelectors.getUnifiedReadingsForTask(task));
    const evaluationIgnoreEstimatedReadingIfOtherReadingIsWithinDays = useSelector(
        applicationSelectors.evaluationIgnoreEstimatedReadingIfOtherReadingIsWithinDays,
    );

    const readableRateName = resolveReadableRateNameWithObis(task);
    const groupedEvaluatedReadingsByYear = useMemo(() => {
        const readingsEventsWithConsumptions = resolveReadingEventsConsumptions(
            readings,
            evaluationIgnoreEstimatedReadingIfOtherReadingIsWithinDays,
        );

        const readingsByYear = groupBy(
            readingsEventsWithConsumptions,
            'readingDate',
            (readingDate) => dayjs(readingDate).format('YYYY'),
        );

        return Object.entries(readingsByYear).sort(([a], [b]) => b.localeCompare(a));
    }, [readings, evaluationIgnoreEstimatedReadingIfOtherReadingIsWithinDays]);

    const chartData = useMemo(
        () =>
            readings.map((event) => {
                return {
                    readingDate: dayjs(event.readingDate).valueOf(),
                    readingValue: event.readingValue,
                };
            }),
        [readings],
    );

    const resolveReadableReason = (event: ReadingEventType) => {
        if (event.reason === 'OTHER') {
            return event.otherReason
                ? t([`common.reasons.${event.otherReason}`, `${event.otherReason}`]) // with fallback
                : t(`common.reasons.${event.reason}`);
        } else {
            return t(`common.reasons.${event.reason}`);
        }
    };

    if (groupedEvaluatedReadingsByYear.length === 0) {
        return null;
    }

    return (
        <>
            <Collapsable
                defaultOpen={defaultOpen}
                className={classNames(
                    'meter-reading-events-history mb-4 bg-gray-100 shadow-md p-2 rounded',
                    className,
                )}
                arrowPosition={'right'}
                triggerText={
                    <div
                        className={'text-lg font-medium h-6 flex justify-between items-center mr-2'}
                    >
                        <div className={'self-center'}>{readableRateName || 'Ablesehistorie'}</div>
                        {readings.length > 1 && (
                            <div className={'self-center'}>
                                <ReadingValueComposedChart data={chartData} />
                            </div>
                        )}
                    </div>
                }
            >
                {groupedEvaluatedReadingsByYear.map(([year, readingEvents], index) => (
                    <div key={index} className={'flex flex-col mb-4 ml-1 gap-y-2'}>
                        <div className={'text-xl font-bold text-gray-300'}>{year}</div>
                        <div className={'mx-4 flex flex-col gap-y-2'}>
                            {readingEvents.map((event, index) => {
                                const unit = task.obis && resolveUnitByObis(task.obis);
                                const readingValueReadable = formatNumberToLocaleString(
                                    event.readingValue,
                                    task.postDecimals,
                                );

                                const consumptionReadable = insertArithmeticOperator(
                                    formatNumberToLocaleString(
                                        event.consumption,
                                        task.postDecimals,
                                    ),
                                );

                                const consumptionPerDayReadable = roundNumberToLocaleString(
                                    event.consumptionPerDay,
                                );

                                return (
                                    <div
                                        key={index}
                                        className={
                                            'ml-2 pl-2 border-l border-solid border-gray-300'
                                        }
                                    >
                                        <div
                                            className={
                                                'text-sm font-medium xl:flex xl:justify-between'
                                            }
                                        >
                                            <div className={'flex flex-nowrap gap-x-4 ml-4 mb-1'}>
                                                <div className={'w-8'}>
                                                    {formatDate(event.readingDate, 'DD.MM.')}
                                                </div>
                                                <div className={'text-gray-400'}>
                                                    {resolveReadableReason(event)}
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    'grid grid-cols-8 grid-rows-2 w-64 xl:mr-24 items-baseline gap-x-1 ml-auto md:mx-auto'
                                                }
                                            >
                                                <div
                                                    className={classNames(
                                                        'text-right row-span-1 row-start-1 col-span-7 md:col-span-6',
                                                        {
                                                            'text-gray-400': event.isGhost,
                                                        },
                                                    )}
                                                >
                                                    {splitJoin(' ', readingValueReadable, unit)}
                                                </div>
                                                {consumptionReadable && (
                                                    <>
                                                        <div
                                                            className={classNames(
                                                                'mt-1 mb-2 text-xs font-light row-span-1 row-start-2 col-start-2 md:col-start-1 col-span-6 flex flex-nowrap gap-x-0.5 justify-end',
                                                                {
                                                                    'text-gray-400': event.isGhost,
                                                                },
                                                            )}
                                                        >
                                                            <div>{consumptionReadable}</div>
                                                            <div>{unit}</div>
                                                        </div>
                                                        {consumptionPerDayReadable && (
                                                            <div
                                                                className={
                                                                    'text-xs font-light text-gray-400 row-span-1 row-start-2 col-span-2 flex flex-nowrap gap-x-0.5 invisible md:visible'
                                                                }
                                                            >
                                                                <div>{`(`}</div>
                                                                <Icon
                                                                    className={
                                                                        'flex-shrink-0 self-center w-3'
                                                                    }
                                                                    path={
                                                                        Icon.Path.mdiDiameterVariant
                                                                    }
                                                                />
                                                                <div>
                                                                    {consumptionPerDayReadable}
                                                                </div>
                                                                <div>{`/`}</div>
                                                                <div>{t('common.day')}</div>
                                                                <div>{`)`}</div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                ))}
            </Collapsable>
        </>
    );
};

export default MeterReadingEventsHistory;
