import * as serviceWorkerRegistration from '../../../serviceWorkerRegistration';
import { useEffect, useState } from 'react';
import Dialog from '../../../components/envago/Dialog/Dialog';
import Button from '../../../components/envago/Button/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { offlineSelectors } from '../../../redux/offline';

const CheckUpdates = () => {
    const { t } = useTranslation();

    const isOnline = useSelector(offlineSelectors.isOnline);

    const [dialogVisible, setDialogVisible] = useState(false);
    const [serviceWorker, setServiceWorker] = useState<ServiceWorker>();

    useEffect(() => {

        serviceWorkerRegistration.register({
            onUpdate: (registration) => {
                const waitingServiceWorker = registration.waiting;

                if (waitingServiceWorker && isOnline) {
                    setDialogVisible(true);
                    setServiceWorker(waitingServiceWorker);
                }
            }
        });
    }, []);

    if (dialogVisible) {
        return (
            <Dialog
                closable={true}
                onClose={() => {
                    setDialogVisible(false);
                }}
                title={t('update.dialog.title')}
                visible={dialogVisible}
                actions={[
                    <Button
                        onClick={()=>{
                            setDialogVisible(false);
                            // if(serviceWorker){
                            //     console.log("sw skip waiting")
                            //     serviceWorker.postMessage({ type: 'SKIP_WAITING' });
                            // }
                        }}
                        ghost={true}>{t('update.dialog.buttonNegative')}</Button>,
                    <Button
                        onClick={() => {
                            if (serviceWorker) {
                                serviceWorker.addEventListener('statechange', (event) => {
                                    if (event && event.target && 'state' in event.target) {
                                        if (event.target?.state === 'activated') {
                                            window.location.reload();
                                        }
                                    }
                                });
                                serviceWorker.postMessage({ type: 'SKIP_WAITING' });
                            }
                        }}
                        primary={true}
                    >
                        {t('update.dialog.buttonPositive')}
                    </Button>,
                ]}
            >
                <div className={'max-w-md'}>
                    <p className={'font-semibold mb-4'}>{t('update.dialog.content')}</p>
                    <p className={''}>{t('update.dialog.hint')}</p>
                </div>
            </Dialog>
        );
    }

    return null;
};

export default CheckUpdates;
