import React, { ReactElement, useEffect, useState } from 'react';
import './SubAccountShareList.less';
import { useDispatch, useSelector } from 'react-redux';
import { sharesActions, sharesSelectors } from '../../../../redux/shares';
import Empty from '../../../../components/envago/Empty/Empty';
import { jobsSelectors } from '../../../../redux/jobs';
import { pluralHelper } from '../../../../helper/TextHelper';
import Toolbar from '../../../../components/envago/Toolbar/Toolbar';
import Button from '../../../../components/envago/Button/Button';
import Dialog from '../../../../components/envago/Dialog/Dialog';
import { SharedAccountType } from '../../../../redux/shares/shares.types';
import AccountShareJobs from '../AccountShareJobs/AccountShareJobs';
import Icon from '../../../../components/envago/Icon/Icon';
import ShareJobDialog from '../../../common/ShareJobDialog/ShareJobDialog';
import { JobType } from '../../../../redux/jobs/jobs.types';
import { loginSelectors } from '../../../../redux/login';
import Loading from '../../../../components/common/Loading/Loading';
import ProgressBar from '../../../../components/envago/ProgressBar/ProgressBar';
import LabeledList from '../../../../components/envago/LabeledList/LabeledList';
import OptionalLabel from '../../../../components/envago/OptionalLabel/OptionalLabel';
import Card from '../../../../components/envago/Card/Card';
import BasicScreen from '../../../common/BasicScreen/BasicScreen';
import SubAccountDetails from '../SubAccountDetails/SubAccountDetails';
import { useParams } from 'react-router';
import Hint from '../../../../components/envago/Hint/Hint';
import ShareJobGroupsDialog from '../../../common/ShareJobGroupsDialog/ShareJobGroupsDialog';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '../../../../helper/LayoutHelper';
import Information, {
    InformationType,
} from '../../../../components/envago/Information/Information';
import { applicationSelectors } from '../../../../redux/application';

const ShareDetails = ({ share, accountId }: { share: SharedAccountType; accountId: string }) => {
    const { t } = useTranslation();

    const params: any = useParams();

    const subAccountIdParam = params?.subAccountId;

    const jobIds = share.jobs.map((j) => j.id);

    const jobsLoading = useSelector(jobsSelectors.isLoading);
    const jobs = useSelector(jobsSelectors.getJobsByIds(jobIds));
    const isSendSubaccountReminderMailtoLinkEnabled = useSelector(
        applicationSelectors.isSendSubaccountReminderMailtoLinkEnabled,
    );

    const [detailsDialogVisible, setDetailsDialogVisible] = useState(
        subAccountIdParam === share.id,
    );

    const jobsDone = jobs.filter((job) => job.done).length;

    return (
        <Card>
            <Toolbar
                title={share.name}
                toolbarButtons={[
                    isSendSubaccountReminderMailtoLinkEnabled && (
                        <Button
                            key={'reminder-mailto-button'}
                            small={true}
                            iconButton={true}
                            ghost={true}
                            primary={false}
                            onClick={() => {
                                const subject = t('management.accounts.reminder.subject');

                                const loginURL = `${window.location.origin}/m/${share.token}`;
                                const body = t('management.accounts.reminder.body', {
                                    name: share.name,
                                    login: loginURL,
                                });

                                const mailToUrl = `mailto:?subject=${encodeURI(
                                    subject,
                                )}&body=${encodeURI(body)}`;

                                window.open(mailToUrl, '_self');
                            }}
                        >
                            <Icon path={Icon.Path.mdiBellRingOutline} />
                        </Button>
                    ),
                    <Button
                        small={true}
                        iconButton={true}
                        ghost={true}
                        primary={true}
                        key={'share-details-button'}
                        onClick={() => {
                            setDetailsDialogVisible(true);
                        }}
                    >
                        <Icon path={Icon.Path.mdiEye} />
                    </Button>,
                ]}
            />
            <LabeledList>
                <OptionalLabel label={t('common.total')}>
                    {t('common.jobs', { count: jobs.length })}
                </OptionalLabel>
                <OptionalLabel label={t('common.progress')}>
                    <ProgressBar showPercentage={true} progress={jobsDone / jobs.length} />
                </OptionalLabel>
            </LabeledList>

            <Dialog
                visible={detailsDialogVisible}
                actions={[
                    <Button
                        onClick={() => {
                            setDetailsDialogVisible(false);
                        }}
                        key={'close-button'}
                        primary={true}
                    >
                        Schließen
                    </Button>,
                ]}
                closable={true}
                onClose={setDetailsDialogVisible}
            >
                {jobsLoading && <Loading text={t('common.loadingJobs')} />}
                {!jobsLoading && (
                    <SubAccountDetails
                        openedByToken={!!subAccountIdParam}
                        accountId={accountId}
                        subAccount={share}
                        jobs={jobs}
                    />
                )}
            </Dialog>
        </Card>
    );
};

const SubAccountShareList = ({ accountId }: { accountId: string }) => {
    const groupShareActivated = false; // TODO: Group-Sharing is not yet supported -> add in later versions
    const isMobile = useIsMobile();

    const { t } = useTranslation();

    const [createShareVisible, setCreateShareVisible] = useState(false);
    const [selectedJobs, setSelectedJobs] = useState<JobType[]>([]);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);

    const [selectedGroups, setSelectedGroups] = useState<{ name: string; data: JobType[] }[]>([]);

    const [showShareDialog, setShowShareDialog] = useState(false);
    const [showShareGroupsDialog, setShowShareGroupsDialog] = useState(false);

    const accountInfo = useSelector(loginSelectors.getAccountInfoForAccount(accountId));

    const shares = useSelector(sharesSelectors.getSharesForAccount(accountId));
    const showShareButton = useSelector(sharesSelectors.showShareButtonForAccount(accountId));

    const dispatch = useDispatch();

    useEffect(() => {
        if (!showShareDialog) {
            dispatch(sharesActions.loadSubAccountsForAccount(accountId));
        }
    }, [showShareDialog]);

    return (
        <>
            <BasicScreen>
                <Toolbar
                    title={accountInfo?.name}
                    showBackButton={true}
                    toolbarButtons={
                        showShareButton && (
                            <Button
                                className={'sub-account-share-list__share-btn'}
                                small={true}
                                primary={true}
                                key={'share-button'}
                                onClick={() => {
                                    setCreateShareVisible(true);
                                }}
                            >
                                <Icon path={Icon.Path.mdiShare} />
                                <span>
                                    {isMobile
                                        ? t('management.accounts.subaccounts.buttons.shareShort')
                                        : t('management.accounts.subaccounts.buttons.share')}
                                </span>
                            </Button>
                        )
                    }
                />
                <div className={'flex flex-col gap-2'}>
                    {shares?.map((share) => (
                        <ShareDetails key={share.id} accountId={accountId} share={share} />
                    ))}
                    {shares?.length === 0 && (
                        <Empty>{t('management.accounts.subaccounts.empty')}</Empty>
                    )}
                </div>
            </BasicScreen>

            <Dialog
                visible={createShareVisible}
                closable={true}
                title={t('management.accounts.share.title')}
                actions={[
                    groupShareActivated && (
                        <div className={'flex-1'}>
                            <Button
                                key="share-group"
                                accent={true}
                                block={true}
                                disabled={
                                    !(selectedGroups.length >= 1 && selectedJobs.length !== 1)
                                }
                                onClick={() => {
                                    setCreateShareVisible(false);
                                    setShowShareGroupsDialog(true);
                                }}
                            >
                                {selectedGroups.length}{' '}
                                {pluralHelper(selectedIds.length, 'Zugang', 'Zugänge')} erstellen
                            </Button>
                            <small>
                                {selectedGroups.length >= 1 && selectedJobs.length !== 1 ? (
                                    <span>
                                        Für jede ausgewählte Gruppe wird ein separater Zugang
                                        erstellt, insgesamt {selectedGroups.length}{' '}
                                        {pluralHelper(selectedIds.length, 'Zugang', 'Zugänge')}.
                                    </span>
                                ) : (
                                    <span>
                                        <Hint /> Nutze das Gruppierung-Symbol um mehrere Zugänge auf
                                        einmal zu erstellen.
                                    </span>
                                )}
                            </small>
                        </div>
                    ),
                    <div className={'flex-1'}>
                        {selectedJobs.length > 999 && (
                            <Information
                                type={InformationType.ERROR}
                                className={'text-sm text-red-500'}
                            >
                                {t('management.accounts.share.error.maxjobs', {
                                    count: 999,
                                })}
                            </Information>
                        )}
                        <Button
                            key="share"
                            primary={true}
                            block={true}
                            disabled={selectedJobs.length === 0 || selectedJobs.length > 999}
                            onClick={() => {
                                setCreateShareVisible(false);
                                setShowShareDialog(true);
                            }}
                        >
                            {t('management.accounts.share.buttons.share', {
                                count: selectedIds.length,
                            })}
                            <br />
                        </Button>
                    </div>,
                ]
                    .filter(Boolean)
                    .map((a, index) => React.cloneElement(a as ReactElement, { key: index }))}
                onClose={setCreateShareVisible}
                classNameContent={'w-max h-full max-w-full'}
            >
                <AccountShareJobs
                    onSelectionChanged={(jobIds, jobs, groups) => {
                        console.log('Selected Jobs', jobIds, jobs);
                        setSelectedJobs(jobs);
                        setSelectedIds(jobIds);
                        setSelectedGroups(groups);
                    }}
                    accountId={accountId}
                />
            </Dialog>

            <ShareJobGroupsDialog
                groups={selectedGroups}
                visible={showShareGroupsDialog}
                onClose={setShowShareGroupsDialog}
            />

            <ShareJobDialog
                jobs={selectedJobs}
                visible={showShareDialog}
                onClose={setShowShareDialog}
            />
        </>
    );
};

export default SubAccountShareList;
