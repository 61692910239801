import { AnyAction } from 'redux';
import { SharedAccountType, ShareJobsResultType } from './shares.types';
import { sharesTypes } from './index';

export interface sharesStateType {
    shares: {
        [loginId: string]: SharedAccountType[];
    };
    create: {
        sending: boolean;
        created: boolean;
        error?: true;
        account?: ShareJobsResultType | undefined;
    };
    emails?: {
        emails: Array<string>;
        sending: boolean;
        done: boolean;
        error?: boolean;
    };
}

const initialState: sharesStateType = {
    shares: {},
    create: {
        sending: false,
        created: false,
        account: undefined,
    },
};

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case sharesTypes.LOAD_SUBACCOUNT_DONE: {
            const { sharedAccounts, accountId } = action;
            return {
                ...state,
                shares: {
                    ...state.shares,
                    [accountId]: sharedAccounts,
                },
            };
        }
        case sharesTypes.CREATE_SUBACCOUNT_RESET: {
            return {
                ...state,
                create: {
                    ...initialState.create,
                },
            };
        }
        case sharesTypes.CREATE_SUBACCOUNT: {
            return {
                ...state,
                create: {
                    ...state.create,
                    sending: true,
                    created: false,
                    error: undefined,
                    account: undefined,
                },
            };
        }
        case sharesTypes.CREATE_SUBACCOUNT_DONE: {
            const { account } = action;
            return {
                ...state,
                create: {
                    ...state.create,
                    sending: false,
                    created: true,
                    account: account,
                },
                emails: initialState.emails,
            };
        }
        case sharesTypes.CREATE_SUBACCOUNT_ERROR: {
            const { error } = action;
            return {
                ...state,
                create: {
                    ...state.create,
                    sending: false,
                    created: false,
                    error: error,
                    account: undefined,
                },
            };
        }
        case sharesTypes.SEND_SUBACCOUNT_EMAIL: {
            return {
                ...state,
                emails: {
                    ...state.emails,
                    sending: true,
                    done: false,
                    error: false,
                    emails: undefined,
                },
            };
        }
        case sharesTypes.SEND_SUBACCOUNT_EMAIL_DONE: {
            const { mailTo } = action;
            return {
                ...state,
                emails: {
                    ...state.emails,
                    sending: false,
                    done: true,
                    error: false,
                    emails: mailTo,
                },
            };
        }
        case sharesTypes.SEND_SUBACCOUNT_EMAIL_ERROR: {
            const { mailTo } = action;
            return {
                ...state,
                emails: {
                    ...state.emails,
                    sending: false,
                    done: false,
                    error: true,
                    emails: mailTo,
                },
            };
        }
        case sharesTypes.CREATE_LOGIN_FOR_SUBACCOUNT: {
            return {
                ...state,
                create: {
                    ...state.create,
                    sending: true,
                },
            };
        }
        case sharesTypes.CREATE_LOGIN_FOR_SUBACCOUNT_DONE: {
            let { login } = action;
            return {
                ...state,
                create: {
                    ...state.create,
                    account: login,
                    created: true,
                    sending: false,
                },
            };
        }
        case sharesTypes.DELETE_SHARE_DONE: {
            let { shareId, accountId } = action;
            return {
                ...state,
                shares: {
                    ...state.shares,
                    [accountId]: state.shares[accountId].filter((s) => s.id !== shareId),
                },
            };
        }

        default:
            return state;
    }
};

export default reducer;
