import { createSelector } from 'reselect';
import { ISearchState } from './search.types';

const getSubState = (state: any) => state.search as ISearchState;

const getSearchValue = createSelector(getSubState, (state) => state.search.value);

const searchSelectors = { getSearchValue };

export default searchSelectors;
