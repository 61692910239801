import { call, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { loginSelectors, loginTypes } from '../login';

import { communicationActionTypes } from './communication.actions';
import { getToken } from '../login/login.saga';
import communicationApi from '../../api/communication.api';
import { AccountCrmRegistrationInfoResponseDTO } from '../../api/communication.api.types';
import { communicationActions, communicationSelectors } from './index';
import { applicationTypes } from '../application';
import { jobsSelectors, jobsTypes } from '../jobs';

function* getCRMRegistrationInfoSaga() {
    try {
        const isRegisteredForUserReadingReceiptViaCRM: boolean = yield select(communicationSelectors.isRegisteredForUserReadingReceiptViaCRM);
        const logins: any[] = yield select(loginSelectors.getLogins);
        const token: string | undefined = yield call(getToken, logins?.[0]?.[0]);

        yield put(communicationActions.getCRMRegistrationInfo());

        if (isRegisteredForUserReadingReceiptViaCRM || logins.length > 1 || !token) {
            yield put(communicationActions.getCRMRegistrationInfoCancelled());

            return;
        }

        const data: AccountCrmRegistrationInfoResponseDTO = yield communicationApi.fetchCRMRegistrationInfo(token);

        yield put(communicationActions.getCRMRegistrationInfoDone(data));
    } catch (err) {
        yield put(communicationActions.getCRMRegistrationInfoError());
    }
}

function* automaticCRMReadingReceiptDialogObservationSaga() {
    const jobsCount: number = yield select(jobsSelectors.getJobsCount);
    const doneJobCount: number = yield select(jobsSelectors.getCountJobsDone);

    if (jobsCount === doneJobCount) {
        return;
    }

    while (true) {
        yield take(jobsTypes.SEND_METER_TASK_RESULT_DONE);

        const currDoneJobCount: number = yield select(jobsSelectors.getCountJobsDone);

        if (jobsCount === currDoneJobCount) {
            yield put(communicationActions.automaticTriggerCRMDialog());

            break;
        }
    }
}

function* sendCRMReadingReceiptRequestSaga() {
    try {
        const logins: any[] = yield select(loginSelectors.getLogins);

        if (logins.length > 1) return;

        const token: string | undefined = yield call(getToken, logins?.[0]?.[0]);

        if (!token) return;

        yield communicationApi.sendCRMReadingReceiptRequest(token);

        yield put(communicationActions.sendCRMReadingReceiptRequestDone());
    } catch (err) {
        yield put(communicationActions.sendCRMReadingReceiptRequestError());
    }
}

export default function* communicationSaga() {
    yield take(loginTypes.ACCOUNT_INFO_RECEIVED);

    yield call(getCRMRegistrationInfoSaga);

    yield fork(automaticCRMReadingReceiptDialogObservationSaga);

    yield takeLatest(applicationTypes.WINDOW_FOCUS, getCRMRegistrationInfoSaga);

    yield takeLatest(communicationActionTypes.COMMUNICATION_SEND_CRM_READING_RECEIPT_REQUEST, sendCRMReadingReceiptRequestSaga);
}
