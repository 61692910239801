import React, { ReactChild, ReactNode } from 'react';
import './LabeledList.less';
import classNames from 'classnames';
import LabeledListTitle from '../LabeledListTitle/LabeledListTitle';
import LabeledListDescription from '../LabeledListDescription/LabeledListDescription';

const LabeledList = ({
                         title,
                         description,
                         children,
                         className,
                     }: {title?: string | undefined, description?: string | undefined, children: ReactNode | string | undefined, className?: string }) => {
    return (
        <>
            <LabeledListTitle title={title}/>
            <LabeledListDescription description={description} />
            <div
                className={classNames(className, 'labeled-list lg:grid lg:grid-cols-labeled gap-x-4 sm:gap-y-1 items-baseline')}>
                {children}
            </div>
        </>
);
};

export default LabeledList;
