import React from 'react';
import './ShareJobGroupsDialog.less';
import Dialog from '../../../components/envago/Dialog/Dialog';
import Button from '../../../components/envago/Button/Button';
import { JobType } from '../../../redux/jobs/jobs.types';

const ShareJobGroupsDialog = ({
    groups,
    visible,
    onClose,
}: {
    groups: { name: string; data: JobType[] }[];
    visible: boolean;
    onClose: (visible: boolean) => void;
}) => {
    return (
        <Dialog
            visible={visible}
            closable={true}
            onClose={onClose}
            actions={[<Button key={'close'}>Close</Button>]}
        >
            <h1>Hello</h1>

            <p>{groups.length}</p>

            {groups.map((group, index) => {
                return <div key={index}>Name: {group.name}</div>;
            })}
        </Dialog>
    );
};

export default ShareJobGroupsDialog;
