import React, { useEffect, useLayoutEffect } from 'react';
import './JobsScreen.less';
import { useDispatch, useSelector } from 'react-redux';
import { jobsActions, jobsSelectors } from '../../redux/jobs';
import Loading from '../../components/common/Loading/Loading';
import JobList from './components/JobList/JobList';
import { Route, Switch, useParams } from 'react-router';
import SideBarLayout from '../MainScreen/SideBarLayout';
import JobDetails from './components/JobDetails/JobDetails';
import JobFilterToolbar from './components/JobFilterToolbar/JobFilterToolbar';
import JobStatistics from './components/JobStatistics/JobStatistics';
import { useIsMobile } from '../../helper/LayoutHelper';
import BasicScreen from '../common/BasicScreen/BasicScreen';
import { useTranslation } from 'react-i18next';
import JobDetailsAddUnknown from './components/JobDetailsAddUnknown/JobDetailsAddUnknown';
import { push } from 'connected-react-router';

const JobsScreen = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const preselectedJobId = useSelector(jobsSelectors.getPreselectedJobId);

    useLayoutEffect(() => {
        dispatch(jobsActions.loadJobs());

        if (preselectedJobId) {
            dispatch(push(`/ablesung/${preselectedJobId}`));
        }
    }, [preselectedJobId]);

    const isLoading = useSelector(jobsSelectors.isLoading);
    const jobs = useSelector(jobsSelectors.getFilteredJobs);

    let isMobile = useIsMobile();

    const { jobId }: any = useParams();

    return (
        <>
            {isLoading && (
                <div className={'flex-1 p-4'}>
                    <Loading size={50} text={t('common.loadingReadings')} />
                </div>
            )}

            {!isLoading && !isMobile && (
                <Switch>
                    <Route exact={true} path={'/ablesung'}>
                        <SideBarLayout
                            sidebar={
                                <BasicScreen>
                                    <JobFilterToolbar />
                                    <JobList jobs={jobs} />
                                </BasicScreen>
                            }
                        >
                            <JobStatistics jobs={jobs} />
                        </SideBarLayout>
                    </Route>
                    <Route exact={true} path={'/ablesung/neu'}>
                        <SideBarLayout
                            sidebar={
                                <BasicScreen>
                                    <JobFilterToolbar />
                                    <JobList jobs={jobs} />
                                </BasicScreen>
                            }
                        >
                            <JobDetailsAddUnknown />
                        </SideBarLayout>
                    </Route>
                    <Route exact={true} path={'/ablesung/:jobId'}>
                        <SideBarLayout
                            sidebar={
                                <BasicScreen>
                                    <JobFilterToolbar />
                                    <JobList jobs={jobs} />
                                </BasicScreen>
                            }
                        >
                            <JobDetails jobId={jobId} />
                        </SideBarLayout>
                    </Route>
                </Switch>
            )}

            {!isLoading && isMobile && (
                <Switch>
                    <Route exact={true} path={`/ablesung`}>
                        <BasicScreen>
                            <JobFilterToolbar />
                            <JobList className={''} jobs={jobs} />
                        </BasicScreen>
                    </Route>
                    <Route exact={true} path={'/ablesung/neu'}>
                        <JobDetailsAddUnknown />
                    </Route>
                    <Route exact={true} path={`/ablesung/:jobid`}>
                        <JobDetails className={'w-full p-2'} jobId={jobId} />
                    </Route>
                </Switch>
            )}
        </>
    );
};

export default JobsScreen;
