import React, { ReactElement } from 'react';
import './Empty.less';
import Icon from '../Icon/Icon';

const Empty = ({
    children,
    showIcon = true,
}: {
    children?: undefined | ReactElement | ReactElement[] | string;
    showIcon?: boolean;
}) => {
    return (
        <div className="empty shadow text-sm p-4 bg-gray-200 m-4 text-gray-400 flex items-center justify-center rounded">
            {showIcon && <Icon path={Icon.Path.mdiArchiveOutline} className={'h-6 mr-2'} />}
            {children || 'Keine Elemente vorhanden'}
        </div>
    );
};

export default Empty;
