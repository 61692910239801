import React, { useEffect } from 'react';
import './AccountList.less';
import { useDispatch, useSelector } from 'react-redux';
import { loginActions, loginSelectors } from '../../../../redux/login';
import ListItem from '../../../../components/envago/ListItem/ListItem';
import Icon from '../../../../components/envago/Icon/Icon';
import { push } from 'connected-react-router';
import { useParams } from 'react-router';
import { applicationSelectors } from '../../../../redux/application';
import { LoginType } from '../../../../redux/login/login.types';
import { jobsActions } from '../../../../redux/jobs';
import { useTranslation } from 'react-i18next';

const AccountListItem = ({ active, accountId, account }: {
    active: boolean;
    accountId: string;
    account: LoginType
}) => {
    const { t } = useTranslation();

    const applicationName = useSelector(applicationSelectors.getApplicationName);
    const dispatch = useDispatch();

    const loading = useSelector(loginSelectors.isAccountLoading(accountId));

    useEffect(() => {
        if (!account.info?.name) {
            dispatch(loginActions.fetchAccountInformation(accountId));
        }
    }, [accountId]);

    return (
        <ListItem
            active={active}
            subText={
                account.info?.parentName
                    ? `${t('management.accounts.list.by')} ${account.info?.parentName}`
                    : `${t('management.accounts.list.by')} ${applicationName}`
            }
            onClick={() => {
                dispatch(push(`/management/account/${accountId}/`));
            }}
            rightIcon={<Icon className={'text-gray-300 h-4 w-4'} path={Icon.Path.mdiChevronRight} />}
        >
            {loading ? t('common.loading') : account.info?.name || t('common.account.noName')}
        </ListItem>
    );
};

const AccountList = () => {
    const dispatch = useDispatch();
    const { accountId } = useParams<{ accountId?: string }>();

    const accounts = useSelector(loginSelectors.getLogins);

    useEffect(() => {
        dispatch(jobsActions.loadJobs());
    }, []);

    useEffect(() => {
        if (!accountId && accounts.length === 1) {
            const potentiallyPreSelectedAccountId = accounts.find(([accountId]) => !!accountId)?.[0];

            if (potentiallyPreSelectedAccountId) {
                dispatch(push(`/management/account/${potentiallyPreSelectedAccountId}/`));
            }
        }
    }, [accounts, accountId]);

    return (
        <div className='account-list flex-1 flex gap-2 flex-col'>
            {accounts.map(([key, account]) => (
                <AccountListItem active={key === accountId} accountId={key} account={account} key={key} />
            ))}
        </div>
    );
};

export default AccountList;
