import React, { useMemo } from 'react';
import './MeterReadingPlausibilityIndicator.less';
import { mdiMenuUp, mdiMenuDown } from '@mdi/js';
import Icon from '@mdi/react';
import Tooltip from '../Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';

interface IMeterReadingPlausibilityIndicatorProps {
    readingValue?: number;
    minimumValue?: number;
    maximumValue?: number;
}

const MeterReadingPlausibilityIndicator = ({ readingValue, minimumValue, maximumValue }: IMeterReadingPlausibilityIndicatorProps) => {
    const { t } = useTranslation();

    const indicator = useMemo(() => {
        if (!readingValue) return undefined;

        if (maximumValue && readingValue > maximumValue) {
            return {
                path: mdiMenuUp,
                tooltip: t('jobs.details.tooltips.readingValueGreaterThanMaximumValue'),
            };
        } else if (minimumValue && readingValue < minimumValue) {
            return {
                path: mdiMenuDown,
                tooltip: t('jobs.details.tooltips.readingValueLessThanMinimumValue'),
            };
        } else {
            return undefined;
        }
    }, [readingValue, maximumValue, minimumValue, t]);

    if (!indicator) return null;

    return (
        <span className={'meter-reading-plausibility-indicator'}>
            {/*FIXME: Tooltip needs functionality for positioning, otherwise there will be horizontal overflow on mobile screens.*/}
            {/*<Tooltip tooltipText={indicator.tooltip} width={'w-40 md:w-64'}>*/}
            <Icon path={indicator.path} size={1.1} color={'#D97706'} />
            {/*</Tooltip>*/}
        </span>
    );
};

export default MeterReadingPlausibilityIndicator;
