import { call, put, spawn, take } from 'redux-saga/effects';
import axios from 'axios';
import { applicationActions } from './index';
import { EventChannel, eventChannel } from 'redux-saga';
import { AnyAction } from 'redux';

function createWindowFocusChannel() {
    return eventChannel((emitter) => {
        const handleFocus = () => emitter(applicationActions.windowFocus());
        const handleBlur = () => emitter(applicationActions.windowBlur());

        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);

        // Emit the current focus state initially
        if (document.hasFocus()) {
            emitter(applicationActions.windowFocus());
        } else {
            emitter(applicationActions.windowBlur());
        }

        // The subscriber must return an unsubscribe function
        return () => {
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('blur', handleBlur);
        };
    });
}

function* trackWindowFocus() {
    const channel: EventChannel<AnyAction> = yield call(createWindowFocusChannel);

    try {
        while (true) {
            const action: AnyAction = yield take(channel);
            yield put(action);
        }
    } finally {
        channel.close();
    }
}

function* loadApplicationInfo() {
    const { data } = yield axios.get('/api/public/application');

    yield put(applicationActions.loadApplicationDone(data));
}

export default function* applicationSaga() {
    console.log('Starting ApplicationSaga');

    yield spawn(trackWindowFocus);
    yield call(loadApplicationInfo);

    console.log('Done with ApplicationSaga');
}
