import React, { ReactNode } from 'react';
import './JobListItem.less';
import Card from '../Card/Card';
import classNames from 'classnames';
import Icon from '../Icon/Icon';

export interface JobListItemProps {
    firstLine?: string;
    title: string;
    titleSize?: 'default' | 'small';
    secondLine?: string;
    onClick: () => void;
    icon: ReactNode;
    color: 'gray-100' | string;
    isActive?: boolean;
    isGhost?: boolean;
    iconRight?: JobListItemIconType;
}

export type JobListItemIconType = false | 'ARROW' | 'PENDING' | 'CHECK'

const JobListItem = ({
                         firstLine,
                         title,
                         titleSize = 'default',
                         secondLine,
                         onClick,
                         icon,
                         color = 'gray-100',
                         isActive = false,
                         isGhost,
                         iconRight = false,
                     }: JobListItemProps) => {
    return (
        <Card
            onClick={onClick}
            className={classNames(
                `job-list-item bg-gray-50 flex items-center cursor-pointer border-${color} border-l-4 hover:bg-white transition`,
                {
                    'card--active': isActive,
                    'hover:shadow': !isActive,
                    'text-gray-400': isGhost,
                    'text-gray-700': !isGhost,
                },
            )}
        >
            <div className={'ml-1 mr-3'}>{icon}</div>
            <div className={'flex-1'}>
                <p className={'text-xs leading-4 text-gray-400'}>{firstLine}</p>
                <p
                    className={classNames('font-medium', {
                        'text-xl': titleSize === 'default',
                        'text-l': titleSize === 'small',
                    })}
                >
                    {title}
                </p>
                <p className={'text-xs leading-4 text-gray-400'}>{secondLine}</p>
            </div>
            <div className={'justify-self-end mr-2'}>
                {(iconRight === 'PENDING' &&
                    <Icon className={'text-yellow-500 h-4'} path={Icon.Path.mdiProgressClock} />)}
                {(iconRight === 'ARROW' && <Icon className={'text-gray-300 h-4'} path={Icon.Path.mdiChevronRight} />)}
                {(iconRight === 'CHECK' && <Icon className={'text-green-500 h-4'} path={Icon.Path.mdiCheck} />)}
            </div>
        </Card>
    );
};

export default JobListItem;
