import React from 'react';
import './ReadingValue.less';
import { splitJoin } from '../../../helper/TextHelper';
import classNames from 'classnames';

const ReadingValue = ({ preDecimals, postDecimals, maskPostDecimals, value }) => {
    let pre = Array.from(Array(preDecimals).keys()).reduce((res) => {
        return `${res}0`;
    }, '');

    let post = Array.from(Array(postDecimals).keys()).reduce((res) => {
        return `${res}0`;
    }, '');

    let maskedPost = Array.from(Array(postDecimals).keys()).reduce((res) => {
        return `${res}X`;
    }, '');

    if (value) {
        let [preValue, postValue] = (value + '').split('.');

        if (!preValue) preValue = value;
        if (!postValue) postValue = '';

        while (preValue.length < preDecimals) preValue = '0' + preValue;

        if (postDecimals > 0) {
            while (postValue.length < postDecimals) postValue = postValue + '0';

            postDecimals = post.length;
        }

        pre = preValue;
        post = postValue;
    }

    return (
        <span className={'reading-value'}>
            {splitJoin('', pre, postDecimals > 0 ? ',' : undefined)
                .split('')
                .map((c, index) => (
                    <span className={'digit'} key={index}>
                        {c}
                    </span>
                ))}
            {postDecimals > 0 &&
                splitJoin('', maskPostDecimals ? maskedPost : post)
                    .split('')
                    .map((c, index) => (
                        <span
                            className={classNames(
                                'digit',
                                maskPostDecimals ? 'masked' : 'postdecimal',
                            )}
                            key={index}
                        >
                            {c}
                        </span>
                    ))}
        </span>
    );
};

export default ReadingValue;
