import { useDispatch, useSelector } from 'react-redux';
import { offlineSelectors } from '../../../redux/offline';
import { jobsSelectors } from '../../../redux/jobs';
import { loginSelectors } from '../../../redux/login';
import { applicationSelectors } from '../../../redux/application';
import { communicationActions, communicationActionTypes, communicationSelectors } from '../../../redux/communication';
import { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { store } from '../../../redux/store';
import CommunicationDialog from './CommunicationDialog/CommunicationDialog';
import { notificationActions } from '../../../redux/notification';
import { useTranslation } from 'react-i18next';

interface CommunicationProviderProps_DRAFT {}

const CommunicationProvider = ({ children }: PropsWithChildren<CommunicationProviderProps_DRAFT>) => {
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const lastActionTypeRef = useRef(null);

    const isFocused = useSelector(applicationSelectors.isFocused);
    const isOnline = useSelector(offlineSelectors.isOnline);
    const jobsDone = useSelector(jobsSelectors.getJobsDone);

    const loginIds = useSelector(loginSelectors.getLoginIds);
    const hasReadingReceiptRoles = useSelector(
        loginSelectors.hasAccountEveryRole(loginIds[0], ['ROLE_KSA_PRO_USER', 'ROLE_KSA_SEND_CRMREGISTEREDUSER_READINGRECEIPT']),
    );

    const showModalAfterLastJobCompletedEnabled = useSelector(applicationSelectors.isShowModalAfterLastJobCompletedEnabled);
    const registrationInfo = useSelector(communicationSelectors.getCRMRegistrationInfo);
    const isRegistered = useSelector(communicationSelectors.isRegisteredForUserReadingReceiptViaCRM);

    const CRMRegistrationURL = useSelector(applicationSelectors.getCRMRegistrationURL);
    const selfServiceToken = useSelector(communicationSelectors.getSelfServiceToken);

    const isSendingPending = useSelector(communicationSelectors.isSendingPending);

    const [isOpen, setIsOpen] = useState(false);

    const jobsDoneIds = useMemo(() => {
        return (jobsDone || []).map((j) => j.id);
    }, [jobsDone]);

    const isEnabled = useMemo(() => {
        if (!isOnline) {
            return false;
        } else if (!loginIds || loginIds.length > 1) {
            return false;
        } else if (!hasReadingReceiptRoles) {
            return false;
        } else if (!registrationInfo) {
            return false;
        }

        return true;
    }, [isOnline, loginIds, hasReadingReceiptRoles, registrationInfo]);

    useEffect(() => {
        if (!isEnabled || !isFocused) {
            return;
        }

        const unsubscribe = store.subscribe(() => {
            const state = store.getState();
            const lastActionType = state.communication.lastActionType;

            if (lastActionTypeRef.current !== lastActionType && !(lastActionTypeRef.current == null && lastActionType == null)) {
                lastActionTypeRef.current = lastActionType;

                if (lastActionType === communicationActionTypes.COMMUNICATION_AUTOMATIC_TRIGGER_CRM_DIALOG) {
                    if (!isOpen && showModalAfterLastJobCompletedEnabled) {
                        setIsOpen(true);
                    }

                    dispatch(communicationActions.clearLastActionType());
                } else if (lastActionType === communicationActionTypes.COMMUNICATION_MANUAL_TRIGGER_CRM_DIALOG) {
                    if (!isOpen) {
                        setIsOpen(true);
                    }

                    dispatch(communicationActions.clearLastActionType());
                } else if (lastActionType === communicationActionTypes.COMMUNICATION_SEND_CRM_READING_RECEIPT_REQUEST_DONE) {
                    dispatch(
                        notificationActions.showInfoNotification(
                            t('communication.notification.communication.done.title'),
                            t('communication.notification.communication.done.description', { count: jobsDoneIds.length }),
                        ),
                    );

                    if (isOpen) {
                        setIsOpen(false);
                    }

                    dispatch(communicationActions.clearLastActionType());
                } else if (lastActionType === communicationActionTypes.COMMUNICATION_SEND_CRM_READING_RECEIPT_REQUEST_ERROR) {
                    dispatch(
                        notificationActions.showErrorNotification(
                            t('communication.notification.communication.error.title'),
                            t('communication.notification.communication.error.description', { count: jobsDoneIds.length }),
                        ),
                    );

                    dispatch(communicationActions.clearLastActionType());
                } else if (lastActionType === communicationActionTypes.COMMUNICATION_GET_CRM_REGISTRATION_INFO_DONE && !isRegistered) {
                    const crmRegistrationInfo = state.communication.crmRegistrationInfo;

                    if (isOpen && crmRegistrationInfo.currentConfirmedCommunicationTypes?.includes('EMAIL')) {
                        if (jobsDoneIds?.length > 0) {
                            dispatch(communicationActions.sendCRMReadingReceiptRequest());
                        } else {
                            setIsOpen(false);
                            dispatch(
                                notificationActions.showInfoNotification(
                                    t('communication.notification.registration.done.title'),
                                    t('communication.notification.registration.done.description'),
                                ),
                            );
                        }
                    }

                    dispatch(communicationActions.clearLastActionType());
                }
            }
        });

        return () => {
            unsubscribe();
        };
    }, [isEnabled, isOpen, jobsDoneIds, t, isFocused, isRegistered, showModalAfterLastJobCompletedEnabled]);

    return (
        <>
            <CommunicationDialog
                isOpen={isOpen}
                isPending={isSendingPending}
                isRegistered={isRegistered}
                isNoUrlError={!CRMRegistrationURL || !selfServiceToken}
                onClose={() => {
                    setIsOpen(false);
                }}
                onSubmit={() => {
                    if (isRegistered) {
                        dispatch(communicationActions.sendCRMReadingReceiptRequest());
                    } else {
                        const link = `${CRMRegistrationURL}/m/${selfServiceToken}?ref=ksa`;

                        window.open(link, '_blank');
                    }
                }}
                doneJobIds={jobsDoneIds}
            />
            {children}
        </>
    );
};

export default CommunicationProvider;
