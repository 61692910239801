import {put, take} from "redux-saga/effects";
import {loginTypes} from "../login";
import {jobsTypes} from "../jobs";
import {push} from "connected-react-router";


export default function* introductionSaga() {
    yield take(loginTypes.TOKEN_LOGIN);
    let {account} = yield take(loginTypes.LOGIN_JWT_TOKEN_RECEIVED);
    let {jobs} = yield take(jobsTypes.LOAD_JOBS_DONE);

    yield put(push('/introduction'));
}
