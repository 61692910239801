import { AnyAction } from 'redux';
import { languageStateType } from './language.types';
import { languageTypes } from './index';
import { applicationTypes } from '../application';

const initialState: languageStateType = {
    currentLanguage: undefined,
    mergedTranslations: false,
    languageConfig: undefined,
};

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case applicationTypes.LOAD_APPLICATION_DONE: {
            const { payload } = action;
            return {
                ...state,
                languageConfig: payload.languageConfig,
            };
        }
        case languageTypes.CHANGE_LANGUAGE_DONE: {
            const { payload } = action;
            return {
                ...state,
                currentLanguage: payload,
            };
        }
        case languageTypes.MERGE_TRANSLATIONS_DONE: {
            const { payload } = action;
            return {
                ...state,
                mergedTranslations: payload,
            };
        }
        default:
            return state;
    }
};

export default reducer;
