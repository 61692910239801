import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import loginReducer from './login';
import applicationReducer from './application';
import contactReducer from './contact';
import jobsReducer from './jobs';
import sharesReducer from './shares';
import notificationReducer from './notification';
import helpReducer from './help';
import languageReducer from './language';
import offlineReducer from './offline';
import queueReducer from './offline/queue.reducer';
import unknownReadingReducer from './unknownReading';
import searchReducer from './search';
import communicationReducer from './communication';

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        login: loginReducer,
        application: applicationReducer,
        communication: communicationReducer,
        contact: contactReducer,
        jobs: jobsReducer,
        search: searchReducer,
        unknownReading: unknownReadingReducer,
        shares: sharesReducer,
        notification: notificationReducer,
        help: helpReducer,
        offline: offlineReducer,
        queue: queueReducer, // offline-functionality needs separate reducer for queue due to redux-persist
        language: languageReducer,
    });

export default createRootReducer;
