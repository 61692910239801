import React, { ReactChild } from 'react';
import './Card.less';
import classNames from 'classnames';

const Card = ({
                  children,
                  className,
                  onClick,
              }: { children: ReactChild | ReactChild[] | Element[] | any | Element | string, className?: string, onClick?: () => void }) => {
    return (
        <div onClick={onClick}
             className={classNames(className, { 'bg-gray-50': !className || className?.indexOf(' bg-') === -1 }, 'card')}>
            {children}
        </div>
    );
};

export default Card;
