import { call, delay, fork, put, select, spawn, take, takeLatest } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import { offlineActions, offlineSelectors, offlineTypes } from './index';
import { EventChannel, eventChannel } from 'redux-saga';
import { AnyAction } from 'redux';
import queueSelectors from './queue.selectors';
import { notificationActions } from '../notification';

function* checkOffline() {
    const detectOfflineChannel: EventChannel<boolean> = yield call(detectOffline);

    while (true) {
        let isOnline: boolean = yield take(detectOfflineChannel);

        if (isOnline) {
            try {
                let response: AxiosResponse = yield axios.get(`/api/public/application`);
                if (response.status === 200) {
                    yield put(offlineActions.setOnlineStatus(true));
                }
            } catch (e) {
                yield put(offlineActions.setOnlineStatus(false));
            }
        } else {
            yield put(offlineActions.setOnlineStatus(false));
        }
    }
}

function* sendQueue() {
    const queueLength: number = yield select(queueSelectors.getQueueLength);
    if (queueLength === 0) {
        yield put(offlineActions.queueTrySendDone());
        return;
    }

    const isOnline: boolean = yield select(offlineSelectors.isOnline);

    if (isOnline) {
        const queuedActions: AnyAction[] = yield select(queueSelectors.getQueuedActions);
        for (let queuedAction of queuedActions) {
            yield put(queuedAction);
            yield put(offlineActions.queueActionSent(queuedAction));
            yield delay(300);
        }
        yield delay(500);
        yield put(offlineActions.queueTrySendDone());
    } else {
        yield delay(2000);
        yield put(offlineActions.queueTrySendError());
    }
}

function detectOffline() {
    return eventChannel((emitter) => {
        window.addEventListener('online', () => {
            emitter(true);
        });
        window.addEventListener('offline', () => {
            emitter(false);
        });

        return () => {};
    });
}

function* showNotification(isOnline: boolean) {
    if (isOnline) {
        const queueLength: number = yield select(queueSelectors.getQueueLength);
        if (queueLength > 0) {
            yield put(
                notificationActions.showInfoNotification(
                    'Internet verfügbar!',
                    `${queueLength} Ablesungen werden nun synchronisiert.`,
                ),
            );
        } else {
            yield put(
                notificationActions.showInfoNotification(
                    'Internet verfügbar!',
                    `Alle Funktionen stehen wieder zur Verfügung.`,
                ),
            );
        }
    } else {
        yield put(
            notificationActions.showInfoNotification(
                'Kein Internet',
                'Ablesungen können weiterhin durchgeführt werden!',
            ),
        );
    }
}

function* watchOnlineStatus() {
    let previousStatus: boolean = yield select(offlineSelectors.isOnline);
    while (true) {
        let { isOnline } = yield take(offlineTypes.SET_ONLINE_STATUS);

        if (previousStatus !== isOnline) {
            yield showNotification(isOnline);
        }

        previousStatus = isOnline;
    }
}

function* pingOnline() {
    while (true) {
        try {
            let fetchResponse: Response = yield fetch('/api/public/application', {
                method: 'HEAD',
            });
            if (fetchResponse.ok) {
                yield put(offlineActions.setOnlineStatus(true));
            } else {
                yield put(offlineActions.setOnlineStatus(false));
            }
        } catch (e) {
            yield put(offlineActions.setOnlineStatus(false));
        }
        yield delay(30000);
    }
}

export default function* offlineSaga() {
    yield fork(watchOnlineStatus);
    yield spawn(checkOffline);

    yield takeLatest(offlineTypes.QUEUE_TRY_SEND, sendQueue);
    yield takeLatest(offlineTypes.SET_ONLINE_STATUS, sendQueue);
    // yield takeLatest(offlineTypes.SET_ONLINE_STATUS, showNotification);

    yield spawn(sendQueue);

    /**
     * initial check for online status
     */
    // if (!window.navigator.onLine) {
    //     yield put(offlineActions.setOnlineStatus(false));
    // }

    // try {
    //     let fetchResponse: Response = yield fetch('/api/public/application', { method: 'HEAD' });
    //     if (fetchResponse.ok) {
    //         yield put(offlineActions.setOnlineStatus(true));
    //     } else {
    //         yield put(offlineActions.setOnlineStatus(false));
    //     }
    // } catch (e) {
    //     yield put(offlineActions.setOnlineStatus(false));
    // }

    yield spawn(pingOnline);
}
