import './ShareAccountEmail.less';

import OptionalLabel from '../../../components/envago/OptionalLabel/OptionalLabel';
import InputEmails from '../../../components/envago/InputEmails/InputEmails';
import Button from '../../../components/envago/Button/Button';
import { sharesActions, sharesSelectors } from '../../../redux/shares';
import LabeledList from '../../../components/envago/LabeledList/LabeledList';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Information, { InformationType } from '../../../components/envago/Information/Information';
import { ShareJobsResultType } from '../../../redux/shares/shares.types';

const ShareAccountEmail = ({ accountId, onEmailsSend }: { accountId: string; onEmailsSend?: (isEmailsSend: boolean) => void }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [emails, setEmails] = useState<Array<string>>([]);
    const [isEmailsValid, setIsEmailsValid] = useState(false);

    const newAccount = useSelector(sharesSelectors.createGetAccount);

    const isSending = useSelector(sharesSelectors.emailsIsSending);
    const isDone = useSelector(sharesSelectors.emailsIsDone);
    const sentToMails = useSelector(sharesSelectors.emailsGetMails);

    useEffect(() => {
        if (isDone && emails && emails.length > 0) {
            setEmails([]);
            setIsEmailsValid(false);

            if (onEmailsSend) {
                onEmailsSend(true);
            }
        }
    }, [isDone]);

    const handleSubmit = (emails: Array<string>, accountId: string, account?: ShareJobsResultType) => {
        if (account) {
            dispatch(sharesActions.sendSubAccountEmails(emails, undefined, accountId, account.token, account.username, account.password));
        }
    };

    return (
        <LabeledList
            className={'share-account-email'}
            title={t('jobs.share.success.loginMethod.email.title')}
            description={t('jobs.share.success.loginMethod.email.description')}
        >
            <OptionalLabel label={t('jobs.share.labels.accessEmail')} className={'space-y-3'}>
                <InputEmails
                    invalidText={t('jobs.share.success.loginMethod.email.notifications.warning')}
                    placeholder={t('jobs.share.placeholders.email')}
                    values={emails}
                    autoFocus={true}
                    onChange={(values, isValid) => {
                        setEmails(values);
                        setIsEmailsValid(isValid);
                    }}
                />

                <Button isLoading={isSending} block={true} primary={true} disabled={!isEmailsValid} onClick={() => handleSubmit(emails, accountId, newAccount)}>
                    {t('jobs.share.buttons.send')}
                </Button>
                {isDone && (
                    <Information type={InformationType.SUCCESS}>
                        {t('jobs.share.success.loginMethod.email.send', {
                            email: sentToMails?.join(', '),
                        })}
                    </Information>
                )}
            </OptionalLabel>
        </LabeledList>
    );
};

export default ShareAccountEmail;
