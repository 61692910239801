// export const groupBy = function(xs, key) {
//     return xs.reduce(function(rv, x) {
//         (rv[x[key]] = rv[x[key]] || []).push(x);
//         return rv;
//     }, {});
// };


// interface <Type>GroupByResultType {
//     [key: string]: Type
// }
import dotProp from 'dot-prop-immutable';


export function hasMultipleValues<Type>(items: Type[], key: string) {
    if (items.length === 0)
        return false;
    let firstValue = dotProp.get(items[0], key);
    for (const item of items) {
        if (dotProp.get(item, key) !== firstValue)
            return true;
    }
    return false;
}


export function groupBy<Type>(items: Type[], key: string, keyMapper = (key: string) => key): {
    [key: string]: Type[]
} {

    return items.reduce((res: {
        [key: string]: Type[]
    }, item: any) => {
        let kv = dotProp.get(item, key, 'default');
        kv = keyMapper(kv);
        (res[kv] = res[kv] || []).push(item);
        return res;
    }, {});
}


export function countGroupBy<Type>(items: Type[], key: string, keyMapper = (key: string) => key): {
    [key: string]: number
} {

    return items.reduce((res: {
        [key: string]: number
    }, item: any) => {
        let kv = dotProp.get(item, key, 'default');
        kv = keyMapper(kv);
        res[kv] = res[kv] + 1 || 1;
        return res;
    }, {});
}

export function objectToArray<Type>(obj: { [key: string]: Type | Type[] }) {
    return Object.entries(obj)
        .sort(([a], [b]) => a.localeCompare(b))
        .flatMap(([k, v]) => ({ key: k, values: v }))

        ;
}
