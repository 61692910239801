import React, { useLayoutEffect, useState } from 'react';
import './JobStatistics.less';
import { JobType } from '../../../../redux/jobs/jobs.types';
import Toolbar from '../../../../components/envago/Toolbar/Toolbar';
import Card from '../../../../components/envago/Card/Card';
import LabeledList from '../../../../components/envago/LabeledList/LabeledList';
import OptionalLabel from '../../../../components/envago/OptionalLabel/OptionalLabel';
import ProgressBar from '../../../../components/envago/ProgressBar/ProgressBar';
import EnergyIcon from '../../../../components/envago/EnergyIcon/EnergyIcon';
import { METER_TYPES, resolveMeterColorTailwindClass } from '../../../../helper/MeterHelper';
import { countGroupBy, groupBy, objectToArray } from '../../../../helper/GroupHelper';
import { formatDate, toNow } from '../../../../helper/DateHelper';
import Information, {
    InformationType,
} from '../../../../components/envago/Information/Information';
import dayjs from 'dayjs';
import BasicScreen from '../../../common/BasicScreen/BasicScreen';
import { Trans, useTranslation } from 'react-i18next';
import Collapsable from '../../../../components/envago/Collapsable/Collapsable';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import JobListShortItem from '../JobListShortItem/JobListShortItem';

interface StatisticType {
    done: number;
    byType: {
        [key: string]: {
            done: number;
            total: number;
        };
    };
    latestExecutionList: {
        key: string;
        values: {
            false?: number;
            true?: number;
        };
        jobs?: JobType[];
    }[];
}

const JobStatistics = ({ jobs }: { jobs: JobType[] }) => {
    const { t } = useTranslation();
    const [statistic, setStatistic] = useState<StatisticType | undefined>(undefined);
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        const doneCount = jobs.reduce((res, job) => (job.done ? res + 1 : res), 0);

        setStatistic({
            latestExecutionList: objectToArray(
                groupBy(jobs, 'jobConstraints.latestExecution', (k) =>
                    dayjs(k).format('YYYY-MM-DD'),
                ),
            ).map((e) => ({
                key: e.key,
                values: countGroupBy(e.values as any[], 'done'),
                jobs: e.values as JobType[],
            })),
            done: doneCount,
            byType: jobs.reduce((res: any, job) => {
                let old = res[job.jobInfo.meterType || ''] || { done: 0, total: 0 };

                return {
                    ...res,
                    [job.jobInfo.meterType || '']: {
                        done: job.done ? old.done + 1 : old.done,
                        total: old.total + 1,
                    },
                };
            }, {}),
        });
    }, [jobs]);

    return (
        <BasicScreen>
            <Toolbar title={t('jobs.home.title')} />
            {(statistic?.latestExecutionList[0]?.values.false || 0) > 0 &&
                dayjs(statistic?.latestExecutionList[0]?.key).diff(dayjs(), 'day') < 5 && (
                    <Information type={InformationType.INFO} className={'mb-2'}>
                        <span>
                            <Trans
                                i18nKey={'jobs.home.expireWarning'}
                                values={{
                                    expireDate: dayjs(
                                        statistic?.latestExecutionList[0]?.key,
                                    ).format('DD.MM.YYYY'),
                                    expireCount:
                                        statistic?.latestExecutionList[0]?.values.false || 0,
                                    count: statistic?.latestExecutionList[0]?.values.false || 0,
                                }}
                            >
                                <b>Achtung</b> am{' '}
                                {dayjs(statistic?.latestExecutionList[0]?.key).format('DD.MM.YYYY')}{' '}
                                laufen{` `}
                                {statistic?.latestExecutionList[0]?.values.false || 0} Aufträge ab,
                                die du noch nicht abgelesen hast!
                            </Trans>
                            <br />
                        </span>
                    </Information>
                )}

            <div className={'grid xl:grid-cols-2 gap-2'}>
                <Card>
                    <h2>{t('jobs.home.statistic.title')}</h2>
                    <LabeledList>
                        <OptionalLabel label={t('jobs.home.statistic.total')}>
                            {jobs.length} {t('common.reading', { count: jobs.length })}
                        </OptionalLabel>
                        <OptionalLabel label={t('jobs.home.statistic.done')}>
                            <ProgressBar
                                showPercentage={true}
                                value={statistic?.done || 0}
                                total={jobs.length}
                                showNumbers={true}
                                progress={(statistic?.done || 0) / jobs.length}
                            />
                        </OptionalLabel>
                        <OptionalLabel>&nbsp;</OptionalLabel>
                        <>
                            {Object.entries(statistic?.byType || {}).map(([k, v]) => {
                                return (
                                    <OptionalLabel
                                        key={`statistic-${k}`}
                                        className={'self-center'}
                                        label={
                                            <div className={'flex '}>
                                                <EnergyIcon meterType={k as METER_TYPES} />{' '}
                                                {t(`common.metertypes.${k}`)}
                                            </div>
                                        }
                                    >
                                        <ProgressBar
                                            progress={v.done / v.total}
                                            total={v.total}
                                            value={v.done}
                                            showNumbers={true}
                                            showPercentage={true}
                                            barClassName={'bg-gray-200'}
                                            activeBarClassName={`bg-${resolveMeterColorTailwindClass(
                                                k as METER_TYPES,
                                            )}`}
                                        />
                                    </OptionalLabel>
                                );
                            })}
                        </>
                    </LabeledList>
                </Card>
                <Card>
                    <h2>{t('jobs.home.period.title')}</h2>

                    <p className={'description'}>{t('jobs.home.period.description')}</p>

                    <LabeledList>
                        <>
                            {statistic?.latestExecutionList.map((entry) => {
                                const done = entry.values.true || 0;
                                const total = done + (entry.values.false || 0);
                                const jobs = entry.jobs;

                                if (entry.key === 'default') return null;

                                return (
                                    <OptionalLabel
                                        key={`latestExecution-${entry.key}`}
                                        label={
                                            <div>
                                                {t('common.until')} {formatDate(entry.key)}
                                                <br />
                                                <small>{toNow(entry.key)}</small>
                                            </div>
                                        }
                                    >
                                        <ProgressBar
                                            progress={done / total}
                                            total={total}
                                            value={done}
                                            showNumbers={true}
                                            showPercentage={true}
                                        />
                                        <Collapsable
                                            triggerText={t('jobs.home.period.showMeters')}
                                            className={'text-sm'}
                                        >
                                            {jobs?.map((job) => {
                                                return (
                                                    <JobListShortItem
                                                        key={job.id}
                                                        job={job}
                                                        onClick={() =>
                                                            dispatch(push(`/ablesung/${job.id}`))
                                                        }
                                                    />
                                                );
                                            })}
                                        </Collapsable>
                                    </OptionalLabel>
                                );
                            })}
                        </>
                    </LabeledList>
                </Card>
            </div>
        </BasicScreen>
    );
};

export default JobStatistics;
