import { createSelector } from 'reselect';
import { helpStateType } from './help.types';
import { getFakeObis, METER_TYPES } from '../../helper/MeterHelper';

const getSubState = (state: any) => state.help as helpStateType;

const getInstructions = createSelector(getSubState, (state) => state.meterReadingInstructions);

const getInstructionsForMeter = (numberOfMeterRates: number, meterNumber?: string, obis?: string, meterType?: METER_TYPES,) =>
    createSelector(getInstructions, (instructions) => {
        if(!obis){
            obis = getFakeObis(meterType);
        }


        return instructions?.filter((instruction) => {
            if (instruction.obisFilter && obis) {
                try {
                    let obisRe = new RegExp(instruction.obisFilter, 'g');
                    if (!obis.match(obisRe)) {
                        // console.log("MATCH", instruction.obisFilter, 'not matching', obis)
                        return false;
                    }
                } catch (e) {
                    console.warn('Invalid Regex!', instruction.obisFilter);
                    return false;
                }
            }

            if (instruction.meterNumberFilter && meterNumber) {
                try {
                    let meterNumberRe = new RegExp(instruction.meterNumberFilter, 'g');
                    if (!meterNumber.match(meterNumberRe)) {
                        // console.log("MATCH", instruction.meterNumberFilter, 'not matching', meterNumber)
                        return false;
                    }
                } catch (e) {
                    console.warn('Invalid Regex!', instruction.meterNumberFilter);
                    return false;
                }
            }

            if (instruction.multiMeterRate !== undefined && instruction.multiMeterRate !== null) {
                if (instruction.multiMeterRate && numberOfMeterRates <= 1) {
                    //console.log("multiMeterRate not matching");
                    return false;
                }
                if (!instruction.multiMeterRate && numberOfMeterRates > 1) {
                    //console.log("multiMeterRate not matching");
                    return false;
                }
            }

            return true;
        });
    });

const hasInstructions = (numberOfMeterRates: number, meterNumber?: string, obis?: string, meterType?: METER_TYPES,) =>
    createSelector(
        getInstructionsForMeter(numberOfMeterRates, meterNumber, obis, meterType),
        (instructions) => (!!obis || !!meterType) && instructions && instructions.length > 0,
    );

const isGlobalHelpEnabled = createSelector(getSubState, (state) => state.globalHelpEnabled);

export default {
    getInstructionsForMeter,
    hasInstructions,
    isGlobalHelpEnabled,
};
