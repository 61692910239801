import React, { ReactNode } from 'react';
import './SubAccountDetails.less';
import { SharedAccountType } from '../../../../redux/shares/shares.types';
import { JobType } from '../../../../redux/jobs/jobs.types';
import JobTable from '../../../common/JobTable/JobTable';
import Information, {
    InformationType,
} from '../../../../components/envago/Information/Information';
import PopConfirmButton from '../../../../components/envago/PopConfirmButton/PopConfirmButton';
import Icon from '../../../../components/envago/Icon/Icon';
import { useDispatch } from 'react-redux';
import { sharesActions } from '../../../../redux/shares';
import Toolbar from '../../../../components/envago/Toolbar/Toolbar';
import { Trans, useTranslation } from 'react-i18next';

const SubAccountDetails = ({
    subAccount,
    accountId,
    jobs,
    openedByToken = false,
}: {
    subAccount: SharedAccountType;
    accountId: string;
    jobs: JobType[];
    openedByToken?: boolean;
}) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    const reshareEnabled = false; // TODO: currently resharing the same account is disabled -> enable in future versions

    console.log('subaccountdetails?', jobs);

    return (
        <div className="sub-account-details flex flex-col">
            <Toolbar
                title={subAccount.name}
                toolbarButtons={[
                    reshareEnabled && (
                        <PopConfirmButton
                            key={'reshare'}
                            title={t('management.accounts.subaccounts.details.reshare.title')}
                            onClick={() => {
                                dispatch(
                                    sharesActions.createLoginForSubAccount(
                                        accountId,
                                        subAccount.id,
                                    ),
                                );
                            }}
                            content={t(
                                'management.accounts.subaccounts.details.reshare.description',
                            )}
                            small={true}
                            ghost={true}
                            iconButton={true}
                        >
                            <Icon path={Icon.Path.mdiShare} />
                        </PopConfirmButton>
                    ),

                    <PopConfirmButton
                        key={'delete-share'}
                        title={t('management.accounts.subaccounts.details.delete.confirm.title')}
                        onClick={() => {
                            dispatch(sharesActions.deleteShare(accountId, subAccount.id));
                        }}
                        content={t(
                            'management.accounts.subaccounts.details.delete.confirm.description',
                        )}
                        small={true}
                        ghost={true}
                        iconButton={true}
                    >
                        <Icon path={Icon.Path.mdiDelete} />
                    </PopConfirmButton>,
                ]
                    .filter(Boolean)
                    .map((a) => a as ReactNode)}
            />

            {openedByToken && (
                <Information type={InformationType.INFO} className={'my-4'}>
                    {t('management.accounts.subaccounts.details.openedByToken')}
                </Information>
            )}

            <p>
                {t('management.accounts.subaccounts.details.description', { count: jobs.length })}
            </p>

            <div className={'flex-1 max-h-full overflow-auto'} style={{ maxHeight: 600 }}>
                <JobTable
                    jobs={jobs}
                    rowActions={(job) => {
                        if (openedByToken) return null;
                        return (
                            <div className={'align-right'}>
                                <PopConfirmButton
                                    onClick={() => {
                                        dispatch(
                                            sharesActions.deleteJobFromShare(
                                                accountId,
                                                subAccount.id,
                                                job.id,
                                            ),
                                        );
                                    }}
                                    ghost={true}
                                    iconButton={true}
                                    showIcon={false}
                                    title={t(
                                        'management.accounts.subaccounts.details.delete.title',
                                    )}
                                    content={
                                        <p>
                                            <Trans
                                                i18nKey={
                                                    'management.accounts.subaccounts.details.delete.description'
                                                }
                                            >
                                                <p>
                                                    Die Ablesung wird <b>aus der Freigabe</b>{' '}
                                                    entfernt. In deinen Ablesungen bleibt sie
                                                    weiterhin enthalten.
                                                </p>
                                            </Trans>
                                        </p>
                                    }
                                >
                                    {' '}
                                    <Icon path={Icon.Path.mdiDelete} />
                                </PopConfirmButton>
                            </div>
                        );
                    }}
                />
            </div>
        </div>
    );
};

export default SubAccountDetails;
