import CommunicationTrigger from './CommunicationTrigger';
import Icon from '../../../components/envago/Icon/Icon';
import React from 'react';

interface CommunicationHeaderSectionProps {}

const CommunicationHeaderSection = ({}: CommunicationHeaderSectionProps) => {
    return (
        <CommunicationTrigger
            triggerButtonProps={{
                children: undefined,
                iconButton: true,
                icon: (
                    <div className="hidden sm:flex bg-opacity-20 bg-gray-400 text-gray-400 text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                        <Icon className="h-6 w-6 m-1 rounded-full" aria-hidden="true" path={Icon.Path.mdiAt} />
                    </div>
                ),
            }}
            placement={'MENU'}
        />
    );
};

export default CommunicationHeaderSection;
