import { createSelector } from 'reselect';
import { notificationStateType } from './notification.types';

const getSubState = (state: any) => state.notification as notificationStateType;

const getNotification = createSelector(getSubState, state => state.notification)

export default {
    getNotification
};
