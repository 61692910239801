import React from 'react';
import './ApplicationLoadingScreen.less';
import Loading from "../../components/common/Loading/Loading";

const ApplicationLoadingScreen = ({}) => {
    return (
        <div className='application-loading-screen container mx-auto flex min-h-screen justify-center'>
            <Loading text={'wird geladen...'}/>
        </div>
    );
};

export default ApplicationLoadingScreen;
