import React from 'react';
import Button from '../../components/envago/Button/Button';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

const PageNotExists = ({}) => {
    const dispatch = useDispatch();
    return (
        <div className='main-screen flex flex-col'>
            <div className='page-not-exists mx-auto p-4'>
                <h1>Sorry, tut uns leid!</h1>

                <p className={'mb-8'}>
                    Diese Seite gibt es leider nicht.
                </p>

                <Button onClick={()=>{
                    dispatch(push("/"))
                }} block={true}>Zur Startseite</Button>
            </div>
        </div>
    );
};

export default PageNotExists;