import React, { ReactNode } from 'react';
import './Toolbar.less';
import BackButton from '../../../screens/common/BackButton/BackButton';

const Toolbar = ({
    title,
    toolbarButtons,
    showBackButton = false,
}: {
    title?: string;
    toolbarButtons?: ReactNode | undefined;
    showBackButton?: boolean;
}) => {
    return (
        <div className="toolbar h-12 mb-3 grid grid-cols-12 items-center">
            {showBackButton && (
                <div className={'col-span-3'}>
                    <BackButton />
                </div>
            )}
            <div
                className={
                    'col-start-4 col-span-6 flex justify-center xs:col-span-3 sm:justify-start sm:col-start-1 sm:col-span-7'
                }
            >
                <h2 className={'truncate px-2 mx-2 sm:px-0 sm:mx-0'}>{title}</h2>
            </div>
            {toolbarButtons && (
                <div
                    className={
                        'toolbar--actions flex flex-row justify-end items-end gap-1 col-start-10 col-span-3'
                    }
                >
                    {toolbarButtons}
                </div>
            )}
        </div>
    );
};

export default Toolbar;
