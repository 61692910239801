type splitJoinValuesType = (string | undefined | null)[];
export const splitJoin = (separator = ' ', ...values: splitJoinValuesType) => {
    if (values.length === 0) return null;
    return values.filter((a) => a).join(separator);
};

export const pluralHelper = (count: number, singular: any, plural: any) =>
    count === 0 || count > 1 ? plural : singular;

export const insertArithmeticOperator = (s?: string, negative?: boolean) => {
    if (!s) return undefined;

    if (s.startsWith('-') || s.startsWith('+')) {
        return s;
    }

    if (negative) {
        return '-' + s;
    }

    return '+' + s;
};

export const formatNumberToLocaleString = (number?: number, postDecimals?: number) => {
    if (number == null) return undefined;

    return Number(number.toFixed(postDecimals)).toLocaleString(undefined, {
        minimumFractionDigits: postDecimals,
    });
};
