import { contactInformationItem } from './contact.types';

export const types = {
    LOAD_CONTACT_INFORMATION: 'LOAD_CONTACT_INFORMATION',
    LOAD_CONTACT_INFORMATION_DONE: 'LOAD_CONTACT_INFORMATION_DONE',
    LOAD_CONTACT_INFORMATION_ERROR: 'LOAD_CONTACT_INFORMATION_ERROR',
};

const loadContactInformation = () => ({ type: types.LOAD_CONTACT_INFORMATION });
const loadContactInformationDone = (payload: contactInformationItem[]) => ({
    type: types.LOAD_CONTACT_INFORMATION_DONE,
    payload: payload,
});
const loadContactInformationError = () => ({ type: types.LOAD_CONTACT_INFORMATION_ERROR });

export default {
    loadContactInformation,
    loadContactInformationDone,
    loadContactInformationError,
};
