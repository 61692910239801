import React from 'react';
import './JobListShortItem.less';
import classNames from 'classnames';

import Button from '../../../../components/envago/Button/Button';
import EnergyIcon from '../../../../components/envago/EnergyIcon/EnergyIcon';
import Icon from '../../../../components/envago/Icon/Icon';

import { JobType } from '../../../../redux/jobs/jobs.types';
import { resolveMeterType } from '../../../../helper/MeterHelper';

interface Props {
    job: JobType;
    onClick: () => void;
    className?: string;
}

const JobListShortItem = ({ job, onClick, className }: Props) => {
    return (
        <div className={classNames('flex items-center text-sm gap-x-4 border-b-2', className)}>
            <EnergyIcon className={'flex-0'} meterType={resolveMeterType(job.meter)} />
            {job.done ? (
                <Icon className={'h-5 text-green-500'} path={Icon.Path.mdiCheckCircle} />
            ) : (
                <Icon
                    className={'h-5 text-gray-300'}
                    path={Icon.Path.mdiCheckboxBlankCircleOutline}
                />
            )}
            <div className={'flex-1 '}>{job.meter.meterNumber}</div>
            <Button ghost iconButton onClick={onClick}>
                <Icon path={Icon.Path.mdiEye} />
            </Button>
        </div>
    );
};

export default JobListShortItem;
