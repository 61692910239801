import { UnknownMeterReadingResultType } from './unknownReading.types';

export const types = {
    SEND_UNKNOWN_METER_RESULT: 'SEND_UNKNOWN_METER_RESULT',
    SEND_UNKNOWN_METER_RESULT_DONE: 'SEND_UNKNOWN_METER_RESULT_DONE',
};

export interface UnknownMeterReadingResultAction {
    type: 'SEND_UNKNOWN_METER_RESULT';
    payload: {
        result: UnknownMeterReadingResultType;
        accountId: string;
    };
}

const sendUnknownMeterReadingResult = (
    result: UnknownMeterReadingResultType,
    accountId: string,
) => ({
    type: types.SEND_UNKNOWN_METER_RESULT,
    payload: { result, accountId },
});

export default {
    sendUnknownMeterReadingResult,
};
