import React from 'react';
import './EnergyIcon.less';
import { METER_TYPES } from '../../../helper/MeterHelper';
import classNames from 'classnames';

const Icons = {
    Electric: ({ className }: { className?: string }) => (
        <svg id="electric_icon" data-name="electric_icon" className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
            <path
                fill={'currentColor'}
                d="M289.15,131.07H266V42.82a10,10,0,0,0-10-10h-5.41a10,10,0,0,0-10,10v88.25H159.43V42.82a10,10,0,0,0-10-10H144a10,10,0,0,0-10,10v88.25H110.85a20,20,0,0,0-19.51,24.39l14,62.47a19.61,19.61,0,0,1,.49,4.38v82.45a20,20,0,0,0,20,20h57.5v34.79a7.64,7.64,0,0,0,7.63,7.63h18a7.64,7.64,0,0,0,7.63-7.63V324.76h57.54a20,20,0,0,0,20-20V222.15a20.22,20.22,0,0,1,.48-4.39l14-62.3A20,20,0,0,0,289.15,131.07Zm-55.31,84.35-46.12,83.35a2.64,2.64,0,0,1-4.74-2.13l14.19-55.72a2.64,2.64,0,0,0-2.71-3.29l-26,1.48a2.63,2.63,0,0,1-2.49-3.85l40.9-77.95a2.64,2.64,0,0,1,4.92,1.74l-9.28,47.06a2.63,2.63,0,0,0,2.44,3.14l26.81,2.06A2.64,2.64,0,0,1,233.84,215.42Z"
            />
        </svg>
    ),
    Gas: ({ className }: { className?: string }) => (
        <svg id="gas_icon" data-name="gas_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" className={className}>
            <path
                d="M259.56,149.68s11.31,50.43-9,56.19c-1.17-80-32.27-107.26-88.07-174.71,34.84,80-22,110.94-55.45,192.69a122.5,122.5,0,0,0-7.56,26.24c-9.36,56.26,7.13,97.93,63.4,117.16,1.77.58,3.55,1.1,5.32,1.59-14.91-9.35-24.71-25.07-24.39-42.89,1.33-74.45,40.22.68,56.15-51.91s56.16,23.24,56.16,51.91c0,15.78-7.63,29.9-19.65,39.42a121.8,121.8,0,0,0,20.54-10.43c25.75-16.22,42.14-42.18,45-70.87C307.84,226.86,283.91,155,259.56,149.68Z"
                fill={'currentColor'}
            />
        </svg>
    ),
    Heat: ({ className }: { className?: string }) => (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" className={className}>
            <path
                d="M54.94,97.12,27.72,94.83a10.14,10.14,0,0,1-9.29-10.1V67.83a10.13,10.13,0,0,1,9.29-10.09l27.22-2.3a10.13,10.13,0,0,1,11,10.1V87A10.13,10.13,0,0,1,54.94,97.12Z"
                fill={'currentColor'}
            />
            <path
                d="M105.41,41.75v-8a3,3,0,0,0-3-3H96.54a3,3,0,0,0-3,3v8A26.25,26.25,0,0,0,73.22,67.32V332.68a26.25,26.25,0,0,0,26.25,26.25h0a26.26,26.26,0,0,0,26.26-26.25V67.32A26.25,26.25,0,0,0,105.41,41.75Z"
                fill={'currentColor'}
            />
            <path
                d="M172.43,41.75v-8a3,3,0,0,0-3-3h-5.87a3,3,0,0,0-3,3v8a26.25,26.25,0,0,0-20.33,25.57V332.68a26.26,26.26,0,0,0,26.26,26.25h0a26.26,26.26,0,0,0,26.26-26.25V67.32A26.25,26.25,0,0,0,172.43,41.75Z"
                fill={'currentColor'}
            />
            <path
                d="M239.44,41.75v-8a3,3,0,0,0-3-3h-5.87a3,3,0,0,0-3,3v8a26.25,26.25,0,0,0-20.32,25.57V332.68a26.26,26.26,0,0,0,26.26,26.25h0a26.26,26.26,0,0,0,26.26-26.25V67.32A26.25,26.25,0,0,0,239.44,41.75Z"
                fill={'currentColor'}
            />
            <path
                d="M306.46,41.75v-8a3,3,0,0,0-3-3h-5.87a3,3,0,0,0-3,3v8a26.25,26.25,0,0,0-20.32,25.57V332.68a26.26,26.26,0,0,0,26.26,26.25h0a26.25,26.25,0,0,0,26.25-26.25V67.32A26.25,26.25,0,0,0,306.46,41.75Z"
                fill={'currentColor'}
            />
        </svg>
    ),
    Water: ({ className }: { className?: string }) => (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" className={className}>
            <path
                d="M309.28,224.9A110.64,110.64,0,0,0,280.16,167c-33.25-34.88-58.45-78.14-74.63-130.78a6.94,6.94,0,0,0-5.53-5,6.94,6.94,0,0,0-5.53,5c-16.18,52.64-41.38,95.9-74.63,130.78A110.64,110.64,0,0,0,90.72,224.9,131.05,131.05,0,0,0,89,246.4C89,312.56,138.29,366.47,200,368.84c61.71-2.37,111.05-56.28,111.05-122.44A131.05,131.05,0,0,0,309.28,224.9Zm-157.34,76-3.16,3.69c-4.84,5.65-13.36,5.22-17.85-1a96.74,96.74,0,0,1-17-42c-3.76-24.69,2-48.43,14.34-66.91a11.17,11.17,0,0,1,17.92-1.18l3.27,3.63a13.63,13.63,0,0,1,1.45,16.32,62.83,62.83,0,0,0-9.22,43.91,64.1,64.1,0,0,0,10.83,27.2A13.53,13.53,0,0,1,151.94,300.86Z"
                fill={'currentColor'}
            />
        </svg>
    ),
    Generic: ({ className }: { className?: string }) => (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" className={className}>
            <path
                d="M200,140.12c3.71,0,7.39.18,11,.53a8.32,8.32,0,0,0,9.14-8.27v-8.86a8.3,8.3,0,0,0-7.53-8.28c-4.16-.37-8.37-.57-12.62-.57s-8.46.2-12.62.57a8.3,8.3,0,0,0-7.53,8.28v8.86a8.32,8.32,0,0,0,9.14,8.27C192.61,140.3,196.29,140.12,200,140.12Z"
                fill={'currentColor'}
            />
            <path
                d="M156.82,138.54l-4.43-7.68a8.32,8.32,0,0,0-10.68-3.41,139,139,0,0,0-21.83,12.64A8.32,8.32,0,0,0,117.5,151l4.43,7.68A8.32,8.32,0,0,0,134,161.28a113.31,113.31,0,0,1,19.06-11A8.31,8.31,0,0,0,156.82,138.54Z"
                fill={'currentColor'}
            />
            <path
                d="M105.08,175.56l-7.68-4.44a8.31,8.31,0,0,0-10.93,2.38,139.14,139.14,0,0,0-12.64,21.84A8.32,8.32,0,0,0,77.23,206l7.69,4.44a8.32,8.32,0,0,0,11.72-3.78,112.83,112.83,0,0,1,11-19A8.31,8.31,0,0,0,105.08,175.56Z"
                fill={'currentColor'}
            />
            <path
                d="M86.5,253.62c0-3.71.18-7.39.52-11a8.31,8.31,0,0,0-8.26-9.14H69.9A8.31,8.31,0,0,0,61.62,241q-.57,6.23-.57,12.61t.57,12.62a8.3,8.3,0,0,0,8.28,7.53h8.86A8.31,8.31,0,0,0,87,264.63C86.68,261,86.5,257.34,86.5,253.62Z"
                fill={'currentColor'}
            />
            <path
                d="M313.5,253.62c0,3.72-.18,7.39-.52,11a8.31,8.31,0,0,0,8.26,9.14h8.86a8.3,8.3,0,0,0,8.28-7.53q.57-6.24.57-12.62T338.38,241a8.31,8.31,0,0,0-8.28-7.54h-8.86a8.31,8.31,0,0,0-8.26,9.14C313.32,246.23,313.5,249.91,313.5,253.62Z"
                fill={'currentColor'}
            />
            <path
                d="M278.07,158.71,282.5,151a8.32,8.32,0,0,0-2.38-10.94,139,139,0,0,0-21.83-12.64,8.32,8.32,0,0,0-10.68,3.41l-4.43,7.68A8.31,8.31,0,0,0,247,150.26a113.31,113.31,0,0,1,19.06,11A8.32,8.32,0,0,0,278.07,158.71Z"
                fill={'currentColor'}
            />
            <path
                d="M315.08,210.45l7.69-4.44a8.32,8.32,0,0,0,3.4-10.67,139.14,139.14,0,0,0-12.64-21.84,8.31,8.31,0,0,0-10.93-2.38l-7.68,4.44a8.31,8.31,0,0,0-2.58,12.06,112.83,112.83,0,0,1,11,19A8.32,8.32,0,0,0,315.08,210.45Z"
                fill={'currentColor'}
            />
            <path
                d="M361.82,311.7H38.18a6.9,6.9,0,0,1-6.59-4.86h0a176.3,176.3,0,0,1-8-52.52C23.63,157.07,102.75,78,200,78s176.37,79.12,176.37,176.37a176.3,176.3,0,0,1-8,52.52h0A6.9,6.9,0,0,1,361.82,311.7ZM43.35,297.87h313.3a163,163,0,0,0,5.9-43.55c0-89.63-72.92-162.55-162.55-162.55S37.45,164.69,37.45,254.32A163,163,0,0,0,43.35,297.87Z"
                fill={'currentColor'}
            />
            <path
                d="M205.83,225.46l5.39,2.67a4.16,4.16,0,0,1,1.87,5.57l-35.3,71.08h27.93l29.77-59.95a4.16,4.16,0,0,1,5.57-1.88l5.39,2.68a4.16,4.16,0,0,0,6-4l-2.84-45.27a4.16,4.16,0,0,0-6.45-3.2l-37.78,25.09A4.16,4.16,0,0,0,205.83,225.46Z"
                fill={'currentColor'}
            />
        </svg>
    ),
};

const EnergyIcon = ({ meterType, className, iconClassName }: { meterType?: METER_TYPES; className?: string; iconClassName?: string }) => {
    switch (meterType) {
        case METER_TYPES.ELECTRIC_METER:
            return (
                <div className={classNames('energy-icon inline-block text-electric-meter', className)}>
                    <Icons.Electric className={classNames('h-4', iconClassName)} />
                </div>
            );
        case METER_TYPES.GAS_METER:
            return (
                <div className={classNames('energy-icon inline-block text-gas-meter', className)}>
                    <Icons.Gas className={classNames('h-4', iconClassName)} />
                </div>
            );
        case METER_TYPES.GENERIC_METER:
            return (
                <div className={classNames('energy-icon inline-block text-generic-meter', className)}>
                    <Icons.Generic className={classNames('h-4', iconClassName)} />
                </div>
            );
        case METER_TYPES.HEAT_METER:
            return (
                <div className={classNames('energy-icon inline-block text-heat-meter', className)}>
                    <Icons.Heat className={classNames('h-4', iconClassName)} />
                </div>
            );
        case METER_TYPES.WATER_METER:
        case METER_TYPES.WATER_WARM_METER:
            return (
                <div className={classNames('energy-icon inline-block text-water-meter', className)}>
                    <Icons.Water className={classNames('h-4', iconClassName)} />
                </div>
            );
    }
    return null;
};

export default EnergyIcon;
