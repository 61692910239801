import React, { ReactChild } from 'react';
import './LoadingOverlay.less';
import Loading from '../../common/Loading/Loading';
import { useTranslation } from 'react-i18next';

const LoadingOverlay = ({
                            text,
                            loading,
                            children,
                        }: { text?: string, loading: boolean, children: ReactChild | ReactChild[] | string }) => {


    const { t, i18n } = useTranslation();

    return (
        <div className={'relative'}>
            {loading && (
                <div
                    className={'absolute pointer-events-auto flex align-center justify-center inset-0 w-full bg-white bg-opacity-80 rounded-md min-h-full'}>
                    <Loading text={text || t('common.loading')} />
                </div>
            )}
            {children}
        </div>
    );
};

export default LoadingOverlay;
