import React from 'react';
import './MarkdownViewer.less';
import Collapsable from '../Collapsable/Collapsable';
import classNames from 'classnames';
import Markdown from 'markdown-to-jsx';
import ImageOffline from '../ImageOffline/ImageOffline';

type Props = {
    text: string;
    className?: string;
    light?: boolean;
};

const MarkdownViewer = ({ text, className, light = false }: Props) => {
    return (
        <div
            className={classNames(
                {
                    'markdown-viewer-light': light,
                    'markdown-viewer text-justify text-base leading-6': !light,
                },
                className,
            )}
        >
            <Markdown
                options={{
                    overrides: {
                        img: {
                            component: ImageOffline,
                        },
                        h1: { component: light ? 'h1' : 'h3' },
                        h2: { component: light ? 'h2' : 'h4' },
                        h3: { component: light ? 'h3' : 'h5' },
                        a: {
                            component: 'a',
                            props: {
                                target: '_blank',
                                className: 'text-primary hover:text-primary-light',
                            },
                        },
                        Collapse: {
                            component: (props) => {
                                console.log('collapse props', props);
                                return (
                                    <Collapsable
                                        className={'mt-6 text-white'}
                                        triggerText={props.text}
                                    >
                                        <div className={'p-4'}>{props.children}</div>
                                    </Collapsable>
                                );
                            },
                        },
                    },
                }}
                children={text}
            />
        </div>
    );
};

export default MarkdownViewer;
