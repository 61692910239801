import React, { useMemo } from 'react';
import './JobTable.less';
import { JobType } from '../../../redux/jobs/jobs.types';
import Table from '../../../components/envago/Table/Table';
import { resolveMeterType } from '../../../helper/MeterHelper';
import EnergyIcon from '../../../components/envago/EnergyIcon/EnergyIcon';
import Icon from '../../../components/envago/Icon/Icon';
import { splitJoin } from '../../../helper/TextHelper';
import OptionalRow from '../../../components/envago/OptionalRow/OptionalRow';
import { useTranslation } from 'react-i18next';
import { hasMultipleValues } from '../../../helper/GroupHelper';
import { ColumnDef } from '@tanstack/react-table';


const JobTable = ({ jobs, rowActions }: { jobs: JobType[], rowActions?: (job: JobType) => (void) }) => {

    console.log("Table with jobs", jobs)

    const { t } = useTranslation();

    const showColumns = useMemo(() => {
        return {
            'meter.meterAddress.objectKey': hasMultipleValues(jobs, 'meter.meterAddress.objectKey'),
        }
    }, [jobs]);
    
    return (
        <div className='job-table w-full flex-1 flex' style={{minHeight: 200}}>
            <Table
                className={'text-sm flex-1'}
                selectable={false}
                groupable={false}
                columns={[
                    {
                        header: t('meterReadingJob.type'),
                        id: 'meterType',
                        cell: (props: any) => {
                            // console.log('props');
                            const meterType = resolveMeterType(props.row.original.meter);
                            return <EnergyIcon iconClassName={'h-5 w-5'} meterType={meterType} />;
                        },
                        enableSorting: false,
                        enableGrouping: false,
                        size: 40,
                    },
                    {
                        header:  t('meterReadingJob.status'),
                        size: 50,
                        enableSorting: false,
                        enableGrouping: false,
                        cell: (props: any) => {
                            return <div>{props.row.original.done ?
                                <Icon path={Icon.Path.mdiCheckCircleOutline} className={'h-5 text-green-500'} /> :
                                <Icon path={Icon.Path.mdiCheckboxBlankCircleOutline} className={'h-5 text-gray-300'} />
                            }</div>;
                        },
                    },
                    {
                        id: 'meternumber',
                        header:  t('meterReadingJob.meterNumber'),
                        accessorFn: (row: JobType) => row.meter.meterNumber,
                        enableSorting: false,
                        enableGrouping: false,
                    },
                    showColumns['meter.meterAddress.objectKey'] && {
                        id: 'objectId',
                        header:  t('meterReadingJob.objectKey'),
                        accessor: 'meter.meterAddress.objectKey',
                        enableSorting: false,
                        enableGrouping: false,
                    },
                    {
                        id: 'customer',
                        header:  t('meterReadingJob.customer.title'),
                        canSort: false,
                        enableGrouping: false,
                        cell: (props: any) => {
                            return <div className={'leading-none my-2'}>
                                <OptionalRow className={''}>{props.row.original.customer?.company}</OptionalRow>
                                <OptionalRow>{splitJoin(' ', props.row.original.customer?.firstname, props.row.original.customer?.lastname)}</OptionalRow>
                            </div>;
                        },
                    },
                    {
                        id: 'address',
                        header: t('meterReadingJob.address.title'),
                        canSort: false,
                        enableGrouping: false,
                        cell: (props: any) => {
                            return <div className={'leading-none'}>
                                <OptionalRow>
                                    {splitJoin(' ', props.row.original.meter.meterAddress?.street, props.row.original.meter.meterAddress?.houseNumber)}
                                </OptionalRow>
                                <OptionalRow>
                                    {splitJoin(' ', props.row.original.meter.meterAddress?.zip, props.row.original.meter.meterAddress?.city)}
                                </OptionalRow>
                            </div>;
                        },
                    },
                    rowActions && {
                        id: 'actions',
                        size: 40,
                        canSort: false,
                        enableGrouping: false,
                        cell: (props : any)  => rowActions(props.row.original),

                    }
                ].filter(a => !!a).map(a => a as ColumnDef<any>)}
                data={jobs}
            />
        </div>
    );
};

export default JobTable;
