import React from 'react';
import './BasicScreen.less';

const BasicScreen = ({children} : {children: any}) => {
    return (
        <div className='basic-screen p-1 md:p-2 flex-1'>
            {children}
        </div>
    );
};

export default BasicScreen;
