import React, { useMemo } from 'react';
import './ReadingValueComposedChart.less';
import { Area, CartesianGrid, ComposedChart, XAxis, YAxis } from 'recharts';

interface IReadingValueComposedChartProps {
    data: {
        readingDate: any;
        readingValue?: number;
        readingValueMinMax?: number[];
    }[];
}

const ReadingValueComposedChart = ({ data }: IReadingValueComposedChartProps) => {
    const isSameReadingValueInAllEvents = useMemo(
        () =>
            data.reduce(
                (acc, curr, index, data) =>
                    data.every((event) => event.readingValue === curr.readingValue),
                false,
            ),
        [data],
    );

    if (isSameReadingValueInAllEvents) return null;

    return (
        <ComposedChart
            className={'rounded border border-solid border-gray-400'}
            width={120}
            height={30}
            data={data}
            style={{ overflow: 'hidden' }}
            margin={{
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
            }}
        >
            <CartesianGrid vertical={false} horizontal={false} />
            <XAxis
                hide
                dataKey={'readingDate'}
                scale={'time'}
                type={'number'}
                domain={['auto', 'auto']}
            />
            <YAxis hide domain={['dataMin', 'dataMax']} />
            <Area
                isAnimationActive={false}
                type={'linear'}
                dataKey={'readingValue'}
                stroke={'#aaa'}
                fill={'#ccc'}
                strokeWidth={1}
                connectNulls={true}
                dot={false}
            />
        </ComposedChart>
    );
};

export default ReadingValueComposedChart;
