import { enabledLanguageType } from './language.types';

export const types = {
    SET_DEFAULT_LANGUAGE: 'SET_DEFAULT_LANGUAGE',
    SET_DEFAULT_LANGUAGE_DONE: 'SET_DEFAULT_LANGUAGE_DONE',

    MERGE_TRANSLATIONS: 'MERGE_TRANSLATIONS',
    MERGE_TRANSLATIONS_DONE: 'MERGE_TRANSLATIONS_DONE',

    CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
    CHANGE_LANGUAGE_DONE: 'CHANGE_LANGUAGE_DONE',
};

const setDefaultLanguage = () => ({ type: types.SET_DEFAULT_LANGUAGE });
const setDefaultLanguageDone = (currentLanguage: string) => ({
    type: types.SET_DEFAULT_LANGUAGE_DONE,
    payload: currentLanguage,
});

const mergeTranslations = () => ({ type: types.MERGE_TRANSLATIONS });
const mergeTranslationsDone = (mergedTranslations: boolean) => ({
    type: types.MERGE_TRANSLATIONS_DONE,
    payload: mergedTranslations,
});

const changeLanguage = (language: string) => ({ type: types.CHANGE_LANGUAGE, payload: language });
const changeLanguageDone = (language: string) => ({
    type: types.CHANGE_LANGUAGE_DONE,
    payload: language,
});

export default {
    setDefaultLanguage,
    setDefaultLanguageDone,
    mergeTranslations,
    mergeTranslationsDone,
    changeLanguage,
    changeLanguageDone,
};
