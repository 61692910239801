import { JobConstraintsType } from '../../../../redux/jobs/jobs.types';
import dayjs from 'dayjs';
import Information, {
    InformationDetails,
    InformationType,
} from '../../../../components/envago/Information/Information';
import { formatDate } from '../../../../helper/DateHelper';
import { Trans, useTranslation } from 'react-i18next';


const JobConstraintWarnings = ({ constraints }: { constraints?: JobConstraintsType }) => {
    const { t, i18n } = useTranslation();

    if (!constraints ){
        return null;
    }

    const today = dayjs();

    /*
       Beides gesetzt
     */
    if (constraints.earliestExecution && constraints.latestExecution) {
        if (today.isAfter(dayjs(constraints.earliestExecution)) && today.isBefore(dayjs(constraints.latestExecution))) {
            return null;
        }
    }




    if (constraints.earliestExecution) {
        if (today.isBefore(dayjs(constraints.earliestExecution))) {
            return <Information className={'mb-2'} type={InformationType.INFO}>
                <InformationDetails title={t('jobs.details.warnings.constraints.beforeEarliestExecution.title')}>
                    <Trans i18nKey={'jobs.details.warnings.constraints.beforeEarliestExecution.description'} values={{earliestExecution: formatDate(constraints.earliestExecution)}}>
                        Der Zählerstand dieser Ablesung wurde ab dem <b>{formatDate(constraints.earliestExecution)}</b> angefragt.<br/>
                        Du kannst die Ablesung durchführen, eventuell wird der tatsächliche Verbrauch jedoch geschätzt.
                    </Trans>
                </InformationDetails>
            </Information>;
        }
    }

    if (constraints.latestExecution) {
        if (today.isAfter(dayjs(constraints.latestExecution))) {
            return <Information className={'mb-2'} type={InformationType.INFO}>
                <InformationDetails title={t('jobs.details.warnings.constraints.afterLatestExecution.title')}>
                    <Trans i18nKey={'jobs.details.warnings.constraints.afterLatestExecution.description'} values={{latestExecution: formatDate(constraints.latestExecution)}}>
                        Der Zählerstand dieser Ablesung wurde bis zum <b>{formatDate(constraints.latestExecution)}</b> angefragt.<br/>
                        Du kannst die Ablesung durchführen, eventuell kann der Zählerstand jedoch nicht mehr für die Abrechnung
                        berücksichtigt werden.
                    </Trans>
                </InformationDetails>
            </Information>;
        }
    }


    return null;
};

export default JobConstraintWarnings;
