import React, { useEffect, useMemo, useState } from 'react';
import Input from '../../../../../components/envago/Input/Input';
import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';

import { CustomMeterConfig, MeterConfig, MeterNumberValidation } from '../../../../../redux/application/application.types';
import { useTranslation } from 'react-i18next';
import RegEx from '../../../../../constants/RegEx';
import Icon from '@mdi/react';
import { mdiCheckCircle, mdiCircle } from '@mdi/js';
import Description from '../../Description';
import Information, { InformationType } from '../../../../../components/envago/Information/Information';
import { MeterType } from '../../../../../api/unknownUserReading.api.types';
import { resolveMeterTypeByMeterNumber } from '../../../../../helper/MeterHelper';

export interface MeterFormData {
    meterNumber?: string;
    meterType?: MeterType;
    subTypeMeterConfig?: MeterConfig<MeterType> | CustomMeterConfig<MeterType>;
}

interface MeterFormProps {
    initialValues: MeterFormData;
    onChange: (data: MeterFormData, isValid: boolean) => void;

    meterTypes: Array<MeterType>;
    subTypeMeterConfigs?: Array<MeterConfig<MeterType>>;
    customMeterConfig?: CustomMeterConfig<MeterType>;

    validation?: MeterNumberValidation;
}

const MeterForm = ({ meterTypes, subTypeMeterConfigs, customMeterConfig, initialValues, onChange, validation }: MeterFormProps) => {
    const [t] = useTranslation();

    const [data, setData] = useState<MeterFormData>(initialValues);

    const isValid = useMemo(() => {
        if (!data.meterNumber) return false;

        if (validation) {
            const minLength = validation?.meterNumberMinLength ?? 8;
            const maxLength = validation?.meterNumberMaxLength ?? 14;

            if (data.meterNumber.length < minLength || data.meterNumber.length > maxLength) {
                return false;
            }
        }

        if (subTypeMeterConfigs && subTypeMeterConfigs?.length > 0 && data.subTypeMeterConfig == null) {
            return false;
        }

        return true;
    }, [data, subTypeMeterConfigs, validation]);

    useEffect(() => {
        if (data.subTypeMeterConfig != null) return;

        const shouldReInitialiseMeterTypeConfig =
            (subTypeMeterConfigs && subTypeMeterConfigs?.length > 0 && subTypeMeterConfigs?.[0].meterType === data.meterType) ||
            (customMeterConfig && customMeterConfig.meterType === data.meterType);

        if (shouldReInitialiseMeterTypeConfig) {
            setData((prevState) => ({
                ...prevState,
                subTypeMeterConfig: subTypeMeterConfigs?.[0] || customMeterConfig,
            }));
        }
    }, [data.subTypeMeterConfig, data.meterType, subTypeMeterConfigs, customMeterConfig]);

    useEffect(() => {
        onChange(data, isValid);
    }, [data, isValid]);

    return (
        <>
            <div>
                <div className={'my-2'}>
                    <h3>{t('unknownUserReading.meterStep.title')}</h3>
                    <Description>{t('unknownUserReading.meterStep.description')}</Description>
                </div>
                <h4 className={'mt-4'}>{t('unknownUserReading.subTitle.meterNumber')}</h4>
                <div className={'flex flex-col items-end'}>
                    <Input
                        autoFocus={true}
                        className={'h-10 text-lg'}
                        maxLength={validation?.meterNumberMaxLength ?? Number.MAX_SAFE_INTEGER}
                        value={data.meterNumber}
                        placeholder={t('unknownUserReading.placeholder.meterNumber')}
                        onChange={(value) => {
                            const resolvedMeterType = resolveMeterTypeByMeterNumber(value.toUpperCase(), meterTypes, data.meterType);

                            setData((prevState) => ({
                                ...prevState,
                                meterNumber: value.replace(RegEx.NON_ALPHA_NUMERIC_REGEX, '')?.toUpperCase(),
                                meterType: resolvedMeterType,
                                subTypeMeterConfig: prevState.subTypeMeterConfig?.meterType === resolvedMeterType ? prevState.subTypeMeterConfig : undefined,
                            }));
                        }}
                    />
                    {validation?.meterNumberMaxLength && (
                        <div className={'text-xs text-secondary -mt-2'}>{`(${data.meterNumber?.length || 0} / ${validation?.meterNumberMaxLength})`}</div>
                    )}
                    <Description className={'self-start'}>{t('unknownUserReading.information.meterNumberHint')}</Description>
                </div>
                <h4>{t('unknownUserReading.subTitle.meterType')}</h4>
                <RadioGroup
                    value={data.meterType}
                    onChange={(value: MeterType) => {
                        setData((prevState) => ({
                            ...prevState,
                            meterType: value,
                            subTypeMeterConfig: prevState.subTypeMeterConfig?.meterType === value ? prevState.subTypeMeterConfig : undefined,
                        }));
                    }}
                >
                    {meterTypes.map((type, index) => {
                        return (
                            <RadioGroup.Option key={index} value={type} className={'flex mb-1'}>
                                {({ checked }) => (
                                    <div
                                        className={classNames(
                                            'flex flex-1 rounded p-2 cursor-pointer items-center hover:bg-gray-100 transition-colors duration-300 hover:text-gray-800',
                                            checked && 'bg-gray-100',
                                        )}
                                    >
                                        <span>
                                            {checked ? (
                                                <Icon path={mdiCheckCircle} size={1.1} className={'text-accent'} />
                                            ) : (
                                                <Icon path={mdiCircle} size={1.1} className={'text-gray-200'} />
                                            )}
                                        </span>
                                        <span className={classNames('ml-4 cursor-pointer flex-1')}>{t(`unknownUserReading.meterType.${type}`, type)}</span>
                                    </div>
                                )}
                            </RadioGroup.Option>
                        );
                    })}
                </RadioGroup>
                {subTypeMeterConfigs && subTypeMeterConfigs?.length > 0 && (
                    <>
                        <h4>{t('unknownUserReading.subTitle.meterSubType')}</h4>
                        <RadioGroup
                            value={data.subTypeMeterConfig}
                            onChange={(v) => {
                                setData((prevState) => ({
                                    ...prevState,
                                    subTypeMeterConfig: v,
                                }));
                            }}
                        >
                            <>
                                {subTypeMeterConfigs?.map((c) => (
                                    <RadioGroup.Option key={c.subType} value={c} className={'flex mb-1'}>
                                        {({ checked }) => (
                                            <div
                                                className={classNames(
                                                    'flex flex-1 rounded p-2 cursor-pointer items-center hover:bg-gray-100 transition-colors duration-300 hover:text-gray-800',
                                                    checked && 'bg-gray-100',
                                                )}
                                            >
                                                <span>
                                                    {checked ? (
                                                        <Icon path={mdiCheckCircle} size={1.1} className={'text-accent'} />
                                                    ) : (
                                                        <Icon path={mdiCircle} size={1.1} className={'text-gray-200'} />
                                                    )}
                                                </span>
                                                <div className={'flex flex-col'}>
                                                    <span className={classNames('ml-4 cursor-pointer flex-1')}>
                                                        {t(`unknownUserReading.meterSubType.${c.subType}`, c.subType)}
                                                    </span>
                                                    <span className={classNames('ml-4 cursor-pointer flex-1 text-sm text-gray-500')}>
                                                        {t(`unknownUserReading.meterSubTypeDescription.${c.subType}`, c.subTypeDescription || '')}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </RadioGroup.Option>
                                ))}
                                {customMeterConfig && (
                                    <RadioGroup.Option key={customMeterConfig.subType || 'OTHER'} value={customMeterConfig} className={'flex mb-1'}>
                                        {({ checked }) => (
                                            <div
                                                className={classNames(
                                                    'flex flex-1 rounded p-2 cursor-pointer items-center hover:bg-gray-100 transition-colors duration-300 hover:text-gray-800',
                                                    checked && 'bg-gray-100',
                                                )}
                                            >
                                                <span>
                                                    {checked ? (
                                                        <Icon path={mdiCheckCircle} size={1.1} className={'text-accent'} />
                                                    ) : (
                                                        <Icon path={mdiCircle} size={1.1} className={'text-gray-200'} />
                                                    )}
                                                </span>
                                                <div className={'flex flex-col'}>
                                                    <span className={classNames('ml-4 cursor-pointer flex-1')}>
                                                        {t(
                                                            `unknownUserReading.meterSubType.${customMeterConfig?.subType}`,
                                                            customMeterConfig?.subTypeLabel || customMeterConfig?.subType,
                                                        )}
                                                    </span>
                                                    <span className={classNames('ml-4 cursor-pointer flex-1 text-sm text-gray-500')}>
                                                        {t(
                                                            `unknownUserReading.meterSubTypeDescription.${customMeterConfig.subType}`,
                                                            customMeterConfig.subTypeDescription || '',
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </RadioGroup.Option>
                                )}
                            </>
                        </RadioGroup>
                    </>
                )}
            </div>
            {!isValid && (
                <Information showIcon={false} className={'mt-8'} type={InformationType.ERROR}>
                    {t('unknownUserReading.meterStep.validationError')}
                </Information>
            )}
        </>
    );
};

export default MeterForm;
