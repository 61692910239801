import React, { useEffect, useState } from 'react';
import './JobList.less';
import { JobType } from '../../../../redux/jobs/jobs.types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useLocation, useParams } from 'react-router';
import ListSeparator from '../../../../components/envago/ListSeparator/ListSeparator';
import { Trans, useTranslation } from 'react-i18next';
import queueSelectors from '../../../../redux/offline/queue.selectors';
import { applicationSelectors } from '../../../../redux/application';
import JobListItem from '../../../../components/envago/JobListItem/JobListItem';
import Icon from '../../../../components/envago/Icon/Icon';
import JobListItemWrapper from '../../../common/JobListItemWrapper';
import { searchSelectors } from '../../../../redux/search';

const JobList = ({ jobs, className }: { jobs: JobType[]; className?: string }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { jobId }: { jobId: string } = useParams();
    const location = useLocation();

    const { unknownReadingsAllowed } = useSelector(applicationSelectors.getFeaturesConfig);
    const searchValue = useSelector(searchSelectors.getSearchValue);

    const pendingJobs = useSelector(queueSelectors.getPendingJobIds);
    const [jobsDone, setJobsDone] = useState(jobs.filter((j) => j.done));
    const [jobsOpen, setJobsOpen] = useState(jobs.filter((j) => !j.done));

    useEffect(() => {
        setJobsDone(jobs.filter((j) => j.done));
        setJobsOpen(jobs.filter((j) => !j.done));
    }, [jobs]);

    return (
        <div style={{}} className={classNames('job-list flex flex-col flex-1 gap-2', className)}>
            {jobsOpen.map((job) => (
                <JobListItemWrapper
                    key={job.id}
                    job={job}
                    onClick={() => dispatch(push(`/ablesung/${job.id}`))}
                    isActive={jobId === job.id}
                    iconRight={pendingJobs.includes(job.id) ? 'PENDING' : 'ARROW'}
                />
            ))}
            {unknownReadingsAllowed && location.pathname.includes('ablesung') && (
                <JobListItem
                    title={t('jobs.unknownMeterReading.listItemLabel')}
                    titleSize={'small'}
                    icon={<Icon className={'h-7 text-accent'} path={Icon.Path.mdiPlus} />}
                    color={'accent'}
                    onClick={() => {
                        dispatch(push(`/ablesung/neu`));
                    }}
                    isActive={location.pathname === '/ablesung/neu'}
                    iconRight={'ARROW'}
                />
            )}
            {jobsDone.length > 0 && <ListSeparator text={t('jobs.labels.readingsDone')} />}
            {jobsDone.map((job) => (
                <JobListItemWrapper
                    key={job.id}
                    job={job}
                    onClick={() => dispatch(push(`/ablesung/${job.id}`))}
                    isActive={jobId === job.id}
                    isGhost={job.done}
                    isDone={job.done}
                    iconRight={pendingJobs.includes(job.id) ? 'PENDING' : 'CHECK'}
                />
            ))}

            {searchValue.length > 0 && jobs.length === 0 && (
                <div className={'bg-gray-200 p-4 rounded text-gray-700 text-sm text-center '}>
                    <Trans
                        i18nKey={'jobs.search.noResults'}
                        values={{ searchValue }}
                        tOptions={{ interpolation: { escapeValue: true } }}
                    >
                        Es konnten keine Ergebnisse für die Suche nach <br /> <b>"{searchValue}"</b>{' '}
                        <br /> gefunden werden.
                    </Trans>
                    <br />
                </div>
            )}
        </div>
    );
};

export default JobList;
