import { createSelector } from 'reselect';
import { offlineStateType } from './offline.types';
import { QueueStateType } from './queue.reducer';
import { jobsActions, jobsTypes } from '../jobs';

const getSubState = (state: any) => state.queue as QueueStateType;

const getQueuedActions = createSelector(getSubState, state => state.queue);
const getQueueLength = createSelector(getQueuedActions, actions => actions.length);

const getPendingJobIds = createSelector(getQueuedActions, actions => actions.filter(a => a.type === jobsTypes.SEND_METER_TASK_RESULTS).map(a => a.jobId))
const isJobPending = (jobId: string) => createSelector(getQueuedActions, actions => actions.find(a => a.type === jobsTypes.SEND_METER_TASK_RESULTS && a.jobId === jobId))

const queueSelectors = {
    getPendingJobIds,
    getQueueLength,
    getQueuedActions,
    isJobPending,
}

export default queueSelectors;
