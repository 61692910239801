import { AnyAction } from 'redux';
import { offlineStateType } from './offline.types';
import { offlineTypes } from './index';

const initialState: offlineStateType = {
    isOnline: true,
    queueSending: false,
    dialogShown: false,
};

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case offlineTypes.OFFLINE_MESSAGE_SHOWN: {
            return {
                ...state,
                dialogShown: true,
            }
        }

        case offlineTypes.QUEUE_TRY_SEND_ERROR:
        case offlineTypes.QUEUE_TRY_SEND_DONE: {
            return {
                ...state,
                queueSending: false,
            };
        }
        case offlineTypes.QUEUE_TRY_SEND: {
            return {
                ...state,
                queueSending: true,
            };
        }
        case offlineTypes.SET_ONLINE_STATUS: {
            return {
                ...state,
                isOnline: action.isOnline,
            };
        }

        default:
            return state;
    }
};

export default reducer;
