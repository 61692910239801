import React, { CSSProperties } from 'react';
import './Icon.less';
import MdiIcon from '@mdi/react';
import {
    mdiAccount,
    mdiAlertCircleOutline,
    mdiAlertOutline,
    mdiArchiveOutline,
    mdiArrowRight,
    mdiAt,
    mdiBellRingOutline,
    mdiCalendar,
    mdiCamera,
    mdiChartAreaspline,
    mdiCheck,
    mdiCheckCircle,
    mdiCheckCircleOutline,
    mdiCheckboxBlankCircleOutline,
    mdiChevronDown,
    mdiChevronLeft,
    mdiChevronRight,
    mdiClipboardOutline,
    mdiClose,
    mdiCloseOctagonOutline,
    mdiDelete,
    mdiDiameterVariant,
    mdiEye,
    mdiHelpCircle,
    mdiImage,
    mdiInformationOutline,
    mdiMenu,
    mdiMenuDown,
    mdiMenuUp,
    mdiMinus,
    mdiMinusBoxOutline,
    mdiPlus,
    mdiPlusBoxOutline,
    mdiProgressClock,
    mdiQrcodeScan,
    mdiRefreshCircle,
    mdiSelectGroup,
    mdiShare,
    mdiSortAscending,
    mdiSortDescending,
} from '@mdi/js';
import classNames from 'classnames';

const Icon = ({ path, className, size, style }: { className?: string; path: string; size?: number | string; style?: CSSProperties }) => {
    return <MdiIcon className={classNames(className, 'icon')} path={path} size={size} style={style} />;
};

Icon.Path = {
    mdiAccount,
    mdiAlertCircleOutline,
    mdiAlertOutline,
    mdiArchiveOutline,
    mdiArrowRight,
    mdiAt,
    mdiBellRingOutline,
    mdiCalendar,
    mdiCamera,
    mdiChartAreaspline,
    mdiCheck,
    mdiCheckCircle,
    mdiCheckCircleOutline,
    mdiCheckboxBlankCircleOutline,
    mdiChevronDown,
    mdiChevronLeft,
    mdiChevronRight,
    mdiClipboardOutline,
    mdiClose,
    mdiCloseOctagonOutline,
    mdiDelete,
    mdiDiameterVariant,
    mdiEye,
    mdiHelpCircle,
    mdiImage,
    mdiInformationOutline,
    mdiMenu,
    mdiMenuDown,
    mdiMenuUp,
    mdiMinus,
    mdiMinusBoxOutline,
    mdiPlus,
    mdiPlusBoxOutline,
    mdiProgressClock,
    mdiQrcodeScan,
    mdiRefreshCircle,
    mdiSelectGroup,
    mdiShare,
    mdiSortAscending,
    mdiSortDescending,
};

export default Icon;
