import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router'; // react-router v4/v5
import { ConnectedRouter } from 'connected-react-router';
import { history, persistor, runSaga, store } from './redux/store';
import MainScreen from './screens/MainScreen/MainScreen';
import HomeScreen from './screens/HomeScreen/HomeScreen';
import rootSaga from './redux/root.saga';
import TokenLoginScreen from './screens/TokenLoginScreen/TokenLoginScreen';
import JobsScreen from './screens/JobsScreen/JobsScreen';
import { PersistGate } from 'redux-persist/integration/react';
import IntroductionScreen from './screens/IntroductionScreen/IntroductionScreen';
import ScrollToTop from './components/common/ScrollToTop/ScrollToTop';
import ManagementScreen from './screens/ManagementScreen/ManagementScreen';
import LoginScreen from './screens/LoginScreen/LoginScreen';
import HelpScreen from './screens/HelpScreen/HelpScreen';
import PageNotExists from './screens/404/PageNotExists';
import EvaluationScreen from './screens/EvaluationScreen';
import UnknownUserReadingScreen from './screens/UnknownUserReadingScreen/UnknownUserReadingScreen';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import CommunicationProvider from './screens/common/Communication/CommunicationProvider';
import CheckUpdates from './screens/common/CheckUpdates';

dayjs.extend(duration);

runSaga(rootSaga);

function App() {
    return (
        <div id={'App'}>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <ConnectedRouter history={history}>
                        <>
                            <CheckUpdates/>
                            <ScrollToTop />

                            <Switch>
                                <Route path={['/m/:token', '//m/:token']} render={() => <TokenLoginScreen />} />

                                <Route
                                    exact={true}
                                    path={['/ablesung-ohne-zugang']}
                                    render={() => (
                                        <>
                                            <MainScreen showNavBarIfNotLoggedIn={true}>
                                                <UnknownUserReadingScreen />
                                            </MainScreen>
                                        </>
                                    )}
                                />

                                <MainScreen>
                                    <LoginScreen>
                                        <CommunicationProvider>
                                            <Switch>
                                                <Route exact={true} path={'/'} component={HomeScreen} />

                                                <Route exact={true} path={'/ablesung'} component={JobsScreen} />
                                                <Route path={'/ablesung/:jobId'} component={JobsScreen} />

                                                <Route exact={true} path={'/auswertung'} component={EvaluationScreen} />

                                                <Route path={'/auswertung/:jobId'} component={EvaluationScreen} />

                                                <Route path={'/introduction'} component={IntroductionScreen} />
                                                <Route path={'/management'} component={ManagementScreen} />
                                                <Route path={'/help'} component={HelpScreen} />

                                                <Route render={() => <PageNotExists />} />
                                            </Switch>
                                        </CommunicationProvider>
                                    </LoginScreen>
                                </MainScreen>
                            </Switch>
                        </>
                    </ConnectedRouter>
                </PersistGate>
            </Provider>
        </div>
    );
}

export default App;
