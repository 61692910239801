import React, { ReactChild, ReactNode, ReactText } from 'react';
import './Label.less';
import classNames from 'classnames';

const Label = ({
    children,
    className,
}: {
    children: ReactChild | ReactNode;
    className?: string;
}) => {
    return (
        <div
            className={classNames(
                'label text-gray-400 text-sm whitespace-nowrap mt-2 lg:mt-0',
                className,
            )}
        >
            {children}
        </div>
    );
};

export default Label;
