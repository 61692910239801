import React from 'react';
import './HomeScreen.less';
import Card from '../../components/envago/Card/Card';
import Button from '../../components/envago/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { applicationSelectors } from '../../redux/application';
import { loginSelectors } from '../../redux/login';
import { jobsSelectors } from '../../redux/jobs';
import LabeledList from '../../components/envago/LabeledList/LabeledList';
import OptionalLabel from '../../components/envago/OptionalLabel/OptionalLabel';
import ProgressBar from '../../components/envago/ProgressBar/ProgressBar';
import BasicScreen from '../common/BasicScreen/BasicScreen';
import { Trans, useTranslation } from 'react-i18next';
import ContactInformation from '../../components/envago/ContactInformation/ContactInformation';
import CommunicationHomeSection from '../common/Communication/CommunicationHomeSection';

const HomeScreen = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const applicationName = useSelector(applicationSelectors.getApplicationName);
    const logins = useSelector(loginSelectors.getLogins);
    const jobCount = useSelector(jobsSelectors.getJobsCount);
    const jobCountOpen = useSelector(jobsSelectors.getCountJobsOpen);
    const jobCountDone = useSelector(jobsSelectors.getCountJobsDone);
    const hasShareableJobs = useSelector(jobsSelectors.hasShareableJobs);
    const reshareAllowed = useSelector(jobsSelectors.isAnyJobReshareAllowed);

    return (
        <BasicScreen>
            <div className={'my-4'}>
                <h1>{t('home.title', { applicationName })}</h1>
            </div>

            <div className={'grid grid-cols-1 md:grid-cols-2 gap-2 text-gray-500'}>
                <Card className={'row-span-2 hidden md:block'}>
                    <h1>{t('home.introduction.title')}</h1>

                    <div className={''}>
                        <LabeledList>
                            <OptionalLabel label={t('menu.reading')}>
                                <Trans i18nKey={'home.introduction.reading'}>
                                    Deine Zähler findest du im Menü <b>{t('menu.reading')}</b>. Dort kannst du schnell & einfach deine Zählerstände eintragen
                                    und abschicken.
                                </Trans>
                                <br />
                            </OptionalLabel>

                            <>
                                {hasShareableJobs && reshareAllowed && (
                                    <OptionalLabel className={'mt-2'} label={t('menu.management')}>
                                        <p>
                                            <Trans i18nKey={'home.introduction.management'}>
                                                Über den Menüpunkt <b>{t('menu.management')}</b> kannst du Ablesungen mit anderen teilen.
                                                <br />
                                                Als Hausverwaltung teilst du z.B. deine Ablesungen mit deinem Hausverwalter oder den Hausmeistern.
                                            </Trans>
                                        </p>
                                    </OptionalLabel>
                                )}
                            </>
                        </LabeledList>
                    </div>
                </Card>

                <Card className={''}>
                    <h2>{t('home.account.title', { count: logins.length })}</h2>

                    <>
                        {logins.length > 1 && (
                            <p className={'text-sm my-1'}>{t('home.account.multipleAccounts', 'Du hast dich mit mehreren Zugängen angemeldet:')}</p>
                        )}
                    </>

                    <>
                        {logins.length === 1 ? (
                            <>
                                {logins.map(([loginId, login]) => {
                                    console.log('login');
                                    return (
                                        <div key={`login-${loginId}`}>
                                            <p>
                                                <Trans
                                                    i18nKey={'home.account.login.info'}
                                                    values={{
                                                        name: login.info?.name || t('common.account.noName'),
                                                    }}
                                                >
                                                    Du bist mit dem Zugang
                                                    <b>{login.info?.name || t('common.account.noName')}</b>
                                                    angemeldet.
                                                </Trans>
                                                {login.info?.parentName && (
                                                    <span>
                                                        {' '}
                                                        <Trans
                                                            i18nKey={'home.account.login.parent'}
                                                            values={{
                                                                parent: login.info?.parentName || '',
                                                            }}
                                                        >
                                                            Dieser Zugang wurde von
                                                            <b>{login.info?.parentName || ''}</b>
                                                            für dich erstellt.
                                                        </Trans>
                                                    </span>
                                                )}
                                            </p>
                                        </div>
                                    );
                                })}
                            </>
                        ) : (
                            <div className={'divide-solid'}>
                                {logins.map(([loginId, login]) => {
                                    console.log('login');
                                    return (
                                        <div key={`login-${loginId}`} className={'flex-1'}>
                                            <div className={'font-medium'}>{login.info?.name}</div>
                                            <div className={'text-sm text-gray-400'}>
                                                {login.info?.parentName
                                                    ? t('home.account.login.shared', {
                                                          parent: login.info.parentName,
                                                      })
                                                    : ``}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </>
                </Card>
                <Card className={''}>
                    <h2>{t('home.readings.title')}</h2>

                    {jobCountOpen > 0 && (
                        <p>
                            <Trans
                                i18nKey={'home.readings.open'}
                                values={{
                                    jobCountOpen: jobCountOpen || 0,
                                    count: jobCountOpen || 0,
                                }}
                            >
                                Du hast <b>{{ jobCountOpen }} offene</b> Ablesungen.
                            </Trans>
                        </p>
                    )}

                    {jobCountOpen === 0 && <p>{t('home.readings.done')}</p>}

                    <LabeledList className={'mt-2'}>
                        <OptionalLabel label={t('home.readings.labels.totalReadings')}>
                            {jobCount} {t('common.reading', { count: jobCount })}
                        </OptionalLabel>
                        <OptionalLabel label={t('home.readings.labels.progress')}>
                            <ProgressBar progress={jobCountDone / jobCount} value={jobCountDone} total={jobCount} showPercentage={true} showNumbers={true} />
                        </OptionalLabel>

                        <OptionalLabel>
                            <Button
                                primary={true}
                                onClick={() => {
                                    dispatch(push('/ablesung'));
                                }}
                                block={true}
                                className={''}
                            >
                                {t('home.readings.buttons.openReadings')}
                            </Button>
                        </OptionalLabel>
                    </LabeledList>
                </Card>
                <CommunicationHomeSection />
                <ContactInformation wrapper={Card} wrapperProps={{ className: 'md:col-start-2' }} path={'home.contact'} />
            </div>
        </BasicScreen>
    );
};

export default HomeScreen;
