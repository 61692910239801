import { createSelector } from 'reselect';
import { offlineStateType } from './offline.types';

const getSubState = (state: any) => state.offline as offlineStateType;

const isOnline = createSelector(getSubState, (state) => state.isOnline);
const isQueueSending = createSelector(getSubState, (state) => state.queueSending);

const hasDialogBeenShown = createSelector(getSubState, (state) => state.dialogShown);

export default {
    isOnline,
    isQueueSending,
    hasDialogBeenShown,
};
