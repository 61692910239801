import axios, { AxiosResponse } from 'axios';
import { AccountCrmRegistrationInfoResponseDTO, UnknownUserReadingResultReceiptRequestDTO } from './communication.api.types';

const fetchCRMRegistrationInfo = async (token: string) => {
    const { data } = await axios.get<AccountCrmRegistrationInfoResponseDTO>('/api/pro/account/crm-registration-info', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return data;
};

const sendCRMReadingReceiptRequest = async (token: string) => {
    const response: AxiosResponse = await axios.post('/api/pro/job/reading-receipts', undefined, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response;
};

const sendUnknownUserReadingReceipt = async (request: UnknownUserReadingResultReceiptRequestDTO, token: string) => {
    const response = await axios.post<any>(`/api/pro/unknown-readings/receipts`, request, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response.data;
};

export default {
    fetchCRMRegistrationInfo,
    sendCRMReadingReceiptRequest,
    sendUnknownUserReadingReceipt,
} as const;
