import { call, put, select, takeLatest } from 'redux-saga/effects';
import { sharesActions, sharesTypes } from './index';
import { LoginActionType } from './shares.actions';
import axios from 'axios';
import { loginSelectors } from '../login';
import {
    SharedAccountsResponseType,
    ShareJobsRequestType,
    ShareJobsResultType,
    SendSubAccountEmailType,
} from './shares.types';
import { normalize } from 'normalizr';
import { shareSchema } from '../store.schema';
import { getToken } from '../login/login.saga';
import { notificationActions } from '../notification';
import { splitJoin } from '../../helper/TextHelper';

function* loadShareForAccount({ accountId }: LoginActionType) {
    try {
        const token: string = yield call(getToken, accountId);
        const { data }: { data: SharedAccountsResponseType } = yield axios.get(
            `/api/pro/share/account`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );

        let norm = normalize(data.sharedAccounts, shareSchema);
        console.log('normalizedShares', norm);

        yield put(sharesActions.loadSubAccountDoneForAccount(accountId, data.sharedAccounts));
    } catch (e) {
        console.log('Could not load shares for', accountId, e.message);
    }
}

function* loadAllShares() {
    const loginIds: string[] = yield select(loginSelectors.getLoginIds);

    for (let loginId of loginIds) {
        yield call(loadShareForAccount, { type: undefined, accountId: loginId });
    }
}

function* createSubAccount({
    accountId,
    data,
}: {
    data: ShareJobsRequestType;
    accountId: string;
    type: string;
}) {
    try {
        const token: string = yield getToken(accountId);
        const { data: resultData } = yield axios.post(`/api/pro/share/job`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        yield put(sharesActions.createSubAccountDone(resultData));
    } catch (error) {
        yield put(sharesActions.createSubAccountError(true));

        const { fieldErrors } = error?.response?.data;

        yield put(
            notificationActions.showErrorNotification(
                'Zugang nicht erstellt',
                splitJoin(
                    ': ',
                    'Die Zähler konnten nicht geteilt werden',
                    splitJoin(', ', ...fieldErrors?.map((error: any) => error.message)),
                ) as string,
            ),
        );
    }
}

function* sendSubAccountEmail({ data }: { data: SendSubAccountEmailType; type: string }) {
    const token: string = yield getToken(data.accountId);

    try {
        for (const email of data.mailTo) {
            yield axios.post(
                '/api/communication/email/share/account',
                {
                    payload: {
                        salutation: data.salutation,
                        login: {
                            token: data.token,
                            username: data.username,
                            password: data.password,
                        },
                    },
                    metaData: {
                        mailTo: {
                            name: data.mailToName,
                            email: email,
                        },
                    },
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
        }

        yield put(sharesActions.sendSubAccountEmailsDone(data.mailTo));
    } catch (e) {
        yield put(sharesActions.sendSubAccountEmailsError('E-Mail konnte nicht versandt werden'));
    }
}

function* deleteShare({ accountId, shareId }: any) {
    const token: string = yield getToken(accountId);
    yield axios.delete(`/api/pro/share/account/${shareId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    yield put(sharesActions.deleteShareDone(accountId, shareId));
}

function* deleteJobFromShare({ accountId, shareId, jobId }: any) {
    const token: string = yield getToken(accountId);
    yield axios.delete(`/api/pro/share/account/${shareId}/job/${jobId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    yield put(sharesActions.loadSubAccountsForAccount(accountId));
}

function* createLoginForSubAccount({
    accountId,
    shareId,
}: {
    accountId: string;
    shareId: string;
    type: string;
}) {
    const token: string = yield getToken(accountId);
    try {
        let { data }: { data: ShareJobsResultType } = yield axios.post(
            `/api/pro/share/account/${shareId}/login`,
            undefined,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );

        yield put(sharesActions.createLoginForSubAccountDone(data));
    } catch (e) {
        yield put(
            notificationActions.showErrorNotification(
                'Fehler: Zugang erstellen',
                'Der Zugang konnte nicht erstellt werden. Bitte versuche es später erneut.',
            ),
        );
    }
}

export function* shareSaga() {
    console.log('running share saga');

    yield takeLatest(sharesTypes.CREATE_SUBACCOUNT, createSubAccount);
    yield takeLatest(sharesTypes.SEND_SUBACCOUNT_EMAIL, sendSubAccountEmail);

    yield takeLatest(sharesTypes.LOAD_SUBACCOUNT, loadShareForAccount);
    yield takeLatest(sharesTypes.LOAD_ALL_SUBACCOUNTS, loadAllShares);

    yield put(sharesActions.loadAllSubAccounts());

    yield takeLatest(sharesTypes.DELETE_SHARE, deleteShare);
    yield takeLatest(sharesTypes.DELETE_JOB_FROM_SHARE, deleteJobFromShare);

    yield takeLatest(sharesTypes.CREATE_LOGIN_FOR_SUBACCOUNT, createLoginForSubAccount);
}
