import React, { ImgHTMLAttributes, useState } from 'react';
import './ImageOffline.less';
import Icon from '../Icon/Icon';


type Props = {
    src: string;
    alt?: string;
};

const ImageOffline = (props : ImgHTMLAttributes<any>) => {

    //TODO: Add i18n
    const [error, setError] = useState(false);

    if(error){
        return (
            <div
                className={'text-gray-500 w-full bg-background border p-2 my-2 flex flex-row justify-center space-x-2'}
            >
                <Icon className={'w-12'} path={Icon.Path.mdiImage}/>
                <p className={''}>
                    <i className={'text-sm'}>{props.alt}</i><br/>
                    Dieses Bild wird geladen sobald Ihre Internetverbindung wieder besteht.<br/>
                    <i className={'text-sm italic'}>Bitte prüfen Sie die Internetverbindung</i>
                </p>
            </div>
        );
    }

    return <img {...props} onError={()=>{
        setError(true);
    }} />
};

export default ImageOffline;
