import React, { ReactChild, ReactNode } from 'react';
import './OptionalLabel.less';
import Label from '../Label/Label';
import classNames from 'classnames';

interface OptionalLabelType {
    label?: string | undefined | ReactNode;
    children: string | undefined | ReactNode;
    description?: string | ReactNode;
    className?: string;
    labelClassName?: string;
}

const OptionalLabel = ({
    label,
    children,
    description,
    className,
    labelClassName,
}: OptionalLabelType) => {
    if (!children) return null;
    return (
        <>
            <Label className={classNames(labelClassName)}>{label || ''}</Label>
            <div>
                <div className={className}>{children}</div>
                {description && (
                    <div className={'optional-label-description text-sm'}>{description}</div>
                )}
            </div>
        </>
    );
};

export default OptionalLabel;
