import BasicScreen from '../common/BasicScreen/BasicScreen';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../../components/envago/Card/Card';
import UnknownUserReading from './components/UnknownUserReading/UnknownUserReading';
import { useDispatch, useSelector } from 'react-redux';
import LanguageButton from '../common/LanguageButton/LanguageButton';
import { applicationSelectors } from '../../redux/application';
import unknownUserReadingApi from '../../api/unknownUserReading.api';
import Information, { InformationType } from '../../components/envago/Information/Information';
import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import Loading from '../../components/common/Loading/Loading';
import Button from '../../components/envago/Button/Button';
import { push } from 'connected-react-router';
import Description from './components/Description';
import ContactInformation from '../../components/envago/ContactInformation/ContactInformation';

const UnknownUserReadingScreen = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const isUnknownUserReadingAllowed = useSelector(applicationSelectors.isUnknownUserReadingAllowed);

    const [token, setToken] = useState<string | undefined>(undefined);
    const [isAuthPending, setIsAuthPending] = useState(false);
    const [isAuthError, setIsAuthError] = useState(false);

    const tokenInfo:
        | {
              exp: number;
              [key: string]: any;
          }
        | undefined = useMemo(() => {
        if (!token) return undefined;

        return jwtDecode(token);
    }, [token]);

    const isTokenExpired = useMemo(() => {
        if (!tokenInfo) return true;
        const willExpireAt = tokenInfo.exp;
        return dayjs.unix(willExpireAt).isAfter(dayjs());
    }, [tokenInfo]);

    const authenticate = async () => {
        try {
            setIsAuthPending(true);
            setIsAuthError(false);

            const token = await unknownUserReadingApi.fetchUnknownUserReadingToken();
            
            if (token) {
                setToken(token);
            }
        } catch (err) {
            console.log(err);
            setToken(undefined);
            setIsAuthError(true);
        } finally {
            setIsAuthPending(false);
        }
    };

    useEffect(() => {
        authenticate();
    }, []);

    if (isAuthPending) {
        return (
            <div className={'h-full'}>
                <div className={'flex items-center justify-center'}>
                    <Loading text={t('unknownUserReading.errorHandling.isAuthPending')} />
                </div>
            </div>
        );
    }

    if (isAuthError) {
        return (
            <div className={'h-full items-center flex justify-center '}>
                <Card className={'flex max-w-2xl justify-center flex-col p-4'}>
                    <h3>Die Zählerstandserfassung ist momentan nicht möglich</h3>
                    <Description className={'mt-4'}>
                        Die Erfassung eines Zählerstandes ohne Anmeldung ist momentan leider nicht möglich.
                        <br />
                        Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.
                        {/*    TODO: i18n*/}
                    </Description>
                    <Button
                        className={'mt-8'}
                        primary
                        small={true}
                        onClick={async () => {
                            dispatch(push('/'));
                        }}
                    >
                        {t('unknownUserReading.button.toHome')}
                    </Button>
                </Card>
            </div>
        );
    }

    if (!isTokenExpired) {
        return (
            <div className={'h-full items-center flex justify-center '}>
                <Card className={'flex max-w-2xl justify-center flex-col p-4'}>
                    <h3>Session abgelaufen</h3>
                    <Description className={'mt-4'}>
                        Ihre Session ist abgelaufen. Wenn Sie Ihren Zählerstand erfassen möchten, klicken Sie bitte hier:
                        {/*    TODO: i18n*/}
                    </Description>
                    <Button
                        className={'mt-8'}
                        primary
                        small={true}
                        onClick={async () => {
                            await authenticate();
                        }}
                    >
                        {t('unknownUserReading.button.refreshToken')}
                    </Button>
                </Card>
            </div>
        );
    }

    if (!isUnknownUserReadingAllowed)
        return (
            <div className={'h-full'}>
                <div className={'flex items-center justify-center flex-col'}>
                    <Information className={'my-2'} type={InformationType.ERROR}>
                        {t('unknownUserReading.errorHandling.featureNotAvailable')}
                    </Information>
                    <Button
                        primary
                        onClick={async () => {
                            dispatch(push('/'));
                        }}
                    >
                        {t('unknownUserReading.button.toHome')}
                    </Button>
                </div>
            </div>
        );

    return (
        <>
            <BasicScreen>
                <div className={'my-4'}>
                    <div className={'flex gap-2 items-center justify-between'}>
                        <h1>{t('unknownUserReading.screenTitle')}</h1>
                        <div className={'flex gap-2 items-center'}>
                            <LanguageButton />
                        </div>
                    </div>
                    <Description>{t('unknownUserReading.screenDescription', '')}</Description>
                </div>
                <div className={'unknown-user-reading-main grid grid-cols-1 md:grid-cols-3 gap-2'}>
                    <UnknownUserReading
                        className={'col-span-2'}
                        token={token}
                        onDone={() => {
                            setToken(undefined);
                            authenticate();
                        }}
                    />

                    <div className={'md:col-start-3 hidden md:flex flex-col gap-2'}>
                        <Card>
                            <h2>{t('unknownUserReading.introduction.title','')}</h2>
                            <div className={'opacity-80'}>{t('unknownUserReading.introduction.screenHint', '')}</div>
                            <div className={'my-2'}>
                                <div className={'opacity-80'}>{t('unknownUserReading.introduction.pinReceivedHint', '')}</div>
                                <div className={'flex align-middle justify-center mt-2'}>
                                    <Button
                                        secondary
                                        small
                                        onClick={() => {
                                            dispatch(push('/'));
                                        }}
                                    >
                                        {t('unknownUserReading.button.loginWithPIN')}
                                    </Button>
                                </div>
                            </div>
                        </Card>
                        <ContactInformation wrapper={Card} wrapperProps={{ className: '' }} path={'unknownReading.contact'} />
                        <ContactInformation wrapper={Card} wrapperProps={{ className: '' }} path={'unknownReading.help'} />
                    </div>
                </div>
            </BasicScreen>
        </>
    );
};

export default UnknownUserReadingScreen;
