export default {
    'update': {
        'dialog': {
            'title': 'Neue Version aktivieren',
            'content': 'Es wurde eine neue Version dieser Anwendung geladen, die nun aktiviert werden kann.',
            'hint':  'Durch die Aktivierung wird die Seite neu geladen. Nicht gespeicherte Eingaben gehen hierbei eventuell verloren.',
            'buttonNegative': 'Später',
            'buttonPositive': 'Jetzt aktivieren'
        }
    },
    'login': {
        'title': 'Willkommen bei der Ablesung der {{applicationName}}',
        'subtitle': 'Bitte melden Sie sich mit den Zugangsdaten an, die Sie von uns erhalten haben:',
        'qrcode': {
            'error': {
                'access': {
                    'dialogtitle': 'Kamera Zugriff nicht möglich',
                    'title': 'Leider konnten wir nicht auf Ihre Kamera zugreifen.',
                    'text': 'Bitte melden Sie sich mit Ihren Zugangsdaten an.',
                },
                'device': {
                    'title': 'Die Anmeldung über die Kamera ist leider mit Ihrem Gerät nicht möglich - oder Sie haben uns keinen Zugriff auf die Kamera erlaubt.',
                },
            },
            'teaser': {
                'text': 'Sie haben einen QR-Code auf einer Ablesekarte oder in einem Brief erhalten? Und Sie haben eine Webcam?<1/> Scannen Sie jetzt einfach den QR-Code um schnell und einfach angemeldet zu werden:',
                'access': 'Für die Anmeldung mit QR-Code benötigen wir Zugriff auf Ihre Kamera.',
            },
            'dialog': {
                'instruction': 'Halten Sie den QR-Code von Ihrer Ablesekarte oder Ihrem Brief vor die Kamera:',
            },
            'buttons': {
                'login': 'Mit QR-Code anmelden',
                'close': 'Schließen',
            },
        },
        'labels': {
            'username': 'Benutzername',
            'password': 'Passwort',
            'qr-login': 'QR-Code Anmeldung',
        },
        'placeholders': {
            'username': 'Mein Benutzername',
            'password': 'Passwort',
        },
        'buttons': {
            'login': 'Anmelden',
        },
        'tabs': { // todo: remove when known where to implement unknownUserReading
          'login': 'Anmelden mit Zugangsdaten',
          'unknownUserReading': 'Ablesen ohne Zugangsdaten'
        },
        'error': {
            'login': {
                'text': 'Anmeldung fehlgeschlagen. Bitte prüfen Sie Ihre Anmeldedaten.',
            },
            'dialog': {
                'title': 'Anmeldung nicht möglich',
                'description': 'Tut mir leid, die Anmeldung mit diesen Zugangsdaten ist momentan nicht möglich.',
                'content': ' Wenn Sie eine Ablesekarte erhalten haben, so wurden die Daten der Karte eventuell noch nicht übermittelt.<1 /><2 />Probiere Sie es einfach später nochmal!',
                'buttons': {
                    'close': 'Schließen',
                    'unknownUserReading': 'Ablesung ohne Anmeldung'
                },
                'unknownUserReading': 'Alternativ können Sie hier Ihren Zählerstand ohne Anmeldung übermitteln:',

            },
        },
        'usage': {
            'text': 'Diese Seite wird von {{applicationName}} bereitgestellt. Mit der Anmeldung und Nutzung erklären Sie sich mit den <3>Datenschutzbedingungen</3> einverstanden.',
        },
    },
    'unknownUserReading': {
        'screenTitle': 'Zählerstand mitteilen',

        'loginScreen': {
            'text': 'Sie möchten uns einen Zählerstand mitteilen, haben aber keinen Brief von uns erhalten?',
            'buttonText': 'Ablesung ohne Zugang'
        },
        'doneScreen': {
            'title': 'Ihre Ablesung ist erledigt',
            'message': 'Vielen Dank. Ihr Zählerstand wurde erfolgreich übermittelt.'
        },

        'errorHandling': {
            'isAuthPending':'Sie werden angemeldet, bitte warten.',
            'isAuthError': 'Fehler beim Anmelden. Versuchen Sie es bitte später erneut.',
            'tokenExpired': 'Ihre Sitzung ist abgelaufen, Bitte melden Sie sich erneut an.',
            'featureNotAvailable': 'Das Ablesen ohne Zugangsdaten ist aktuell nicht möglich. Versuchen Sie es bitte später erneut.',
        },
        'featureTitle': 'Zählerstand mitteilen',
        'introduction': {
            // 'title': 'Ihre Zählerablesung',
            'screenHint': 'Sie können uns Ihren Zählerstand auch ohne Anmeldung mitteilen.',
            'pinReceivedHint': 'Wenn Sie Zugangsdaten per Brief oder eine Ablesekarte von unserem Ableser erhalten haben, dann melden Sie sich bitte hier an:'
        },
        'meterStep': {
            'title': 'Zähler auswählen',
            'description': 'Bitte geben Sie zunächst Ihre Zählernummer ein und wählen die Art Ihres Zählers aus.',
            'validationError': 'Bitte geben Sie die Zählernummer vollständig an.'
        },
        'meterReadingStep': {
            'title': 'Ablesung durchführen',
            'description': 'Bitte tragen Sie nun die Zählerstände Ihres Zählers <1>{{preposition}} Nachkommastellen</1> ein.',
            'validationError': 'Bitte geben Sie die Zählerstände vollständig an.'
        },
        'contactStep': {
            'title': 'Kontaktdaten angeben',
            'description': 'Damit wir Ihre Angaben korrekt zuordnen können, benötigen wir Ihre Kontaktdaten und die Adresse des Zählers.',
            'validationError': 'Bitte geben Sie alle Pflichtfelder (*) an.'
        },
        'summaryStep': {
            'title': 'Zusammenfassung',
            'description': 'Bitte prüfen Sie abschließend Ihre Daten'
        },
        'information': {
            'meterNumberHint': 'Für die korrekte Zuordnung Ihrer Ablesung benötigen wir die vollständige Zählernummer.',
            'emailProcessing': 'Hinweis: Die Nutzung der E-Mail-Funktion erfolgt freiwillig. Die E-Mail-Adresse wird ohne Ihre Zustimmung nicht dauerhaft gespeichert.',
            'privacyPolicy': 'Diese Seite wird von {{applicationName}} bereitgestellt. Ich habe die <3>Datenschutzbedingungen</3> gemäß Art. 13 und 14 DS-GVO zur Kenntnis genommen.',
        },
        'notification': {
            'readingRequest': {
                'error': {
                    'title': 'Mitteilung fehlgeschlagen',
                    'description': 'Der Zählerstand konnte nicht übermittelt werden. Versuchen Sie es bitte später erneut.',
                },
                'success': {
                    'title': 'Zählerstand mitgeteilt',
                    'description': 'Der Zählerstand wurde erfolgreich übermittelt.',
                }
            },
            'readingReceipt': {
                'error': {
                    'title': 'Kopie per E-Mail fehlgeschlagen',
                    'description': 'Eine Kopie Ihrer Zählerstände konnte an Ihre angegebene E-Mail nicht versendet werden.',
                },
                'success': {
                    'title': 'Kopie per E-Mail versendet',
                    'description': 'Eine Kopie Ihrer Zählerstände wurde an Ihre angegebene E-Mail versendet.',
                }
            }
        },
        'subTitle': {
            'meterNumber': 'Zählernummer',
            'meterType': 'Zählertyp',
            'meterSubType': 'Untertyp',
            'meterReading': 'Ihre Zählerstände',
            'readingDate': 'Ablesedatum',
            'contact': 'Ihre Kontaktdaten',
            'meterAddress': 'Adresse des Zählers',
            'readingReason': 'Grund der Ablesung',
            'meter': 'Ihr Zähler',
            'readingValues': 'Ablesewerte',
            'email': 'Sie möchten eine Kopie Ihrer Zählerstände per E-Mail?',
            'privacyPolicy': 'Datenschutzerklärung',
        },
        'description': {
            'readingDate': 'Wann haben Sie den Zählerstand erfasst?',
            'dataPrivacyRequired': 'Zum Übermitteln Ihrer Daten, stimmen Sie bitte der Datenschutzerklärung zu.',
        },
        'label': {
            'meterNumber': 'Zählernummer',
            'meterType': 'Zählertyp',
            'meterSubType': 'Untertyp',
            'queryTransformerOrMWhPostDecimals': 'Nachkommastellen für Wandler- oder MWh-Messung erfassen',
            'contact': 'Kontaktdaten',
            'firstname': 'Vorname',
            'lastname': 'Nachname',
            'meterAddress': 'Adresse des Zählers',
            'street': 'Straße',
            'houseNumber': 'Hausnummer',
            'zip': 'Postleitzahl',
            'city': 'Stadt',
            'readingReason': 'Grund der Ablesung',
            'readingDate': 'Ablesedatum',
            'email': 'E-Mail Adresse'
        },
        'placeholder': {
            'meterNumber': 'Zählernummer',
            'firstname': 'Vorname',
            'lastname': 'Nachname',
            'street': 'Straße',
            'houseNumber': 'Hausnummer',
            'zip': 'PLZ',
            'city': 'Stadt',
            'email': 'E-Mail',
        },
        'meterType': {
            'ELECTRIC_METER': 'Stromzähler',
            'GAS_METER': 'Gaszähler',
            'WATER_METER': 'Wasserzähler',
            'WATER_WARM_METER': 'Warmwasserzähler',
            'HEAT_METER': 'Wärmezähler',
        },
        'meterSubType': {
            'ELECTRIC_METER_TARIFFS_ONE': 'Stromzähler (1 Tarif)',
            'ELECTRIC_METER_TARIFFS_TWO': 'Stromzähler (2 Tarife)',
            'ELECTRIC_METER_FEED_IN_METER': 'Stromzähler (Einspeiser)',
            'OTHER': 'anderer'
        },
        'readingReason': {
            'ANNUAL_READING': 'Jahresablesung',
            'CONTROL_READING': 'Kontrollablesung',
            'PROVIDER_CHANGE': 'Lieferantenwechel / Umzug',
        },
        'rateName': {
            'Verbrauch': 'Verbrauch',
            'Einspeisung': 'Einspeisung',
        },
        'button': {
            'refreshToken': 'Erneut anmelden',
            'toHome': 'Zur Startseite zurückkehren',
            'removeMeterRate': 'Tarif entfernen',
            'loginWithPIN': 'mit Zugangsdaten anmelden',
            'continue': 'Weiter',
            'submit': 'Ablesedaten senden'
        },
        'preposition': {
            'with': 'mit',
            'without': 'ohne'
        },
    },
    'home': {
        'title': 'Mein Ableseportal - {{applicationName}}',
        'introduction': {
            'title': 'Willkommen',
            'reading': 'Ihre Zähler finden Sie im Menü <1>$t(menu.reading)</1>. Dort können Sie schnell & einfach Zählerstände eintragen und abschicken.',
            'management': 'Über den Menüpunkt <1>$t(menu.management)</1> können Sie Ablesungen mit anderen teilen. Als Hausverwaltung teilen Sie z.B. Ablesungen mit Ihrem Hausverwalter oder den Hausmeistern.',
        },
        'account': {
            'title': 'Mein Zugang',
            'title_plural': 'Meine Zugänge',
            'multipleAccounts': 'Sie haben sich mit mehreren Zugängen angemeldet:',
            'login': {
                'info': 'Sie sind mit dem Zugang <1>{{name}}</1> angemeldet.',
                'parent': 'Dieser Zugang wurde von <1>{{parent}}</1> für Sie erstellt.',
                'shared': 'von {{parent}} mit Ihnen geteilt',
            },
        },
        'readings': {
            'title': 'Meine Ablesungen',
            'open': 'Sie haben <1>{{jobCountOpen}} offene</1> Ablesung.',
            'open_plural': 'Sie haben <1>{{jobCountOpen}} offene</1> Ablesungen.',
            'done': 'Sie haben alle Ablesungen erledigt!',
            'labels': {
                'totalReadings': 'Ablesungen gesamt',
                'progress': 'Fortschritt (erledigt)',
            },
            'buttons': {
                'openReadings': '$t(common.reading_plural) öffnen',
            },
        },
    },
    'jobs': {
        'search': {
            'noResults': 'Es konnten keine Ergebnisse für die Suche nach <1/> <3>{{searchValue}}</3> <5/> gefunden werden.',
        },
        'labels': {
            'readingsDone': 'Erledigte $t(common.reading_plural)',
        },
        'share': {
            'title': 'Ablesung teilen',
            'placeholders': {
                'name': 'Hausverwaltung Müller',
                'email': 'max@mustermann.de',
            },
            'descriptions': {
                'accessName': 'Legen Sie einen Namen für den neuen Zugang fest. Der Zugangsname wird Ihnen später in der Übersichtsseite Ihrer geteilten Zugänge gemeinsam mit dem jeweiligen Fortschritt der Ablesung angezeigt. Der Name ist für den erstellten Zugang ebenfalls sichtbar.',
                'reshareAllowed': 'Durch Aktivierung erlauben Sie dem Nutzer dieses Zugangs das erneute Teilen seiner Zähler in weitere Unterzugänge. Falls der von Ihnen erstellte Zugang die Zähler direkt abliest, wird die Aktivierung dieser Funktion nicht benötigt.',
            },
            'defaultShareName': 'Zähler {{meterNumber}}',
            'defaultShareName_plural': 'Zähler {{meterNumber}} u.a.',
            'labels': {
                'accessName': 'Zugangsname',
                'additionalOptions': 'Zusätzliche Optionen',
                'reshareAllowed': 'Darf weiterteilen',
                'accessEmail': 'E-Mails vom Zugang',
                'username': 'Benutzername',
                'password': 'Passwort',
                'tokenLogin': 'Direkt-Link',
                'webLogin': 'Webportal-URL',
                'qrcode': 'QR Code',
            },
            'sending': 'Teile Ablesungen...',
            'success': {
                'summary': 'Der Zugang "{{name}}" wurde erfolgreich erstellt und beinhaltet {{count}} Zähler.',
                'message': 'Sie können die nachfolgenden Anmeldedaten für diesen Zugang nun weitergeben.',
                'loginMethod': {
                    'email': {
                        'title': 'Zugang per E-Mail versenden',
                        'description':
                            'Sie können die Anmeldedaten direkt an die E-Mail-Adressen des Zugangs versenden.',
                        'notifications': {
                            'valid': 'Die von Ihnen angegebenen E-Mail-Adressen sind gültig.',
                            'warning': 'Die von Ihnen angegebenen E-Mail-Adressen sind nicht gültig!',
                        },
                        'send' : 'Die Zugangsdaten wurden an {{email}} versendet.'
                    },
                    'triggerText': 'weitere Anmeldeoptionen anzeigen',
                    'direct': {
                        'title': 'Direkt-Anmeldung per Link',
                        'description': 'Der Link zur Direkt-Anmeldung beinhaltet bereits die Zugangsdaten.'
                    },
                    'web': {
                        'title': 'Manuelle Anmeldung per Login',
                        'description': 'Optional können Sie die folgenden Anmeldedaten zur manuellen Anmeldung weitergeben.',
                    },
                },
            },
            'buttons': {
                'createAccess': 'Zugang erstellen',
                'copyTokenUrl': 'Zugangs-URL kopieren',
                'send': 'Senden',
                'close': 'Schließen',
            },
        },
        'details': {
            'title': 'Ableseauftrag',
            'doneHint': '<0>Dieser Zähler wurde bereits abgelesen.</0><1/> Sie können den Zählerstand korrigieren und uns einen neuen Stand schicken.',
            'doneHintLate': '<0>Dieser Zähler wurde bereits abgelesen.</0><1/> Sie können den Zählerstand korrigieren und uns einen neuen Stand schicken.',
            'doneHintOverdue': '<0>Dieser Zähler wurde bereits abgelesen.</0><1/> Sie können den Zählerstand korrigieren und uns einen neuen Stand schicken.',
            'sending': 'Sende Daten...',
            'multiObisWarning': 'Dieser Zähler hat <1 className=\'font-medium\'>{{meterRateCount}} Tarife</1>: Bitte achten Sie auf die Tarif-Bezeichnungen!',
            'send': {
                'confirm': 'Trotzdem senden',
                'cancel': 'Korrigieren',
            },
            'labels': {
                'meterNumber': '$t(meterReadingJob.meterNumber)',
                'meterType': 'Zählerart',
                'customer': '$t(meterReadingJob.customer.title)',
                'customerNumber': '$t(meterReadingJob.customer.customernumber)',
                'deliveryPoint': 'Adresse',
                'readingValue': 'Ablesewert',
                'reading': '$t(common.reading)',
                'readingImage': 'Ablesefoto',
                'partner': 'Vertragspartner',
                'objectKey': 'Objektschlüssel',
                'locationDescription': 'Beschreibung',
                'hint': 'Hinweis',
                'obis': 'Tarif',
                'readingDate': 'Ablesedatum',
                'readingHint': 'Ablesehinweis',
                'targetReadingDate': '$t(common.targetReadingDate)',
            },
            'readingHint': {
                'placeholder': 'Kein Hinweis',
                'meterChanged': 'Zähler gewechselt',
                'freetext': {
                    'label': 'anderer Hinweis',
                    'placeholder': 'Hinweis zur Ablesung...',
                },
            },
            'warnings': {
                'constraints': {
                    'beforeEarliestExecution': {
                        'title': 'Ablesezeitraum hat noch nicht begonnen',
                        'description': 'Der Zählerstand dieser Ablesung wurde ab dem <1>{{earliestExecution}}</1> angefragt.<br/> Sie können die Ablesung durchführen, eventuell wird der tatsächliche Verbrauch jedoch geschätzt.',
                    },
                    'afterLatestExecution': {
                        'title': 'Ablesezeitraum ist überschritten',
                        'description': 'Der Zählerstand dieser Ablesung wurde bis zum <1>{{latestExecution}}</1> angefragt.<br/> Sie können die Ablesung durchführen, eventuell kann der Zählerstand jedoch nicht mehr für die Abrechnung berücksichtigt werden.',
                    },
                    'beforeSendingReadingValuesIsAllowedBasedOnEarliestExecutionAndDuration': {
                        'title': 'Ablesezeitraum hat noch nicht begonnen',
                        'description': 'Der Zählerstand dieser Ablesung kann erst ab dem <1>{{earliestExecution}}</1> übermittelt werden.<br/> Bei Fragen wenden Sie sich bitte an den Kundenservice.'
                    },
                    'afterSendingReadingValuesIsAllowedBasedOnLatestExecutionAndDuration': {
                        'title': 'Ablesezeitraum ist überschritten',
                        'description': 'Der Zählerstand dieser Ablesung kann leider nicht mehr übermittelt werden, da der Ablesezeitraum überschritten ist.<br/> Bei Fragen wenden Sie sich bitte an den Kundenservice.'
                    },
                },
                'check': {
                    'title': 'Bitte Eingabe prüfen',
                    'message': 'Bitte prüfen Sie die eingegebenen Zählerstände nochmal.',
                    'details': 'Bitte achten Sie darauf, ob Sie die richtige Anzahl von Nachkommastellen abgelesen haben und nicht versehentlich mit dem Komma `verrutscht` sind.',
                },
                'incomplete': {
                    'title': 'Unvollständige Werte',
                    'message': 'Ihr Zähler hat mehrere Tarife, Sie haben jedoch nur für einen Teil davon Werte eingetragen.',
                    'details': 'Die Abrechnung mit Ihrem Anbieter kann meist nur korrekt erfolgen, wenn alle Zählerstände vorhanden sind. Fehlen Zählerstände, so werden diese eventuell geschätzt.',
                },
                'invalidText': 'Bitte exakt {{count}} Nachkommastelle angeben.',
                'invalidText_plural': 'Bitte exakt {{count}} Nachkommastellen angeben.',
                'validationWarningText': 'Der Zählerstand ist nicht plausibel, bitte prüfen Sie die Eingabe.',
                'historicValidationWarningText': 'Der Zählerstand ist nicht plausibel, der Wert ist geringer als bei der letzten Ablesung.',
                // fixme: is not processed | 'historicValidationErrorText': 'Der Zählerstand ist nicht plausibel, der Wert ist geringer als bei der letzten Ablesung. Bitte fügen Sie ein Ablesefoto oder einen Ablesehinweis hinzu.',
                'historicValidationFeedInMeterWarningText': 'Der Zählerstand ist nicht plausibel, der Wert ist geringer als bei der letzten Ablesung.',
                'continuousReadingsValidationWarningText': 'Der Zählerstand ist nicht plausibel, der Wert ist geringer als bei der letzten Ablesung.',
                'readingDateWarning': 'Ungültiges Ablesedatum ausgewählt: Bitte wählen Sie ein Ablesedatum zwischen dem {{earliest}} und dem {{latest}} aus. Das Ablesedatum wurde auf den {{readingDate}} zurückgesetzt.',
                'imageRequiredError': 'Der Zählerstand ist nicht plausibel. Bitte fügen Sie ein Ablesefoto hinzu.',
                'imageOrCommentRequiredError': 'Der Zählerstand ist nicht plausibel. Bitte fügen Sie ein Ablesefoto oder einen Ablesehinweis hinzu.',
                // fixme: is not processed | 'imageAndCommentRequiredError': 'Der Zählerstand ist nicht plausibel. Bitte fügen Sie ein Ablesefoto und einen Ablesehinweis hinzu.',
                'imageRequiredFeedInMeterError': 'Der Zählerstand ist nicht plausibel. Bitte fügen Sie ein Ablesefoto hinzu.',
                'imageOrCommentRequiredFeedInMeterError': 'Der Zählerstand ist nicht plausibel. Bitte fügen Sie ein Ablesefoto oder einen Ablesehinweis hinzu.',
                // fixme: is not processed | 'imageAndCommentRequiredFeedInMeterError': 'Der Zählerstand ist nicht plausibel. Bitte fügen Sie ein Ablesefoto und einen Ablesehinweis hinzu.',
                'hintRequiredError': 'Ein Zählerstand ist nicht plausibel. Bitte geben Sie einen Ablesehinweis an.',
                'hintMinLengthWarning': 'Der Ablesehinweis sollte mindestens 5 Zeichen enthalten.',
                'imageAlwaysRequiredHint': 'Bitte fügen Sie ein Foto von Ihrem Zählerstand hinzu, um Ihr Ableseergebnis zu senden.',
                'imageAlwaysRequiredHint_plural': 'Bitte fügen Sie Fotos von Ihren Zählerständen hinzu, um Ihre Ableseergebnisse zu senden.'
            },
            'tooltips': {
                'readingValueGreaterThanMaximumValue' : 'Der Ablesewert ist größer als erwartet.',
                'readingValueLessThanMinimumValue' : 'Der Ablesewert ist kleiner als erwartet.',
            },
            'imageUploaded': 'Ablesefoto hinzugefügt',
            'buttons': {
                'uploadImage': 'Ablesefoto hinzufügen',
                'send': 'Senden',
                'share': 'Teilen',
                'resendMeterReading' : 'Zählerstand erneut mitteilen',
                'resendMeterReading_plural' : 'Zählerstände erneut mitteilen'
            },
        },
        'home': {
            'title': 'Übersicht: Ablesungen',
            'expireWarning': '<0>Achtung</0> am {{expireDate}} laufen {{expireCount}} Aufträge ab, die noch nicht abgelesen wurden!',
            'statistic': {
                'title': '$t(common.reading_plural)',
                'total': 'Gesamt',
                'done': 'Erledigt',
            },
            'period': {
                'title': 'Zeiträume',
                'description': 'Damit die Verbräuche korrekt abgerechnet werden können, müssen die Zählerstände rechtzeitig an uns übermittelt werden. Hier sehen Sie die Zeiträume, bis wann wir Ihren Zählerstand benötigen:',
                'showMeters': 'Zähler anzeigen',
            },
        },
        'unknownMeterReading': {
            'listItemLabel': 'weiteren Zählerstand erfassen',
            'heading': 'Zählerstand für weiteren Zähler erfassen',
            'description': 'Gern können Sie Zählerstände für Zähler erfassen, welche nicht in dieser Ableseliste angefragt wurden. Diese zusätzlichen Stände werden nach der Erfassung direkt an <1>{{applicationName}}</1> weitergeleitet. Bitte beachten Sie, dass diese zusätzlich erfassten Stände nach der Weiterleitung hier nicht mehr angezeigt werden.',
            'notAllowedNotification': {
                'title': 'Nicht erlaubt',
                'message': 'Sie verfügen nicht über die benötigten Rechte für diese Funktion.'
            },
        },
    },
    'evaluation': {
        'hint': 'Wählen Sie im linken Menü einen Zähler aus, um dessen Auswertung anzuzeigen.',
        'labels': {
            'readingsNotDone': 'Nicht erledigte $t(common.reading_plural)',
            'readingsWithoutEvaluation': 'Nicht auswertbar',
        },
        'details': {
            'title': 'Zählerauswertung',
            'labels': {
                'consumptions': 'Verbräuche',
                'history': 'Ablesehistorie',
                'meterAddress': 'Zähleranschrift',
                'consumption': 'Verbrauch',
                'comparisonToPreviousConsumption': 'Vergleich zum letzten Verbrauch',
            },
        },
        'empty': {
            'noJobs': 'Für die Auswertung liegen aktuell nicht genügend Daten vor.',
            'noReadingEventsForMeterRate': 'Für den Tarif {{rateName}} liegen keine Ableseergebnisse vor.',
            'noReadingEventsForMeterRates': 'Für die Tarife {{rateNames}} liegen keine Ableseergebnisse vor.',
            'noEvaluationPossibleWithRateName': 'Es kann keine Verbrauchsauswertung für den Tarif: {{rateName}} durchgeführt werden.',
            'noEvaluationPossibleNoRateName': 'Es kann keine Verbrauchsauswertung für diesen Tarif durchgeführt werden.',
        },
    },
    'management': {
        'hint': 'Wählen Sie im linken Menü einen Zugang aus, um Aufträge zu teilen.',
        'accounts': {
            'title': 'Meine Zugänge',
            'reminder': {
              'subject': 'Erinnerung zur Ablesung',
              'body': 'Sehr geehrte/r {{name}},\n\n\nwir möchten Sie daran erinnern, Ihre Zählerstände im Ableseportal zu erfassen.\n\nUm zu Ihrem Ableseportal zu gelangen klicken Sie bitte auf den folgenden Link:\n\n{{login}}\n'
            },
            'labels': {
                'total': 'Gesamt',
            },
            'list': {
                'by': 'von',
            },
            'subaccounts': {
                'empty': 'Dieser Zugang hat keine Aufträge geteilt.',
                'buttons': {
                    'share': 'Aufträge teilen',
                    'shareShort': 'Teilen'
                },
                'overview': {
                    'title': 'Erstellte Zugänge',
                },
                'details': {
                    'openedByToken': 'Diese Freigabe wurde von Ihnen selbst erstellt. Hier sehen Sie die Aufträge, die darin enthalten sind.',
                    'description': 'Dieser Zugang enthält {{count}} Auftrag',
                    'description_plural': 'Dieser Zugang enthält {{count}} Aufträge',
                    'delete': {
                        'confirm': {
                            'title': 'Freigabe wirklich löschen?',
                            'description': 'Die Freigabe wird gelöscht, Aufträge und Ergebnisse bleiben erhalten.',
                        },
                        'title': 'Ablesung aus Freigabe entfernen?',
                        'description': 'Die Ablesung wird <1>aus der Freigabe</1> entfernt. In Ihren Ablesungen bleibt sie weiterhin enthalten.',
                    },
                    'reshare': {
                        'title': 'Neue Zugangsdaten anlegen',
                        'description': 'Wollen Sie weitere Zugangsdaten anlegen? Der bestehende Zugang bleibt erhalten.',
                    },
                },
            },

            'share': {
                'title': 'Aufträge teilen',
                'task': 'Auftrag',
                'task_plural': 'Aufträge',
                'buttons': {
                    'share': '{{count}} Auftrag teilen',
                    'share_plural': '{{count}} Aufträge teilen',
                },
                'error': {
                  'maxjobs': 'Es können maximal {{count}} Aufträge geteilt werden.'
                },
                'loading': 'Aufträge werden geladen...',
                'labels': {
                    // 'status',
                },
                'empty': 'Sie haben keine Aufträge, die Sie (weiter)teilen dürfen',
                'description': 'Bitte wählen Sie die Aufträge, die geteilt werden sollen.',
                'hint': '<0>Tipp:</0> Klicken Sie auf die Spalten-Namen um die Tabelle zu sortieren. Mit einem Klick auf <3/> können Sie Aufträge gruppieren.',
                'tableColumns': {
                    'isShared': "Geteilt"
                },
                'exportXlsx': {
                    'title': 'Ablesungen als Excel-Datei herunterladen',
                    'description': 'Bei Bedarf können Sie die ausgewählten Ableseaufträge als Excel-Datei herunterladen. Bitte erfassen Sie die Stände trotzdem hier, da ein späterer Excel-Upload zur Übernahme der Zählerstände nicht möglich ist.',
                    'label': 'Datei herunterladen',
                    'button': 'Excel-Datei herunterladen',
                    'headers': {
                        'partnerIdentifier': '$t(meterReadingJob.partner.identifier)',
                        'partnerCompany': '$t(meterReadingJob.partner.company)',
                        'customerNumber': '$t(meterReadingJob.customer.customernumber)',
                        'customerCompany': '$t(meterReadingJob.customer.company) GP',
                        'customerMailingAddressStreet': '$t(meterReadingJob.address.street) GP',
                        'customerMailingAddressHouseNumber': '$t(meterReadingJob.address.houseNumber) GP',
                        'customerMailingAddressZip': '$t(meterReadingJob.address.zip) GP',
                        'customerMailingAddressCity': '$t(meterReadingJob.address.city) GP',
                        'maLoId': '$t(meterReadingJob.maLoId)',
                        'meterAddressStreet': 'Verbrauchsstelle $t(meterReadingJob.address.street)',
                        'meterAddressHouseNumber': 'Verbrauchsstelle $t(meterReadingJob.address.houseNumber)',
                        'meterAddressZip': 'Verbrauchsstelle $t(meterReadingJob.address.zip)',
                        'meterAddressCity': 'Verbrauchsstelle $t(meterReadingJob.address.city)',
                        'meterAddressObjectKey': 'Verbrauchsstelle $t(meterReadingJob.objectKey)',
                        'meterAddressLocationDescription': 'Verbrauchsstelle $t(meterReadingJob.address.locationDescription)',
                        'meterAddressHint': '$t(meterReadingJob.address.hint)',
                        'lastMeterReadingDate': 'letztes Ablesedatum',
                        'lastMeterReadingValue': 'letzter Ablesewert',
                        'meterReadingDate': '$t(meterReadingJob.readingDate)',
                        'meterReadingValue': '$t(meterReadingJob.readingValue)',
                        'meterNumber': '$t(meterReadingJob.meterNumber)',
                        'meterType': '$t(meterReadingJob.meterType)',
                        'rateName': '$t(meterReadingJob.rates)',
                        'obis': '$t(meterReadingJob.obisCode)',
                        'targetReadingDate': '$t(common.targetReadingDate)',
                        'latestExecution': '$t(meterReadingJob.jobConstraints.latestExecution)',
                    },
                    'error' : {
                        'title': 'Excel-Export Fehler',
                        'message': 'Beim Export der Excel Daten ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut.'
                    }
                },
            },
        },
    },
    'help': {
        'global': {
            'contentTitle': 'Ableseportal - Hilfe',
            'tableOfContents': 'Inhaltsverzeichnis',
            'defaultText': 'Die Hilfe ist in der gewählten Sprache nicht verfügbar.',
        },
        'sidePopOver': {
            'title': 'Hilfe zur Ablesung',
            'button': 'Hilfe',
        },
    },
    'communication': {
        'triggerButton': {
            'registration': 'Hier Anmelden',
            'communication': 'Ablesebestätigung zusenden',
        },
        'home': {
            'title': 'Digitale & papierlose Kommunikation',
            'registration': {
                'description': 'Indem Sie auf digitale Kommunikation umstellen, können Sie nicht nur die Umwelt schonen und Zeit sparen, sondern auch von effizienteren Prozessen und besserer Nachverfolgbarkeit profitieren.',
                'descriptionBulletPointsTitle': 'Ihre Vorteile:',
                'descriptionBulletPoints': [
                    'Bestätigung zur erfolgten Ablesung per E-Mail',
                    'Zusammenstellung aller Informationen zu Ihrer Ablesung',
                    'Zukünftig schnelle und unkomplizierte Mitteilungen per E-Mail',
                ],
            },
            'communication': {
                'description': 'Sie sind für die digitale Kommunikation angemeldet und können die Ablesebestätigung per E-Mail anfordern.',
            }
        },
        'jobDetails': {
            'registration': {
                'description': 'Indem Sie auf digitale Kommunikation umstellen, können Sie nicht nur die Umwelt schonen und Zeit sparen, sondern auch von effizienteren Prozessen und besserer Nachverfolgbarkeit profitieren.',
            },
            'communication': {
                'description': 'Sie sind für die digitale Kommunikation angemeldet und können die Ablesebestätigung per E-Mail anfordern.',
            }
        },
        'dialog': {
            'registration': {
                'title': 'Anmeldung zur digitalen & papierlosen Kommunikation',
                'description': 'Um die Ablesebestätigung per E-Mail zu erhalten, melden Sie sich bitte für die digitale Kommunikation an.',
                'content': 'Erhalten Sie Ihre abgelesenen Zählerstände bequem per E-Mail. Ihre Kommunikationseinstellungen können Sie bei Bedarf jederzeit anpassen.',
                'missingURL': 'Die Anmeldung zur digitalen Kommunikation ist aktuell nicht möglich. Bitte versuchen Sie es später erneut.',
                'submitButtonText': 'Anmelden',
                'cancelButtonText': 'Abbrechen',
            },
            'communication': {
                'title': 'Ablesebestätigung per E-Mail erhalten',
                'description': 'Sie sind bereits für die digitale Kommunikation angemeldet. Sie können die Ablesebestätigungen per E-Mail hier anfordern.',
                'content': 'Der Versand der Ablesebestätigung per E-Mail ist für Sie kostenfrei. Sie haben einen Zähler abgelesen. Möchten Sie eine Ablesebestätigung per E-Mail anfordern?',
                'content_plural': 'Der Versand der Ablesebestätigung per E-Mail ist für Sie kostenfrei. Sie haben bereits {{count}} Zähler abgelesen. Möchten Sie eine Ablesebestätigung per E-Mail anfordern?',
                'submitButtonText': 'Ablesebestätigung anfordern',
                'cancelButtonText': 'Abbrechen',
            }
        },
        'notification': {
            'registration': {
                'done': {
                    'title': 'Erfolgreich zur digitalen Kommunikation angemeldet',
                    'description': 'Ab sofort können Sie sich eine Ablesebestätigung per E-Mail zusenden lassen, nachdem Sie einen Zähler abgelesen haben.'
                }
            },
            'communication': {
                'done': {
                    'title': 'Ablesebestätigung erfolgreich angefordert',
                    'description': 'Die Ablesebestätigung für einen Zähler wurde an Sie per E-Mail versandt.',
                    'description_plural': 'Die Ablesebestätigung für {{count}} Zähler wurde an Sie per E-Mail versandt.',
                },
                'error': {
                    'title': 'Anfrage für Ablesebestätigung fehlgeschlagen',
                    'description': 'Der Versand der Ablesebestätigung per E-Mail für einen Zähler konnte nicht ausgeführt werden. Bitte versuchen Sie es später erneut.',
                    'description_plural': 'Der Versand der Ablesebestätigung per E-Mail für {{count}} Zähler konnte nicht ausgeführt werden. Bitte versuchen Sie es später erneut.',
                }
            }
        }
    },
    'meterReadingJob': {
        'type': 'Typ',
        'status': 'Status',
        'meter': 'Zähler',
        'meLoId': 'Messlokation',
        'maLoId': 'Marktlokation',
        'meterNumber': 'Zählernummer',
        'meterType': 'Zählerart',
        'rates': 'Tarife',
        'obisCode': 'OBIS',
        'objectKey': 'Objekt',
        'readingDate': 'Ablesedatum',
        'readingValue': 'Ablesewert',
        'customer': {
            'title': 'Kunde',
            'customernumber': 'Kundennummer',
            'company': 'Firma',
            'name': 'Name',
            'firstname': 'Vorname',
            'lastname': 'Nachname',
        },
        'partner': {
            'title': 'Partner',
            'identifier': 'Partner-Nr.',
            'company': 'Partner-Firma',
            'name': 'Name',
        },
        'jobConstraints': {
            'earliestExecution': 'Ablesung ab',
            'latestExecution': 'Ablesefrist',
        },
        'address': {
            'title': 'Adresse',
            'fullAddress': 'Zähleranschrift',
            'street': 'Straße',
            'houseNumber': 'Hausnummer',
            'zip': 'PLZ',
            'city': 'Stadt',
            'cityDistrict': 'Stadtteil',
            'hint': 'Ablesehinweis',
            'locationDescription': 'Beschreibung',
        },
        'aggregation': {
            'street': '{{count}} Straße',
            'street_plural': '{{count}} Straßen',
            'zip': '{{count}} PLZ',
            'zip_plural': '{{count}} PLZ',
            'city': '{{count}} Stadt',
            'city_plural': '{{count}} Städte',
            'cityDistrict': '{{count}} Stadtteil',
            'cityDistrict_plural': '{{count}} Stadtteile',
            'customernumber': '{{count}} KdNr',
            'customernumber_plural': '{{count}} Kdnr',
            'objectKey': '{{count}} Objekt',
            'objectKey_plural': '{{count}} Objekte',
            'meter': '{{count}} Zähler',
            'meter_plural': '{{count}} Zähler',
            'latestExecution': '{{count}} Ablesefrist',
            'latestExecution_plural': '{{count}} Ablesefristen',
        },
    },
    'offline': {
        'warning': 'Bitte schließen Sie Ihren Browser und diesen Tab nicht!',
        'description' : 'Ihre Ablesung kann momentan nicht gesendet werden. Sobald eine Verbindung besteht, werden die Aufträge nachträglich gesendet.',
        'details': 'Sie können weitere Ablesungen durchführen. Sobald Ihr Gerät wieder über eine Internetverbindung verfügt, werden alle Ablesungen gesendet.\n\n\nNoch nicht gesendete Ablesungen werden in einem Statusfenster angezeigt.',
        'infopanel' : {
            'message' : '{{queueLength}} Ablesung wurden noch nicht gesendet',
            'message_plural' : '{{queueLength}} Ablesungen wurden noch nicht gesendet',
            'details' : 'Die Aufträge werden gesendet, sobald eine Internetverbindung besteht.'
        },
        'buttons' : {
            'close': 'Hinweis ausblenden',
            'retry': 'Erneut versuchen'
        }
    },
    'menu': {
        'home': 'Startseite',
        'reading': 'Ablesung',
        'evaluation': 'Auswertung',
        'management': 'Verwaltung',
        'help': 'Hilfe',
        'accounts': 'Meine Zugänge',
        'logout': 'Abmelden',
    },
    'common': {
        'metertypes': {
            'ELECTRIC_METER': 'Stromzähler',
            'HEAT_METER': 'Wärmemengenzähler',
            'GAS_METER': 'Gaszähler',
            'WATER_METER': 'Wasserzähler',
            'WATER_WARM_METER': 'Warmwasserzähler',
            'GENERIC_METER': 'allg. Zähler',
        },
        'reasons': {
            'INITIAL_READING': 'Einbauzählerstand',
            'PERIODIC_READING': 'Turnusablesung',
            'ESTIMATED_READING': 'Schätzung',
            'INTERMEDIATE_READING': 'Zwischenablesung',
            'METER_CHANGE': 'Zählerwechsel',
            'OTHER': 'andere Ablesung',
            'OTHER_KSA': 'Kundenselbstablesung',
            'null': 'Unbekannter Grund'
        },
        'jobs': '{{count}} Auftrag',
        'jobs_plural': '{{count}} Aufträge',
        'reading': 'Ablesung',
        'reading_plural': 'Ablesungen',
        'progress': 'Fortschritt',
        'targetReadingDate': 'Zielablesedatum',
        'loadingReadings': 'Lade Ablesungen...',
        'loadingJobs': 'Lade Aufträge...',
        'search': 'Suche',
        'total': 'Gesamt',
        'until': 'bis',
        'of': 'von',
        'loading': 'wird geladen...',
        'dataPrivacy': 'Datenschutzbestimmungen',
        'imprint': 'Impressum',
        'dialog': {
            'ok': 'OK',
            'abort': 'Abbrechen',
        },
        'requiredField': 'Pflichtfeld',
        'fillRequiredFields': 'Bitte Pflichtfelder (*) angeben.',
        'account': {
            'noName': 'Mein Zugang',
        },
        'day': 'Tag'
    },
};
