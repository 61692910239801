import { call, put, select } from 'redux-saga/effects';
import axios from 'axios';
import { contactActions } from './index';
import {applicationSelectors} from '../application';

function* loadContactSaga() {
    const applicationId: string = yield select(applicationSelectors.getApplicationId);

    try {
        const { data } = yield axios.get(`/api/public/contact/${applicationId}`);
        yield put(contactActions.loadContactInformationDone(data.contactInformationItems));
    } catch (e) {
        yield put(contactActions.loadContactInformationError())
    }
}

export default function* contactSaga() {
    yield call(loadContactSaga)
}