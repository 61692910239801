import React, { ReactChild, ReactNode } from 'react';
import './ListItem.less';
import classNames from 'classnames';
import EnergyIcon from '../EnergyIcon/EnergyIcon';
import { splitJoin } from '../../../helper/TextHelper';
import Icon from '../Icon/Icon';
import Card from '../Card/Card';

const ListItem = ({
                      className,
                      children,
                      active = false,
                      rightIcon,
                      subText,
                      onClick,
                  }: {
    className?: string,
    children: ReactChild | ReactChild[] | string | ReactNode,
    active?: boolean,
    rightIcon?: ReactChild | ReactChild[] | string,
    subText?: string,
    onClick?: () => (void),
}) => {


    return (
        <Card
            onClick={onClick}
            className={classNames(`job-list-item bg-white flex items-center cursor-pointer hover:bg-white transition`, {
                'card--active': active,
                'hover:shadow': !active,
            })}>
            <div className={'flex-1'}>
                <div>
                    {children}
                </div>
                {subText && (
                    <div className={'text-sm text-gray-400'}>
                        {subText}
                    </div>
                )}
            </div>
            <div className={'justify-self-end mr-2'}>
                {rightIcon}
            </div>
        </Card>
    );
};

export default ListItem;
