import { put, select, spawn, takeLatest } from 'redux-saga/effects';
import { languageConfigType } from './language.types';
import { languageActions, languageSelectors, languageTypes } from '../language';
import i18n from 'i18next';
import { deepReplaceKeys } from '../../helper/DataHelper';

function* setDefaultLanguageSaga() {
    const languageConfig: languageConfigType = yield select(languageSelectors.getLanguageConfig);
    yield put(languageActions.changeLanguage(languageConfig.defaultLanguage));
}

function* mergeCustomTranslationsSaga() {
    const languageConfig: languageConfigType = yield select(languageSelectors.getLanguageConfig);

    let merged = false;
    for (const value in languageConfig.translations) {
        const translations = deepReplaceKeys(languageConfig.translations[value], '.');

        i18n.addResourceBundle(value, 'envago', translations, true, true);
        merged = i18n.hasResourceBundle(value, 'envago');
    }
    yield put(languageActions.mergeTranslationsDone(merged));
}

function* changeLanguageSaga({ payload, type }: { payload: string; type: string }) {
    yield i18n.changeLanguage(payload);
    yield put(languageActions.changeLanguageDone(payload));
}

export default function* languageSaga() {
    yield takeLatest(languageTypes.CHANGE_LANGUAGE, changeLanguageSaga);
    yield spawn(setDefaultLanguageSaga);
    yield spawn(mergeCustomTranslationsSaga);
}
