import React, { useMemo } from 'react';
import './EvaluatedMeterDetails.less';
import { useSelector } from 'react-redux';
import { jobsSelectors } from '../../../../redux/jobs';
import BasicScreen from '../../../common/BasicScreen/BasicScreen';
import Toolbar from '../../../../components/envago/Toolbar/Toolbar';
import { useTranslation } from 'react-i18next';
import Card from '../../../../components/envago/Card/Card';
import LabeledList from '../../../../components/envago/LabeledList/LabeledList';
import OptionalLabel from '../../../../components/envago/OptionalLabel/OptionalLabel';
import MeterMeta from '../../../../components/envago/MeterMeta';
import MeterReadingEventsHistory from '../MeterReadingEventsHistory';
import { resolveReadableRateNameWithObis } from '../../../../helper/MeterHelper';
import { splitJoin } from '../../../../helper/TextHelper';
import classNames from 'classnames';

interface IEvaluatedMeterDetailsProps {
    jobId: string;
}

const EvaluatedMeterDetails = ({ jobId }: IEvaluatedMeterDetailsProps) => {
    const { t } = useTranslation();
    const job = useSelector(jobsSelectors.getJobById(jobId));

    const emptyRates = useMemo(() => {
        return job.meter.meterRateTasks.reduce((acc, curr) => {
            if (
                (!curr.readingResults || curr.readingResults.length === 0) &&
                (!curr.readingEvents || curr.readingEvents.length === 0)
            ) {
                acc.push(resolveReadableRateNameWithObis(curr));
                return acc;
            }

            return acc;
        }, [] as string[]);
    }, [job]);

    if (!job) return null;

    return (
        <BasicScreen key={job.id}>
            <Toolbar showBackButton={true} title={t('evaluation.details.title')} />
            <Card className={'evaluated-meter-details'}>
                <MeterMeta
                    meter={job.meter}
                    className={'mb-2 pb-2 border-b-2 border-gray-100 border-solid'}
                />
                <LabeledList className={'mb-2 pb-2 border-b-2 border-gray-100 border-solid'}>
                    <OptionalLabel
                        label={t('evaluation.details.labels.history')}
                        labelClassName={'self-start'}
                    >
                        {job.meter.meterRateTasks.map((task, index) => (
                            <MeterReadingEventsHistory
                                key={index}
                                task={task}
                                defaultOpen={job.meter.meterRateTasks.length === 1}
                            />
                        ))}
                        {emptyRates.length > 0 && (
                            <div
                                className={classNames('', {
                                    'text-gray-400 text-sm font-medium mt-2':
                                        job.meter.meterRateTasks.length > emptyRates.length,
                                })}
                            >
                                {emptyRates.length > 1
                                    ? t('evaluation.empty.noReadingEventsForMeterRates', {
                                          rateNames: splitJoin(', ', ...emptyRates),
                                      })
                                    : t('evaluation.empty.noReadingEventsForMeterRate', {
                                          rateName: splitJoin('', ...emptyRates),
                                      })}
                            </div>
                        )}
                    </OptionalLabel>
                </LabeledList>
            </Card>
        </BasicScreen>
    );
};

export default EvaluatedMeterDetails;
