import React, { useState } from 'react';
import './ClipboardText.less';
import Icon from '../Icon/Icon';
import classNames from 'classnames';

interface ClipboardTextProps {
    text: string;
    showText?: string;
    onCopiedToClipboard?: () => void;
}

const ClipboardText = ({ text, showText, onCopiedToClipboard }: ClipboardTextProps) => {
    const [isCopied, setCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(text);
        setCopied(true);

        if (onCopiedToClipboard) {
            onCopiedToClipboard();
        }

        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    return (
        <span className="clipboard-text inline-flex items-center cursor-pointer max-w-full" onClick={copyToClipboard}>
            <span className="flex-1 truncate font-light text-base">{showText || text}</span>
            <Icon
                className={classNames('ml-1 h-4 flex-none', {
                    'text-gray-400': !isCopied,
                    'text-primary': isCopied,
                })}
                path={isCopied ? Icon.Path.mdiCheck : Icon.Path.mdiClipboardOutline}
            />
        </span>
    );
};

export default ClipboardText;
