import React, { useEffect, useState } from 'react';
import './MarkdownNavigationSidebar.less';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type Props = {
    text: string;
    className?: string;
};

type HeadingType = {
    depth: number;
    content: string;
    hash: string;
};

const MenuEntry = ({ entry }: { entry: HeadingType }) => {
    return (
        <div
            className={`help-menu-entry help-menu-entry-${entry.depth}`}
            style={{
                marginLeft: (entry.depth - 1) * 10,
            }}
        >
            <a href={entry.hash}>{entry.content}</a>
        </div>
    );
};

const MarkdownNavigationSidebar = ({ text, className }: Props) => {
    const [headings, setHeadings] = useState<HeadingType[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        let matches = Array.from(text.matchAll(/(#+) (.*)$/gm));
        const headings = matches.map((group) => {
            return {
                depth: group[1].length,
                content: group[2],
                hash:
                    '#' +
                    group[2]
                        .trim()
                        .replace(/\s/g, '-')
                        .toLowerCase()
                        .replaceAll("ä", 'a')
                        .replaceAll("ü", 'u')
                        .replaceAll("ö", 'o')
                        .replaceAll("ß", 'ss')
                        .replaceAll(/[^A-Za-z0-9-]/g, '')
                ,
            } as HeadingType;
        });
        setHeadings(headings.filter(entry => entry.depth < 3));
    }, [text]);

    if (!text) {
        return null;
    }

    return (
        <aside className={classNames('markdown-navigation-sidebar', className)}>
            <h3>{t('help.global.tableOfContents')}</h3>
            <div className={'mt-4 px-2 py-2 shadow-md text-sm bg-white leading-loose fixed top-28 bottom-4 overflow-y-auto'}>
                {headings.map((h, index) => {
                    return <MenuEntry key={`${h.hash}-${index}`} entry={h} />;
                })}
            </div>
        </aside>
    );
};

export default MarkdownNavigationSidebar;
