import React, { ReactChild, ReactChildren, ReactNode } from 'react';
import './Button.less';
import classNames from 'classnames';
import Loader from 'react-loader-spinner';

export interface ButtonProps {
    children: string | ReactChildren | ReactChild | ReactChild[] | ReactNode;
    primary?: boolean;
    secondary?: boolean;
    accent?: boolean;
    onClick?: () => void;
    block?: boolean;
    className?: string;
    disabled?: boolean;
    small?: boolean;
    ghost?: boolean;
    iconButton?: boolean;
    isLoading?: boolean;
}

const Button = ({
    children,
    primary,
    secondary,
    accent,
    ghost,
    onClick,
    block = false,
    className,
    disabled = false,
    small = false,
    iconButton = false,
    isLoading = false,
}: ButtonProps) => {
    return (
        <button
            onClick={() => {
                if (isLoading) return;

                onClick && onClick();
            }}
            disabled={disabled}
            className={classNames('button flex justify-center', className, {
                'button--primary': !disabled && primary,
                'button--secondary': !disabled && secondary,
                'button--accent': !disabled && accent,
                block: block,
                'w-full': block,
                'button--disabled': disabled,
                'button--small': small && !iconButton,
                'button--ghost': !disabled && ghost,
                'button--icon': iconButton,
            })}
        >
            <div className={'button-text-wrapper flex items-center'}>
                {isLoading && (
                    <div className={'mr-2'}>
                        <Loader color={'#999'} type="TailSpin" height={18} width={18} />
                    </div>
                )}
                {children}
            </div>
        </button>
    );
};

export default Button;
