import { takeLatest } from 'redux-saga/effects';
import { notificationTypes } from './index';

function* scrollToTop() {
    try {
        yield window.scrollTo(0, 0);
    } catch (err) {
        console.log('Error in scrollToTop saga: ', err);
    }
}

export default function* notificationSaga() {
    yield takeLatest(notificationTypes.SCROLL_TO_TOP, scrollToTop);
}
