import React, { createRef, ReactNode, useEffect, useState } from 'react';
import './UploadImage.less';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import { useTranslation } from 'react-i18next';

function imageToDataUri(base64: string, targetWidth: number, targetHeight: number) {
    return new Promise((resolve, reject) => {
        let img = new Image();
        // img.src = window.URL.createObjectURL(file);

        img.onload = () => {
            const canvas = document.createElement('canvas');
            const MAX_WIDTH = targetWidth;
            const MAX_HEIGHT = targetHeight;

            // console.log('image', img);

            let width = img.width || targetWidth;
            let height = img.height || targetHeight;

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }

            // console.log('targeting w/h', width, height);

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            // console.log('canvas', canvas, 'ctx', ctx);
            if (ctx) {
                ctx.drawImage(img, 0, 0, width, height);

                const res = canvas.toDataURL('image/jpeg', 0.75);
                // console.log('Result - Transofrmation done', res.length);

                return resolve(res);
            } else {
                // return base64;
                // return reject();
                return resolve(base64);
            }
        };

        img.src = base64;

        // document.getElementById('images')?.appendChild(img);
    });
}

const ImageListItem = ({ image }: { image: string }) => {
    const { t, i18n } = useTranslation();
    return (
        <div className={'border rounded flex items-center px-2 py-1 mt-1 bg-gray-100 text-gray-500 text-sm'}>
            <div>
                <img src={image} className={'h-8 w-8 mr-2 object-contain'} />
            </div>
            <div className={'flex-1 leading-none'}>
                {t('jobs.details.imageUploaded')}
                <br />
                <small>{(image.length / 1024).toFixed(0)} kB</small>
            </div>
            <div className={'w-6  text-green-500'}>
                <Icon path={Icon.Path.mdiCheck} />
            </div>

            {/*<div  className={'w-7 h-7 text-red-500 border-red-400 rounded border p-1 mx-2'}>*/}
            {/*    <Icon  path={Icon.Path.mdiDelete}/>*/}
            {/*</div>*/}
        </div>
    );
};

const UploadImage = ({
    onChange,
    text = 'Ablesefoto hinzufügen',
    primary = false,
}: {
    onChange?: (images: string[]) => void;
    text?: string | ReactNode;
    primary?: boolean;
}) => {
    const inputRef = createRef<HTMLInputElement>();

    // const [imgData, setImgData] = useState<string | null>(null);
    const [imageDataArray, setImageDataArray] = useState<string[]>([]);

    useEffect(() => {
        if (onChange) {
            onChange(imageDataArray);
        }
    }, [imageDataArray]);

    return (
        <div className="upload-image">
            <label>
                <input
                    ref={inputRef}
                    disabled={imageDataArray.length >= 3}
                    onChange={(e) => {
                        const files = e.currentTarget.files;
                        console.log('Changed', files);
                        const reader = new FileReader();

                        const file = files?.item(0);
                        if (file) {
                            reader.onload = () => {
                                imageToDataUri(reader.result as string, 1600, 1600)
                                    .then((res) => {
                                        setImageDataArray([...imageDataArray, res as string]);
                                    })
                                    .catch((e) => {
                                        console.log('error!');
                                    });
                            };
                            reader.readAsDataURL(file);
                        }
                    }}
                    type={'file'}
                    accept={'image/jpeg'}
                    capture={'environment'}
                    style={{ display: 'none' }}
                />
                <Button
                    primary={primary}
                    ghost={!primary}
                    disabled={imageDataArray.length >= 3}
                    block={true}
                    onClick={() => {
                        inputRef.current?.click();
                    }}
                >
                    <Icon path={Icon.Path.mdiCamera} /> {text}
                </Button>
            </label>

            {imageDataArray.map((image, index) => {
                return (
                    <div key={`image-${index}`}>
                        <ImageListItem image={image} />
                    </div>
                );
            })}
        </div>
    );
};

export default UploadImage;
