import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './HelpScreen.less';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import MarkdownNavigationSidebar from './components/MarkdownNavigationSidebar/MarkdownNavigationSidebar';
import MarkdownHelpContent from './components/MarkdownHelpContent/MarkdownHelpContent';
import MarkdownViewer from '../../components/envago/MarkdownViewer/MarkdownViewer';
import BasicScreen from '../common/BasicScreen/BasicScreen';
import { applicationSelectors } from '../../redux/application';
import { helpSelectors } from '../../redux/help';
import { languageSelectors } from '../../redux/language';

const HelpScreen = () => {
    const applicationId = useSelector(applicationSelectors.getApplicationId);
    const isGlobalHelpEnabled = useSelector(helpSelectors.isGlobalHelpEnabled);
    const currentLanguage = useSelector(languageSelectors.getCurrentLanguage);
    const [markdownText, setMarkdownText] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        axios
            .get(`/api/public/help/${applicationId}/${currentLanguage}`)
            .then((res) => {
                setMarkdownText(res.data.markdown);
            })
            .catch((error) => {
                console.log('error in axios get supportedLanguages: ', error);
                setMarkdownText('');
            });
    }, [applicationId, currentLanguage]);

    if (!isGlobalHelpEnabled) return null;

    return (
        <BasicScreen>
            <div className={'flex gap-4'}>
                <div className={'hidden md:block w-3/12 flex-initial'}>
                    <MarkdownNavigationSidebar
                        className={'fixed top-20 w-3/12 lg:w-64 xl:w-72 2xl:w-80'}
                        text={markdownText === '' ? '' : markdownText}
                    />
                </div>
               <div className={'flex-1 w-9/12 max-w-lg'}>
                   <MarkdownHelpContent
                       className={'h-full mx-4'}
                       children={
                           <MarkdownViewer
                               text={markdownText === '' ? t('help.global.defaultText') : markdownText}
                           />
                       }
                   />
               </div>
            </div>
        </BasicScreen>
    );
};

export default HelpScreen;
