import { AnyAction } from 'redux';
import { contactStateType } from './contact.types';
import { contactTypes } from './index';

const initialState: contactStateType = {
    contactInformationItems: undefined,
};

const reducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case contactTypes.LOAD_CONTACT_INFORMATION_DONE: {
            const { payload } = action;
            return {
                ...state,
                contactInformationItems: payload,
            };
        }
        default:
            return state;
    }
};

export default reducer;
