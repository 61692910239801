import React, { ChangeEvent, useState } from 'react';
import './MobileDatePicker.less';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Information, { InformationType } from '../Information/Information';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../helper/DateHelper';

interface Props {
    className?: string;
    value: Date;
    onChange: (date: Date) => void;
    minDate: Date;
    maxDate: Date;
}

const MobileDatePicker = ({ className, value, onChange, minDate, maxDate }: Props) => {
    const [isValid, setIsValid] = useState(true);
    const { t } = useTranslation();

    const val = dayjs(value).format('YYYY-MM-DD');
    const min = dayjs(minDate).format('YYYY-MM-DD');
    const max = dayjs(maxDate).format('YYYY-MM-DD');

    const validateDateInput = (min: string, max: string, value: string): boolean => {
        return (
            dayjs(value).toDate() <= dayjs(max).toDate() &&
            dayjs(value).toDate() >= dayjs(min).toDate()
        );
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setIsValid(validateDateInput(min, max, e.target.value));
        return validateDateInput(min, max, e.target.value)
            ? onChange(dayjs(e.target.value).toDate())
            : onChange(dayjs().toDate());
    };

    return (
        <>
            <div className={classNames('mobile-date-picker flex justify-center', className)}>
                <input
                    className={'flex-1 text-black rounded-md p-2 mb-2 h-10 bg-gray-100'}
                    type="date"
                    min={min}
                    max={max}
                    value={val}
                    onChange={handleChange}
                />
            </div>

            {!isValid && (
                <Information type={InformationType.WARNING}>
                    {t('jobs.details.warnings.readingDateWarning', {
                        earliest: formatDate(min),
                        latest: formatDate(max),
                        readingDate: formatDate(max),
                    })}
                </Information>
            )}
        </>
    );
};

export default MobileDatePicker;
